<!-- /**  消耗订单内容 */ --> 
<template>
  <section class="treatBillDetailContent">
    <div>
      <!-- 订单信息 -->
      <div style="margin-right: 10px">
        <div class="tip" style="margin-top: 0">订单信息</div>
        <el-form label-width="100px" class="treatInfoClass" size="small">
          <el-row>
            <el-col :span="8">
              <el-form-item label="订单编号:">{{
                treatInfo.ID
              }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单类型:">{{
                treatInfo.BillType == "10" ? "消耗单" : "消耗退单"
              }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单状态:">{{ treatInfo.BillStatus == "20" ? "已完成" : "已取消" }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="顾客信息:">{{ treatInfo.Name }} <span v-if="treatInfo.PhoneNumber != null">({{ treatInfo.PhoneNumber }})</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="操作人:">{{
                treatInfo.EmployeeName
              }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="消耗金额:">￥{{ treatInfo.Amount | NumFormat }}</el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="下单时间:">{{
                treatInfo.BillDate
              }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="录单时间:">{{
                treatInfo.CreatedOn
              }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单备注:">
                <span v-if="treatInfo.Remark" class="marrt_5">{{ treatInfo.Remark }}
                </span>
                <el-button type="text" @click="upRemarkDialog">
                  修改备注</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="treatInfo.BillStatus=='30'">
            <el-col :span="8">
              <el-form-item label="取消时间:">{{treatInfo.CancelCreatedOn }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="取消人:">{{treatInfo.CancelCreatedBy }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="取消原因:">
                <span v-if="treatInfo.CancelRemark" class="marrt_5">{{treatInfo.CancelRemark }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>

          </el-row>
          <el-row> </el-row>
        </el-form>
      </div>
      <div class="martp_10">
        <div class="tip">消耗信息</div>
        <!-- 项目 -->
        <div v-if="
            treatInfo.Project != undefined && treatInfo.Project.length > 0
          ">
          <el-row class="tipback_col pad_10">
            <el-col :span="7">项目</el-col>
            <el-col :span="4">数量</el-col>
            <el-col v-show="treatInfo.BillType == '10'" :span="6">优惠金额</el-col>
            <el-col :span="7">小计</el-col>
          </el-row>
          <el-row v-for="(item, index) in treatInfo.Project" :key="index + 'x1'" class="text_left border_right border_left">
            <el-col :span="24" class="pad_10 border_bottom">
              <el-col :span="7">
                <div>
                  {{ item.ProjectName }}
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                </div>
                <div class="color_red martp_5 font_12">
                  ￥{{ item.Price | NumFormat}}
                </div>
              </el-col>
              <el-col :span="4">x {{ item.Quantity }}</el-col>
              <el-col :span="6" v-show="treatInfo.BillType == '10'">
                <div>
                  <span v-if="item.CardPreferentialAmount < 0">+ ¥ {{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                  <span v-else-if="item.CardPreferentialAmount > 0">- ¥ {{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                  <span v-else>¥ 0.00</span>
                </div>
              </el-col>
              <el-col :span="7">
                <div>¥ {{ item.TotalAmount | NumFormat }}</div>
                <div class="dis_flex">
                  <div class="color_green font_12 marrt_10" v-if="item.Amount ">非赠送金额：<span class="">{{item.Amount  | NumFormat}}</span></div>
                  <div class="color_red font_12 " v-if="item.LargessAmount">赠送金额：<span class="">{{item.LargessAmount  | NumFormat}}</span></div>
                </div>
              </el-col>
            </el-col>
            <el-col :span="24" v-if="item.TreatBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
              <el-row v-for="(handler, pIndex) in item.TreatBillHandler" :key="pIndex + 'h5'">
                <el-col :span="2">
                  <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini" label-position="left">
                    <el-form-item :label="`${handler.TreatHandlerName}`"></el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="22">
                  <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini">
                    <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <!-- 储值卡 -->
        <div v-if="
            treatInfo.SavingCard != undefined &&
            treatInfo.SavingCard.length > 0
          ">
          <el-row class="tipback_col pad_10">
            <el-col :span="7">储值卡</el-col>
            <el-col :span="4">数量</el-col>
            <el-col :span="6" v-show="treatInfo.BillType == '10'">优惠金额</el-col>
            <el-col :span="7">小计</el-col>
          </el-row>
          <el-row v-for="(item, index) in treatInfo.SavingCard" :key="index + 'x4'" class="border_right item border_left text_left">
            <el-row style="background-color: #f5f7fa" class="pad_10 border_top border_bottom">
              <div>
                {{ item.SavingCardName }} <span v-if="item.Alias">({{ item.Alias }})</span>
              </div>
            </el-row>
            <el-row v-for="(childItem, childIndex) in item.Project" :key="childIndex + 'c3'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="7">
                    <div>
                      {{ childItem.ProjectName }} <span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                    </div>
                  </el-col>
                  <el-col :span="4">x {{ childItem.Quantity }}</el-col>
                  <el-col :span="6" v-show="treatInfo.BillType == '10'">
                    <span v-if="(childItem.PricePreferentialAmount + childItem.CardPreferentialAmount)<0">+ ¥ {{Math.abs(childItem.PricePreferentialAmount + childItem.CardPreferentialAmount) | NumFormat}}</span>
                    <span v-else-if="(childItem.PricePreferentialAmount + childItem.CardPreferentialAmount)>0">- ¥ {{Math.abs(childItem.PricePreferentialAmount + childItem.CardPreferentialAmount) | NumFormat}}</span>
                    <span v-else>¥ 0.00</span>
                  </el-col>
                  <el-col :span="7">¥ {{ childItem.TotalAmount | NumFormat }}</el-col>
                </el-col>
                <el-col :span="24" class="martp_5">
                  <el-col :span="11">
                    <div class="color_red font_12">
                      ¥ {{ childItem.Price | NumFormat }}
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div v-if="childItem.PricePreferentialAmount == 0 && childItem.CardPreferentialAmount <=0" style="opacity: 0;">1</div>
                    <span class="color_gray font_12" v-if="childItem.PricePreferentialAmount != 0">
                      手动改价：
                      <span class="color_red" v-if="childItem.PricePreferentialAmount > 0">- ¥
                        {{
                          childItem.PricePreferentialAmount | NumFormat
                        }}</span>
                      <span class="color_green" v-else>+ ¥
                        {{
                          Math.abs(childItem.PricePreferentialAmount)
                            | NumFormat
                        }}</span>
                    </span>
                    <span class="color_gray font_12" :class="
                        childItem.PricePreferentialAmount != 0
                          ? 'marlt_15'
                          : ''
                      " v-if="childItem.CardPreferentialAmount > 0">
                      卡优惠：
                      <span class="color_red">- ¥
                        {{
                          parseFloat(childItem.CardPreferentialAmount)
                            | NumFormat
                        }}</span>
                    </span>
                  </el-col>
                  <el-col :span="7">
                    <div class="dis_flex">
                      <div class="color_green font_12 marrt_10" v-if="childItem.Amount ">非赠送金额：<span class="">{{childItem.Amount  | NumFormat}}</span></div>
                      <div class="color_red font_12 " v-if="childItem.LargessAmount">赠送金额：<span class="">{{childItem.LargessAmount  | NumFormat}}</span></div>
                    </div>
                  </el-col>
                </el-col>
              </el-col>
              <el-col :span="24" v-if="childItem.TreatBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler, pIndex) in childItem.TreatBillHandler" :key="pIndex + 'h5'">
                  <el-col :span="2">
                    <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.TreatHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                      </el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-row>
        </div>
        <!-- 时效卡 -->
        <div v-if="
            treatInfo.TimeCard != undefined && treatInfo.TimeCard.length > 0
          ">
          <el-row class="tipback_col pad_10">
            <el-col :span="6">时效卡</el-col>
            <el-col :span="2">数量</el-col>
            <el-col :span="4">消耗时体重</el-col>
            <el-col :span="4">本次减重</el-col>
            <el-col :span="4" v-show="treatInfo.BillType == '10'">优惠金额</el-col>
            <el-col :span="4">小计</el-col>
          </el-row>
          <el-row v-for="(item, index) in treatInfo.TimeCard" :key="index + 'x4'" class="border_right item border_left">
            <el-row style="background-color: #f5f7fa" class="pad_10 border_top border_bottom">
              <div>
                {{ item.TimeCardName
                }} <span v-if="item.Alias">({{ item.Alias }})</span>
                <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
              </div>
            </el-row>
            <el-row v-for="(childItem, childIndex) in item.Project" :key="childIndex + 'c3'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="6">
                  <div>
                    {{ childItem.ProjectName }}
                    <span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                    <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                  </div>
                  <div class="color_red martp_5 font_12">
                    ¥ {{ childItem.Price | NumFormat }}
                  </div>
                </el-col>
                <el-col :span="2">x {{ childItem.Quantity }}</el-col>
                <el-col :span="4">{{ item.CurrentWeight }}</el-col>
                <el-col :span="4">{{ item.LostWeight }}</el-col>
                <el-col :span="4" v-show="treatInfo.BillType == '10'">
                  <div>
                    <span v-if="childItem.CardPreferentialAmount < 0">+ ¥ {{Math.abs(childItem.CardPreferentialAmount) | NumFormat}}</span>
                    <span v-else-if="childItem.CardPreferentialAmount > 0">- ¥ {{Math.abs(childItem.CardPreferentialAmount) | NumFormat}}</span>
                    <span v-else>¥ 0.00</span>
                  </div>
                  <div class="martp_5 font_12">
                    <span class="color_gray font_12" v-if="childItem.CardPreferentialAmount != 0">
                      卡优惠
                      <span class="color_red" v-if="childItem.CardPreferentialAmount > 0">- ¥
                        {{
                          childItem.CardPreferentialAmount | NumFormat
                        }}</span>
                      <span class="color_green" v-else>+ ¥
                        {{
                          Math.abs(childItem.CardPreferentialAmount)
                            | NumFormat
                        }}</span>
                    </span>
                  </div>
                </el-col>
                <el-col :span="4">

                  <div>¥ {{ childItem.TotalAmount | NumFormat }}</div>
                  <div class="dis_flex">
                    <div class="color_green font_12 marrt_10" v-if="childItem.Amount ">非赠送金额：<span class="">{{childItem.Amount  | NumFormat}}</span></div>
                    <div class="color_red font_12 " v-if="childItem.LargessAmount">赠送金额：<span class="">{{childItem.LargessAmount  | NumFormat}}</span></div>
                  </div>
                </el-col>
              </el-col>
              <el-col :span="24" v-if="childItem.TreatBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler, pIndex) in childItem.TreatBillHandler" :key="pIndex + 'h5'">
                  <el-col :span="2">
                    <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.TreatHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                      </el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-row>
        </div>
        <!-- 通用次卡 -->
        <div v-if="
            treatInfo.GeneralCard != undefined &&
            treatInfo.GeneralCard.length > 0
          ">
          <el-row class="tipback_col pad_10">
            <el-col :span="7">通用次卡</el-col>
            <el-col :span="4">数量</el-col>
            <el-col :span="6" v-show="treatInfo.BillType == '10'">优惠金额</el-col>
            <el-col :span="7">小计</el-col>
          </el-row>
          <el-row v-for="(item, index) in treatInfo.GeneralCard" :key="index + 'x3'" class="border_right item border_left">
            <el-row style="background-color: #f5f7fa" class="pad_10 border_top border_bottom">
              <div>
                {{ item.GeneralCardName}}<span v-if="item.Alias">({{ item.Alias }})</span>
                <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠
                </el-tag>
              </div>
            </el-row>

            <el-row v-for="(childItem, childIndex) in item.Project" :key="childIndex + 'c3'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="7">
                  <div>
                    {{ childItem.ProjectName }}
                    <span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                    <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠
                    </el-tag>
                  </div>
                  <div class="color_red martp_5 font_12">
                    ￥{{ childItem.Price | NumFormat}}
                  </div>
                </el-col>
                <el-col :span="4">x {{ childItem.Quantity }}</el-col>
                <el-col :span="6" v-show="treatInfo.BillType == '10'">
                  <div>
                    <span v-if="childItem.CardPreferentialAmount < 0">+ ¥ {{Math.abs(childItem.CardPreferentialAmount) | NumFormat}}</span>
                    <span v-else-if="childItem.CardPreferentialAmount > 0">- ¥ {{Math.abs(childItem.CardPreferentialAmount) | NumFormat}}</span>
                    <span v-else>¥ 0.00</span>
                  </div>
                  <div class="martp_5 font_12">
                    <span class="color_gray font_12" v-if="childItem.CardPreferentialAmount != 0">
                      卡优惠
                      <span class="color_red" v-if="childItem.CardPreferentialAmount > 0">- ¥
                        {{
                          childItem.CardPreferentialAmount | NumFormat
                        }}</span>
                      <span class="color_green" v-else>+ ¥
                        {{
                          Math.abs(childItem.CardPreferentialAmount)
                            | NumFormat
                        }}</span>
                    </span>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div>
                    <div>¥ {{ childItem.TotalAmount | NumFormat }}</div>
                    <div class="dis_flex">
                      <div class="color_green font_12 marrt_10" v-if="childItem.Amount ">非赠送金额：<span class="">{{childItem.Amount  | NumFormat}}</span></div>
                      <div class="color_red font_12 " v-if="childItem.LargessAmount">赠送金额：<span class="">{{childItem.LargessAmount  | NumFormat}}</span></div>
                    </div>
                  </div>
                </el-col>
              </el-col>
              <el-col :span="24" v-if="childItem.TreatBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler, pIndex) in childItem.TreatBillHandler" :key="pIndex + 'h5'">
                  <el-col :span="2">
                    <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.TreatHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                      </el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-row>
        </div>
        <!-- 产品 -->
        <div v-if="
            treatInfo.Product != undefined && treatInfo.Product.length > 0
          ">
          <el-row class="tipback_col pad_10">
            <el-col :span="7">产品</el-col>
            <el-col :span="4">数量</el-col>
            <el-col :span="6" v-show="treatInfo.BillType == '10'">优惠金额</el-col>
            <el-col :span="7">小计</el-col>
          </el-row>
          <el-row v-for="(item, index) in treatInfo.Product" :key="index + 'x1'" class="text_left border_right border_left">
            <el-col :span="24" class="pad_10 border_bottom">
              <el-col :span="7">
                <div>
                  {{ item.ProductName
                  }}<span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠
                  </el-tag>
                </div>
                <div class="color_red martp_5 font_12">
                  ￥{{ item.Price }}
                </div>
              </el-col>
              <el-col :span="4">x {{ item.Quantity }}</el-col>
              <el-col :span="6" v-show="treatInfo.BillType == '10'">
                <div>
                  <span v-if="item.CardPreferentialAmount < 0">+ ¥ {{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                  <span v-else-if="item.CardPreferentialAmount > 0">- ¥ {{Math.abs(item.CardPreferentialAmount) | NumFormat}}</span>
                  <span v-else>¥ 0.00</span>
                </div>
              </el-col>
              <el-col :span="7">
                <div>¥ {{ item.TotalAmount | NumFormat }}</div>
                <div class="dis_flex">
                  <div class="color_green font_12 marrt_10" v-if="item.Amount ">非赠送金额：<span class="">{{item.Amount  | NumFormat}}</span></div>
                  <div class="color_red font_12 " v-if="item.LargessAmount">赠送金额：<span class="">{{item.LargessAmount  | NumFormat}}</span></div>
                </div>
              </el-col>
            </el-col>
            <el-col :span="24" v-if="item.TreatBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
              <el-row v-for="(handler, pIndex) in item.TreatBillHandler" :key="pIndex + 'h5'">
                <el-col :span="2">
                  <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini" label-position="left">
                    <el-form-item :label="`${handler.TreatHandlerName}`"></el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="22">
                  <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini">
                    <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <!-- 套餐卡 -->
        <div v-if="
            treatInfo.PackageCard != undefined &&
            treatInfo.PackageCard.length > 0
          ">
          <el-row class="tipback_col pad_10">
            <el-col :span="6">套餐卡</el-col>
            <el-col :span="2">数量</el-col>
            <el-col :span="4">消耗时体重</el-col>
            <el-col :span="4">本次减重</el-col>
            <el-col :span="4" v-show="treatInfo.BillType == '10'">优惠金额</el-col>
            <el-col :span="4">小计</el-col>
          </el-row>

          <el-row v-for="(item, index) in treatInfo.PackageCard" :key="index + 'x6'" class="text_left border_left border_right">
            <el-row style="background-color: #f5f7fa" class="pad_10 border_top border_bottom">
              {{ item.PackageCardName }}<span v-if="item.Alias">({{item.Alias}})</span>
            </el-row>
            <!-- 项目 -->
            <el-row v-for="(childItem, childIndex) in item.Project" :key="childIndex + 'c6-1'">
              <el-col :span="24" class="border_bottom pad_10">
                <el-col :span="6">
                  <div>
                    {{ childItem.ProjectName }}<span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                    <el-tag v-if="childItem.IsLargess" size="mini" type="danger" class="marlt_5">赠
                    </el-tag>
                    <el-tag size="mini marlt_5"> 项目 </el-tag>
                  </div>
                  <div class="martp_5 color_red font_12">
                    ￥{{ childItem.Price | NumFormat}}
                  </div>
                </el-col>
                <el-col :span="2">x {{ childItem.Quantity }}</el-col>
                <el-col :span="4">{{ childItem.CurrentWeight }}</el-col>
                <el-col :span="4">{{ childItem.LostWeight }}</el-col>
                <el-col :span="4" v-show="treatInfo.BillType == '10'">
                  <div>
                    <span v-if="childItem.CardPreferentialAmount < 0">+ ¥ {{Math.abs(childItem.CardPreferentialAmount) | NumFormat}}</span>
                    <span v-else-if="childItem.CardPreferentialAmount > 0">- ¥ {{Math.abs(childItem.CardPreferentialAmount) | NumFormat}}</span>
                    <span v-else>¥ 0.00</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div>¥ {{ childItem.TotalAmount | NumFormat }}</div>
                  <div class="dis_flex">
                    <div class="color_green font_12 marrt_10" v-if="childItem.Amount ">非赠送金额：<span class="">{{childItem.Amount  | NumFormat}}</span></div>
                    <div class="color_red font_12 " v-if="childItem.LargessAmount">赠送金额：<span class="">{{childItem.LargessAmount  | NumFormat}}</span></div>
                  </div>
                </el-col>
              </el-col>
              <el-col :span="24" v-if="childItem.TreatBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler, pIndex) in childItem.TreatBillHandler" :key="pIndex + 'h5'">
                  <el-col :span="2">
                    <el-form class="saleHandler" :inline="true" size="mini" label-position="left" @submit.native.prevent>
                      <el-form-item :label="`${handler.TreatHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                      </el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <!--  储值卡 -->
            <div class="border_right border_left" v-if="item.SavingCard.length > 0">

              <el-row v-for="(childItem, childIndex) in item.SavingCard" :key="childIndex + 'c2-3'">
                <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_top border_bottom">
                  <div>
                    {{ childItem.SavingCardName
                    }}<span v-if="
                        childItem.Alias">({{ childItem.Alias }})</span>
                    <el-tag size="mini" class="marlt_5">储值卡</el-tag>
                  </div>
                </el-row>
                <el-row v-for="(childItem_1, childIndex_1) in childItem.Project" :key="childIndex_1 + 'c6-3-1'" class="item">
                  <el-col :span="24" class="border_bottom pad_10">
                    <el-col :span="6">
                      <div>
                        {{ childItem_1.ProjectName }}<span v-if="childItem_1.Alias">({{ childItem_1.Alias }})</span>
                      </div>
                      <div class="color_red martp_5 font_12">
                        ￥{{ childItem_1.Price | NumFormat }}
                      </div>
                    </el-col>
                    <el-col :span="2">x {{ childItem_1.Quantity }} </el-col>
                    <el-col :span="4">{{ childItem.CurrentWeight }} </el-col>
                    <el-col :span="4">{{ childItem.LostWeight }} </el-col>
                    <el-col :span="4" v-show="treatInfo.BillType == '10'">
                      <div>
                        <span v-if="(childItem_1.PricePreferentialAmount + childItem_1.CardPreferentialAmount)<0">+ ¥ {{Math.abs(childItem_1.PricePreferentialAmount + childItem_1.CardPreferentialAmount) | NumFormat}}</span>
                        <span v-else-if="(childItem_1.PricePreferentialAmount + childItem_1.CardPreferentialAmount)>0">- ¥ {{Math.abs(childItem_1.PricePreferentialAmount + childItem_1.CardPreferentialAmount) | NumFormat}}</span>
                        <span v-else>¥ 0.00</span>
                      </div>
                      <div class="martp_5">
                        <span class="color_gray font_12" v-if="childItem_1.PricePreferentialAmount != 0">
                          手动改价：
                          <span class="color_red" v-if="childItem_1.PricePreferentialAmount > 0">- ¥
                            {{
                          childItem_1.PricePreferentialAmount | NumFormat
                        }}</span>
                          <span class="color_green" v-else>+ ¥
                            {{
                          Math.abs(childItem_1.PricePreferentialAmount)
                            | NumFormat
                        }}</span>
                        </span>
                        <span class="color_gray font_12" :class="
                            childItem_1.PricePreferentialAmount != 0
                              ? 'marlt_15'
                              : ''
                          " v-if="childItem_1.CardPreferentialAmount > 0">
                          卡优惠：
                          <span class="color_red">- ¥
                            {{
                              parseFloat(childItem_1.CardPreferentialAmount)
                                | NumFormat
                            }}</span>
                        </span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div>¥ {{ childItem_1.TotalAmount | NumFormat }}</div>
                      <div class="dis_flex">
                        <div class="color_green font_12 marrt_10" v-if="childItem_1.Amount ">非赠送金额：<span class="">{{childItem_1.Amount  | NumFormat}}</span></div>
                        <div class="color_red font_12 " v-if="childItem_1.LargessAmount">赠送金额：<span class="">{{childItem_1.LargessAmount  | NumFormat}}</span></div>
                      </div>
                    </el-col>
                  </el-col>
                  <el-col :span="24" v-if="childItem_1.TreatBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                    <el-row v-for="(handler,
                      pIndex) in childItem_1.TreatBillHandler" :key="pIndex + 'h5'">
                      <el-col :span="2">
                        <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini" label-position="left">
                          <el-form-item :label="`${handler.TreatHandlerName}`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="22">
                        <el-form class="saleHandler" :inline="true" size="mini">
                          <el-form-item @submit.native.prevent v-for="(employee,
                            handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-row>
            </div>
            <!-- 时效卡 -->
            <div class="border_right border_left" v-if="item.TimeCard.length > 0">
              <el-row v-for="(childItem, childIndex) in item.TimeCard" :key="childIndex + 'c1-3'">
                <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_top border_bottom">
                  <div>
                    {{ childItem.TimeCardName
                    }}<span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                    <el-tag v-if="childItem.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                    <el-tag class="marlt_5" size="mini">时效卡</el-tag>
                  </div>
                </el-row>
                <el-row v-for="(childItem_1, childIndex_1) in childItem.Project" :key="childIndex_1 + 'c6-3-1'" class="item">
                  <el-col :span="24" class="border_bottom pad_10">
                    <el-col :span="6">
                      <div>
                        {{ childItem_1.ProjectName }}<span v-if="childItem_1.Alias">({{childItem_1.Alias}})</span>
                        <el-tag v-if="childItem.IsLargess" size="mini" type="danger" class="marlt_5">
                          赠
                        </el-tag>

                      </div>
                      <div class="color_red martp_5 font_12">
                        ￥{{ childItem_1.Price | NumFormat}}
                      </div>
                    </el-col>
                    <el-col :span="2">x {{ childItem_1.Quantity }} </el-col>
                    <el-col :span="4">{{ childItem.CurrentWeight }} </el-col>
                    <el-col :span="4">{{ childItem.LostWeight }} </el-col>
                    <el-col :span="4" v-show="treatInfo.BillType == '10'">
                      <div>
                        <span v-if="childItem_1.CardPreferentialAmount < 0">+ ¥ {{Math.abs(childItem_1.CardPreferentialAmount) | NumFormat}}</span>
                        <span v-else-if="childItem_1.CardPreferentialAmount > 0">- ¥ {{Math.abs(childItem_1.CardPreferentialAmount) | NumFormat}}</span>
                        <span v-else>¥ 0.00</span>
                      </div>
                      <div class="martp_5 font_12">
                        <span class="color_gray font_12" v-if="childItem_1.CardPreferentialAmount != 0">
                          卡优惠
                          <span class="color_red" v-if="childItem_1.CardPreferentialAmount > 0">- ¥
                            {{
                          childItem_1.CardPreferentialAmount | NumFormat
                        }}</span>
                          <span class="color_green" v-else>+ ¥
                            {{
                          Math.abs(childItem_1.CardPreferentialAmount)
                            | NumFormat
                        }}</span>
                        </span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div>
                        <div>¥ {{ childItem_1.TotalAmount | NumFormat }}</div>
                        <div class="dis_flex">
                          <div class="color_green font_12 marrt_10" v-if="childItem_1.Amount ">非赠送金额：<span class="">{{childItem_1.Amount  | NumFormat}}</span></div>
                          <div class="color_red font_12 " v-if="childItem_1.LargessAmount">赠送金额：<span class="">{{childItem_1.LargessAmount  | NumFormat}}</span></div>
                        </div>
                      </div>
                    </el-col>
                  </el-col>
                  <el-col :span="24" v-if="childItem_1.TreatBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                    <el-row v-for="(handler,
                      pIndex) in childItem_1.TreatBillHandler" :key="pIndex + 'h5'">
                      <el-col :span="2">
                        <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini" label-position="left">
                          <el-form-item :label="`${handler.TreatHandlerName}`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="22">
                        <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini">
                          <el-form-item v-for="(employee,
                            handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-row>
            </div>
            <!-- 通用次卡 -->
            <div class="border_right border_left" v-if="item.GeneralCard.length > 0">
              <el-row v-for="(childItem, childIndex) in item.GeneralCard" :key="childIndex + 'c6-3'" class="item">
                <el-row :span="24" style="background-color: #f5f7fa" class="pad_10 border_top border_bottom">
                  <div>
                    {{ childItem.GeneralCardName }}<span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                    <el-tag v-if="childItem.IsLargess" size="mini" type="danger" class="marlt_5"> 赠</el-tag>
                    <el-tag class="marlt_5" size="mini">通用次卡</el-tag>
                  </div>
                </el-row>
                <el-row v-for="(childItem_1, childIndex_1) in childItem.Project" :key="childIndex_1 + 'c6-3-1'">
                  <el-col :span="24" class="border_bottom pad_10">
                    <el-col :span="6">
                      <div>
                        {{ childItem_1.ProjectName }}<span v-if="childItem_1.Alias">({{childItem_1.Alias}})</span>
                        <el-tag v-if="childItem.IsLargess" size="mini" type="danger" class="marlt_5">
                          赠
                        </el-tag>
                      </div>
                      <div class="color_red martp_5 font_12">
                        ￥{{ childItem_1.Price | NumFormat }}
                      </div>
                    </el-col>
                    <el-col :span="2">x {{ childItem_1.Quantity }} </el-col>
                    <el-col :span="4">{{ childItem.CurrentWeight }} </el-col>
                    <el-col :span="4">{{childItem.LostWeight }} </el-col>
                    <el-col :span="4" v-show="treatInfo.BillType == '10'">
                      <div>
                        <span v-if="childItem_1.CardPreferentialAmount < 0">+ ¥ {{Math.abs(childItem_1.CardPreferentialAmount) | NumFormat}}</span>
                        <span v-else-if="childItem_1.CardPreferentialAmount > 0">- ¥ {{Math.abs(childItem_1.CardPreferentialAmount) | NumFormat}}</span>
                        <span v-else>¥ 0.00</span>
                      </div>
                      <div class="martp_5 font_12">
                        <span class="color_gray font_12" v-if="childItem_1.CardPreferentialAmount != 0">
                          卡优惠
                          <span class="color_red" v-if="childItem_1.CardPreferentialAmount > 0">- ¥
                            {{
                          childItem_1.CardPreferentialAmount | NumFormat
                        }}</span>
                          <span class="color_green" v-else>+ ¥
                            {{
                          Math.abs(childItem_1.CardPreferentialAmount)
                            | NumFormat
                        }}</span>
                        </span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div>¥ {{ childItem_1.TotalAmount | NumFormat }}</div>
                      <div class="dis_flex">
                        <div class="color_green font_12 marrt_10" v-if="childItem_1.Amount ">非赠送金额：<span class="">{{childItem_1.Amount  | NumFormat}}</span></div>
                        <div class="color_red font_12 " v-if="childItem_1.LargessAmount">赠送金额：<span class="">{{childItem_1.LargessAmount  | NumFormat}}</span></div>
                      </div>
                    </el-col>
                  </el-col>

                  <el-col :span="24" v-if="childItem_1.TreatBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                    <el-row v-for="(handler,
                      pIndex) in childItem_1.TreatBillHandler" :key="pIndex + 'h5'">
                      <el-col :span="2">
                        <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini" label-position="left">
                          <el-form-item :label="`${handler.TreatHandlerName}`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="22">
                        <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini">
                          <el-form-item v-for="(employee,
                            handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-row>
            </div>
            <!-- 产品 -->
            <el-row v-for="(childItem, childIndex) in item.Product" :key="childIndex + 'c6-2'">
              <el-col :span="24" class="border_bottom pad_10">
                <el-col :span="6">
                  <div>
                    {{ childItem.ProductName }}<span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                    <el-tag v-if="childItem.IsLargess" size="mini" type="danger" class="marlt_5">赠
                    </el-tag>
                    <el-tag class="marlt_5" size="mini"> 产品 </el-tag>
                  </div>
                  <div class="color_red martp_5 font_12">
                    ¥ {{ childItem.Price | NumFormat }}
                  </div>
                </el-col>
                <el-col :span="2">x {{ childItem.Quantity }}</el-col>
                <el-col :span="4">{{ childItem.CurrentWeight }}</el-col>
                <el-col :span="4">{{ childItem.LostWeight }}</el-col>
                <el-col :span="4" v-show="treatInfo.BillType == '10'">
                  <div>
                    <span v-if="childItem.CardPreferentialAmount < 0">+ ¥ {{Math.abs(childItem.CardPreferentialAmount) | NumFormat}}</span>
                    <span v-else-if="childItem.CardPreferentialAmount > 0">- ¥ {{Math.abs(childItem.CardPreferentialAmount) | NumFormat}}</span>
                    <span v-else>¥ 0.00</span>
                  </div>
                </el-col>
                <el-col :span="4">¥ {{ childItem.TotalAmount | NumFormat }}
                  <div>¥ {{ childItem.TotalAmount | NumFormat }}</div>
                  <div class="dis_flex">
                    <div class="color_green font_12 marrt_10" v-if="childItem.Amount ">非赠送金额：<span class="">{{childItem.Amount  | NumFormat}}</span></div>
                    <div class="color_red font_12 " v-if="childItem.LargessAmount">赠送金额：<span class="">{{childItem.LargessAmount  | NumFormat}}</span></div>
                  </div>
                </el-col>
              </el-col>
              <el-col :span="24" v-if="childItem.TreatBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler, pIndex) in childItem.TreatBillHandler" :key="pIndex + 'h5'">
                  <el-col :span="2">
                    <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.TreatHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form @submit.native.prevent class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                      </el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-row>
        </div>
      </div>
      <div class="border_left border_right border_bottom padtp_10 padbm_10" style="margin-bottom:10px;">
        <el-row>
          <el-col :span="6" :offset="17">
            <el-form class="saleInfo" size="mini">
              <el-form-item label="非赠送金额：" v-if="treatInfo.PrincipalAmount">
                <div class="text_right">
                  ￥{{treatInfo.PrincipalAmount  | NumFormat}}
                </div>
              </el-form-item>
              <el-form-item label="赠送金额：" v-if="treatInfo.LargessPrincipalAmount">
                <div class="text_right">
                  ￥{{treatInfo.LargessPrincipalAmount | NumFormat}}
                </div>
              </el-form-item>
              <el-form-item label="合计消耗金额：">
                <div class="text_right">
                  ￥{{ treatInfo.Amount | NumFormat }}
                </div>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 修改备注弹框 -->
    <el-dialog width="30%" title="修改消耗单备注" :visible.sync="innerVisible" append-to-body>
      <el-input type="textarea" :rows="4" v-model="Remark"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="updateRemarkClick" size="small">保存</el-button>
      </span>
    </el-dialog>

  </section>
</template>

<script>
import API from "@/api/iBeauty/Order/treatBill";

export default {
  name: "treatBillDetailContent",
  props: {
    treatInfo: Object,
  },
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      innerVisible: false,
      Remark: "",
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    upRemarkDialog() {
      var that = this;
      that.Remark = that.treatInfo.Remark;
      that.innerVisible = true;
    },

    //修改备注
    updateRemarkClick() {
      var that = this;
      var params = {
        TreatBillID: that.treatInfo.ID,
        Remark: that.Remark,
      };
      API.updateRemark(params).then((res) => {
        if (res.StateCode == 200) {
          that.innerVisible = false;
          that.treatInfo.Remark = that.Remark;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {},
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
.treatBillDetailContent {
  .saleInfo {
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .saleHandler {
    .el-form-item__label {
      font-size: 12px !important;
      line-height: 18px;
      color: #c0c4cc;
    }
    .el-form-item__content {
      font-size: 12px !important;
      line-height: 20px;
      color: #c0c4cc;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }
}
 .el-col {
    border:1px solid transparent;
  }
</style>

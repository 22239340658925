/**
 * Created by jerry on 2020/04/13.
 * 顾客来源api
 */
import * as API from '@/api/index'

export default {
    // 获取顾客来源列表
    getCustomerSource: params => {
        return API.POST('api/customerSource/all',params)
    },
    // 新增来源
    createCustomerSource: params => {
        return API.POST('api/customerSource/create',params)
    },
    // 修改来源
    updateCustomerSource: params => {
        return API.POST('api/customerSource/update',params)
    },
    // 移动顾客来源
    moveCustomerSource: params => {
        return API.POST('api/customerSource/move',params)
    },
}

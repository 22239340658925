/**
 * Created by jerry on 2020/04/13.
 * 会员等级api
 */

import * as API from '@/api/index'

export default {
    // 获取会员等级列表
    getCustomerLevel: params => {
        return API.POST('api/customerLevel/all',params)
    },
    // 新增会员等级
    createCustomerLevel: params => {
        return API.POST('api/customerLevel/create',params)
    },
    // 更新会员等级
    updateCustomerLevel: params => {
        return API.POST('api/customerLevel/update',params)
    },
    // 移动会员等级
    moveCustomerSource: params => {
        return API.POST('api/customerLevel/move',params)
    },
}
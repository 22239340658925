/**
 * Created by jerry on 2020/02/14.
 * 组织架构api
 */
import * as API from '@/api/index'

export default {
    // 通讯录-组织架构
    getEntityAll:() =>{
        return API.POST('api/entity/entityPermission')
    },
    getEntity: params => {
        return API.POST('api/entity/list', params)
    },
    storeList: params => {
        return API.POST('api/entity/storeList', params)
    },
    //采购门店权限回显
    purchaseEntity: params => {
        return API.POST('api/entity/purchaseEntity', params)
    },
    //调拨门店回显
    allocationEntity: params => {
        return API.POST('api/entity/allocationEntity', params)
    },
     // 用户管理-组织架构
     getEntityAllManagement:() =>{
        return API.POST('api/entityEmployeeManage/all')
    },
    getEntityManagement: params => {
        return API.POST('api/entityEmployeeManage/list', params)
    },
    // 通讯录-新增组织架构
    createEntity: params => {
        return API.POST('api/entity/create', params)
    },
    // 通讯录-编辑组织架构
    updateEntity: params => {
        return API.POST('api/entity/update', params)
    },
    // 获取门店其他信息
    getOnlineEntityByID: params => {
      return API.POST('api/onlineEntity/getOnlineEntityByID', params)
  },
    // 通讯录-组织架构调整顺序
    moveEntity: params => {
        return API.POST('api/entity/move', params)
    },
}


import * as API from '@/api/index'

export default{
    //获取销售订单列表
    CustomerBill:params =>{
        return API.POST('api/customerBill/saleBill',params)
    },
    //获取消耗订单列表
    getTreatBillList:params =>{
        return API.POST('api/customerBill/treatBill',params)
    },
    //获取退存量列表
    getTreatStockList:params =>{
        return API.POST('api/customerBill/refundBill',params)
    },
    //退消耗申请列表
    getTreatRefundBillList:params =>{
        return API.POST('api/customerBill/refundTreatBill',params)
    },
    //转账列表
    getTranserList:params =>{
        return API.POST('api/customerBill/transer',params)
    },
    //延期申请列表
    getPostponeList:params =>{
        return API.POST('api/customerBill/postponeBill',params)
    }

}


/**
 * Created by preference on 2021/03/25
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /**  获取顾客护理日志列表  */
  getCustomerNursingLog: params => {
    return API.POST('api/customerBill/nursingLog', params)
  },
  /**  添加护理日志评论  */
  getNursingLogAddComment: params => {
    return API.POST('api/nursingLog/addComment', params)
  },
  /**  添加护理日志 点赞  */
  getNursingLogAddThumbsUp: params => {
    return API.POST('api/nursingLog/addThumbsUp', params)
  },
}
export default {
    validPhoneNumber: function (rule, value, callback) {
        if (value.trim() === "") {
            if (rule.required) {
                callback(new Error("请输入手机号码"));
            }else{
                callback();
            }
        } else {
            if (!/^1[3456789]\d{9}$/.test(value)) {
                callback(new Error("请输入正确的手机号码"));
            } else {
                callback();
            }
        }
    },
    validEmail: function (rule, value, callback) {
        if (value.trim() === "") {
            if (rule.required) {
                callback(new Error("请输入邮箱"));
            }else{
                callback();
            }
        } else {
            if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.]){1,2}[A-Za-z\d]{2,5}$/g.test(value)) {
                callback(new Error("请输入正确的邮箱"));
            } else {
                callback();
            }
        }
    },

}
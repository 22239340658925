/**
 * Created by jerry on 2020/02/14.
 * 开单销售api
 */
import * as API from '@/api/index'

export default {
  // 搜索全部商品
  getSaleGoods: params => {
    return API.POST('api/saleGoods/all', params)
  },
  // 项目列表
  getSaleGoodsProject: params => {
    return API.POST('api/saleGoods/project', params)
  },
  //产品列表
  getSaleGoodsProduct: params => {
    return API.POST('api/saleGoods/product', params)
  },
  // 通用次卡列表
  getSaleGoodsGeneralCard: params => {
    return API.POST('api/saleGoods/generalCard', params)
  },
  // 时效卡列表
  getSaleGoodsTimeCard: params => {
    return API.POST('api/saleGoods/timeCard', params)
  },
  // 储值卡列表
  getSaleGoodsSavingCard: params => {
    return API.POST('api/saleGoods/savingCard', params)
  },
  //  套餐卡列表
  getSaleGoodsPackageCard: params => {
    return API.POST('api/saleGoods/packageCard', params)
  },
  //  查找会员
  getSaleCustomer: params => {
    return API.POST('api/saleCustomer/customer', params)
  },
  // 获取支付方式
  getSalePayMethod: params => {
    return API.POST('api/salePayMethod/all', params)
  },
  // 获取产品销售经手人
  getProductHandler: params => {
    return API.POST('api/saleHandler/productHandler', params)
  },
  // 获取项目销售经手人
  getProjectHandler: params => {
    return API.POST('api/saleHandler/projectHandler', params)
  },
  // 获取通用次卡销售经手人
  getGeneralCardHandler: params => {
    return API.POST('api/saleHandler/generalCardHandler', params)
  },
  // 获取时效卡销售经手人
  getTimeCardHandler: params => {
    return API.POST('api/saleHandler/timeCardHandler', params)
  },
  // 获取储值卡销售经手人
  getSavingCardHandler: params => {
    return API.POST('api/saleHandler/savingCardHandler', params)
  },
  // 获取套餐卡销售经手人
  getPackageCardHandler: params => {
    return API.POST('api/saleHandler/packageCardHandler', params)
  },
  // 获取会员通用储值卡
  getSavingCardAllGoods: params => {
    return API.POST('api/savingCardAccount/allGoods', params)
  },
  //  获取会员非通用储值卡（指定商品抵扣卡）
  getSavingCardSomeGoods: params => {
    return API.POST('api/savingCardAccount/someGoods', params)
  },
  // 获取可抵扣产品的储值卡列表
  getSavingCardProduct: params => {
    return API.POST('api/savingCardAccount/product', params)
  },
  // 获取可抵扣项目的储值卡列表
  getSavingCardProject: params => {
    return API.POST('api/savingCardAccount/project', params)
  },
  // 获取可抵扣通用次卡的储值卡列表
  getSavingCardGeneralCard: params => {
    return API.POST('api/savingCardAccount/generalCard', params)
  },
  //  获取可抵扣时效卡的储值卡列表
  getSavingCardTimeCard: params => {
    return API.POST('api/savingCardAccount/timeCard', params)
  },
  //  获取可抵扣套餐卡的储值卡列表
  getSavingCardPackageCard: params => {
    return API.POST('api/savingCardAccount/packageCard', params)
  },
  //  结帐
  createSaleBill: params => {
    return API.POST('api/saleBill/create', params)
  },
   // 顾客档案信息
   getCustomerBasicsArchives: params => {
    return API.POST('api/customer/getCustomerBasicsArchives',params)
},
// 选择推荐会员列表
recommendCustomerList: params => {
    return API.POST('api/customer/recommendCustomerList',params)
},
// 返佣列表
CommissionList: params => {
    return API.POST('api/customer/recommendCustomerCommissionList',params)
},
// 返佣记录
recommendCommissionList: params => {
    return API.POST('api/customer/recommendCommissionList',params)
},
//修改顾客头像
updateCustomerUploadImage: params => {
  return API.POST('api/customer/uploadImage',params)
},
// 获取顾客标签信息
getCustomerTag: params => {
  return API.POST('api/customer/getCustomerTagLibrary',params)
},
//保存选中顾客标签
updateCustomerTagLibrary: params => {
  return API.POST('api/customer/updateCustomerTagLibrary',params)
},
}

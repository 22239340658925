/**
 * Created by jerry on 2020/03/30.
 * 项目消耗提成 api
 */
import * as API from '@/api/index'

export default {
    // 获取项目消耗组织单位提成方案列表
    getTreatProjectCommissionScheme: params => {
        return API.POST('api/treatProjectCommissionScheme/list', params)
    },
    // 创建项目消耗组织单位提成方案
    createTreatProjectCommissionScheme: params => {
        return API.POST('api/treatProjectCommissionScheme/create', params)
    },
    //删除项目消耗提成方案
    deleteTreatProjectCommissionScheme: params => {
        return API.POST('api/treatProjectCommissionScheme/delete', params)
    },
    //获取项目消耗分类提佣方案
    getTreatProjectCategoryCommission: params => {
        return API.POST('api/treatProjectCategoryCommission/all', params)
    },
    //保存项目消耗分类提成方案
    updateTreatProjectCategoryCommission: params => {
        return API.POST('api/treatProjectCategoryCommission/update', params)
    },
    //获取项目消耗分类经手人提佣方案
    getTreatProjectCategoryHandlerCommission: params => {
        return API.POST('api/treatProjectCategoryHandlerCommission/all', params)
    },
    //保存项目消耗分类经手人提成方案
    updateTreatProjectCategoryHandlerCommission: params => {
        return API.POST('api/treatProjectCategoryHandlerCommission/update', params)
    },
    //获取分类下的项目提佣方案
    getTreatProjectCommission: params => {
        return API.POST('api/treatProjectCommission/all', params)
    },
    //保存项目消耗提成方案
    updateTreatProjectCommission: params => {
        return API.POST('api/treatProjectCommission/update', params)
    },
    //获取项目下经手人提佣方案
    getTreatProjectHanderCommission: params => {
        return API.POST('api/treatProjectHandlerCommission/all', params)
    },
    //保存项目下经手人提成方案
    updateTreatProjectHanderCommission: params => {
        return API.POST('api/treatProjectHandlerCommission/update', params)
    },
    //获取所有项目下经手人提成方案
    treatProjectSchemeHanderCommission: params => {
        return API.POST('api/treatProjectSchemeHandlerCommission/all', params)
    },
    //保存所有项目下经手人提成方案
    treatProjectSchemeHanderCommissionupdate: params => {
        return API.POST('api/treatProjectSchemeHandlerCommission/update', params)
    },
}
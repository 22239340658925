/**
 * Created by zh on 2020/08/44.
 * 账户信息
 */
import * as API from '@/api/index'

export default {
    //项目存量列表
    projectAccount: params => {
        return API.POST('api/customerAccount/projectAccount', params)
    },
    // 产品存量列表
    productAccount: params => {
        return API.POST('api/customerAccount/productAccount', params)
    },
    //储值卡存量列表
    savingCardAccount: params => {
        return API.POST('api/customerAccount/savingCardAccount', params)
    },
    //通用次卡存量列表
    generalCardAccount: params => {
        return API.POST('api/customerAccount/generalCardAccount', params)
    },
    //时效卡存量列表
    timeCardAccount: params => {
        return API.POST('api/customerAccount/timeCardAccount', params)
    },
    //套餐卡存量列表
    packageCardAccount: params => {
        return API.POST('api/customerAccount/packageCardAccount', params)
    },
    //项目存量平账
    createProjectAccountArrearAmountProcess: params => {
        return API.POST('api/customerAccount/createProjectAccountArrearAmountProcess', params)
    },
    //产品存量平账
    createProductAccountArrearAmountProcess: params => {
        return API.POST('api/customerAccount/createProductAccountArrearAmountProcess', params)
    },
    //储值卡存量平账
    createSavingCardAccountArrearAmountProcess: params => {
        return API.POST('api/customerAccount/createSavingCardAccountArrearAmountProcess', params)
    },
    //通用次卡存量平账
    createGeneralCardAccountArrearAmountProcess: params => {
        return API.POST('api/customerAccount/createGeneralCardAccountArrearAmountProcess', params)
    },
    //时效卡存量平账
    createTimeCardAccountArrearAmountProcess: params => {
        return API.POST('api/customerAccount/createTimeCardAccountArrearAmountProcess', params)
    },
    //项目消耗记录
    treatBill: params => {
        return API.POST('api/customerProjectAccount/treatBill', params)
    },
    //项目退存量记录
    refundBill: params => {
        return API.POST('api/customerProjectAccount/refundBill', params)
    },
    //项目退消耗
    refundTreatBill: params => {
        return API.POST('api/customerProjectAccount/refundTreatBill', params)
    },
    //项目转出记录
    transferBill: params => {
        return API.POST('api/customerProjectAccount/transferBill', params)
    },
    //项目还款记录
    repayment: params => {
        return API.POST('api/customerProjectAccount/repayment', params)
    },
    //项目平账记录
    arrearAmountProcess: params => {
        return API.POST('api/customerProjectAccount/arrearAmountProcess', params)
    },

    //产品消耗记录
    productAccountTreatBill: params => {
        return API.POST('api/customerProductAccount/treatBill', params)
    },
    //产品退存量记录
    productAccountRefundBill: params => {
        return API.POST('api/customerProductAccount/refundBill', params)
    },
    //产品退消耗记录
    productAccountRefundTreatBill: params => {
        return API.POST('api/customerProductAccount/refundTreatBill', params)
    },
    //产品转出记录
    productAccountTransferBill: params => {
        return API.POST('api/customerProductAccount/transferBill', params)
    },
    //60.22. 产品还款记录
    productAccountRepayment: params => {
        return API.POST('api/customerProductAccount/repayment', params)
    },
    //产品平账记录
    productAccountArrearAmountProcess: params => {
        return API.POST('api/customerProductAccount/arrearAmountProcess', params)
    },

    //储值卡消耗记录
    savingCardTreatBill: params => {
        return API.POST('api/customerSavingCardAccount/treatBill', params)
    },
    // 储值卡消耗记录
    savingCardTreatBillToo:params => {
        return API.POST('api/customerSavingCardAccount/treatBillSavingCard',params)
    },
    //储值卡退存量记录
    savingCardRefundBill: params => {
        return API.POST('api/customerSavingCardAccount/refundBill', params)
    },
    //储值卡退消耗记录
    savingCardRefundTreatBill: params => {
        return API.POST('api/customerSavingCardAccount/refundTreatBill', params)
    },
    //储值卡转出记录
    savingCardTransferBill: params => {
        return API.POST('api/customerSavingCardAccount/transferBill', params)
    },
    //储值卡还款记录
    savingCardRepayment: params => {
        return API.POST('api/customerSavingCardAccount/repayment', params)
    },
    //储值卡平账记录
    savingCardArrearAmountProcess: params => {
        return API.POST('api/customerSavingCardAccount/arrearAmountProcess', params)
    },
    //储值卡抵扣记录
    savingCardDeduction: params => {
        return API.POST('api/customerSavingCardAccount/savingCardDeduction', params)
    },

    //通用次卡消耗记录
    generalCardTreatBill: params => {
        return API.POST('api/customerGeneralCardAccount/treatBill', params)
    },
    //60.25. 通用次卡退存量记录
    generalCardRefundBill: params => {
        return API.POST('api/customerGeneralCardAccount/refundBill', params)
    },
    //60.26. 通用次卡退消耗记录
    generalCardRefundTreatBill: params => {
        return API.POST('api/customerGeneralCardAccount/refundTreatBill', params)
    },

    //通用次卡转出记录
    generalCardRefundTransferBill: params => {
        return API.POST('api/customerGeneralCardAccount/transferBill', params)
    },
    //60.28. 通用次卡还款记录
    generalCardRefundRepayment: params => {
        return API.POST('api/customerGeneralCardAccount/repayment', params)
    },
    //60.28. 通用平账记录
    generalCardArrearAmountProcess: params => {
        return API.POST('api/customerGeneralCardAccount/arrearAmountProcess', params)
    },


    //60.30. 时效卡消耗记录
    timeCardTreatBill: params => {
        return API.POST('api/customerTimeCardAccount/treatBill', params)
    },
    //60.31. 时效卡退存量记录
    timeCardRefundBill: params => {
        return API.POST('api/customerTimeCardAccount/refundBill', params)
    },
    // 时效卡退消耗记录
    timeCardRefundTreatBill: params => {
        return API.POST('api/customerTimeCardAccount/refundTreatBill', params)
    },
    // 时效卡转出记录
    timeCardRefundTransferBill: params => {
        return API.POST('api/customerTimeCardAccount/transferBill', params)
    },
    // 60.34. 时效卡还款记录
    timeCardRefundRepayment: params => {
        return API.POST('api/customerTimeCardAccount/repayment', params)
    },
    // 60.34. 时效卡平账记录
    timeCardRefundArrearAmountProcess: params => {
        return API.POST('api/customerTimeCardAccount/arrearAmountProcess', params)
    },
    //套餐卡明细
    packageCardAccountDetails: params => {
        return API.POST('api/customerAccount/packageCardAccountDetails', params)
    },
    //修改目标减重
    updateTimeCardAccountTargetWeight: params => {
        return API.POST('api/customerAccount/updateTimeCardAccountTargetWeight', params)
    },
    //时效卡修改记录
    getTimeCardList: params=>{
        return API.POST("api/customerAccount/getTimeCardTargetWeightEditLogByTimeCardAccountID",params)
    },

}

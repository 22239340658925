<template>
  <div class="customerAccount position_relative">
    <div class="rightDiv">
      <el-select size="small" v-model="IsShowZero" placeholder="请选择" @change="handleIsZeroChange">
        <el-option v-for="item in IsShowZeroOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </div>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="项目" name="0">
        <el-table v-if="activeName == '0'" size="small" :data="projectData" height="360px" show-summary ref="table" :summary-method="getProjectData">
          <el-table-column prop="Name" label="名称">
            <template slot-scope="scope">
              <span>{{ scope.row.Name }}</span
              ><span v-if="scope.row.Alias" class="font_12 color_gray">({{ scope.row.Alias }})</span>
              <el-tag v-if="scope.row.IsLargess" type="danger" size="mini" class="marlt_5">赠</el-tag>
            </template>
            <span></span>
          </el-table-column>
          <el-table-column prop="BuyDate" label="开卡时间"></el-table-column>
          <el-table-column prop="EntityName" label="开卡门店"></el-table-column>
          <el-table-column prop="TotalAmount" label="卡售价">
            <template slot-scope="scope"> ￥{{ scope.row.TotalAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="PayAmount" label="实付">
            <template slot-scope="scope"> ￥{{ scope.row.PayAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="SavingCardDeductionAmount" label="卡扣">
            <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="SavingCardDeductionLargessAmount" label="赠卡扣">
            <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionLargessAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="Quantity" label="开卡次数"></el-table-column>
          <el-table-column prop="Balance" label="剩余次数"></el-table-column>
          <el-table-column prop="BalanceAmount">
            <div slot="header">
              <div>余额</div>
              <div>不含赠卡扣</div>
            </div>
            <template slot-scope="scope">
              <span class="color_main">￥{{ scope.row.BalanceAmount | NumFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column label="欠款" prop="ArrearAmount">
            <template slot-scope="scope">
              <span class="color_red">￥{{ scope.row.ArrearAmount | NumFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ChannelName" label="来源"></el-table-column>
          <el-table-column label="操作" width="100px">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="changeRecord(scope.row)" v-prevent-click>操作记录 </el-button>
              <!-- <el-button v-if="scope.row.ArrearAmount > 0" type="primary" size="small" @click="balancingAccount(scope.row, 0)" v-prevent-click>平账
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="text_right pad_15">
          <el-pagination background v-if="paginations.total1 > 0" @current-change="handleCurrentChange1" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total1"></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="储值卡" name="1">
        <el-table v-if="activeName == '1'" size="small" :data="projectData" height="360px" show-summary ref="table" :summary-method="getSaveCardData">
          <el-table-column prop="Name" label="名称">
            <template slot-scope="scope">
              <span>{{ scope.row.Name }}</span
              ><span v-if="scope.row.Alias" class="font_12 color_gray">({{ scope.row.Alias }})</span>
              <el-tag v-if="scope.row.IsLargess" type="danger" size="mini" class="marlt_5">赠</el-tag>
            </template>
            <span></span>
          </el-table-column>
          <el-table-column prop="BuyDate" label="开卡时间"></el-table-column>
          <el-table-column prop="EntityName" label="开卡门店"></el-table-column>
          <el-table-column prop="Amount" label="卡售价">
            <template slot-scope="scope"> ￥{{ scope.row.Amount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="ValidDayName" label="有效期"></el-table-column>
          <el-table-column prop="TotalBalance" label="账户余额">
            <template slot-scope="scope"> ￥{{ scope.row.TotalBalance | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="Balance" label="本金">
            <template slot-scope="scope"> ￥{{ scope.row.Balance | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="LargessBalance" label="赠额">
            <template slot-scope="scope"> ￥{{ scope.row.LargessBalance | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="BalanceAmount">
            <div slot="header">
              <div>余额</div>
              <div>不含赠卡扣</div>
            </div>
            <template slot-scope="scope">
              <span class="color_main">￥{{ scope.row.BalanceAmount | NumFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ArrearAmount" label="欠款">
            <template slot-scope="scope">
              <span class="color_red">￥{{ scope.row.ArrearAmount | NumFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ChannelName" label="来源"></el-table-column>
          <el-table-column label="操作" width="100px">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="changeRecord(scope.row)" v-prevent-click>操作记录 </el-button>
              <!-- <el-button v-if="scope.row.ArrearAmount > 0" type="primary" size="small" @click="balancingAccount(scope.row, 1)" v-prevent-click>平账
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="text_right pad_15">
          <el-pagination background v-if="paginations.total1 > 0" @current-change="handleCurrentChange1" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total1"></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="时效卡" name="4">
        <el-table v-if="activeName == '4'" size="small" :data="projectData" height="360px" ref="table" show-summary :summary-method="getTimeCardData">
          <el-table-column prop="Name" label="名称" width="120" fixed>
            <template slot-scope="scope">
              <span>{{ scope.row.Name }}</span
              ><span v-if="scope.row.Alias" class="font_12 color_gray">({{ scope.row.Alias }})</span>
              <el-tag v-if="scope.row.IsLargess" type="danger" size="mini" class="marlt_5">赠</el-tag>
            </template>
            <span></span>
          </el-table-column>
          <el-table-column prop="BuyDate" label="开卡时间" width="120"></el-table-column>
          <el-table-column prop="EntityName" label="开卡门店" width="120"></el-table-column>
          <el-table-column prop="TotalAmount" label="卡售价" width="100">
            <template slot-scope="scope"> ￥{{ scope.row.TotalAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="PayAmount" label="实付" width="100">
            <template slot-scope="scope"> ￥{{ scope.row.PayAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="SavingCardDeductionAmount" label="卡扣">
            <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="SavingCardDeductionLargessAmount" label="赠卡扣">
            <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionLargessAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="CustomerTargetWeight" label="目标体重"></el-table-column>
          <el-table-column prop="TargetWeight" label="目标减重"></el-table-column>
          <el-table-column prop="LostWeight" label="已减体重"></el-table-column>
          <el-table-column prop="ValidDayName" label="有效期"></el-table-column>
          <el-table-column prop="ConsumeAmount" label="累计消耗次数" width="100"></el-table-column>
          <el-table-column prop="BalanceAmount" width="100">
            <div slot="header">
              <div>余额</div>
              <div>不含赠卡扣</div>
            </div>
            <template slot-scope="scope">
              <span class="color_main">￥{{ scope.row.BalanceAmount | NumFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column label="欠款" prop="ArrearAmount">
            <template slot-scope="scope">
              <span class="color_red">￥{{ scope.row.ArrearAmount | NumFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ChannelName" label="来源"></el-table-column>
          <el-table-column label="操作" width="170" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="changeRecord(scope.row)" v-prevent-click>操作记录</el-button>
              <el-button v-if="scope.row.TargetWeight" type="primary" size="small" @click="editRecord(scope.row)" v-prevent-click>修改</el-button>
              <!-- <el-button v-if="scope.row.ArrearAmount > 0" type="primary" size="small" @click="balancingAccount(scope.row, 3)" v-prevent-click>平账
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="text_right pad_15">
          <el-pagination background v-if="paginations.total1 > 0" @current-change="handleCurrentChange1" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total1"></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="通用次卡" name="3">
        <el-table v-if="activeName == '3'" size="small" :data="projectData" height="360px" ref="table" show-summary :summary-method="getGeneralData">
          <el-table-column prop="Name" label="名称">
            <template slot-scope="scope">
              <span>{{ scope.row.Name }}</span
              ><span v-if="scope.row.Alias" class="font_12 color_gray">({{ scope.row.Alias }})</span>
              <el-tag v-if="scope.row.IsLargess" type="danger" size="mini" class="marlt_5">赠</el-tag>
            </template>
            <span></span>
          </el-table-column>
          <el-table-column prop="BuyDate" label="开卡时间"></el-table-column>
          <el-table-column prop="EntityName" label="开卡门店"></el-table-column>
          <el-table-column prop="TotalAmount" label="卡售价">
            <template slot-scope="scope"> ￥{{ scope.row.TotalAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="PayAmount" label="实收">
            <template slot-scope="scope"> ￥{{ scope.row.PayAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="SavingCardDeductionAmount" label="卡扣">
            <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="SavingCardDeductionLargessAmount" label="赠卡扣">
            <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionLargessAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="ValidDayName" label="有效期"></el-table-column>
          <el-table-column prop="CardTimes" label="开卡次数"></el-table-column>
          <el-table-column prop="Balance" label="剩余次数"></el-table-column>
          <el-table-column prop="BalanceAmount">
            <div slot="header">
              <div>余额</div>
              <div>不含赠卡扣</div>
            </div>
            <template slot-scope="scope">
              <span class="color_main">￥{{ scope.row.BalanceAmount | NumFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column label="欠款" prop="ArrearAmount">
            <template slot-scope="scope">
              <span class="color_red">￥{{ scope.row.ArrearAmount | NumFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ChannelName" label="来源"></el-table-column>
          <el-table-column label="操作" width="100px">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="changeRecord(scope.row)" v-prevent-click>操作记录 </el-button>
              <!-- <el-button v-if="scope.row.ArrearAmount > 0" type="primary" size="small" @click="balancingAccount(scope.row, 2)" v-prevent-click>平账
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="text_right pad_15">
          <el-pagination background v-if="paginations.total1 > 0" @current-change="handleCurrentChange1" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total1"></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="套餐卡" name="2">
        <el-table v-if="activeName == '2'" size="small" :data="projectData" height="360px" ref="table" show-summary :summary-method="getPackageData">
          <el-table-column prop="Name" label="名称">
            <template slot-scope="scope">
              <span>{{ scope.row.Name }}</span
              ><span v-if="scope.row.Alias" class="font_12 color_gray">({{ scope.row.Alias }})</span>
              <el-tag v-if="scope.row.IsLargess" type="danger" size="mini" class="marlt_5">赠</el-tag>
            </template>
            <span></span>
          </el-table-column>
          <el-table-column prop="BuyDate" label="开卡时间"></el-table-column>
          <el-table-column prop="EntityName" label="开卡门店"></el-table-column>
          <el-table-column prop="TotalAmount" label="卡售价">
            <template slot-scope="scope"> ￥{{ scope.row.TotalAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="PayAmount" label="实收">
            <template slot-scope="scope"> ￥{{ scope.row.PayAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="SavingCardDeductionAmount" label="卡扣">
            <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="SavingCardDeductionLargessAmount" label="赠卡扣">
            <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionLargessAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="ValidDayName" label="有效期"></el-table-column>
          <el-table-column prop="BalanceAmount">
            <div slot="header">
              <div>余额</div>
              <div>不含赠卡扣</div>
            </div>
            <template slot-scope="scope">
              <span class="color_main">￥{{ scope.row.BalanceAmount | NumFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column label="欠款" prop="ArrearAmount">
            <template slot-scope="scope">
              <span class="color_red">￥{{ scope.row.ArrearAmount | NumFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ChannelName" label="来源"></el-table-column>
          <el-table-column label="操作" width="120px">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="getPackageCardDetails(scope.row)" v-prevent-click>套餐卡明细</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="text_right pad_15">
          <el-pagination background v-if="paginations.total1 > 0" @current-change="handleCurrentChange1" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total1"></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="产品" name="5">
        <el-table v-if="activeName == '5'" size="small" :data="projectData" height="360px" ref="table" show-summary :summary-method="getProductData">
          <el-table-column prop="Name" label="名称">
            <template slot-scope="scope">
              <span>{{ scope.row.Name }}</span
              ><span v-if="scope.row.Alias" class="font_12 color_gray">({{ scope.row.Alias }})</span>
              <el-tag v-if="scope.row.IsLargess" type="danger" size="mini" class="marlt_5">赠</el-tag>
            </template>
            <span></span>
          </el-table-column>
          <el-table-column prop="BuyDate" label="购买时间"></el-table-column>
          <el-table-column prop="EntityName" label="购买门店"></el-table-column>
          <el-table-column prop="TotalAmount" label="售价">
            <template slot-scope="scope"> ￥{{ scope.row.TotalAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="PayAmount" label="实收">
            <template slot-scope="scope"> ￥{{ scope.row.PayAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="SavingCardDeductionAmount" label="卡扣">
            <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="SavingCardDeductionLargessAmount" label="赠卡扣">
            <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionLargessAmount | NumFormat }} </template>
          </el-table-column>
          <el-table-column prop="Quantity" label="购买数量"></el-table-column>
          <el-table-column prop="Balance" label="剩余数量"></el-table-column>
          <el-table-column prop="BalanceAmount">
            <div slot="header">
              <div>余额</div>
              <div>不含赠卡扣</div>
            </div>
            <template slot-scope="scope">
              <span class="color_main">￥{{ scope.row.BalanceAmount | NumFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column label="欠款" prop="ArrearAmount">
            <template slot-scope="scope">
              <span class="color_red">￥{{ scope.row.ArrearAmount | NumFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ChannelName" label="来源"></el-table-column>
          <el-table-column label="操作" width="100px">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="changeRecord(scope.row)" v-prevent-click>操作记录 </el-button>
              <!-- <el-button v-if="scope.row.ArrearAmount > 0" type="primary" size="small" @click="balancingAccount(scope.row, 4)" v-prevent-click>平账
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="text_right pad_15">
          <el-pagination background v-if="paginations.total1 > 0" @current-change="handleCurrentChange1" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total1"></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!--异动记录 项目-->
    <el-dialog title="操作记录" :visible.sync="changeRecordDialog" width="980px" :append-to-body="true">
      <el-tabs v-model="tabProject" @tab-click="handleProjctRecord" v-show="isShowRecord == 0">
        <el-tab-pane label="消耗记录" name="0">
          <el-table size="small" :data="treatBillList">
            <el-table-column prop="BillDate" label="消耗时间"></el-table-column>
            <el-table-column prop="BillID" label="消耗订单"></el-table-column>
            <el-table-column label="消耗次数">
              <template slot-scope="scope">
                <span class="color_red">-{{ scope.row.Quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="退消耗记录" name="2">
          <el-table size="small" :data="treatBillList">
            <el-table-column prop="BillDate" label="退消耗时间"></el-table-column>
            <el-table-column prop="BillID" label="退消耗订单"></el-table-column>
            <el-table-column label="退消耗次数">
              <template slot-scope="scope">
                <span class="color_green">+{{ scope.row.Quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="退款记录" name="1">
          <el-table size="small" :data="treatBillList">
            <el-table-column prop="BillDate" label="退款时间"></el-table-column>
            <el-table-column prop="BillID" label="退款订单"></el-table-column>
            <el-table-column label="退款次数">
              <template slot-scope="scope">
                <span class="color_red">-{{ scope.row.Quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="转账记录" name="3">
          <el-table size="small" :data="treatBillList">
            <el-table-column prop="BillDate" label="转账时间"></el-table-column>
            <el-table-column prop="BillID" label="转账订单"></el-table-column>
            <el-table-column label="转账次数">
              <template slot-scope="scope">
                <span v-if="scope.row.TransferType == 10" class="color_red">-{{ scope.row.Quantity }}</span>
                <span v-if="scope.row.TransferType == 20" class="color_green">+{{ scope.row.Quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="还款记录" name="4">
          <el-table size="small" :data="treatBillList">
            <el-table-column prop="BillDate" label="还款时间"></el-table-column>
            <el-table-column prop="BillID" label="还款订单"></el-table-column>
            <el-table-column prop="Amount" label="还款金额">
              <template slot-scope="scope">
                <span class="color_green">￥{{ scope.row.Amount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>

        <!-- <el-tab-pane label="平账记录" name="5">
            <el-table size="small" :data="treatBillList" style="width: 100%">
              <el-table-column prop="Amount" label="平账金额">
                <template slot-scope="scope">
                  ￥{{ scope.row.Amount | NumFormat }}
                </template>
              </el-table-column>
              <el-table-column prop="EmployeeName" label="平账人员"></el-table-column>
              <el-table-column prop="EntityName" label="平账门店"></el-table-column>
              <el-table-column prop="BillDate" label="平账时间"></el-table-column>
            </el-table>
            <div class="text_right pad_15">
              <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
            </div>
          </el-tab-pane> -->
      </el-tabs>
    </el-dialog>

    <!--异动记录 储值卡 -->
    <el-dialog title="操作记录" :visible.sync="checkSavingCardRecord" width="980px" :append-to-body="true">
      <el-tabs v-model="tabSavingcard" @tab-click="handleSavingCardRecord" v-show="isShowRecord == 1">
        <el-tab-pane label="消耗记录" name="0">
          <el-table size="small" :data="savingCardRecord">
            <el-table-column prop="BillDate" label="消耗时间"></el-table-column>
            <el-table-column prop="BillID" label="消耗订单"></el-table-column>
            <el-table-column label="消耗金额">
              <template slot-scope="scope">
                <span class="color_red">-￥{{ scope.row.Amount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="消耗本金">
              <template slot-scope="scope">
                <span class="color_red">-￥{{ scope.row.CardTreatAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="消耗赠额">
              <template slot-scope="scope">
                <span class="color_red">-￥{{ scope.row.LargessCardTreatAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="退消耗记录" name="2">
          <el-table size="small" :data="savingCardRecord">
            <el-table-column prop="BillDate" label="退消耗时间"></el-table-column>
            <el-table-column prop="BillID" label="退消耗订单"></el-table-column>
            <el-table-column label="退消耗金额">
              <template slot-scope="scope">
                <span class="color_green">+￥{{ scope.row.TotalAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="退消耗本金">
              <template slot-scope="scope">
                <span class="color_green">+￥{{ scope.row.Amount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="退消耗赠额">
              <template slot-scope="scope">
                <span class="color_green">+￥{{ scope.row.LargessAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="退款记录" name="1">
          <el-table size="small" :data="savingCardRecord">
            <el-table-column prop="BillDate" label="退款时间"></el-table-column>
            <el-table-column prop="BillID" label="退款订单"></el-table-column>
            <el-table-column label="退金额">
              <template slot-scope="scope">
                <span class="color_red">-￥{{ scope.row.TotalAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="退本金">
              <template slot-scope="scope">
                <span class="color_red">-￥{{ scope.row.Amount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="退赠额">
              <template slot-scope="scope">
                <span class="color_red">-￥{{ scope.row.LargessAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="转账记录" name="3">
          <el-table size="small" :data="savingCardRecord">
            <el-table-column prop="BillDate" label="转账时间"></el-table-column>
            <el-table-column prop="BillID" label="转账订单"></el-table-column>
            <el-table-column label="转账金额">
              <template slot-scope="scope">
                <span v-if="scope.row.TransferType == 10" class="color_red">-￥{{ scope.row.TotalAmount | NumFormat }}</span>
                <span v-if="scope.row.TransferType == 20" class="color_green">+￥{{ scope.row.TotalAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="转账本金">
              <template slot-scope="scope">
                <span v-if="scope.row.TransferType == 10" class="color_red">-￥{{ scope.row.Amount | NumFormat }}</span>
                <span v-if="scope.row.TransferType == 20" class="color_green">+￥{{ scope.row.Amount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="转账赠额">
              <template slot-scope="scope">
                <span v-if="scope.row.TransferType == 10" class="color_red">-￥{{ scope.row.LargessAmount | NumFormat }}</span>
                <span v-if="scope.row.TransferType == 20" class="color_green">+￥{{ scope.row.LargessAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="销售抵扣/退抵扣记录" name="4">
          <el-table size="small" :data="savingCardRecord">
            <el-table-column prop="BillDate" label="抵扣/退抵扣编号"></el-table-column>
            <el-table-column prop="BillID" label="销售单"></el-table-column>
            <el-table-column label="抵扣/退抵扣金额">
              <template slot-scope="scope">
                <span v-if="scope.row.DeductionType == 10" class="color_red">-￥{{ scope.row.TotalAmount | NumFormat }}</span>
                <span v-if="scope.row.DeductionType == 20" class="color_green">+￥{{ scope.row.TotalAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="抵扣/退抵扣本金">
              <template slot-scope="scope">
                <span v-if="scope.row.DeductionType == 10" class="color_red">-￥{{ scope.row.Amount | NumFormat }}</span>
                <span v-if="scope.row.DeductionType == 20" class="color_green">+￥{{ scope.row.Amount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="抵扣/退抵扣赠额">
              <template slot-scope="scope">
                <span v-if="scope.row.DeductionType == 10" class="color_red">-￥{{ scope.row.LargessAmount }}</span>
                <span v-if="scope.row.DeductionType == 20" class="color_green">+￥{{ scope.row.LargessAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="还款记录" name="5">
          <el-table size="small" :data="savingCardRecord">
            <el-table-column prop="BillDate" label="还款时间"></el-table-column>
            <el-table-column prop="BillID" label="还款订单"></el-table-column>
            <el-table-column prop="Amount" label="还款金额">
              <template slot-scope="scope">
                <span class="color_green">￥{{ scope.row.Amount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="平账记录" name="6">
            <el-table size="small" :data="savingCardRecord" style="width: 100%">
              <el-table-column prop="Amount" label="平账金额">
                <template slot-scope="scope">
                  {{ scope.row.Amount | NumFormat }}
                </template>
              </el-table-column>
              <el-table-column prop="EmployeeName" label="平账人员"></el-table-column>
              <el-table-column prop="EntityName" label="平账门店"></el-table-column>
              <el-table-column prop="BillDate" label="平账时间"></el-table-column>
            </el-table>
            <div class="text_right pad_15">
              <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
            </div>
          </el-tab-pane> -->
      </el-tabs>
    </el-dialog>

    <!--异动记录 通用次卡 -->
    <el-dialog title="操作记录" :visible.sync="checkGeneralCardRecord" width="980px" :append-to-body="true">
      <el-tabs v-model="tabGeneralCard" @tab-click="handleGeneralCardRecord" v-show="isShowRecord == 3">
        <el-tab-pane label="消耗记录" name="0">
          <el-table size="small" :data="generalCardRecord">
            <el-table-column prop="BillDate" label="消耗时间"></el-table-column>
            <el-table-column prop="BillID" label="消耗订单"></el-table-column>
            <el-table-column label="耗用次数">
              <template slot-scope="scope">
                <span class="color_red">-{{ scope.row.Quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="退消耗记录" name="2">
          <el-table size="small" :data="generalCardRecord">
            <el-table-column prop="BillDate" label="退消耗时间"></el-table-column>
            <el-table-column prop="BillID" label="消耗订单"></el-table-column>
            <el-table-column label="退消耗次数">
              <template slot-scope="scope">
                <span class="color_green">+{{ scope.row.Quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="退款记录" name="1">
          <el-table size="small" :data="generalCardRecord">
            <el-table-column prop="BillDate" label="退款时间"></el-table-column>
            <el-table-column prop="BillID" label="退款订单"></el-table-column>
            <el-table-column label="退款次数">
              <template slot-scope="scope">
                <span class="color_red">-{{ scope.row.Quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="转账记录" name="3">
          <el-table size="small" :data="generalCardRecord">
            <el-table-column prop="BillDate" label="转账时间"></el-table-column>
            <el-table-column prop="BillID" label="转账订单"></el-table-column>
            <el-table-column label="转账次数">
              <template slot-scope="scope">
                <span v-if="scope.row.TransferType == 10" class="color_red">-{{ scope.row.Quantity }}</span>
                <span v-if="scope.row.TransferType == 20" class="color_green">+{{ scope.row.Quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="还款记录" name="4">
          <el-table size="small" :data="generalCardRecord">
            <el-table-column prop="BillDate" label="还款时间"></el-table-column>
            <el-table-column prop="BillID" label="还款订单"></el-table-column>
            <el-table-column prop="Amount" label="还款金额">
              <template slot-scope="scope">
                <span class="color_green">￥{{ scope.row.Amount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="平账记录" name="5">
          <el-table size="small" :data="generalCardRecord" style="width: 100%">
            <el-table-column prop="Amount" label="平账金额">
              <template slot-scope="scope">
                {{ scope.row.Amount | NumFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="EmployeeName" label="平账人员"></el-table-column>
            <el-table-column prop="EntityName" label="平账门店"></el-table-column>
            <el-table-column prop="BillDate" label="平账时间"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </el-dialog>

    <!--异动记录 时效卡 -->
    <el-dialog title="操作记录" :visible.sync="checkTimerCardRecord" width="980px" :append-to-body="true">
      <el-tabs v-model="tabTimeCard" @tab-click="handleTimeCardRecord" v-show="isShowRecord == 4">
        <el-tab-pane label="消耗记录" name="0">
          <el-table size="small" :data="timeCardRecord">
            <el-table-column prop="BillDate" label="消耗时间" :render-header="renderHeader"></el-table-column>
            <el-table-column prop="BillID" label="消耗订单"></el-table-column>
            <el-table-column prop="CurrentWeight" label="消耗时体重"></el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="HandlerName" label="消耗经手人"></el-table-column>
            <el-table-column prop="Remark" label="消耗备注"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="退消耗记录" name="2">
          <el-table size="small" :data="timeCardRecord">
            <el-table-column prop="BillDate" label="退消耗时间"></el-table-column>
            <el-table-column prop="BillID" label="退消耗订单"></el-table-column>
            <el-table-column label="退消耗次数">
              <template slot-scope="scope">
                <span class="color_green">+{{ scope.row.Quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="退款记录" name="1">
          <el-table size="small" :data="timeCardRecord">
            <el-table-column prop="BillDate" label="退款时间"></el-table-column>
            <el-table-column prop="BillID" label="退款订单"></el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="转账记录" name="3">
          <el-table size="small" :data="timeCardRecord">
            <el-table-column prop="BillDate" label="转账时间"></el-table-column>
            <el-table-column prop="BillID" label="转账订单"></el-table-column>
            <el-table-column label="转账类型">
              <template slot-scope="scope">
                <span v-if="scope.row.TransferType == 10" class="color_red">转出</span>
                <span v-if="scope.row.TransferType == 20" class="color_green">转入</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>

        <el-tab-pane label="还款记录" name="4">
          <el-table size="small" :data="timeCardRecord">
            <el-table-column prop="BillDate" label="还款时间"></el-table-column>
            <el-table-column prop="BillID" label="还款订单"></el-table-column>
            <el-table-column prop="Amount" label="还款金额">
              <template slot-scope="scope">
                <span class="color_green">￥{{ scope.row.Amount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="修改记录" name="5">
          <el-table size="small" :data="timeCardRecord" style="width: 100%" tooltip-effect="light">
            <el-table-column prop="EntityName" label="修改门店"></el-table-column>
            <el-table-column prop="OriginalTargetWeight" label="原目标减重"></el-table-column>
            <el-table-column prop="EditTargetWeight" label="修改后目标减重"></el-table-column>
            <el-table-column prop="Remark" label="备注" show-overflow-tooltip></el-table-column>
            <el-table-column prop="CreatedOn" label="修改时间"></el-table-column>
            <el-table-column prop="EmployeeName" label="修改人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="平账记录" name="5">
          <el-table size="small" :data="timeCardRecord" style="width: 100%">
            <el-table-column prop="Amount" label="平账金额">
              <template slot-scope="scope">
                {{ scope.row.Amount | NumFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="EmployeeName" label="平账人员"></el-table-column>
            <el-table-column prop="EntityName" label="平账门店"></el-table-column>
            <el-table-column prop="BillDate" label="平账时间"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </el-dialog>

    <!--异动记录 产品 -->
    <el-dialog title="操作记录" :visible.sync="checkProductRecord" width="980px" :append-to-body="true">
      <el-tabs v-model="tabProduct" @tab-click="handleProductRecord" v-show="isShowRecord == 5">
        <el-tab-pane label="消耗记录" name="0">
          <el-table size="small" :data="productRecord">
            <el-table-column prop="BillDate" label="消耗时间"></el-table-column>
            <el-table-column prop="BillID" label="消耗订单"></el-table-column>
            <el-table-column label="消耗数量">
              <template slot-scope="scope">
                <span class="color_red">-{{ scope.row.Quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="退消耗记录" name="2">
          <el-table size="small" :data="productRecord">
            <el-table-column prop="BillDate" label="退消耗时间"></el-table-column>
            <el-table-column prop="BillID" label="退消耗订单"></el-table-column>
            <el-table-column label="退消耗次数">
              <template slot-scope="scope">
                <span class="color_green">+{{ scope.row.Quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="退款记录" name="1">
          <el-table size="small" :data="productRecord">
            <el-table-column prop="BillDate" label="退款时间"></el-table-column>
            <el-table-column prop="BillID" label="退款订单"></el-table-column>
            <el-table-column label="退款次数">
              <template slot-scope="scope">
                <span class="color_red">-{{ scope.row.Quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="转账记录" name="3">
          <el-table size="small" :data="productRecord">
            <el-table-column prop="BillDate" label="转账时间"></el-table-column>
            <el-table-column prop="BillID" label="转账订单"></el-table-column>
            <el-table-column label="转账次数">
              <template slot-scope="scope">
                <span v-if="scope.row.TransferType == 10" class="color_red">-{{ scope.row.Quantity }}</span>
                <span v-if="scope.row.TransferType == 20" class="color_green">+{{ scope.row.Quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="还款记录" name="4">
          <el-table size="small" :data="productRecord">
            <el-table-column prop="BillDate" label="还款时间"></el-table-column>
            <el-table-column prop="BillID" label="还款订单"></el-table-column>
            <el-table-column prop="Amount" label="还款金额">
              <template slot-scope="scope">
                <span class="color_green">￥{{ scope.row.Amount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="操作门店"></el-table-column>
            <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
          </el-table>
          <div class="text_right pad_15">
            <el-pagination background v-if="recordPaginations.total > 0" @current-change="recordHandleCurrentChange" :current-page.sync="recordPaginations.page" :page-size="recordPaginations.page_size" :layout="recordPaginations.layout" :total="recordPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!--套餐卡明细-->
    <el-dialog class="customerAccount_package_detial" title="套餐卡详情" :visible.sync="packageDetails" width="1080px" :append-to-body="true">
      <el-tabs v-model="activePackageDetailName" v-if="Object.keys(packageCardDetail).length > 0" type="border-card">
        <el-tab-pane label="项目" name="0">
          <span slot="label"
            >项目
            <el-badge v-if="packageCardDetail.Project.length > 0" is-dot />
          </span>
          <el-table size="small" :data="packageCardDetail.Project" height="500">
            <el-table-column prop="Name" label="名称">
              <template slot-scope="scope">
                <span>{{ scope.row.Name }}</span
                ><span v-if="scope.row.Alias" class="font_12 color_gray">({{ scope.row.Alias }})</span>
                <el-tag v-if="scope.row.IsLargess" type="danger" size="mini" class="marlt_5">赠</el-tag>
              </template>
              <span></span>
            </el-table-column>
            <el-table-column prop="BuyDate" label="开卡时间"></el-table-column>
            <el-table-column prop="EntityName" label="开卡门店"></el-table-column>
            <el-table-column prop="TotalAmount" label="卡售价">
              <template slot-scope="scope"> ￥{{ scope.row.TotalAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="PayAmount" label="实付">
              <template slot-scope="scope"> ￥{{ scope.row.PayAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="SavingCardDeductionAmount" label="卡扣">
              <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="SavingCardDeductionLargessAmount" label="赠卡扣">
              <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionLargessAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="Quantity" label="开卡次数"></el-table-column>
            <el-table-column prop="Balance" label="剩余次数"></el-table-column>
            <el-table-column prop="BalanceAmount">
              <div slot="header">
                <div>余额</div>
                <div>不含赠卡扣</div>
              </div>
              <template slot-scope="scope">
                <span class="color_main">￥{{ scope.row.BalanceAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="欠款">
              <template slot-scope="scope">
                <span class="color_red">￥{{ scope.row.ArrearAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ChannelName" label="来源"></el-table-column>
            <el-table-column label="操作" width="100px">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="handlePackageRecord(scope.row, 0)" v-prevent-click>操作记录 </el-button>
                <!-- <el-button v-if="scope.row.ArrearAmount > 0" type="primary" size="small" @click="balancingAccount(scope.row, 0)" v-prevent-click>平账
              </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="储值卡" name="1">
          <span slot="label"
            >储值卡
            <el-badge v-if="packageCardDetail.SavingCard.length > 0" is-dot />
          </span>
          <el-table size="small" :data="packageCardDetail.SavingCard" height="500" ref="table" :summary-method="getSaveCardData">
            <el-table-column prop="Name" label="名称">
              <template slot-scope="scope">
                <span>{{ scope.row.Name }}</span
                ><span v-if="scope.row.Alias" class="font_12 color_gray">({{ scope.row.Alias }})</span>
                <el-tag v-if="scope.row.IsLargess" type="danger" size="mini" class="marlt_5">赠</el-tag>
              </template>
              <span></span>
            </el-table-column>
            <el-table-column prop="BuyDate" label="开卡时间"></el-table-column>
            <el-table-column prop="EntityName" label="开卡门店"></el-table-column>
            <el-table-column prop="Amount" label="卡售价">
              <template slot-scope="scope"> ￥{{ scope.row.Amount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="ValidDayName" label="有效期"></el-table-column>
            <el-table-column prop="TotalBalance" label="账户余额">
              <template slot-scope="scope"> ￥{{ scope.row.TotalBalance | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="Balance" label="本金">
              <template slot-scope="scope"> ￥{{ scope.row.Balance | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="LargessBalance" label="赠额">
              <template slot-scope="scope"> ￥{{ scope.row.LargessBalance | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="BalanceAmount">
              <div slot="header">
                <div>余额</div>
                <div>不含赠卡扣</div>
              </div>
              <template slot-scope="scope">
                <span class="color_main">￥{{ scope.row.BalanceAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="欠款">
              <template slot-scope="scope">
                <span class="color_red">￥{{ scope.row.ArrearAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ChannelName" label="来源"></el-table-column>
            <el-table-column label="操作" width="100px">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="handlePackageRecord(scope.row, 1)" v-prevent-click>操作记录 </el-button>
                <!-- <el-button v-if="scope.row.ArrearAmount > 0" type="primary" size="small" @click="balancingAccount(scope.row, 1)" v-prevent-click>平账
              </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="时效卡" name="2">
          <span slot="label"
            >时效卡
            <el-badge v-if="packageCardDetail.TimeCard.length > 0" is-dot />
          </span>
          <el-table size="small" :data="packageCardDetail.TimeCard" height="500">
            <el-table-column prop="Name" label="名称">
              <template slot-scope="scope">
                <span>{{ scope.row.Name }}</span
                ><span v-if="scope.row.Alias" class="font_12 color_gray">({{ scope.row.Alias }})</span>
                <el-tag v-if="scope.row.IsLargess" type="danger" size="mini" class="marlt_5">赠</el-tag>
              </template>
              <span></span>
            </el-table-column>
            <el-table-column prop="BuyDate" label="开卡时间"></el-table-column>
            <el-table-column prop="EntityName" label="开卡门店"></el-table-column>
            <el-table-column prop="TotalAmount" label="卡售价">
              <template slot-scope="scope"> ￥{{ scope.row.TotalAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="PayAmount" label="实付">
              <template slot-scope="scope"> ￥{{ scope.row.PayAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="SavingCardDeductionAmount" label="卡扣">
              <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="SavingCardDeductionLargessAmount" label="赠卡扣">
              <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionLargessAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="ValidDayName" label="有效期"></el-table-column>
            <el-table-column prop="ConsumeAmount" label="累计消耗次数"></el-table-column>
            <el-table-column prop="BalanceAmount">
              <div slot="header">
                <div>余额</div>
                <div>不含赠卡扣</div>
              </div>
              <template slot-scope="scope">
                <span class="color_main">￥{{ scope.row.BalanceAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="欠款">
              <template slot-scope="scope">
                <span class="color_red">￥{{ scope.row.ArrearAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ChannelName" label="来源"></el-table-column>
            <el-table-column label="操作" width="170px">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="handlePackageRecord(scope.row, 3)" v-prevent-click>操作记录</el-button>
                <el-button v-if="scope.row.TargetWeight" type="primary" size="small" @click="editRecord(scope.row,'packageDetail')" v-prevent-click>修改</el-button>
                <!-- <el-button v-if="scope.row.ArrearAmount > 0" type="primary" size="small" @click="balancingAccount(scope.row, 3)" v-prevent-click>平账
              </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="通用次卡" name="3">
          <span slot="label"
            >通用次卡
            <el-badge v-if="packageCardDetail.GeneralCard.length > 0" is-dot />
          </span>
          <el-table size="small" :data="packageCardDetail.GeneralCard" height="500">
            <el-table-column prop="Name" label="名称">
              <template slot-scope="scope">
                <span>{{ scope.row.Name }}</span
                ><span v-if="scope.row.Alias" class="font_12 color_gray">({{ scope.row.Alias }})</span>
                <el-tag v-if="scope.row.IsLargess" type="danger" size="mini" class="marlt_5">赠</el-tag>
              </template>
              <span></span>
            </el-table-column>
            <el-table-column prop="BuyDate" label="开卡时间"></el-table-column>
            <el-table-column prop="EntityName" label="开卡门店"></el-table-column>
            <el-table-column prop="TotalAmount" label="卡售价">
              <template slot-scope="scope"> ￥{{ scope.row.TotalAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="PayAmount" label="实付">
              <template slot-scope="scope"> ￥{{ scope.row.PayAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="SavingCardDeductionAmount" label="卡扣">
              <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="SavingCardDeductionLargessAmount" label="赠卡扣">
              <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionLargessAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="ValidDayName" label="有效期"></el-table-column>
            <el-table-column prop="CardTimes" label="开卡次数"></el-table-column>
            <el-table-column prop="Balance" label="剩余次数"></el-table-column>
            <el-table-column prop="BalanceAmount">
              <div slot="header">
                <div>余额</div>
                <div>不含赠卡扣</div>
              </div>
              <template slot-scope="scope">
                <span class="color_main">￥{{ scope.row.BalanceAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="欠款">
              <template slot-scope="scope">
                <span class="color_red">￥{{ scope.row.ArrearAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ChannelName" label="来源"></el-table-column>
            <el-table-column label="操作" width="100px">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="handlePackageRecord(scope.row, 2)" v-prevent-click>操作记录 </el-button>
                <!-- <el-button v-if="scope.row.ArrearAmount > 0" type="primary" size="small" @click="balancingAccount(scope.row, 2)" v-prevent-click>平账
              </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="产品" name="4">
          <span slot="label"
            >产品
            <el-badge v-if="packageCardDetail.Product.length > 0" is-dot />
          </span>
          <el-table size="small" :data="packageCardDetail.Product" height="500">
            <el-table-column prop="Name" label="名称">
              <template slot-scope="scope">
                <span>{{ scope.row.Name }}</span
                ><span v-if="scope.row.Alias" class="font_12 color_gray">({{ scope.row.Alias }})</span>
                <el-tag v-if="scope.row.IsLargess" type="danger" size="mini" class="marlt_5">赠</el-tag>
              </template>
              <span></span>
            </el-table-column>
            <el-table-column prop="BuyDate" label="购买时间"></el-table-column>
            <el-table-column prop="EntityName" label="购买门店"></el-table-column>
            <el-table-column prop="TotalAmount" label="售价">
              <template slot-scope="scope"> ￥{{ scope.row.TotalAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="PayAmount" label="实付">
              <template slot-scope="scope"> ￥{{ scope.row.PayAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="SavingCardDeductionAmount" label="卡扣">
              <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="SavingCardDeductionLargessAmount" label="赠卡扣">
              <template slot-scope="scope"> ￥{{ scope.row.SavingCardDeductionLargessAmount | NumFormat }} </template>
            </el-table-column>
            <el-table-column prop="Quantity" label="购买数量"></el-table-column>
            <el-table-column prop="Balance" label="剩余数量"></el-table-column>
            <el-table-column prop="BalanceAmount">
              <div slot="header">
                <div>余额</div>
                <div>不含赠卡扣</div>
              </div>
              <template slot-scope="scope">
                <span class="color_main">￥{{ scope.row.BalanceAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="欠款">
              <template slot-scope="scope">
                <span class="color_red">￥{{ scope.row.ArrearAmount | NumFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ChannelName" label="来源"></el-table-column>
            <el-table-column label="操作" width="100px">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="handlePackageRecord(scope.row, 4)" v-prevent-click>操作记录 </el-button>
                <!-- <el-button v-if="scope.row.ArrearAmount > 0" type="primary" size="small" @click="balancingAccount(scope.row, 4)" v-prevent-click>平账
              </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!--平账-->
    <el-dialog :visible.sync="balancingAccounts" width="30%" :append-to-body="true">
      <span slot="title">平账<span class="marlt_20 font_14 color_gray">客户不再还欠款金额</span></span>
      <el-form :model="balanceParams" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="欠款金额" prop="ArrearAmount">
          <span>{{ balanceParams.ArrearAmount }}元</span>
        </el-form-item>
        <el-form-item label="平账次数" prop="Quantity" v-if="isSavingCard">
          <el-input-number style="width: 120px" size="small" v-model="balanceParams.Quantity" :min="1" @change="handleBalanceChange" :max="balanceParams.maxQuantity"></el-input-number>
          <span class="marlt_20 font_14 color_gray">平账金额=项目单价*平账次数</span>
        </el-form-item>
        <el-form-item label="平账金额">
          <el-input @input="balancePriceChange" type="number" min="0" v-enter-number2 v-model="balanceParams.Amount" size="small" :disabled="isSavingCard" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="balancingAccounts = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="submitAccounts" v-prevent-click>平账 </el-button>
      </div>
    </el-dialog>
    <!-- 修改目标体重 -->
    <el-dialog title="修改目标体重" :visible.sync="TargetWeightDialog" width="650px" :append-to-body="true" @close="clearValidate('TargetWeightruleFormTo')">
      <el-form label-width="150px" ref="TargetWeightruleFormTo" :rules="rules_Weight" :model="TargetWeightruleForm">
        <el-form-item label="当前目标减重">
          <el-input v-model="TargetWeightruleForm.Weight" size="small" disabled>
            <template slot="append">斤</template>
          </el-input>
        </el-form-item>
        <el-form-item label="当前目标体重">
          <el-input v-model="TargetWeightruleForm.CustomerTargetWeight" size="small" disabled>
            <template slot="append">斤</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="CustomerTargetWeight" label="修改后目标体重" v-enter-int>
          <el-input v-model="TargetWeightruleFormTo.CustomerTargetWeight" size="small" @change="getValueInput">
            <template slot="append">斤</template>
          </el-input>
        </el-form-item>
        <el-form-item label="修改后目标减重" v-enter-int>
          <el-input v-model="TargetWeightruleForm.TargetWeight" size="small" disabled>
            <template slot="append">斤</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="" label="备注">
          <el-input v-model="TargetWeightruleForm.Remark" type="textarea" :rows="3" resize="none"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="TargetWeightDialog = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="submitTargetWeight" v-prevent-click>确定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/CRM/Customer/customerAccount";

export default {
  props: {
    customerID: Number,
  },
  data() {
    return {
      TargetWeightType:"",
      TargetWeightDialog: false, //修改目标减重弹窗
      checkSavingCardRecord: false, // 储值卡 异动记录
      checkGeneralCardRecord: false,
      checkTimerCardRecord: false,
      checkProductRecord: false,
      IsShowZero: true,

      IsShowZeroOptions: [
        {
          value: true,
          label: "可使用",
        },
        {
          value: false,
          label: "已失效",
        },
      ],
      balanceParams: {
        Amount: "",
        price: 0,
        Quantity: 1,
        maxQuantity: 0,
        ArrearAmount: "",
        ID: "",
      },
      projectData: [],
      summation: {}, //合计
      rules: {
        ArrearAmount: [{ required: true, message: "", trigger: "blur" }],
        Quantity: [{ required: true, message: "", trigger: "blur" }],
      },
      TargetWeightruleForm: {
        ID: "",
        Weight: "",
        TargetWeight: "",
        Remark: "",
        CustomerTargetWeight: "",
      },
      TargetWeightruleFormTo: {
        CustomerTargetWeight: "",
      },
      rules_Weight: {
        CustomerTargetWeight: [{ required: true, message: "请输入修改后的目标体重", trigger: "blur" }],
      },
      treatBillList: [],
      packageDetails: false,
      value: false,
      loading: false,
      changeRecordDialog: false,
      isSavingCard: false,
      balancingAccounts: false,
      isShowRecord: 0,
      selectRow: {},
      activeName: "0",
      activePackageDetailName: "0",
      packageRecordActive: "",
      tabProject: "0",
      tabSavingcard: "0",
      tabPackage: 0,
      tabGeneralCard: "0",
      tabTimeCard: "0",
      tabProduct: "0",
      savingCardRecord: [], //储值卡异动记录
      timeCardModification: [], //储值卡修改记录
      generalCardRecord: [],
      timeCardRecord: [],
      productRecord: [],
      packageCardDetail: {},
      selectPackageID: "",
      paginations: {
        page: 1, // 当前位于哪页
        total: 5, // 总数
        total1: 0,
        page_size: 5, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      }, //需要给分页组件传的信息
      recordPaginations: {
        page: 1, // 当前位于哪页
        total: 5, // 总数
        page_size: 5, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      }, //需要给分页组件传的信息
      upState: "up", // 时效卡排序
    };
  },
  methods: {
    // 自定义表头
    renderHeader(h, { column }) {
      console.log(h);
      console.log(column);
      return h("div", [
        h("span", column.label),
        h("i", {
          class: "el-icon-sort",
          style: "color:#409eff;margin-left:5px;transforms:rotate(180deg);cursor:pointer;",
          on: {
            click: this.tableClick, // 排序事件
          },
        }),
      ]);
    },
    // 排序
    tableClick() {
      console.log("请求了");
      if (this.upState == "up") {
        this.upState = "down";
      } else {
        this.upState = "up";
      }
      this.getTimeCardTreatBill();
    },
    // 获取修改后的目标体重
    getValueInput(e) {
      var that = this;
      that.TargetWeightruleFormTo.CustomerTargetWeight = "";
      that.TargetWeightruleFormTo.CustomerTargetWeight = e;
      console.log(that.TargetWeightruleFormTo.CustomerTargetWeight);
      var item = that.TargetWeightruleForm;
      // var itemTo = that.TargetWeightruleFormTo
      item.TargetWeight = item.CustomerTargetWeight - that.TargetWeightruleFormTo.CustomerTargetWeight + item.Weight;
      if (that.TargetWeightruleFormTo.CustomerTargetWeight == "") {
        item.TargetWeight = "";
      }
    },
    // 上下分页
    handleCurrentChange1(page) {
      var that = this;
      that.paginations.page = page;
      switch (this.activeName) {
        case "0":
          that.getProjectAccount();
          break;
        case "1":
          that.getSavingCardAccount();
          break;
        case "2":
          that.getPackageCardAccount();
          break;
        case "3":
          that.getGeneralCardAccount();
          break;
        case "4":
          that.getTimeCardAccount();
          break;
        case "5":
          that.getProductAccount();
          break;
      }
    },
    /**  操作记录 分页切换  */
    recordHandleCurrentChange(page) {
      let that = this;
      that.recordPaginations.page = page;
      switch (this.activeName) {
        case "0":
          that.getProjectRecordNet();
          break;
        case "1":
          that.getSavingCardRecordNet();
          break;
        case "2":
          // that.getPackageCardAccount();
          switch (that.packageRecordActive) {
            case "project":
              that.getProjectRecordNet();
              break;
            case "savingCard":
              that.getSavingCardRecordNet();
              break;
            case "generalCard":
              that.getGeneralCardRecord();
              break;
            case "timeCard":
              that.getTimeCardRecord_Net();
              break;
            case "product":
              that.getProductRecord_Net();
              break;
          }

          break;
        case "3":
          that.getGeneralCardRecord();
          break;
        case "4":
          that.getTimeCardRecord_Net();
          break;
        case "5":
          that.getProductRecord_Net();
          break;
      }
    },
    //清除表单校验结果
    clearValidate(ref) {
      this.$refs[ref].clearValidate();
    },
    // 修改体重
    submitTargetWeight() {
      var that = this;
      this.$refs["TargetWeightruleFormTo"].validate((prop) => {
        if (prop) {
          that.TargetWeightruleForm.CustomerTargetWeight = that.TargetWeightruleFormTo.CustomerTargetWeight;
          API.updateTimeCardAccountTargetWeight(this.TargetWeightruleForm).then((res) => {
            if (res.StateCode == 200) {
              this.$message.success("已修改");
              this.TargetWeightDialog = false;
              if(that.TargetWeightType == "packageDetali"){
                that.packageDetail();
              }
              else{
                this.handleClick();
              }
            } else {
              this.$message.error(res.Message);
            }
          });
        }
      });
    },
    /** ============ 平账============  */
    //平账确认
    submitAccounts() {
      var that = this;
      this.$confirm("是否继续平账?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          switch (this.activeName) {
            case "0":
              await that.updateProjectAccounts().then((res) => {
                if (res.StateCode == 200) {
                  that.$message.success({
                    message: res.Message,
                    duration: 2000,
                  });
                  that.getProjectAccount();
                } else {
                  that.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
              });
              break;
            case "1":
              await that.undataSavingCardAccounts().then((res) => {
                if (res.StateCode == 200) {
                  that.$message.success({
                    message: res.Message,
                    duration: 2000,
                  });
                  that.getSavingCardAccount();
                } else {
                  that.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
              });
              break;
            case "2":
              switch (that.tabPackage) {
                case 0:
                  await that.updateProjectAccounts().then((res) => {
                    if (res.StateCode == 200) {
                      that.$message.success({
                        message: res.Message,
                        duration: 2000,
                      });
                      that.packageDetail();
                    } else {
                      that.$message.error({
                        message: res.Message,
                        duration: 2000,
                      });
                    }
                  });
                  break;
                case 1:
                  await that.undataSavingCardAccounts().then((res) => {
                    if (res.StateCode == 200) {
                      that.$message.success({
                        message: res.Message,
                        duration: 2000,
                      });
                      that.packageDetail();
                    } else {
                      that.$message.error({
                        message: res.Message,
                        duration: 2000,
                      });
                    }
                  });
                  break;
                case 2:
                  await that.undataGeneralCardAccounts().then((res) => {
                    if (res.StateCode == 200) {
                      that.$message.success({
                        message: res.Message,
                        duration: 2000,
                      });
                      that.packageDetail();
                    } else {
                      that.$message.error({
                        message: res.Message,
                        duration: 2000,
                      });
                    }
                  });
                  break;
                case 3:
                  await that.undataTimeAccounts().then((res) => {
                    if (res.StateCode == 200) {
                      that.$message.success({
                        message: res.Message,
                        duration: 2000,
                      });
                      that.packageDetail();
                    } else {
                      that.$message.error({
                        message: res.Message,
                        duration: 2000,
                      });
                    }
                  });
                  break;
                case 4:
                  await that.undataProductAccounts().then((res) => {
                    if (res.StateCode == 200) {
                      that.$message.success({
                        message: res.Message,
                        duration: 2000,
                      });
                      that.packageDetail();
                    } else {
                      that.$message.error({
                        message: res.Message,
                        duration: 2000,
                      });
                    }
                  });
                  break;
              }
              break;
            case "3":
              await that.undataGeneralCardAccounts().then((res) => {
                if (res.StateCode == 200) {
                  that.$message.success({
                    message: res.Message,
                    duration: 2000,
                  });
                  that.getGeneralCardAccount();
                } else {
                  that.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
              });

              break;
            case "4":
              await that.undataTimeAccounts().then((res) => {
                if (res.StateCode == 200) {
                  that.$message.success({
                    message: res.Message,
                    duration: 2000,
                  });
                  that.getTimeCardAccount();
                } else {
                  that.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
              });
              break;
            case "5":
              await that.undataProductAccounts().then((res) => {
                if (res.StateCode == 200) {
                  that.$message.success({
                    message: res.Message,
                    duration: 2000,
                  });
                  that.getProductAccount();
                } else {
                  that.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
              });
              break;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        })
        .finally(() => {
          that.balancingAccounts = false;
        });
    },
    editRecord(row,type) {
      this.TargetWeightType = type;
      this.TargetWeightruleFormTo.CustomerTargetWeight = "";
      this.TargetWeightruleForm = {
        Weight: row.TargetWeight,
        ID: row.ID,
        TargetWeight: "",
        Remark: "",
        CustomerTargetWeight: row.CustomerTargetWeight,
      };
      this.TargetWeightDialog = true;
    },
    //项目平账
    updateProjectAccounts() {
      var that = this;
      let params = {
        ProjectAccountID: that.balanceParams.ID,
        Amount: that.balanceParams.Amount,
        Quantity: that.balanceParams.Quantity,
      };
      return API.createProjectAccountArrearAmountProcess(params);
    },
    //通用次卡平账
    undataGeneralCardAccounts() {
      var that = this;
      let params = {
        GeneralCardAccountID: that.balanceParams.ID,
        Amount: that.balanceParams.Amount,
        Quantity: that.balanceParams.Quantity,
      };
      return API.createGeneralCardAccountArrearAmountProcess(params);
    },
    //产品平账
    undataProductAccounts() {
      var that = this;
      let params = {
        ProductAccountID: that.balanceParams.ID,
        Amount: that.balanceParams.Amount,
        Quantity: that.balanceParams.Quantity,
      };
      return API.createProductAccountArrearAmountProcess(params);
    },
    //储值卡平账
    undataSavingCardAccounts() {
      var that = this;
      let params = {
        SavingCardAccountID: that.balanceParams.ID,
        Amount: that.balanceParams.Amount,
        Quantity: that.balanceParams.Quantity,
      };
      return API.createSavingCardAccountArrearAmountProcess(params);
    },
    //时效卡平账
    undataTimeAccounts() {
      var that = this;
      let params = {
        TimeCardAccountID: that.balanceParams.ID,
        Amount: that.balanceParams.Amount,
        Quantity: that.balanceParams.Quantity,
      };
      return API.createTimeCardAccountArrearAmountProcess(params);
    },
    //平账次数改变
    handleBalanceChange() {
      var that = this;
      that.balanceParams.Amount = parseFloat(that.balanceParams.price * that.balanceParams.Quantity).toFixed(2);
    },
    //平账金额改变
    balancePriceChange() {
      var that = this;
      if (parseFloat(that.balanceParams.Amount) > that.balanceParams.ArrearAmount) {
        that.balanceParams.Amount = that.balanceParams.ArrearAmount;
      }
    },
    //平账
    balancingAccount(row, index) {
      var that = this;
      let number = 0;
      switch (index) {
        case 0:
          that.isSavingCard = true;
          break;
        case 1:
          that.isSavingCard = false;
          break;
        case 2:
          that.isSavingCard = true;
          break;
        case 3:
          that.isSavingCard = true;
          break;
        case 4:
          that.isSavingCard = true;
          break;
      }
      number = parseInt(row.ArrearAmount / row.Price);
      that.tabPackage = index;
      that.balanceParams = {
        ID: row.ID,
        price: row.Price,
        Amount: typeof row.Price == "undefined" ? row.ArrearAmount : row.Price > row.ArrearAmount ? row.ArrearAmount : row.Price,
        Quantity: 1,
        maxQuantity: isNaN(number) ? 1 : number,
        ArrearAmount: row.ArrearAmount,
      };
      that.balancingAccounts = true;
    },
    //是否显示已耗尽项目信息
    handleIsZeroChange() {
      var that = this;
      that.handleClick();
    },
    //项目、储值卡...切换事件
    handleClick() {
      var that = this;
      that.paginations.page = 1;
      switch (this.activeName) {
        case "0":
          that.getProjectAccount();
          break;
        case "1":
          that.getSavingCardAccount();
          break;
        case "2":
          that.getPackageCardAccount();
          break;
        case "3":
          that.getGeneralCardAccount();
          break;
        case "4":
          that.getTimeCardAccount();
          break;
        case "5":
          that.getProductAccount();
          break;
      }
    },
    //异动记录
    changeRecord(row) {
      var that = this;
      that.selectRow = row;
      switch (this.activeName) {
        case "0":
          that.tabProject = "0";
          that.isShowRecord = 0;
          that.changeRecordDialog = true;
          that.handleProjctRecord();
          break;
        case "1":
          that.tabSavingcard = "0";
          that.isShowRecord = 1;
          that.checkSavingCardRecord = true;
          that.handleSavingCardRecord();
          break;
        case "3":
          that.tabGeneralCard = "0";
          that.isShowRecord = 3;
          that.checkGeneralCardRecord = true;
          that.handleGeneralCardRecord();
          break;
        case "4":
          that.tabTimeCard = "0";
          that.isShowRecord = 4;
          that.checkTimerCardRecord = true;
          that.handleTimeCardRecord();
          break;
        case "5":
          that.tabProduct = "0";
          that.isShowRecord = 5;
          that.checkProductRecord = true;
          that.handleProductRecord();
          break;
      }
    },

    /**  ================  */

    //项目异动记录切换
    handleProjctRecord() {
      var that = this;
      that.treatBillList = [];
      that.recordPaginations.page = 1;
      that.getProjectRecordNet();
    },
    /**  项目操作记录 请求  */
    getProjectRecordNet() {
      let that = this;
      switch (this.tabProject) {
        case "0":
          that.getTreatBill();
          break;
        case "1":
          that.getRefundBill();
          break;
        case "2":
          that.getRefundTreatBill();
          break;
        case "3":
          that.getTransferBill();
          break;
        case "4":
          that.getRepayment();
          break;
        case "5":
          that.getArrearAmountProcess();
          break;
      }
    },

    /**  ================  */
    //储值卡异动记录切换
    handleSavingCardRecord() {
      var that = this;
      that.savingCardRecord = [];
      that.recordPaginations.page = 1;
      that.getSavingCardRecordNet();
    },
    /**  储值卡操作记录请求  */
    getSavingCardRecordNet() {
      let that = this;
      switch (that.tabSavingcard) {
        case "0":
          that.getSavingCardTreatBill();
          break;
        case "1":
          that.getSavingCardRefundBill();
          break;
        case "2":
          that.getSavingCardRefundTreatBill();
          break;
        case "3":
          that.getSavingCardTransferBill();
          break;
        case "4":
          that.getSavingCardDeduction();
          break;
        case "5":
          that.getSavingCardRepayment();
          break;
        case "6":
          that.getSavingCardArrearAmountProcess();
          break;
      }
    },

    /**  ================  */

    //通用次卡异动记录切换
    handleGeneralCardRecord() {
      var that = this;
      that.generalCardRecord = [];
      that.recordPaginations.page = 1;
      that.getGeneralCardRecord();
    },
    /** 通用次卡操作记录   */
    getGeneralCardRecord() {
      let that = this;
      switch (that.tabGeneralCard) {
        case "0":
          that.getGeneralCardRefundBill();
          break;
        case "1":
          that.getGeneralCardTreatBill();
          break;
        case "2":
          that.getGeneralCardRefundTreatBill();
          break;
        case "3":
          that.getGeneralCardRefundTransferBill();
          break;
        case "4":
          that.getGeneralCardRefundRepayment();
          break;
        case "5":
          that.getGeneralCardArrearAmountProcess();
          break;
      }
    },

    /**  ================  */

    //时效卡异动记录切换
    handleTimeCardRecord() {
      var that = this;
      that.timeCardRecord = [];
      that.recordPaginations.page = 1;
      that.getTimeCardRecord_Net();
    },
    /** 时效卡操作记录请求   */
    getTimeCardRecord_Net() {
      var that = this;
      switch (this.tabTimeCard) {
        case "0":
          that.getTimeCardTreatBill();
          break;
        case "1":
          that.getTimeCardRefundBill();
          break;
        case "2":
          that.getTimeCardRefundTreatBill();
          break;
        case "3":
          that.getTimeCardRefundTransferBill();
          break;
        case "4":
          that.getTimeCardRefundRepayment();
          break;
        case "5":
          // that.getTimeCardRefundArrearAmountProcess();
          that.getTimeCardList();
          break;
      }
    },
    getTimeCardList() {
      var params = {
        PageNum: this.recordPaginations.page,
        TimeCardAccountID: this.selectRow.ID,
      };
      API.getTimeCardList(params).then((res) => {
        if (res.StateCode == 200) {
          this.recordPaginations.total = res.Total;
          this.recordPaginations.page_size = res.PageSize;
          this.timeCardRecord = res.List;
        } else {
          this.$message.error(res.Message);
        }
      });
    },
    /**  ================  */
    //产品异动记录切换
    handleProductRecord() {
      var that = this;
      that.productRecord = [];
      that.recordPaginations.page = 1;
      that.getProductRecord_Net();
    },
    /**  产品操作记录  */
    getProductRecord_Net() {
      let that = this;
      switch (that.tabProduct) {
        case "0":
          that.getProductAccountTreatBill();
          break;
        case "1":
          that.getProductAccountRefundBill();
          break;
        case "2":
          that.getProductAccountRefundTreatBill();
          break;
        case "3":
          that.getProductAccountTransferBill();
          break;
        case "4":
          that.getProductAccountRepayment();
          break;
        case "5":
          that.getProductAccountArrearAmountProcess();
          break;
      }
    },

    /**  套餐卡明细异动记录  */
    handlePackageRecord(row, index) {
      var that = this;
      that.selectRow = row;
      switch (index) {
        case 0:
          that.tabProject = "0";
          that.isShowRecord = 0;
          that.packageRecordActive = "project";
          that.changeRecordDialog = true;
          that.handleProjctRecord();
          break;
        case 1:
          that.tabSavingcard = "0";
          that.isShowRecord = 1;
          that.packageRecordActive = "savingCard";
          that.checkSavingCardRecord = true;
          that.handleSavingCardRecord();
          break;
        case 2:
          that.tabGeneralCard = "0";
          that.isShowRecord = 3;
          that.packageRecordActive = "generalCard";
          that.checkGeneralCardRecord = true;
          that.handleGeneralCardRecord();
          break;
        case 3:
          that.tabTimeCard = "0";
          that.isShowRecord = 4;
          that.packageRecordActive = "timeCard";
          that.checkTimerCardRecord = true;
          that.handleTimeCardRecord();
          break;
        case 4:
          that.tabProduct = "0";
          that.isShowRecord = 5;
          that.packageRecordActive = "product";
          that.checkProductRecord = true;
          that.handleProductRecord();
          break;
      }
    },

    //获取套餐卡详情
    getPackageCardDetails(row) {
      var that = this;
      that.selectPackageID = row.ID;
      that.packageDetail();
    },
    /**  套餐卡明细 请求  */
    packageDetail() {
      var that = this;
      that.loading = true;
      var params = {
        PackageCardAccountID: that.selectPackageID,
      };
      API.packageCardAccountDetails(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageCardDetail = res.Data;
            if (that.packageCardDetail.Project.length > 0) {
              that.activePackageDetailName = "0";
            } else if (that.packageCardDetail.SavingCard.length > 0) {
              that.activePackageDetailName = "1";
            } else if (that.packageCardDetail.TimeCard.length > 0) {
              that.activePackageDetailName = "2";
            } else if (that.packageCardDetail.GeneralCard.length > 0) {
              that.activePackageDetailName = "3";
            } else if (that.packageCardDetail.Product.length > 0) {
              that.activePackageDetailName = "4";
            }
            that.packageDetails = true;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /** ==========  时效卡卡操作记录  =======================   */
    //时效卡消耗记录
    getTimeCardTreatBill() {
      var that = this;
      that.loading = true;
      var CustomerTargetWeight = that.selectRow.CustomerTargetWeight;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
        Sort: that.upState,
      };
      API.timeCardTreatBill(params)
        .then((res) => {
          console.log(res);
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.timeCardRecord = res.List;
            for (let i in that.timeCardRecord) {
              that.timeCardRecord[i].CustomerTargetWeight = CustomerTargetWeight;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //时效卡退存量记录
    getTimeCardRefundBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.timeCardRefundBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.timeCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //时效卡退消耗记录
    getTimeCardRefundTreatBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.timeCardRefundTreatBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.timeCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //时效卡转出记录
    getTimeCardRefundTransferBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.timeCardRefundTransferBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.timeCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //时效卡还款记录
    getTimeCardRefundRepayment() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.timeCardRefundRepayment(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.timeCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //时效卡平账
    getTimeCardRefundArrearAmountProcess() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.timeCardRefundArrearAmountProcess(params)
        .then((res) => {
          console.log(res);
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.timeCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /** ==========  通用次卡操作记录  =======================   */
    //通用次卡消耗记录
    getGeneralCardRefundBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.generalCardTreatBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.generalCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //通用次卡退存量记录
    getGeneralCardTreatBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.generalCardRefundBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.generalCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    //通用次卡退消耗记录
    getGeneralCardRefundTreatBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.generalCardRefundTreatBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.generalCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    //通用次卡转出记录
    getGeneralCardRefundTransferBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.generalCardRefundTransferBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.generalCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //通用次卡还款记录
    getGeneralCardRefundRepayment() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.generalCardRefundRepayment(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.generalCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //通用次卡平账记录
    getGeneralCardArrearAmountProcess() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.generalCardArrearAmountProcess(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.generalCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    /** ==========  储值卡操作记录  =======================   */
    //储值卡消耗记录
    getSavingCardTreatBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.savingCardTreatBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.savingCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //储值卡退存量记录
    getSavingCardRefundBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.savingCardRefundBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.savingCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //储值卡退消耗记录
    getSavingCardRefundTreatBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.savingCardRefundTreatBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.savingCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //储值卡转出记录
    getSavingCardTransferBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.savingCardTransferBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.savingCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //储值卡还款记录
    getSavingCardRepayment() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.savingCardRepayment(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.savingCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //储值卡平账记录
    getSavingCardArrearAmountProcess() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.savingCardArrearAmountProcess(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.savingCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //储值卡平账记录
    getSavingCardDeduction() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.savingCardDeduction(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.savingCardRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /** ==========  产品操作记录  =======================   */
    //产品消耗记录
    getProductAccountTreatBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.productAccountTreatBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.productRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    //产品退存量记录
    getProductAccountRefundBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.productAccountRefundBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.productRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    //产品退消耗记录
    getProductAccountRefundTreatBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.productAccountRefundTreatBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.productRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //产品转出记录
    getProductAccountTransferBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.productAccountTransferBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.productRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //产品还款记录
    getProductAccountRepayment() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.productAccountRepayment(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.productRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //产品平账记录
    getProductAccountArrearAmountProcess() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.productAccountArrearAmountProcess(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.productRecord = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    /** ==========  项目操作记录  =======================   */
    //项目耗用记录
    getTreatBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.treatBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.treatBillList = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 项目退存量记录
    getRefundBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.refundBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.treatBillList = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //项目退消耗记录
    getRefundTreatBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.refundTreatBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.treatBillList = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //项目转出记录
    getTransferBill() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.transferBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.treatBillList = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //项目还款记录
    getRepayment() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.repayment(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.treatBillList = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    //项目还款记录
    getArrearAmountProcess() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.recordPaginations.page,
        ID: that.selectRow.ID,
      };
      API.arrearAmountProcess(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.recordPaginations.total = res.Total;
            that.recordPaginations.page_size = res.PageSize;
            that.treatBillList = res.List;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    /**  账户存量信息请求   */
    // 获取项目存量列表
    getProjectAccount: function() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        CustomerID: that.customerID,
        Active: that.IsShowZero,
      };
      API.projectAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.paginations.total1 = res.Data.project.Total;
            that.paginations.page_size = res.Data.project.PageSize;
            that.projectData = res.Data.project.List;
            that.summation = res.Data.customerProjectAccountSumOutputForm;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 获取产品存量列表
    getProductAccount: function() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        CustomerID: that.customerID,
        Active: that.IsShowZero,
      };
      API.productAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            console.log(res);
            that.paginations.total1 = res.Data.product.Total;
            that.paginations.page_size = res.Data.product.PageSize;
            that.projectData = res.Data.product.List;
            that.summation = res.Data.customerProductAccountSumOutputForm;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 获取储值卡存量列表
    getSavingCardAccount: function() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        CustomerID: that.customerID,
        Active: that.IsShowZero,
      };
      API.savingCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.paginations.total1 = res.Data.savingCard.Total;
            that.paginations.page_size = res.Data.savingCard.PageSize;
            that.projectData = res.Data.savingCard.List;
            that.summation = res.Data.customerSavingCardAccountSumOutputForm;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    // 获取通用次卡存量列表
    getGeneralCardAccount: function() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        CustomerID: that.customerID,
        Active: that.IsShowZero,
      };
      API.generalCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.paginations.total1 = res.Data.generalCard.Total;
            that.paginations.page_size = res.Data.generalCard.PageSize;
            that.projectData = res.Data.generalCard.List;
            that.summation = res.Data.customerGeneralCardAccountSumOutputForm;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 获取时效卡存量列表
    getTimeCardAccount: function() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        CustomerID: that.customerID,
        Active: that.IsShowZero,
      };
      API.timeCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.paginations.total1 = res.Data.timeCard.Total;
            that.projectData = res.Data.timeCard.List;
            that.summation = res.Data.customerTimeCardAccountSumOutputForm;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 获取套餐卡存量列表
    getPackageCardAccount: function() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        CustomerID: that.customerID,
        Active: that.IsShowZero,
      };
      API.packageCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.paginations.total1 = res.Data.packageCard.Total;
            that.paginations.page_size = res.Data.packageCard.PageSize;
            that.projectData = res.Data.packageCard.List;
            that.summation = res.Data.customerPackageCardAccountSumOutputForm;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /**  清除网络数据  */
    clearAccountData() {
      let that = this;
      that.projectData = [];
    },
    // 合计
    getProjectData(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "TotalAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.TotalAmount)}</span>;
            break;
          case "PayAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.PayAmount)}</span>;
            break;
          case "SavingCardDeductionAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.SavingCardDeductionAmount)}</span>;
            break;
          case "SavingCardDeductionLargessAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.SavingCardDeductionLargessAmount)}</span>;
            break;
          case "Quantity":
            sums[index] = <span class="font_weight_600">{this.summation.Quantity}</span>;
            break;
          case "Balance":
            sums[index] = <span class="font_weight_600">{this.summation.Balance}</span>;
            break;
          case "BalanceAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.BalanceAmount)}</span>;
            break;
          case "ArrearAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.ArrearAmount)}</span>;
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },
    getSaveCardData(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "Amount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.Amount)}</span>;
            break;
          case "TotalBalance":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.TotalBalance)}</span>;
            break;
          case "Balance":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.Balance)}</span>;
            break;
          case "LargessBalance":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.LargessBalance)}</span>;
            break;
          case "BalanceAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.BalanceAmount)}</span>;
            break;
          case "ArrearAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.ArrearAmount)}</span>;
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },
    getTimeCardData(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "TotalAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.TotalAmount)}</span>;
            break;
          case "PayAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.PayAmount)}</span>;
            break;
          case "SavingCardDeductionAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.SavingCardDeductionAmount)}</span>;
            break;
          case "SavingCardDeductionLargessAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.SavingCardDeductionLargessAmount)}</span>;
            break;
          case "ConsumeAmount":
            sums[index] = <span class="font_weight_600">{this.summation.ConsumeAmount}</span>;
            break;
          case "BalanceAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.BalanceAmount)}</span>;
            break;
          case "ArrearAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.ArrearAmount)}</span>;
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },
    getGeneralData(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "TotalAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.TotalAmount)}</span>;
            break;
          case "PayAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.PayAmount)}</span>;
            break;
          case "SavingCardDeductionAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.SavingCardDeductionAmount)}</span>;
            break;
          case "SavingCardDeductionLargessAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.SavingCardDeductionLargessAmount)}</span>;
            break;
          case "CardTimes":
            sums[index] = <span class="font_weight_600">{this.summation.CardTimes}</span>;
            break;
          case "Balance":
            sums[index] = <span class="font_weight_600">{this.summation.Balance}</span>;
            break;
          case "BalanceAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.BalanceAmount)}</span>;
            break;
          case "ArrearAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.ArrearAmount)}</span>;
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },
    getPackageData(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "TotalAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.TotalAmount)}</span>;
            break;
          case "PayAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.PayAmount)}</span>;
            break;
          case "SavingCardDeductionAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.SavingCardDeductionAmount)}</span>;
            break;
          case "SavingCardDeductionLargessAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.SavingCardDeductionLargessAmount)}</span>;
            break;
          case "BalanceAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.BalanceAmount)}</span>;
            break;
          case "ArrearAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.ArrearAmount)}</span>;
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },
    getProductData(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "TotalAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.TotalAmount)}</span>;
            break;
          case "PayAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.PayAmount)}</span>;
            break;
          case "SavingCardDeductionAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.SavingCardDeductionAmount)}</span>;
            break;
          case "SavingCardDeductionLargessAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.SavingCardDeductionLargessAmount)}</span>;
            break;
          case "Quantity":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.Quantity)}</span>;
            break;
          case "Balance":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.Balance)}</span>;
            break;
          case "BalanceAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.BalanceAmount)}</span>;
            break;
          case "ArrearAmount":
            sums[index] = <span class="font_weight_600">￥{filter_NumFormat(this.summation.ArrearAmount)}</span>;
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },
  },

  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout();
    });
  },
};
</script>

<style lang="scss">
.customerAccount {
  .rightDiv {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 3;
  }
  .el-tabs--border-card > .el-tabs__content {
    padding: 0px;
  }
}
.customerAccount_package_detial {
  .el-tabs--border-card {
    // border: 0px,0px,0px,0px !important;
    border-bottom: 0px;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0), 0 0 0px 0 rgba(0, 0, 0, 0);
    .el-tabs__content {
      padding: 0px !important;
    }
  }
}
</style>

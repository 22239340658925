/**
 * Created by jerry on 2020/04/13.
 * 标签库api
 */
import * as API from '@/api/index'

export default {
    // 获取标签列表
    customerTagLibraryList: params => {
        return API.POST('api/tagLibrary/list', params)
    },
    // 获取标签库不分页
    customerTagLibraryAll: params => {
        return API.POST('api/tagLibrary/all', params)
    },
    //新增标签
    customerTagLibraryCreate: params => {
        return API.POST('api/tagLibrary/create', params)
    },
    //修改标签
    customerTagLibraryUpdate: params => {
        return API.POST('api/tagLibrary/update', params)
    },
    //删除标签
    customerTagLibraryDelete: params => {
        return API.POST('api/tagLibrary/delete', params)
    },
}
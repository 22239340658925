/*
 * @Author: mikey.zhaopeng 
 * @Date: 2020-07-03 09:44:26 
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-07-18 11:23:12
 */

import * as API from '@/api/index'
export default{
    // 销售订单列表
    getOrderList: params => {
        return API.POST('api/saleBill/list', params)
    },
    //获取订单详情
    getOrderDetail:params =>{
        return API.POST('api/saleBill/info',params)
    },
    //获取订单经手人业绩和佣金
    getOrderPerformanceCommission:params =>{
        return API.POST('api/saleBill/employeePerformanceCommission',params)
    },

    //获取项目经手人设置列表
    getProjectSaleHandlerList:params=>{
        return API.POST('api/saleHandler/projectHandler',params)
    },
    //获取产品经手人设置列表
    getproductSaleHandlerList:params=>{
        return API.POST('api/saleHandler/productHandler',params)
    },
    //获取通用次卡销售经手人
    getGenerSaleHandlerList:params=>{
        return API.POST('api/saleHandler/generalCardHandler',params)
    },
    //获取时效卡销售经手人
    getTimeSaleHandlerList:params =>{
        return API.POST('api/saleHandler/timeCardHandler',params)
    },
    //获取储值卡销售经手人
    getSaveSaleHandlerList:params =>{
        return API.POST('api/saleHandler/savingCardHandler',params)
    },
    //获取套餐卡销售经手人
    getPackageSaleHandlerList:params =>{
        return API.POST('api/saleHandler/packageCardHandler',params)
    },
    //计算员工提佣
    saleBillemployeeCommission:params =>{
        return API.POST('api/saleBill/employeeCommission',params)
    },
    //  50.7.补尾款计算员工提佣
    saleBillArrearEmployeeCommission: params => {
      return API.POST('api/saleBill/arrearEmployeeCommission', params)
    },
    //取消订单
    cancelOrder:params =>{
        return API.POST('api/saleBill/cancel',params)
    },
    //修改订单经手人业绩及佣金
    updateEmployeePerformanceCommission:params =>{
        return API.POST('api/saleBill/updateEmployeePerformanceCommission',params)
    },
    //修改订单经备注
    updateRemark:params =>{
        return API.POST('api/saleBill/updateRemark',params)
    },
    //修改顾客信息
    updateSaleBillBindCutsomer:params =>{
        return API.POST('api/fastSaleBill/saleBillBindCutsomer',params)
    },
    //上传附件
    addSaleBillAttachment:params =>{
        return API.POST('api/saleBill/addSaleBillAttachment',params)
    },
    // 获取附件
    getSaleBillAttachment:params =>{
        return API.POST('api/saleBill/getSaleBillAttachment',params)
    },
    // 删除附件
    deleteSaleBillAttachment:params =>{
        return API.POST('api/saleBill/deleteSaleBillAttachment',params)
    },
    // 获取销售单签名
     getSignature:params =>{
        return API.POST('api/saleBill/getSignature',params)
    },

     // 获取支付方式
     getSalePayMethod: params => {
        return API.POST('api/salePayMethod/all', params)
      },
      // 修改销售订单下单时间
      updateBillDate_saleBill: params => {
        return API.POST('api/saleBill/updateBillDate', params)
      },
      // 修改销售订单 支付方式
      updatePayMethod_saleBill: params => {
        return API.POST('api/saleBill/updatePayMethod', params)
      },
  
  
      // 修改 产品 备注信息
      updateProductRemark: params => {
        return API.POST('api/saleBill/updateProductRemark', params)
      },
      // 修改 项目 备注信息
      updateProjectRemark: params => {
        return API.POST('api/saleBill/updateProjectRemark', params)
      },
      // 修改 通用次卡 备注信息
      updateGeneralCardRemark: params => {
        return API.POST('api/saleBill/updateGeneralCardRemark', params)
      },
      // 修改 时效卡 备注信息
      updateTimeCardRemark: params => {
        return API.POST('api/saleBill/updateTimeCardRemark', params)
      },
      // 修改 储值卡 备注信息
      updateSavingCardRemark: params => {
        return API.POST('api/saleBill/updateSavingCardRemark', params)
      },
      // 修改 套餐卡 备注信息
      updatePackageCardRemark: params => {
        return API.POST('api/saleBill/updatePackageCardRemark', params)
      },
      // 修改 套餐卡产品 备注信息
      updatePackageCardProductRemark: params => {
        return API.POST('api/saleBill/updatePackageCardProductRemark', params)
      },
      // 修改 套餐卡项目 备注信息
      updatePackageCardProjectRemark: params => {
        return API.POST('api/saleBill/updatePackageCardProjectRemark', params)
      },
      // 修改 套餐卡通用次卡 备注信息
      updatePackageCardGeneralCardRemark: params => {
        return API.POST('api/saleBill/updatePackageCardGeneralCardRemark', params)
      },
      // 修改 套餐卡时效卡 备注信息
      updatePackageCardTimeCardRemark: params => {
        return API.POST('api/saleBill/updatePackageCardTimeCardRemark', params)
      },
      // 修改 套餐卡储值卡 备注信息
      updatePackageCardSavingCardRemark: params => {
        return API.POST('api/saleBill/updatePackageCardSavingCardRemark', params)
      },
      
}
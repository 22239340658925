import * as API from '@/api/index'

export default {

    getAppointmentRecordList:params =>{
        return API.POST('api/customerBill/appointmentBill',params)
    },
    getAppoinementRecordDetail:params =>{
        return API.POST('api/appointmentBill/info',params)
    }

}
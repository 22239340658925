<template>
  <div class="content_body TreatProjectCommissionScheme" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @submit.native.prevent>
            <el-form-item label="组织单位">
              <el-input
                @clear="handleSearch"
                v-model="name"
                placeholder="输入组织单位名称搜索"
                clearable
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="handleSearch"
                v-prevent-click
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button
            type="primary"
            size="small"
            @click="showAddDialog"
            v-prevent-click
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData">
        <el-table-column prop="EntityName" label="组织单位"></el-table-column>
        <el-table-column label="操作" width="145px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="showEditDialog(scope.row)"
              v-prevent-click
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="removeEntityClick(scope.row)"
              v-prevent-click
              v-if="isDelete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page pad_10">
        <div class="text_right" v-if="paginations.total > 0">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="paginations.page"
            :page-size="paginations.page_size"
            :layout="paginations.layout"
            :total="paginations.total"
          ></el-pagination>
        </div>
      </div>
    </div>

    <!--新增弹窗-->
    <el-dialog
      title="新增项目消耗提成方案"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          size="small"
        >
          <el-form-item label="组织单位" prop="EntityID">
            <span slot="label">
              适用组织
              <el-popover placement="top-start" width="200" trigger="hover">
                <p>适用于同级所有节点，则只需选择父节点。</p>
                <p>比如：适用于所有节点，只需选择“顶级/第一个”节点。</p>
                <p>
                  如需要设置经手人/职务提成，请选择已经配置经手人的组织单位。
                </p>
                <el-button
                  type="text"
                  style="color: #dcdfe6"
                  icon="el-icon-info"
                  slot="reference"
                ></el-button>
              </el-popover>
            </span>
            <treeselect
              v-model="ruleForm.EntityID"
              :options="entityList"
              :normalizer="normalizer"
              clearValueText
              noResultsText="无匹配数据"
              placeholder="选择所属部门"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitProductCommissionScheme"
          >保 存</el-button
        >
      </div>
    </el-dialog>

    <!--编辑弹窗-->
    <el-dialog title="分类提成设置" :visible.sync="dialogEdit" width="65%">
      <div slot="title">{{ entityName }} - 项目分类消耗提成方案</div>
      <div>
        <el-table
          :row-class-name="tableRowClassName"
          size="small"
          row-key="id"
          :data="savingCardCategoryCommission"
          :tree-props="{ children: 'Child', hasChildren: 'hasChildren' }"
          max-height="500px"
        >
          <el-table-column
            prop="CategoryName"
            label="分类名称"
            min-width="150px"
            fixed
          ></el-table-column>
          <el-table-column prop="Rate" label="非赠送比例" min-width="105px">
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.Rate"
                v-enter-number
                class="input_type"
                size="mini"
                min="0"
                @input="royaltyRateChange(1, scope.row)"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="Fixed" label="非赠送固定" min-width="115px">
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.Fixed"
                size="mini"
                min="0"
                v-enter-number2
                class="input_type"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="LargessRate"
            label="赠送比例"
            min-width="105px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.LargessRate"
                size="mini"
                min="0"
                v-enter-number
                class="input_type"
                @input="royaltyRateChange(2, scope.row)"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="LargessFixed"
            label="赠送固定"
            min-width="115px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.LargessFixed"
                size="mini"
                min="0"
                v-enter-number2
                class="input_type"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="SpecialBenefit"
            label="特殊补贴"
            min-width="105px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.SpecialBenefit"
                size="mini"
                min="0"
                v-enter-number2
                class="input_type"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250px">
            <template slot-scope="scope">
              <div class="dis_flex">
                <el-button
                  type="primary"
                  size="small"
                  @click="handerCommission(0, scope.row)"
                  v-if="scope.row.isEntity"
                  >所有项目经手人/职务提成</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click="handerCommission(1, scope.row)"
                  v-if="!scope.row.isEntity"
                  >分类经手人/职务提成</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  v-if="scope.row.isChild == true"
                  @click="savingCardsCommission(scope.row)"
                  >项目提成</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogEdit = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitSavingCategoryCommission"
          >保 存</el-button
        >
      </div>
    </el-dialog>

    <!--经手人弹窗-->
    <el-dialog :title="handlerTitle" :visible.sync="dialogHander" width="55%">
      <div slot="title" v-if="personIndex != 2">
        {{ entityName }} - {{ categoryName }} - 经手人/职务消耗提成方案
      </div>
      <div slot="title" v-else>
        {{ entityName }} - {{ categoryName }} - {{ projectName }} -
        经手人/职务消耗提成方案
      </div>
      <div>
        <el-table
          size="small"
          :data="productCategoryHandlerCommission"
          row-key="ID"
          :tree-props="{
            children: 'HandlerJobTypeCommission',
            hasChildren: 'hasChildren',
          }"
          max-height="500px"
        >
          <el-table-column
            prop="Name"
            label="经手人/职务"
            fixed
            min-width="150px"
          ></el-table-column>
          <el-table-column prop="Rate" label="非赠送比例" min-width="105px">
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.Rate"
                v-enter-number
                class="input_type"
                @input="royaltyRateChange(1, scope.row)"
                size="mini"
                min="0"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="Fixed" label="非赠送固定" min-width="115px">
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.Fixed"
                v-enter-number2
                class="input_type"
                size="mini"
                min="0"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="LargessRate"
            label="赠送比例"
            min-width="105px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.LargessRate"
                v-enter-number
                class="input_type"
                @input="royaltyRateChange(2, scope.row)"
                size="mini"
                min="0"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="LargessFixed"
            label="赠送固定"
            min-width="115px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.LargessFixed"
                v-enter-number2
                class="input_type"
                size="mini"
                min="0"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="SpecialBenefit"
            label="特殊补贴"
            min-width="115px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.SpecialBenefit"
                v-enter-number2
                class="input_type"
                size="mini"
                min="0"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogHander = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitProductCategoryHandlerCommission"
          >保 存</el-button
        >
      </div>
    </el-dialog>

    <!--项目弹窗-->
    <el-dialog
      title="项目提成设置"
      :visible.sync="dialogSavingCard"
      width="60%"
    >
      <div slot="title">
        {{ entityName }} - {{ categoryName }} - 项目消耗提成方案
      </div>
      <div>
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-input
              v-model="searchKey"
              placeholder="请输入项目名称搜索"
              prefix-icon="el-icon-search"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <el-table
          :data="
            treatLists.filter(
              (data) =>
                !searchKey ||
                data.ProjectName.toLowerCase().includes(searchKey.toLowerCase())
            )
          "
          row-key="ID"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          size="small"
          max-height="500px"
        >
          <el-table-column
            prop="ProjectName"
            label="项目名称"
            fixed
            min-width="150px"
          ></el-table-column>
          <el-table-column prop="Rate" label="非赠送比例提成" min-width="105px">
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.Rate"
                v-enter-number
                class="input_type"
                @input="royaltyRateChange(1, scope.row)"
                size="mini"
                min="0"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="Fixed"
            label="非赠送固定提成"
            min-width="115px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.Fixed"
                v-enter-number2
                class="input_type"
                size="small"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="LargessRate"
            label="赠送比例提成"
            min-width="105px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.LargessRate"
                v-enter-number
                class="input_type"
                @input="royaltyRateChange(2, scope.row)"
                size="mini"
                min="0"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="LargessFixed"
            label="赠送固定提成"
            min-width="115px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.LargessFixed"
                v-enter-number2
                class="input_type"
                size="mini"
                min="0"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="SpecialBenefit"
            label="特殊补贴"
            min-width="115px"
          >
            <template slot-scope="scope">
              <el-input
                type="number"
                v-model="scope.row.SpecialBenefit"
                v-enter-number2
                class="input_type"
                size="mini"
                min="0"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="115px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="handerCommission(2, scope.row)"
                >经手人提成</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="dialogSavingCard = false"
          v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitSavingCard"
          >保 存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import APIEntity from "@/api/KHS/Entity/entity";
import API from "@/api/iBeauty/HanderCommission/treatProjectCommissionScheme";
import permission from "@/components/js/permission.js";

var Enumerable = require("linq");
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: { Treeselect },
  name: "TreatProjectCommissionScheme",
  data() {
    return {
      loading: false,
      modalLoading: false,
      dialogVisible: false,
      dialogEdit: false,
      dialogHander: false,
      dialogSavingCard: false,
      name: "",
      entityList: [],
      tableData: [],
      entityID: "",
      categoryID: "",
      projectID: "",
      entityName: "",
      categoryName: "",
      projectName: "",
      isDelete: false,
      searchKey: "",
      handlerTitle: "",
      savingCardCategoryCommission: [],
      productCategoryHandlerCommission: [],
      treatLists: [],
      ruleForm: {
        EntityID: null,
      },
      rules: {
        EntityID: [
          { required: true, message: "请选择组织", trigger: "change" },
        ],
      },
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      personIndex: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(
        to.meta.Permission,
        "iBeauty-HanderCommission-TreatProjectCommissionScheme-Delete"
      );
    });
  },
  methods: {
    normalizer(node) {
      return {
        id: node.ID,
        label: node.EntityName,
        children: node.Child,
      };
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 0) {
        return "info-row";
      }
      return "";
    },
    // 所属单位
    entityData: function () {
      var that = this;
      APIEntity.getEntityAll()
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.search();
    },
    // 数据显示
    handleSearch: function () {
      let that = this;
      that.paginations.page = 1;
      that.search();
    },
    // 数据显示
    search: function () {
      let that = this;
      that.loading = true;
      var params = {
        Name: that.name,
        PageNum: that.paginations.page,
      };
      API.getTreatProjectCommissionScheme(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 新增
    showAddDialog: function () {
      var that = this;
      that.ruleForm = {
        EntityID: null,
      };
      that.dialogVisible = true;
    },

    // 新增储值卡销售组织单位提成
    submitProductCommissionScheme: function () {
      var that = this;
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.modalLoading = true;
          let para = Object.assign({}, that.ruleForm);
          API.createTreatProjectCommissionScheme(para)
            .then(function (res) {
              if (res.StateCode === 200) {
                that.$message.success({
                  message: "新增成功",
                  duration: 2000,
                });
                that.search();
                that.$refs["ruleForm"].resetFields();
                that.dialogVisible = false;
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.modalLoading = false;
            });
        }
      });
    },

    // 编辑
    showEditDialog: function (row) {
      var that = this;
      that.entityName = row.EntityName;
      that.entityID = row.EntityID;
      that.productCategoryCommissionData();
      that.dialogEdit = true;
    },

    //获取编辑  产品销售分类提佣方案
    productCategoryCommissionData: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.entityID,
      };
      API.getTreatProjectCategoryCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            res.Data.Category.forEach((item) => {
              item.Child = Enumerable.from(item.Child)
                .select((val) => ({
                  id: val.CategoryName + val.CategoryID + "h",
                  CategoryID: val.CategoryID,
                  CategoryName: val.CategoryName,
                  ParentID: val.ParentID,
                  Fixed: val.Fixed,
                  LargessFixed: val.LargessFixed,
                  Rate: val.Rate,
                  LargessRate: val.LargessRate,
                  SpecialBenefit: val.SpecialBenefit,
                  isChild: true,
                }))
                .toArray();
            });

            var data = {
              id: res.Data.EntityID + "h" + res.Data.EntityName,
              CategoryID: res.Data.EntityID,
              CategoryName: "所有项目",
              Fixed: res.Data.Fixed,
              LargessFixed: res.Data.LargessFixed,
              Rate: res.Data.Rate,
              LargessRate: res.Data.LargessRate,
              SpecialBenefit: res.Data.SpecialBenefit,
              isEntity: true,
              isChild: false,
            };

            var Category = Enumerable.from(res.Data.Category)
              .select((val) => ({
                id: val.CategoryID + "h" + val.CategoryName,
                CategoryID: val.CategoryID,
                CategoryName: val.CategoryName,
                ParentID: val.ParentID,
                Fixed: val.Fixed,
                LargessFixed: val.LargessFixed,
                Rate: val.Rate,
                LargessRate: val.LargessRate,
                SpecialBenefit: val.SpecialBenefit,
                Child: val.Child,
                isEntity: false,
                isChild: false,
              }))
              .toArray();
            that.savingCardCategoryCommission = Category;
            that.savingCardCategoryCommission.unshift(data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 删除
    removeEntityClick: function (row) {
      var that = this;
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.loading = true;
          var params = {
            EntityID: row.EntityID,
          };
          API.deleteTreatProjectCommissionScheme(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.search();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //经手人提成弹窗
    handerCommission: function (index, row) {
      var that = this;
      that.personIndex = index;
      if (index == 0) {
        that.categoryName = row.CategoryName;
        that.getTreatProjectSchemeHanderCommission();
      } else if (index == 1) {
        //获取项目消耗下经手人
        that.handlerTitle = "分类经手人提成设置";
        that.categoryID = row.CategoryID;
        that.categoryName = row.CategoryName;
        that.savingCardCategoryHandlerCommissionData();
      } else {
        //获取销售下经手人
        that.handlerTitle = "项目经手人提成设置";
        that.projectID = row.ProjectID;
        that.projectName = row.ProjectName;
        that.getTreatProjectHanderCommissions();
      }
      that.dialogHander = true;
    },

    //获取所有产品下经手人题案
    getTreatProjectSchemeHanderCommission() {
      var that = this;
      let params = {
        EntityID: that.entityID,
      };
      API.treatProjectSchemeHanderCommission(params).then((res) => {
        if (res.StateCode == 200) {
          that.productCategoryHandlerCommission = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //获取产品下经手人题案
    getTreatProjectHanderCommissions() {
      var that = this;
      let params = {
        EntityID: that.entityID,
        ProjectID: that.projectID, //项目消耗ID
      };
      API.getTreatProjectHanderCommission(params).then((res) => {
        if (res.StateCode == 200) {
          that.productCategoryHandlerCommission = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取项目销售分类经手人提佣方案
    savingCardCategoryHandlerCommissionData: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.entityID,
        CategoryID: that.categoryID, //分类id
      };
      API.getTreatProjectCategoryHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productCategoryHandlerCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 保存经手人
    submitProductCategoryHandlerCommission: function () {
      var that = this;
      that.modalLoading = true;
      var selectTreat = [];
      selectTreat = that.productCategoryHandlerCommission;
      selectTreat.forEach((item) => {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.Rate !== "" && i.Rate !== null) ||
              (i.Fixed !== "" && i.Fixed != null) ||
              (i.LargessRate !== "" && i.LargessRate !== null) ||
              (i.LargessFixed !== "" && i.LargessFixed !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      selectTreat = Enumerable.from(selectTreat)
        .where(function (i) {
          return (
            (i.Rate !== "" && i.Rate !== null) ||
            (i.Fixed !== "" && i.Fixed !== null) ||
            (i.LargessRate !== "" && i.LargessRate !== null) ||
            (i.LargessFixed !== "" && i.LargessFixed !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .select((val) => ({
          TreatHandlerID: val.ID,
          Rate: val.Rate,
          Fixed: val.Fixed,
          LargessRate: val.LargessRate,
          LargessFixed: val.LargessFixed,
          SpecialBenefit: val.SpecialBenefit,
          HandlerJobTypeCommission: val.HandlerJobTypeCommission,
        }))
        .toArray();
      if (that.personIndex == 0) {
        //保存所有经手人提成方案
        let params = {
          EntityID: that.entityID,
          Handler: selectTreat,
        };
        API.treatProjectSchemeHanderCommissionupdate(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.$message.success("提成设置成功");
              that.dialogHander = false;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.modalLoading = false;
          });
      } else if (that.personIndex == 1) {
        //保存项目消耗下经手人
        let params = {
          EntityID: that.entityID,
          CategoryID: that.categoryID,
          Handler: selectTreat,
        };
        API.updateTreatProjectCategoryHandlerCommission(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.$message.success("提成设置成功");
              that.dialogHander = false;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.modalLoading = false;
          });
      } else {
        //保存分类下下经手人
        let params = {
          EntityID: that.entityID,
          ProjectID: that.projectID,
          Handler: selectTreat,
        };
        API.updateTreatProjectHanderCommission(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.$message.success("提成设置成功");
              that.dialogHander = false;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.modalLoading = false;
          });
      }
    },

    // 项目提成弹窗
    savingCardsCommission: function (row) {
      var that = this;
      that.categoryName = row.CategoryName;
      that.categoryID = row.CategoryID;
      that.TreatProjectCommission();
      that.dialogSavingCard = true;
    },
    //获取项目提佣方案
    TreatProjectCommission() {
      var that = this;
      var params = {
        EntityID: that.entityID,
        CategoryID: that.categoryID,
      };
      API.getTreatProjectCommission(params).then((res) => {
        if (res.StateCode == 200) {
          that.treatLists = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //保存项目提成设置
    submitSavingCard: function () {
      var that = this;
      that.modalLoading = true;
      var projectList = [];
      projectList = Enumerable.from(that.treatLists)
        .where(function (i) {
          return (
            (i.Rate !== "" && i.Rate !== null) ||
            (i.Fixed !== "" && i.Fixed !== null) ||
            (i.LargessRate !== "" && i.LargessRate !== null) ||
            (i.LargessFixed !== "" && i.LargessFixed !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
          );
        })
        .select((val) => ({
          ProjectID: val.ProjectID,
          Rate: val.Rate,
          Fixed: val.Fixed,
          LargessRate: val.LargessRate,
          LargessFixed: val.LargessFixed,
          SpecialBenefit: val.SpecialBenefit,
        }))
        .toArray();
      let params = {
        EntityID: that.entityID,
        Project: projectList,
        CategoryID: that.categoryID,
      };

      API.updateTreatProjectCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: res.Message,
              duration: 2000,
            });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
          this.dialogSavingCard = false;
        })
        .finally(() => {
          that.modalLoading = false;
        });
    },

    // 保存项目消耗分类提佣方案
    submitSavingCategoryCommission: function () {
      var that = this;
      var params = {
        EntityID: that.savingCardCategoryCommission[0].CategoryID,
        Rate: that.savingCardCategoryCommission[0].Rate,
        Fixed: that.savingCardCategoryCommission[0].Fixed,
        LargessRate: that.savingCardCategoryCommission[0].LargessRate,
        LargessFixed: that.savingCardCategoryCommission[0].LargessFixed,
        SpecialBenefit: that.savingCardCategoryCommission[0].SpecialBenefit,
      };
      that.savingCardCategoryCommission.forEach((item) => {
        item.Child = Enumerable.from(item.Child)
          .where(function (i) {
            return (
              (i.Rate !== "" && i.Rate !== null) ||
              (i.Fixed !== "" && i.Fixed !== null) ||
              (i.LargessRate !== "" && i.LargessRate !== null) ||
              (i.LargessFixed !== "" && i.LargessFixed !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .select((val) => ({
            id: val.CategoryID + "h",
            CategoryID: val.CategoryID,
            ParentID: val.ParentID,
            Rate: val.Rate,
            Fixed: val.Fixed,
            LargessRate: val.LargessRate,
            LargessFixed: val.LargessFixed,
            SpecialBenefit: val.SpecialBenefit,
          }))
          .toArray();
      });
      that.savingCardCategoryCommission = Enumerable.from(
        that.savingCardCategoryCommission
      )
        .where(function (i) {
          return (
            (i.Rate !== "" && i.Rate !== null) ||
            (i.Fixed !== "" && i.Fixed !== null) ||
            (i.LargessRate !== "" && i.LargessRate !== null) ||
            (i.LargessFixed !== "" && i.LargessFixed !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.Child.length > 0
          );
        })
        .toArray();

      that.modalLoading = true;
      var Category = Enumerable.from(that.savingCardCategoryCommission)
        .where(function (i) {
          return !i.isEntity;
        })
        .select((val) => ({
          CategoryID: val.CategoryID,
          Rate: val.Rate,
          Fixed: val.Fixed,
          LargessRate: val.LargessRate,
          LargessFixed: val.LargessFixed,
          SpecialBenefit: val.SpecialBenefit,
          Child: val.Child,
        }))
        .toArray();
      params.Category = Category;
      API.updateTreatProjectCategoryCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("提成设置成功");
            that.dialogEdit = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },

    // 提成比例
    royaltyRateChange: function (index, row) {
      if (index == 1) {
        if (row.Rate > 100) {
          row.Rate = 100;
        }
      } else if (index == 2) {
        if (row.LargessRate > 100) {
          row.LargessRate = 100;
        }
      }
    },
  },
  mounted() {
    var that = this;
     that.isDelete = permission.permission(
        that.$route.meta.Permission,
        "iBeauty-HanderCommission-TreatProjectCommissionScheme-Delete"
      );
    that.handleSearch();
    that.entityData();
  },
};
</script>

<style lang="scss">
.TreatProjectCommissionScheme{
  .input_type {
    .el-input-group__append {
      padding: 0 10px;
    }
  }
  .el-input__inner {
    padding-right: 0;
  }
}

</style>

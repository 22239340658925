<!-- /**  销售订单详情内容 */ --> 
<template>
  <section class="saleBillDetailContent">
    <div>
      <!-- 订单信息 -->
      <div>
        <div class="tip marbm_10" style="margin-top:0">订单信息</div>
        <el-form label-width="100px" class="saleInfo" size="small">
          <el-row>
            <el-col :span="8">
              <el-form-item label="订单编号:">{{saleOrderDetail.ID}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单类型:">{{saleOrderDetail.BillType == 10?"销售单":saleOrderDetail.BillType==20?"销售退款单":"补欠款单"}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单状态:">{{saleOrderDetail.BillStatus == 10?"待付款":saleOrderDetail.BillStatus==20?"已完成":"已取消"}}</el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="顾客信息:">{{saleOrderDetail.Name}} <span v-if="saleOrderDetail.PhoneNumber != null">({{saleOrderDetail.PhoneNumber}})</span>
                <el-popover placement="right" width="400" trigger="click" v-model="changeMemberDialogVisible">
                  <div class="font_13 color_333 marbm_10">请选择顾客</div>

                  <el-autocomplete popper-class="customer-autocomplete-bill" prefix-icon="el-icon-user-solid" v-model="customerName" style="width:100%" size="small" placeholder="请输入客户姓名、手机号、编号查找" :fetch-suggestions="saleCustomerData" @select="handleCustomerSelect" :disabled="customerID!=null" :trigger-on-focus="false" :hide-loading="false" :highlight-first-item="true" :select-when-unmatched="true">
                    <template slot="append">
                      <el-button icon="el-icon-delete" @click="removeCustomer"></el-button>
                    </template>
                    <template slot-scope="{ item }">
                      <div class="name">
                        {{ item.Name}}
                        <el-tag size="mini" v-if="item.CustomerLevelName">{{ item.CustomerLevelName }}</el-tag>
                      </div>
                      <div class="info">手机号：{{ item.PhoneNumber }}</div>
                      <span class="info" v-if="item.Code">客户编号：{{ item.Code }}</span>
                    </template>
                  </el-autocomplete>

                  <div class="text_right pad_10 martp_10">
                    <el-button class="marrt_10" size="mini" @click="changeMemberDialogVisible = false">取 消</el-button>
                    <el-button type="primary" size="mini" @click="updateSaleBillBindCutsomer">保 存</el-button>
                  </div>

                  <el-button @click="removeCustomer" slot="reference" class="ml_5" v-if="saleOrderDetail.CustomerID == null" type="text" size="small">修改顾客</el-button>
                </el-popover>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收银员:">{{saleOrderDetail.EmployeeName}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单金额:">￥{{saleOrderDetail.Amount | NumFormat}}</el-form-item>
            </el-col>
            
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="返佣比例:"><span v-if="saleOrderDetail.ReturnedCommissionScale">{{saleOrderDetail.ReturnedCommissionScale + '%'}}</span>
              <span v-else>0%</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="返佣金额:">{{saleOrderDetail.ReturnedCommissionAmount | NumFormat}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="返佣人:">{{saleOrderDetail.ReturnedCommissionName}}</el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="欠款金额:">￥{{saleOrderDetail.ArrearAmount | NumFormat}}{{saleOrderDetail.Repayment > 0 ? (saleOrderDetail.Repayment):""}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="下单时间:">{{saleOrderDetail.BillDate}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="录单时间:">{{saleOrderDetail.CreatedOn}}</el-form-item>
            </el-col>
          </el-row>
           <el-row v-if="saleOrderDetail.BillStatus==30">
            <el-col :span="8">
              <el-form-item label="取消时间:">{{saleOrderDetail.CancelCreatedOn }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="取消人:">{{saleOrderDetail.CancelCreatedBy }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="取消原因:">
                <span class="marrt_5">{{saleOrderDetail.CancelRemark }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="订单备注:">
                <span v-if="saleOrderDetail.Remark" class="marrt_5">{{saleOrderDetail.Remark}}</span>
                <el-button type="text" @click="ChangeRemarkinnerVisible">修改备注</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="公司实际收款:">
                {{saleOrderDetail.ActualCollection | NumFormat}}
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row>
            <el-col :span="8">
              <el-form-item label="购买属性:">
                <span v-if="saleOrderDetail.BuyProperty" class="marrt_5">{{saleOrderDetail.BuyProperty}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row></el-row>
        </el-form>
      </div>
      <!-- 消费信息 -->
      <div class="martp_10">
        <div class="tip marbm_10" style="margin-top:0">消费信息</div>
        <div class="border_left border_right border_top">
          <!-- 项目 -->
          <div v-if="saleOrderDetail.Project != undefined && saleOrderDetail.Project.length > 0">
            <el-row class="row_header">
              <el-col :span="8">项目</el-col>
              <el-col :span="3">数量</el-col>
              <el-col :span="5">优惠金额</el-col>
              <el-col :span="4">欠款金额</el-col>
              <el-col :span="4">小计</el-col>
            </el-row>
            <el-row v-for="(item,index) in saleOrderDetail.Project" :key="index + 'x1'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="8">
                    <div>
                      {{item.ProjectName}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      <span v-if="isRemark" class="marlt_5 color_primary cursor_pointer">
                        <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="changeRemarkClick(item,'updateProjectRemark')"></el-button>
                      </span>
                    </div>
                  </el-col>
                  <el-col :span="3">x {{item.Quantity}}</el-col>
                  <el-col :span="5">
                    <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                    <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                    <span v-else>¥ 0.00</span>
                  </el-col>
                  <el-col :span="4">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                  <el-col :span="4">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                </el-col>
                <el-col :span="24" class="martp_5">
                  <el-col :span="11">
                    <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                  </el-col>
                  <el-col :span="13">
                    <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                      手动改价：
                      <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                      <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                    </span>
                    <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                      卡优惠：
                      <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                    </span>
                  </el-col>
                </el-col>
              </el-col>

              <el-col v-if="item.Remark" :span="24" class="pad_10 border_bottom font_12 color_333">备注信息：{{item.Remark}}</el-col>

              <el-col :span="24" v-if="item.SaleBillHandler.length>0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler,pIndex) in item.SaleBillHandler" :key="pIndex + 'h1'">
                  <el-col :span="2">
                    <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.SaleHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{(employee.Scale || 0).toFixed(2)}}%</el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <!-- 储值卡 -->
          <div v-if="saleOrderDetail.SavingCard != undefined && saleOrderDetail.SavingCard.length > 0">
            <el-row class="row_header">
              <el-col :span="8">储值卡</el-col>
              <el-col :span="3">数量</el-col>
              <el-col :span="3">充值金额</el-col>
              <el-col :span="3">赠送金额</el-col>
              <el-col :span="3">欠款金额</el-col>
              <el-col :span="4">小计</el-col>
            </el-row>
            <el-row v-for="(item,index) in saleOrderDetail.SavingCard" :key="index + 'x2'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="8">
                    <div>
                      {{item.SavingCardName}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <span v-if="isRemark" class="marlt_5 color_primary cursor_pointer">
                        <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="changeRemarkClick(item,'updateSavingCardRemark')"></el-button>
                      </span>
                    </div>
                  </el-col>
                  <el-col :span="3">x {{item.Quantity}}</el-col>
                  <el-col :span="3">¥ {{item.TotalAmount | NumFormat}}</el-col>
                  <el-col :span="3">¥ {{item.LargessAmount | NumFormat}}</el-col>
                  <el-col :span="3">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                  <el-col :span="4">¥ {{item.TotalAmount | NumFormat}}</el-col>
                </el-col>
                <el-col :span="24" class="martp_5">
                  <div>
                    <span class="color_red" v-if="item.Price ">¥ {{item.Price | NumFormat}}</span>
                    <span class="marlt_10 font_12 color_gray" v-if="item.LargessPrice">赠送：¥ {{item.LargessPrice}}</span>
                  </div>
                </el-col>
              </el-col>

              <el-col v-if="item.Remark" :span="24" class="pad_10 border_bottom font_12 color_333">备注信息：{{item.Remark}}
              </el-col>


              <el-col :span="24" v-if="item.SaleBillHandler.length>0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler,pIndex) in item.SaleBillHandler" :key="pIndex + 'h2'">
                  <el-col :span="2">
                    <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.SaleHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{(employee.Scale || 0).toFixed(2)}}%</el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <!-- 时效卡 -->
          <div v-if="saleOrderDetail.TimeCard != undefined && saleOrderDetail.TimeCard.length > 0">
            <el-row class="row_header">
              <el-col :span="5">时效卡</el-col>
              <el-col :span="2">数量</el-col>
              <el-col :span="3">销售当前体重</el-col>
              <el-col :span="3">目标体重</el-col>
              <el-col :span="3">目标减重</el-col>
              <el-col :span="3">优惠金额</el-col>
              <el-col :span="3">欠款金额</el-col>
              <el-col :span="2">小计</el-col>
            </el-row>
            <el-row v-for="(item,index) in saleOrderDetail.TimeCard" :key="index + 'x3'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="5">
                    <div>
                      {{item.TimeCardName}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      <span v-if="isRemark" class="marlt_5 color_primary cursor_pointer">
                        <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="changeRemarkClick(item,'updateTimeCardRemark')"></el-button>
                      </span>
                    </div>
                  </el-col>
                  <el-col :span="2">x {{item.Quantity}}</el-col>
                  <el-col :span="3">{{item.SaleCurrentWeight}}</el-col>
                  <el-col :span="3">{{item.CustomerTargetWeight}}</el-col>
                  <el-col :span="3">{{item.TargetWeight}}</el-col>
                  <el-col :span="3">
                    <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                    <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                    <span v-else>¥ 0.00</span>
                  </el-col>
                  <el-col :span="3">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                  <el-col :span="2">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                </el-col>
                <el-col :span="24" class="martp_5">
                  <el-col :span="11">
                    <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                  </el-col>
                  <el-col :span="13">
                    <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                      手动改价：
                      <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                      <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                    </span>
                    <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_5':''" v-if="item.CardPreferentialAmount>0">
                      卡优惠：
                      <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                    </span>
                  </el-col>
                </el-col>
              </el-col>

              <el-col v-if="item.Remark" :span="24" class="pad_10 border_bottom font_12 color_333">备注信息：{{item.Remark}}
              </el-col>
              
              <el-col :span="24" v-if="item.SaleBillHandler.length>0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler,pIndex) in item.SaleBillHandler" :key="pIndex + 'h3'">
                  <el-col :span="2">
                    <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.SaleHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{employee.Scale.toFixed(2)}}%</el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <!-- 通用次卡 -->
          <div v-if="saleOrderDetail.GeneralCard != undefined && saleOrderDetail.GeneralCard.length > 0">
            <el-row class="row_header">
              <el-col :span="8">通用次卡</el-col>
              <el-col :span="3">数量</el-col>
              <el-col :span="5">优惠金额</el-col>
              <el-col :span="4">欠款金额</el-col>
              <el-col :span="4">小计</el-col>
            </el-row>
            <el-row v-for="(item,index) in saleOrderDetail.GeneralCard" :key="index + 'x4'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="8">
                    <div>
                      {{item.GeneralCardName}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      <span v-if="isRemark" class="marlt_5 color_primary cursor_pointer">
                        <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="changeRemarkClick(item,'updateGeneralCardRemark')"></el-button>
                      </span>
                    </div>
                  </el-col>
                  <el-col :span="3">x {{item.Quantity}}</el-col>
                  <el-col :span="5">
                    <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                    <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                    <span v-else>¥ 0.00</span>
                  </el-col>
                  <el-col :span="4">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                  <el-col :span="4">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                </el-col>
                <el-col :span="24" class="martp_5">
                  <el-col :span="11">
                    <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                  </el-col>
                  <el-col :span="13">
                    <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                      手动改价：
                      <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                      <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                    </span>
                    <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_5':''" v-if="item.CardPreferentialAmount>0">
                      卡优惠：
                      <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                    </span>
                  </el-col>
                </el-col>
              </el-col>

              <el-col v-if="item.Remark" :span="24" class="pad_10 border_bottom font_12 color_333">备注信息：{{item.Remark}}
              </el-col>

              <el-col :span="24" v-if="item.SaleBillHandler.length>0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler,pIndex) in item.SaleBillHandler" :key="pIndex + 'h4'">
                  <el-col :span="2">
                    <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.SaleHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{(employee.Scale || 0).toFixed(2)}}%</el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <!-- 产品 -->
          <div v-if="saleOrderDetail.Product != undefined && saleOrderDetail.Product.length > 0">
            <el-row class="row_header">
              <el-col :span="8">产品</el-col>
              <el-col :span="3">数量</el-col>
              <el-col :span="5">优惠金额</el-col>
              <el-col :span="4">欠款金额</el-col>
              <el-col :span="4">小计</el-col>
            </el-row>
            <el-row v-for="(item,index) in saleOrderDetail.Product" :key="index + 'x5'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="8">
                    <div>
                      {{item.ProductName}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      <span v-if="isRemark" class="marlt_5 color_primary cursor_pointer">
                        <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="changeRemarkClick(item,'updateProductRemark')"></el-button>
                      </span>
                    </div>
                  </el-col>
                  <el-col :span="3">x {{item.Quantity}}</el-col>
                  <el-col :span="5">
                    <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                    <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                    <span v-else>¥ 0.00</span>
                  </el-col>
                  <el-col :span="4">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                  <el-col :span="4">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                </el-col>
                <el-col :span="24" class="martp_5">
                  <el-col :span="11">
                    <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                  </el-col>
                  <el-col :span="13">
                    <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                      手动改价：
                      <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                      <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                    </span>
                    <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_5':''" v-if="item.CardPreferentialAmount>0">
                      卡优惠：
                      <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                    </span>
                  </el-col>
                </el-col>
              </el-col>

              <el-col v-if="item.Remark" :span="24" class="pad_10 border_bottom font_12 color_333">备注信息：{{item.Remark}}
              </el-col>

              <el-col :span="24" v-if="item.SaleBillHandler.length>0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler,pIndex) in item.SaleBillHandler" :key="pIndex + 'h5'">
                  <el-col :span="2">
                    <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.SaleHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{(employee.Scale || 0).toFixed(2)}}%</el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <!-- 套餐卡 -->
          <div v-if="saleOrderDetail.PackageCard != undefined && saleOrderDetail.PackageCard.length > 0">
            <el-row class="row_header">
              <el-col :span="5">套餐卡</el-col>
              <el-col :span="2">数量</el-col>
              <el-col :span="3">销售当前体重</el-col>
              <el-col :span="3">目标体重</el-col>
              <el-col :span="3">目标减重</el-col>
              <el-col :span="3">优惠金额</el-col>
              <el-col :span="3">欠款金额</el-col>
              <el-col :span="2">小计</el-col>
            </el-row>
            <el-row v-for="(item,index) in saleOrderDetail.PackageCard" :key="index + 'x6'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="5">
                    <div>
                      {{item.PackageCardName}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      <span class="marlt_15 color_primary cursor_pointer" @click="packDetailClick(item)">
                        <el-link type="primary" style="vertical-align: bottom" :underline="false">
                          查看
                          <i v-if="item.IsShowDetails" class="el-icon-view el-icon--right"></i>
                        </el-link>
                      </span>
                      <span v-if="isRemark" class="marlt_5 color_primary cursor_pointer">
                        <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="changeRemarkClick(item,'updatePackageCardRemark')"></el-button>
                      </span>
                    </div>
                  </el-col>
                  <el-col :span="2">x {{item.Quantity}}</el-col>
                  <el-col :span="3">{{item.SaleCurrentWeight}}</el-col>
                  <el-col :span="3">{{item.CustomerTargetWeight}}</el-col>
                  <el-col :span="3">{{item.TargetWeight}}</el-col>
                  <el-col :span="3">
                    <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                    <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                    <span v-else>¥ 0.00</span>
                  </el-col>
                  <el-col :span="3">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                  <el-col :span="2">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                </el-col>
                <el-col :span="24" class="martp_5">
                  <el-col :span="11">
                    <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                  </el-col>
                  <el-col :span="13">
                    <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                      手动改价：
                      <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                      <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                    </span>
                    <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_5':''" v-if="item.CardPreferentialAmount>0">
                      卡优惠：
                      <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                    </span>
                  </el-col>
                </el-col>
              </el-col>
              <el-col :span="24" v-if="item.IsShowDetails">
                <!-- 套餐卡产品 -->
                <el-col :span="24" class="border_bottom pad_10 font_12" v-for="(packageDetail) in item.PackageCardGoods.PackageCardProduct" :key="`${packageDetail.ProductID}`">
                  <el-col :span="24">
                    <el-col :span="5">
                      <span>{{packageDetail.Name}} ×{{packageDetail.Quantity}}</span>
                      <el-tag class="marlt_5" size="mini">产品</el-tag>
                    </el-col>
                    <el-col :span="2">× {{packageDetail.PackageQuantity}}</el-col>
                    <el-col :span="3">{{packageDetail.SaleCurrentWeight}}</el-col>
                    <el-col :span="3">{{packageDetail.CustomerTargetWeight}}</el-col>
                    <el-col :span="3">{{packageDetail.TargetWeight}}</el-col>
                    <el-col :span="3">
                      <span v-if="packageDetail.PreferentialTotalAmount<0">+ ¥ {{Math.abs(packageDetail.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else-if="packageDetail.PreferentialTotalAmount>0">- ¥ {{Math.abs(packageDetail.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </el-col>
                    <el-col :span="3">¥ {{packageDetail.ArrearAmount | NumFormat}}</el-col>
                    <el-col :span="3">¥ {{item.IsLargess?0:packageDetail.TotalAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" style="margin-top:4px">
                    <el-col :span="11" class="color_red">¥ {{packageDetail.PackageTotalPrice | NumFormat}}</el-col>
                    <el-col :span="13">
                      <div>
                        <span class="color_gray font_12" v-if="packageDetail.PricePreferentialAmount !=0">
                          手动改价：
                          <span class="color_red" v-if="packageDetail.PricePreferentialAmount>0">- ¥ {{Math.abs(packageDetail.PricePreferentialAmount)| NumFormat}}</span>
                          <span class="color_green" v-else-if="packageDetail.PricePreferentialAmount<0">+ ¥ {{Math.abs(packageDetail.PricePreferentialAmount) | NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </span>
                        <span class="color_gray font_12" :class="packageDetail.CardPreferentialAmount !=0?'marlt_5':''" v-if="packageDetail.CardPreferentialAmount>0">
                          卡优惠：
                          <span class="color_red">- ¥ {{parseFloat(packageDetail.CardPreferentialAmount) | NumFormat}}</span>
                        </span>
                      </div>
                    </el-col>
                  </el-col>

                  <el-col v-if="packageDetail.Remark" :span="24" class="martp_5 font_12 color_333">备注信息：{{packageDetail.Remark}}
                  </el-col>

                </el-col>
                <!-- 套餐卡项目 -->
                <el-col :span="24" class="border_bottom pad_10 font_12" v-for="(packageDetail) in item.PackageCardGoods.PackageCardProject" :key="`${packageDetail.ProjectID}`">
                  <el-col :span="24">
                    <el-col :span="5">
                      <span>{{packageDetail.Name}} ×{{packageDetail.Quantity}}</span>
                      <el-tag class="marlt_5" size="mini">项目</el-tag>
                    </el-col>
                    <el-col :span="2">× {{packageDetail.PackageQuantity}}</el-col>
                    <el-col :span="3"> {{packageDetail.SaleCurrentWeight}}</el-col>
                    <el-col :span="3">{{packageDetail.CustomerTargetWeight}}</el-col>
                    <el-col :span="3">{{packageDetail.TargetWeight}}</el-col>
                    <el-col :span="3">
                      <span v-if="packageDetail.PreferentialTotalAmount<0">+ ¥ {{Math.abs(packageDetail.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else-if="packageDetail.PreferentialTotalAmount>0">- ¥ {{Math.abs(packageDetail.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </el-col>
                    <el-col :span="3">¥ {{packageDetail.ArrearAmount | NumFormat}}</el-col>
                    <el-col :span="3">¥ {{item.IsLargess?0:packageDetail.TotalAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" style="margin-top:4px">
                    <el-col :span="11" class="color_red">¥ {{packageDetail.PackageTotalPrice | NumFormat}}</el-col>
                    <el-col :span="13">
                      <div>
                        <span class="color_gray font_12" v-if="packageDetail.PricePreferentialAmount !=0">
                          手动改价：
                          <span class="color_red" v-if="packageDetail.PricePreferentialAmount>0">- ¥ {{Math.abs(packageDetail.PricePreferentialAmount)| NumFormat}}</span>
                          <span class="color_green" v-else-if="packageDetail.PricePreferentialAmount<0">+ ¥ {{Math.abs(packageDetail.PricePreferentialAmount) | NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </span>
                        <span class="color_gray font_12" :class="packageDetail.CardPreferentialAmount !=0?'marlt_5':''" v-if="packageDetail.CardPreferentialAmount>0">
                          卡优惠：
                          <span class="color_red">- ¥ {{parseFloat(packageDetail.CardPreferentialAmount) | NumFormat}}</span>
                        </span>
                      </div>
                    </el-col>
                  </el-col>

                  <el-col v-if="packageDetail.Remark" :span="24" class="martp_5 font_12 color_333">备注信息：{{packageDetail.Remark}}
                  </el-col>

                </el-col>
                <!-- 套餐卡通用次卡 -->
                <el-col :span="24" class="border_bottom pad_10 font_12" v-for="(packageDetail) in item.PackageCardGoods.PackageCardGeneralCard" :key="`${packageDetail.GeneralCardID}`">
                  <el-col :span="24">
                    <el-col :span="5">
                      <span>{{packageDetail.Name}} ×{{packageDetail.Quantity}}</span>
                      <el-tag class="marlt_5" size="mini">通用次卡</el-tag>
                      <span v-if="isRemark" class="marlt_5 color_primary cursor_pointer">
                        <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="changeRemarkClick(packageDetail,'updatePackageCardGeneralCardRemark')"></el-button>
                      </span>
                    </el-col>
                    <el-col :span="2">× {{packageDetail.PackageQuantity}}</el-col>
                    <el-col :span="3">{{packageDetail.SaleCurrentWeight}}</el-col>
                    <el-col :span="3">{{packageDetail.CustomerTargetWeight}}</el-col>
                    <el-col :span="3">{{packageDetail.TargetWeight}}</el-col>
                    <el-col :span="3">
                      <span v-if="packageDetail.PreferentialTotalAmount<0">+ ¥ {{Math.abs(packageDetail.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else-if="packageDetail.PreferentialTotalAmount>0">- ¥ {{Math.abs(packageDetail.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </el-col>
                    <el-col :span="3">¥ {{packageDetail.ArrearAmount | NumFormat}}</el-col>
                    <el-col :span="3">¥ {{item.IsLargess?0:packageDetail.TotalAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" style="margin-top:4px">
                    <el-col :span="11" class="color_red">¥ {{packageDetail.PackageTotalPrice | NumFormat}}</el-col>
                    <el-col :span="13">
                      <div>
                        <span class="color_gray font_12" v-if="packageDetail.PricePreferentialAmount !=0">
                          手动改价：
                          <span class="color_red" v-if="packageDetail.PricePreferentialAmount>0">- ¥ {{Math.abs(packageDetail.PricePreferentialAmount)| NumFormat}}</span>
                          <span class="color_green" v-else-if="packageDetail.PricePreferentialAmount<0">+ ¥ {{Math.abs(packageDetail.PricePreferentialAmount) | NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </span>
                        <span class="color_gray font_12" :class="packageDetail.CardPreferentialAmount !=0?'marlt_5':''" v-if="packageDetail.CardPreferentialAmount>0">
                          卡优惠：
                          <span class="color_red">- ¥ {{parseFloat(packageDetail.CardPreferentialAmount) | NumFormat}}</span>
                        </span>
                      </div>
                    </el-col>
                  </el-col>

                  <el-col v-if="packageDetail.Remark" :span="24" class="martp_5 font_12 color_333">备注信息：{{packageDetail.Remark}}
                  </el-col>

                </el-col>
                <!-- 套餐卡时效卡 -->
                <el-col :span="24" class="border_bottom pad_10 font_12" v-for="(packageDetail) in item.PackageCardGoods.PackageCardTimeCard" :key="`${packageDetail.TimeCardID}`">
                  <el-col :span="24">
                    <el-col :span="5">
                      <span>{{packageDetail.Name}} ×{{packageDetail.Quantity}}</span>
                      <el-tag class="marlt_5" size="mini">时效卡</el-tag>
                      <span v-if="isRemark" class="marlt_5 color_primary cursor_pointer">
                        <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="changeRemarkClick(packageDetail,'updatePackageCardTimeCardRemark')"></el-button>
                      </span>
                    </el-col>
                    <el-col :span="2">× {{packageDetail.PackageQuantity}}</el-col>
                    <el-col :span="3">{{packageDetail.SaleCurrentWeight}}</el-col>
                    <el-col :span="3">{{packageDetail.CustomerTargetWeight}}</el-col>
                    <el-col :span="3">{{packageDetail.TargetWeight}}</el-col>
                    <el-col :span="3">
                      <span v-if="packageDetail.PreferentialTotalAmount<0">+ ¥ {{Math.abs(packageDetail.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else-if="packageDetail.PreferentialTotalAmount>0">- ¥ {{Math.abs(packageDetail.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </el-col>
                    <el-col :span="3">¥ {{packageDetail.ArrearAmount | NumFormat}}</el-col>
                    <el-col :span="2">¥ {{item.IsLargess?0:packageDetail.TotalAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" style="margin-top:4px">
                    <el-col :span="11" class="color_red">¥ {{packageDetail.PackageTotalPrice | NumFormat}}</el-col>
                    <el-col :span="13">
                      <div>
                        <span class="color_gray font_12" v-if="packageDetail.PricePreferentialAmount !=0">
                          手动改价：
                          <span class="color_red" v-if="packageDetail.PricePreferentialAmount>0">- ¥ {{Math.abs(packageDetail.PricePreferentialAmount)| NumFormat}}</span>
                          <span class="color_green" v-else-if="packageDetail.PricePreferentialAmount<0">+ ¥ {{Math.abs(packageDetail.PricePreferentialAmount) | NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </span>
                        <span class="color_gray font_12" :class="packageDetail.CardPreferentialAmount !=0?'marlt_5':''" v-if="packageDetail.CardPreferentialAmount>0">
                          卡优惠：
                          <span class="color_red">- ¥ {{parseFloat(packageDetail.CardPreferentialAmount) | NumFormat}}</span>
                        </span>
                      </div>
                    </el-col>
                  </el-col>

                  <el-col v-if="packageDetail.Remark" :span="24" class="martp_5 font_12 color_333">备注信息：{{packageDetail.Remark}}
                  </el-col>

                </el-col>
                <!-- 套餐卡储值卡 -->
                <el-col :span="24" class="border_bottom pad_10 font_12" v-for="(packageDetail) in item.PackageCardGoods.PackageCardSavingCard" :key="`${packageDetail.SavingCardID}`">
                  <el-col :span="24">
                    <el-col :span="5">
                      <span>{{packageDetail.Name}} ×{{packageDetail.Quantity}}</span>
                      <el-tag class="marlt_5" size="mini">储值卡</el-tag>
                      <span v-if="isRemark" class="marlt_5 color_primary cursor_pointer">
                        <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="changeRemarkClick(packageDetail,'updatePackageCardSavingCardRemark')"></el-button>
                      </span>
                    </el-col>
                    <el-col :span="2">× {{packageDetail.PackageQuantity}}</el-col>
                    <el-col :span="3">{{packageDetail.SaleCurrentWeight}}</el-col>
                    <el-col :span="3">{{packageDetail.CustomerTargetWeight}}</el-col>
                    <el-col :span="3">{{packageDetail.TargetWeight}}</el-col>
                    <el-col :span="3">¥ {{packageDetail.ArrearAmount | NumFormat}}</el-col>
                    <el-col :span="2">¥ {{item.IsLargess?0:packageDetail.TotalAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" style="margin-top:4px">
                    <el-col :span="11" class="color_red">¥ {{packageDetail.TotalPrice | NumFormat}}</el-col>
                  </el-col>

                  <el-col v-if="packageDetail.Remark" :span="24" class="martp_5  font_12 color_333">备注信息：{{packageDetail.Remark}}
                  </el-col>
                </el-col>
                <!-- 套餐卡赠送产品 -->
                <el-col :span="24" class="border_bottom pad_10 font_12" v-for="(packageDetail) in item.PackageCardLargessGoods.PackageCardProduct" :key="`2-${packageDetail.ProductID}`">
                  <el-col :span="24">
                    <el-col :span="8">
                      <div>
                        <el-tag size="mini" type="danger">赠</el-tag>
                        <span>{{packageDetail.Name}} × {{packageDetail.Quantity}}</span>
                        <el-tag class="marlt_5" size="mini">产品</el-tag>
                        <span v-if="isRemark" class="marlt_5 color_primary cursor_pointer">
                          <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="changeRemarkClick(packageDetail,'updatePackageCardProductRemark')"></el-button>
                        </span>
                      </div>
                      <div class="color_red martp_5">¥ {{packageDetail.PackageTotalPrice | NumFormat}}</div>
                    </el-col>
                    <el-col :span="16">× {{packageDetail.PackageQuantity}}</el-col>
                  </el-col>

                   <el-col v-if="packageDetail.Remark" :span="24" class="martp_5 font_12 color_333">备注信息：{{packageDetail.Remark}}
                  </el-col>
                </el-col>
                <!-- 套餐卡赠送项目 -->
                <el-col :span="24" class="border_bottom pad_10 font_12" v-for="(packageDetail) in item.PackageCardLargessGoods.PackageCardProject" :key="`2-${packageDetail.ProjectID}`">
                  <el-col :span="24">
                    <el-col :span="8">
                      <div>
                        <el-tag size="mini" type="danger">赠</el-tag>
                        <span>{{packageDetail.Name}} × {{packageDetail.Quantity}}</span>
                        <el-tag class="marlt_5" size="mini">项目</el-tag>
                        <span v-if="isRemark" class="marlt_5 color_primary cursor_pointer">
                          <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="changeRemarkClick(packageDetail,'updatePackageCardProjectRemark')"></el-button>
                        </span>
                      </div>
                      <div class="color_red martp_5">¥ {{packageDetail.PackageTotalPrice | NumFormat}}</div>
                    </el-col>
                    <el-col :span="16">× {{packageDetail.PackageQuantity}}</el-col>
                  </el-col>

                  <el-col v-if="packageDetail.Remark" :span="24" class="martp_5  font_12 color_333">备注信息：{{packageDetail.Remark}}
                  </el-col>
                </el-col>
                <!-- 套餐卡赠送通用次卡 -->
                <el-col :span="24" class="border_bottom pad_10 font_12" v-for="(packageDetail) in item.PackageCardLargessGoods.PackageCardGeneralCard" :key="`2-${packageDetail.GeneralCardID}`">
                  <el-col :span="24">
                    <el-col :span="8">
                      <div>
                        <el-tag size="mini" type="danger">赠</el-tag>
                        <span>{{packageDetail.Name}} × {{packageDetail.Quantity}}</span>
                        <el-tag class="marlt_5" size="mini">通用次卡</el-tag>
                        <span v-if="isRemark" class="marlt_5 color_primary cursor_pointer">
                          <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="changeRemarkClick(packageDetail,'updatePackageCardGeneralCardRemark')"></el-button>
                        </span>
                      </div>
                      <div class="color_red martp_5">¥ {{packageDetail.PackageTotalPrice | NumFormat}}</div>
                    </el-col>
                    <el-col :span="16">× {{packageDetail.PackageQuantity}}</el-col>
                  </el-col>

                  <el-col v-if="packageDetail.Remark" :span="24" class="martp_5  font_12 color_333">备注信息：{{packageDetail.Remark}}
                  </el-col>
                </el-col>
                <!-- 套餐卡赠送时效卡 -->
                <el-col :span="24" class="border_bottom pad_10 font_12" v-for="(packageDetail) in item.PackageCardLargessGoods.PackageCardTimeCard" :key="`2-${packageDetail.TimeCardID}`">
                  <el-col :span="24">
                    <el-col :span="8">
                      <div>
                        <el-tag size="mini" type="danger">赠</el-tag>
                        <span>{{packageDetail.Name}} × {{packageDetail.Quantity}}</span>
                        <el-tag class="marlt_5" size="mini">时效卡</el-tag>
                      </div>
                      <div class="color_red martp_5">¥ {{packageDetail.PackageTotalPrice | NumFormat}}</div>
                    </el-col>
                    <el-col :span="16">× {{packageDetail.PackageQuantity}}</el-col>
                  </el-col>

                  <el-col v-if="packageDetail.Remark" :span="24" class="martp_5  font_12 color_333">备注信息：{{packageDetail.Remark}}
                  </el-col>
                </el-col>
                <!-- 套餐卡赠送储值卡 -->
                <el-col :span="24" class="border_bottom pad_10 font_12" v-for="(packageDetail) in item.PackageCardLargessGoods.PackageCardSavingCard" :key="`2-${packageDetail.SavingCardID}`">
                  <el-col :span="24">
                    <el-col :span="8">
                      <div>
                        <el-tag size="mini" type="danger">赠</el-tag>
                        <span>{{packageDetail.Name}} × {{packageDetail.Quantity}}</span>
                        <el-tag class="marlt_5" size="mini">储值卡</el-tag>
                        <span v-if="isRemark" class="marlt_5 color_primary cursor_pointer">
                          <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="changeRemarkClick(packageDetail,'updatePackageCardSavingCardRemark')"></el-button>
                        </span>
                      </div>
                      <div class="color_red martp_5">¥ {{packageDetail.TotalPrice | NumFormat}}</div>
                    </el-col>
                    <el-col :span="16">× {{packageDetail.PackageQuantity}}</el-col>
                  </el-col>

                  <el-col v-if="packageDetail.Remark" :span="24" class=" martp_5 font_12 color_333">备注信息：{{packageDetail.Remark}}
                  </el-col>
                </el-col>
              </el-col>

              <el-col v-if="item.Remark" :span="24" class="pad_10 border_bottom font_12 color_333">备注信息：{{item.Remark}}
              </el-col>
              <el-col :span="24" v-if="item.SaleBillHandler.length>0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler,pIndex) in item.SaleBillHandler" :key="pIndex + 'h6'">
                  <el-col :span="2">
                    <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.SaleHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{(employee.Scale || 0).toFixed(2)}}%</el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="border_left border_right border_bottom">
          <el-row>
            <el-col :span="6" :offset="17">
              <el-form class="saleInfo" size="mini">
                <el-form-item label="合计：">
                  <div class="text_right">￥{{saleOrderDetail.TotalAmount | NumFormat}}</div>
                </el-form-item>
                <el-form-item v-if="saleOrderDetail.PricePreferentialAmount<0" label="手动改价：">
                  <div class="text_right">+￥{{Math.abs(saleOrderDetail.PricePreferentialAmount) | NumFormat}}</div>
                </el-form-item>
                <el-form-item v-if="saleOrderDetail.PricePreferentialAmount>0" label="手动改价：">
                  <div class="text_right">-￥{{Math.abs(saleOrderDetail.PricePreferentialAmount) | NumFormat}}</div>
                </el-form-item>
                <el-form-item v-if="saleOrderDetail.CardPreferentialAmount>0" label="卡优惠：">
                  <div class="text_right">-￥{{saleOrderDetail.CardPreferentialAmount | NumFormat}}</div>
                </el-form-item>
                <el-form-item label="订单金额：">
                  <div class="text_right">￥{{saleOrderDetail.Amount | NumFormat}}</div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 支付信息 -->
      <div class="payInfo">
        <div class="tip martp_10 marbm_10">支付信息</div>
        <el-card class="marbm_10" shadow="never" v-if="saleOrderDetail.SaleBillPay!=undefined && saleOrderDetail.SaleBillPay.length>0">
          <div slot="header">
            <span>支付收款</span>
          </div>
          <el-form class="saleInfo" :inline="true" size="mini" label-position="top">
            <el-form-item v-for="salepay in saleOrderDetail.SaleBillPay" :key="salepay.ID" :label="salepay.Name">¥ {{salepay.Amount| NumFormat}}</el-form-item>
          </el-form>
        </el-card>
        <el-card shadow="never" v-if="saleOrderDetail.SaleBillPaySavingCardDeduction!=undefined && saleOrderDetail.SaleBillPaySavingCardDeduction.length>0">
          <div slot="header">
            <span>储值卡抵扣</span>
          </div>
          <el-form class="saleInfo" :inline="true" size="mini" label-position="top">
            <el-form-item v-for="savingCardReduction in saleOrderDetail.SaleBillPaySavingCardDeduction" :key="savingCardReduction.ID" :label="savingCardReduction.Name">¥ {{savingCardReduction.TotalAmount| NumFormat}}</el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>


    <!-- 修改备注弹框 -->
    <el-dialog width="30%" :visible.sync="innerVisible" append-to-body>
      <span slot="title" class="text_center">修改销售单备注</span>
      <el-input type="textarea" :rows="4" v-model="Remark" placeholder="请输入备注内容"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="updateRemarkClick" size="small">保存</el-button>
      </span>
    </el-dialog>



    <el-dialog :visible.sync="projectRemarkDialogVisible" title="备注" width="500px" append-to-body>
      <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" maxlength="100" show-word-limit placeholder="请输入内容" v-model="projectRemark"> </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="projectRemarkDialogVisible = false" size="small" :disabled="RemarkLoading">取 消</el-button>
        <el-button type="primary" @click="saveRemarkClick" :loading="RemarkLoading" v-prevent-click size="small">保 存</el-button>
      </div>
    </el-dialog>
    
  </section>
</template>

<script>

import saleAPI from "@/api/iBeauty/Order/saleGoods";
import API from "@/api/iBeauty/Order/saleBill";

export default {
 name: 'saleBillDetailContent',
  props: {
    saleOrderDetail: Object,
    isRemark: {
      type: Boolean,
      default: false,
    },
  },
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      changeMemberDialogVisible: false,
      innerVisible: false, //备注弹窗
      customerName: "",
      customerID: null,
      Remark:"",
      projectRemarkDialogVisible:false,
      RemarkLoading: false,
      projectRemark: "",
      currentItem: "",
      goodsTypeName: "",
    }
  },
  /**  方法集合  */
  methods: {
     /**    */
    changeRemarkClick(item, type) {
      let that = this;
      console.log(item)
      console.log(type)
      that.currentItem = item;
      that.goodsTypeName = type;
      that.projectRemark = item.Remark;
      that.projectRemarkDialogVisible = true;
    },
    /**  修改备注  */
    saveRemarkClick() {
      let that = this;
      let SaleBillGoodID = "";
      switch (that.goodsTypeName) {
        case "updateProjectRemark":
          SaleBillGoodID = that.currentItem.SaleBillProjectID;
          break;
        case "updateProductRemark":
          SaleBillGoodID = that.currentItem.SaleBillProductID;
          break;
        case "updateGeneralCardRemark":
          SaleBillGoodID = that.currentItem.SaleBillGeneralCardID;
          break;
        case "updateTimeCardRemark":
          SaleBillGoodID = that.currentItem.SaleBillTimeCardID;
          break;
        case "updateSavingCardRemark":
          SaleBillGoodID = that.currentItem.SaleBillSavingCardID;
          break;
        case "updatePackageCardRemark":
          SaleBillGoodID = that.currentItem.SaleBillPackageCardID;
          break;
        case "updatePackageCardProductRemark":
          SaleBillGoodID = that.currentItem.SaleBillPackageCardProductID;
          break;
        case "updatePackageCardProjectRemark":
          SaleBillGoodID = that.currentItem.SaleBillPackageCardProjectID;
          break;
        case "updatePackageCardGeneralCardRemark":
          SaleBillGoodID = that.currentItem.SaleBillPackageCardGeneralCardID;
          break;
        case "updatePackageCardTimeCardRemark":
          SaleBillGoodID = that.currentItem.SaleBillPackageCardTimeCardID;
          break;
        case "updatePackageCardSavingCardRemark":
          SaleBillGoodID = that.currentItem.SaleBillPackageCardSavingCardID;
          break;
        default:
          that.$message.error("明细ID获取失败");
          return;
      }

      let item = {
        Remark: that.projectRemark,
        SaleBillGoodID: SaleBillGoodID,
      };
      that.$emit("saveRemark", item, that.goodsTypeName, () => {
        console.log('走到这了')
        that.currentItem.Remark = that.projectRemark;
        that.$message.success("修改成功");
        that.projectRemarkDialogVisible = false;
      });
    },



     /**  查看套餐卡明细  */
    packDetailClick(item) {
      item.IsShowDetails = !item.IsShowDetails;
    },
       // 顾客
    saleCustomerData: function (queryString, cb) {
      var that = this;
      that.loading = true;
      var params = {
        Name: queryString ? queryString : "",
      };
      saleAPI
        .getSaleCustomer(params)
        .then((res) => {
          if (res.StateCode == 200) {
            cb(res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    handleCustomerSelect(item) {
      if (item.ID != undefined) {
        this.customerID = item.ID;
        this.customerFullName = item.Name;
        this.customerPhoneNumber = item.PhoneNumber;
        this.customerName = item.Name + "【" + item.PhoneNumber + "】";
        // this.customerChange();
      } else {
        // this.addNewCustomer();
      }
    },

    removeCustomer() {
      var that = this;
      that.customerID = null;
      that.customerFullName = "";
      that.customerPhoneNumber = "";
      that.customerName = "";
    },

    /**  更新 顾客网络请求  */
    updateSaleBillBindCutsomer() {
      var that = this;
      that.changeMemberDialogVisible = false;
      var params = {
        BillID: that.saleOrderDetail.ID,
        CustomerID: that.customerID,
      };
      API.updateSaleBillBindCutsomer(params).then((res) => {
        if (res.StateCode == 200) {
          that.saleOrderDetail.Name = that.customerName;
          that.saleOrderDetail.CustomerID = " ";
          // that.handleSearch();
          that.$emit('refreshBillList');
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    /**  修改备注   */
    ChangeRemarkinnerVisible(){
      let that = this;
      that.Remark = that.saleOrderDetail.Remark;
      that.innerVisible = true;
    },
    //修改备注
    updateRemarkClick() {
      var that = this;
      var params = {
        SaleBillID: that.saleOrderDetail.ID,
        Remark: that.Remark,
      };
      API.updateRemark(params).then((res) => {
        if (res.StateCode == 200) {
          that.saleOrderDetail.Remark = that.Remark;
          that.Remark = "";
          that.innerVisible = false;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
  },
  /**  实例被挂载后调用  */
  mounted() {},
}
</script>

<style lang="scss">
  .saleBillDetailContent{
    .el-scrollbar_height {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
    .saleHandler {
      .el-form-item__label {
        font-size: 12px !important;
        line-height: 18px;
        color: #c0c4cc;
      }
      .el-form-item__content {
        font-size: 12px !important;
        line-height: 20px;
        color: #c0c4cc;
      }
      .el-form-item {
        margin-bottom: 0px;
      }
    }

    .payInfo {
      .el-card__header {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .el-card__body {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }


    .saleInfo {
      .el-form-item__label {
        font-size: 13px !important;
      }
      .el-form-item__content {
        font-size: 13px !important;
      }
      .el-form-item {
        margin-bottom: 0px;
      }
    }

  }


  .customer-autocomplete-bill {
    li {
      line-height: normal;
      padding: 7px;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .info {
        font-size: 12px;
        color: #b4b4b4;
      }
      .highlighted .info {
        color: #ddd;
      }
    }
  }
  .el-col {
    border:1px solid transparent;
  }
</style>

import * as API from '@/api/index'
export default{

    /**  查找会员 */
getSaleCustomer: params => {
    return API.POST('api/saleCustomer/customer', params)
  },
    //转账列表
 transerAcount: params =>{
    return API.POST('api/transer/list',params)
  },
 
  //转账详情
  transerInfo:params =>{
    return API.POST('api/transer/info',params)
  },
  //转账项目存量列表
  TranserAccountProjectAccount:params =>{
    return API.POST('api/transerAccount/projectAccount',params)
  },
  //转账产品存量列表
  TranserAccountProductAccount:params =>{
    return API.POST('api/transerAccount/productAccount',params)
  },
  //转账储值卡存量列表
  TranserAccountSavingCardAccount:params =>{
    return API.POST('api/transerAccount/savingCardAccount',params)
  },
  //转账通用次卡存量列表
  TranserAccountGeneralCardAccount:params =>{
    return API.POST('api/transerAccount/generalCardAccount',params)
  },
  //转账时效卡存量列表
  TranserAccountTimeCardAccount:params =>{
    return API.POST('api/transerAccount/timeCardAccount',params)
  },
  //转账套餐卡存量列表
  TranserAccountPackageCardAccount:params =>{
    return API.POST('api/transerAccount/packageCardAccount',params)
  },
  //转账套餐卡存量明细
  TranserAccountPackageCardAccountDetails:params =>{
    return API.POST('api/transerAccount/packageCardAccountDetails',params)
 },
 //会员转账保存
 SaveapiTranserCreate:params =>{
     return  API.POST('api/transer/create',params)
 }
}
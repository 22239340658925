/**
 * Created by preference on 2020/08/07
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /** 获取员工和顾客预约信息  */
  appointmentBillAll: params => {
    return API.POST('api/appointmentBill/all', params)
  },
  /** 创建预约  */
  appointmentBillCreate: params => {
    return API.POST('api/appointmentBill/create', params)
  },
  /** 修改预约  */
  appointmentBillUpdate: params => {
    return API.POST('api/appointmentBill/update', params)
  },
  /** 预约列表 */
  appointmentBillList: params => {
    return API.POST('api/appointmentBill/list', params)
  },
  /** 预约详情 */
  appointmentBillInfo: params => {
    return API.POST('api/appointmentBill/info', params)
  },
  /** 可预约员工列表 */
  getEmployeeList: params => {
    return API.POST('api/appointmentBill/employee', params)
  },
  /** 项目列表 */
  getProjectList: params => {
    return API.POST('api/generalCard/findCategoryAndProject', params)
  },
}
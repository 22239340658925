

 import * as API from '@/api/index'
 export default {
     /**  上传图片*/
     AddImage: params => {
        return API.POST('api/Commodity/addImage', params)
    },
    //添加减重计划
    addCustomerReduceWeightPlan: params=>{
        return API.POST("api/customer/addCustomerReduceWeightPlan",params)
    },
    //编辑减重计划
    updateCustomerReduceWeightPlan: params=>{
        return API.POST("api/customer/updateCustomerReduceWeightPlan",params)
    },
    //上传图片
    addImage:params=>{
        return API.POST("api/customer/addCustomerReduceWeightPlanImage",params)
    },
    //删除图片
    deleteImage:params=>{
        return API.POST("api/customer/deleteCustomerReduceWeightPlanImage",params)
    },
    //更新图片状态
    updateShow:params=>{
        return API.POST("api/customer/updateCustomerReduceWeightPlanIsShow",params)
    },
    //展示计划
    customerReduceWeightPlanList:params=>{
        return API.POST("api/customer/customerReduceWeightPlanList",params)
    }
 }
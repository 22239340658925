<!-- /** 退款订单详情内容  */--> 
<template>
  <section class="refundDetailContent">
    <div>
      <!-- 订单信息 -->
      <div>
        <div class="tip marbm_10" style="margin-top:0">订单信息</div>
        <el-form label-width="100px" class="saleInfo" size="small">
          <el-row>
            <el-col :span="8">
              <el-form-item label="订单编号:">{{saleOrderDetail.ID}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单类型:">{{saleOrderDetail.BillType == 10?"销售单":saleOrderDetail.BillType==20?"销售退款单":"补欠款单"}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单状态:">{{saleOrderDetail.BillStatus == 10?"待付款":saleOrderDetail.BillStatus==20?"已完成":"已取消"}}</el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="顾客信息:">{{saleOrderDetail.Name}} ({{saleOrderDetail.PhoneNumber}})</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收银员:">{{saleOrderDetail.EmployeeName}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单金额:">￥{{saleOrderDetail.Amount | NumFormat}}</el-form-item>
            </el-col>
            
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="下单时间:">{{saleOrderDetail.BillDate}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="录单时间:">{{saleOrderDetail.CreatedOn}}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单备注:">
                <span v-if="saleOrderDetail.Remark" class="marrt_5">{{saleOrderDetail.Remark}}</span>
                <el-button type="text" @click="ChangeRemarkinnerVisible">修改备注</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row></el-row>
        </el-form>
      </div>
      <!-- 消费信息 -->
      <div class="martp_10">
        <div class="tip marbm_10" style="margin-top:0">退款明细</div>
        <div class="border_left border_right border_top">
          <!-- 项目 -->
          <div v-if="saleOrderDetail.Project != undefined && saleOrderDetail.Project.length > 0">
            <el-row class="row_header">
              <el-col :span="6">项目</el-col>
              <el-col :span="3">购买数量</el-col>
              <el-col :span="4">购买金额</el-col>
              <el-col :span="3">退款数量</el-col>
              <el-col :span="4">可退金额</el-col>
              <el-col :span="4">退款金额</el-col>
            </el-row>
            <el-row v-for="(item,index) in saleOrderDetail.Project" :key="index + 'x1'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="6">
                    <div>
                      {{item.ProjectName}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                    </div>
                  </el-col>
                  <el-col :span="3">x {{item.AccountQuantity}}</el-col>
                  <el-col :span="4">
                    ¥ {{item.AccountTotalAmount | NumFormat}}
                    <!-- <span
                      v-if="item.PreferentialTotalAmount<0"
                    >+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                    <span
                      v-else-if="item.PreferentialTotalAmount>0"
                    >- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                    <span v-else>¥ 0.00</span> -->
                  </el-col>
                  <el-col :span="3">x {{item.Quantity}}</el-col>
                  <el-col :span="4">
                    ¥ {{item.TotalAmount + item.PricePreferentialAmount | NumFormat}}
                  </el-col>
                  <el-col :span="4">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                </el-col>
                <!-- <el-col :span="24" class="martp_5">
                  <el-col :span="11">
                    <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                  </el-col>
                  <el-col :span="13">
                    <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                      手动改价：
                      <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                      <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                    </span>
                    <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                      卡优惠：
                      <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                    </span>
                  </el-col>
                </el-col> -->
              </el-col>
              <el-col :span="24" v-if="item.SaleBillHandler.length>0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler,pIndex) in item.SaleBillHandler" :key="pIndex + 'h1'">
                  <el-col :span="2">
                    <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.SaleHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{employee.Scale.toFixed(2)}}%</el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <!-- 储值卡 -->
          <div v-if="saleOrderDetail.SavingCard != undefined && saleOrderDetail.SavingCard.length > 0">
            <el-row class="row_header">
              <el-col :span="6">储值卡</el-col>
              <!-- <el-col :span="3">数量</el-col> -->
              <el-col :span="4">充值金额</el-col>
              <el-col :span="4">赠送金额</el-col>
              <el-col :span="5">退款金额</el-col>
              <el-col :span="5">退款赠额</el-col>
              <!-- <el-col :span="4">退赠送金额</el-col> -->
            </el-row>
            <el-row v-for="(item,index) in saleOrderDetail.SavingCard" :key="index + 'x2'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="6">
                    <div>
                      {{item.SavingCardName}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                    </div>
                  </el-col>
                  <!-- <el-col :span="3">x {{item.Quantity}}</el-col> -->
                  <el-col :span="4">¥ {{item.AccountTotalAmount | NumFormat}}</el-col>
                  <el-col :span="4">¥ {{item.AccountLargessAmount | NumFormat}}</el-col>
                  <el-col :span="5">¥ {{item.Amount | NumFormat}}</el-col>
                  <el-col :span="5">¥ {{item.LargessAmount | NumFormat}}</el-col>
                  <!-- <el-col :span="4">¥ {{item.TotalAmount | NumFormat}}</el-col> -->
                </el-col>
                <!-- <el-col :span="24" class="martp_5">
                  <div>
                    <span class="color_red" v-if="item.Price ">¥ {{item.Price | NumFormat}}</span>
                    <span class="marlt_10 font_12 color_gray" v-if="item.LargessPrice">赠送：¥ {{item.LargessPrice}}</span>
                  </div>
                </el-col> -->
              </el-col>
              <el-col :span="24" v-if="item.SaleBillHandler.length>0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler,pIndex) in item.SaleBillHandler" :key="pIndex + 'h2'">
                  <el-col :span="2">
                    <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.SaleHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{employee.Scale.toFixed(2)}}%</el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <!-- 时效卡 -->
          <div v-if="saleOrderDetail.TimeCard != undefined && saleOrderDetail.TimeCard.length > 0">
            <!-- 购买数量 购买金额 退款数量 可退金额 退款金额 -->
            <el-row class="row_header">
              <el-col :span="6">时效卡</el-col>
              <el-col :span="3">购买数量</el-col>
              <el-col :span="4">购买金额</el-col>
              <el-col :span="3">退款数量</el-col>
              <el-col :span="4">可退金额</el-col>
              <el-col :span="4">退款金额</el-col>
            </el-row>
            <el-row v-for="(item,index) in saleOrderDetail.TimeCard" :key="index + 'x3'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="6">
                    <div>
                      {{item.TimeCardName}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                    </div>
                  </el-col>
                  <el-col :span="3">x {{item.Quantity}}</el-col>
                  <el-col :span="4">¥ {{item.AccountTotalAmount | NumFormat}}</el-col>
                  <el-col :span="3">x {{item.Quantity}}</el-col>
                  <el-col :span="4">¥ {{item.TotalAmount + item.PricePreferentialAmount | NumFormat}}</el-col>
                  <el-col :span="4">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                </el-col>
              </el-col>
              <el-col :span="24" v-if="item.SaleBillHandler.length>0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler,pIndex) in item.SaleBillHandler" :key="pIndex + 'h3'">
                  <el-col :span="2">
                    <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.SaleHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{employee.Scale.toFixed(2)}}%</el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <!-- 通用次卡 -->
          <div v-if="saleOrderDetail.GeneralCard != undefined && saleOrderDetail.GeneralCard.length > 0">
            <el-row class="row_header">
              <el-col :span="6">通用次卡</el-col>
              <el-col :span="3">购买卡次数</el-col>
              <el-col :span="4">购买金额</el-col>
              <el-col :span="3">退卡次数</el-col>
              <el-col :span="4">可退金额</el-col>
              <el-col :span="4">退款金额</el-col>
            </el-row>
            <el-row v-for="(item,index) in saleOrderDetail.GeneralCard" :key="index + 'x4'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="6">
                    <div>
                      {{item.GeneralCardName}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                    </div>
                  </el-col>
                  <el-col :span="3">x {{item.AccountCardTimes}}</el-col>
                  <el-col :span="4">¥ {{item.AccountTotalAmount | NumFormat}}</el-col>
                  <el-col :span="3">x {{item.CardTimes}}</el-col>
                  <el-col :span="4">¥ {{item.TotalAmount + item.PricePreferentialAmount | NumFormat}}</el-col>
                  <el-col :span="4">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                </el-col>
              </el-col>
              <el-col :span="24" v-if="item.SaleBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler,pIndex) in item.SaleBillHandler" :key="pIndex + 'h4'">
                  <el-col :span="2">
                    <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.SaleHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{employee.Scale.toFixed(2)}}%</el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <!-- 产品 -->
          <div v-if="saleOrderDetail.Product != undefined && saleOrderDetail.Product.length > 0">
            <el-row class="row_header">
              <el-col :span="6">产品</el-col>
              <el-col :span="3">购买数量</el-col>
              <el-col :span="4">购买金额</el-col>
              <el-col :span="3">退款数量</el-col>
              <el-col :span="4">可退金额</el-col>
              <el-col :span="4">退款金额</el-col>
            </el-row>
            <el-row v-for="(item,index) in saleOrderDetail.Product" :key="index + 'x5'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="6">
                    <div>
                      {{item.ProductName}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                    </div>
                  </el-col>
                  <el-col :span="3">x {{item.AccountQuantity}}</el-col>
                  <el-col :span="4">¥ {{item.AccountTotalAmount | NumFormat}}</el-col>
                  <el-col :span="3">x {{item.Quantity}}</el-col>
                  <el-col :span="4">¥ {{item.TotalAmount + item.PricePreferentialAmount | NumFormat}}</el-col>
                  <el-col :span="4">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                </el-col>
                <!-- <el-col :span="24" class="martp_5">
                  <el-col :span="11">
                    <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                  </el-col>
                  <el-col :span="13">
                    <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                      手动改价：
                      <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                      <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                    </span>
                    <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_5':''" v-if="item.CardPreferentialAmount>0">
                      卡优惠：
                      <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                    </span>
                  </el-col>
                </el-col> -->
              </el-col>
              <el-col :span="24" v-if="item.SaleBillHandler.length>0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler,pIndex) in item.SaleBillHandler" :key="pIndex + 'h5'">
                  <el-col :span="2">
                    <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.SaleHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{employee.Scale.toFixed(2)}}%</el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <!-- 套餐卡 -->
          <div v-if="saleOrderDetail.PackageCard != undefined && saleOrderDetail.PackageCard.length > 0">
            <el-row class="row_header">
              <el-col :span="6">套餐卡</el-col>
              <el-col :span="3">购买数量</el-col>
              <el-col :span="4">购买金额</el-col>
              <el-col :span="3">退款数量</el-col>
              <el-col :span="4">可退金额</el-col>
              <el-col :span="4">退款金额</el-col>
            </el-row>
            <el-row v-for="(item,index) in saleOrderDetail.PackageCard" :key="index + 'x6'">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="6">
                      {{item.PackageCardName}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      <span class="marlt_15 color_primary cursor_pointer" @click="packDetailClick(item)">
                        <el-link type="primary" style="vertical-align: bottom" :underline="false">
                          查看
                          <i v-if="item.IsShowDetails" class="el-icon-view el-icon--right"></i>
                        </el-link>
                      </span>
                  </el-col>
                  <el-col :span="3">x {{item.Quantity}}</el-col>
                  <el-col :span="4">¥ {{item.AccountTotalAmount | NumFormat}}</el-col>
                  <el-col :span="3">x {{item.Quantity}}</el-col>
                  <el-col :span="4">¥ {{item.TotalAmount + item.PricePreferentialAmount | NumFormat}}</el-col>
                  <el-col :span="4">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                </el-col>
              </el-col>
              <el-col :span="24" v-if="item.IsShowDetails">
                <!-- 套餐卡产品 -->
                <el-col :span="24" class="border_bottom  font_12" v-for="(packageDetail) in item.PackageCardGoods.PackageCardProduct" :key="`${packageDetail.ProductID}`">
                  <el-row class="row_header_package_detail">
                    <el-col :span="6">套餐卡产品</el-col>
                    <el-col :span="3">购买数量</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退款数量</el-col>
                    <el-col :span="4">可退金额</el-col>
                    <el-col :span="4">退款金额</el-col>
                  </el-row>
                  <el-row class="pad_10">
                    <el-col :span="24">
                      <el-col :span="6">{{packageDetail.Name}}
                        <span v-if="packageDetail.Alias">({{packageDetail.Alias}})</span>
                      </el-col>
                      <el-col :span="3">× {{packageDetail.AccountQuantity}}</el-col>
                      <el-col :span="4">¥ {{packageDetail.AccountTotalAmount | NumFormat}}</el-col>
                      <el-col :span="3">× {{packageDetail.Quantity}}</el-col>
                      <el-col :span="4">¥ {{packageDetail.TotalAmount + packageDetail.PricePreferentialAmount | NumFormat}}</el-col>
                      <el-col :span="4">¥ {{item.IsLargess?0:packageDetail.TotalAmount | NumFormat}}</el-col>
                    </el-col>
                  </el-row>
                </el-col>
                <!-- 套餐卡项目 -->
                <el-col :span="24" class="border_bottom  font_12" v-for="(packageDetail) in item.PackageCardGoods.PackageCardProject" :key="`${packageDetail.ProjectID}`">

                  <el-row class="row_header_package_detail">
                    <el-col :span="6">套餐卡项目</el-col>
                    <el-col :span="3">购买数量</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退款数量</el-col>
                    <el-col :span="4">可退金额</el-col>
                    <el-col :span="4">退款金额</el-col>
                  </el-row>
                  <el-row class="pad_10">
                    <el-col :span="24">
                      <el-col :span="6">{{packageDetail.Name}}
                        <span v-if="packageDetail.Alias">({{packageDetail.Alias}})</span>
                      </el-col>
                      <el-col :span="3">× {{packageDetail.AccountQuantity}}</el-col>
                      <el-col :span="4">¥ {{packageDetail.AccountTotalAmount | NumFormat}}</el-col>
                      <el-col :span="3">× {{packageDetail.Quantity}}</el-col>
                      <el-col :span="4">¥ {{packageDetail.TotalAmount + packageDetail.PricePreferentialAmount | NumFormat}}</el-col>
                      <el-col :span="4">¥ {{item.IsLargess?0:packageDetail.TotalAmount | NumFormat}}</el-col>
                    </el-col>
                  </el-row>

                </el-col>
                <!-- 套餐卡通用次卡 -->
                <el-col :span="24" class="border_bottom  font_12" v-for="(packageDetail) in item.PackageCardGoods.PackageCardGeneralCard" :key="`${packageDetail.GeneralCardID}`">
                  <el-row class="row_header_package_detail">
                    <el-col :span="6">套餐卡通用次卡</el-col>
                    <el-col :span="3">购买卡次数</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退卡次数</el-col>
                    <el-col :span="4">可退金额</el-col>
                    <el-col :span="4">退款金额</el-col>
                  </el-row>
                  <el-row class="pad_10">
                    <el-col :span="24">
                      <el-col :span="6">{{packageDetail.Name}}
                        <span v-if="packageDetail.Alias">({{packageDetail.Alias}})</span>
                      </el-col>
                      <el-col :span="3">× {{packageDetail.AccountCardTimes}}</el-col>
                      <el-col :span="4">¥ {{packageDetail.AccountTotalAmount | NumFormat}}</el-col>
                      <el-col :span="3">× {{packageDetail.CardTimes}}</el-col>
                      <el-col :span="4">¥ {{packageDetail.TotalAmount + packageDetail.PricePreferentialAmount | NumFormat}}</el-col>
                      <el-col :span="4">¥ {{item.IsLargess?0:packageDetail.TotalAmount | NumFormat}}</el-col>
                    </el-col>
                  </el-row>
                </el-col>
                <!-- 套餐卡时效卡 -->
                <el-col :span="24" class="border_bottom font_12" v-for="(packageDetail) in item.PackageCardGoods.PackageCardTimeCard" :key="`${packageDetail.TimeCardID}`">
                  <el-row class="row_header_package_detail">
                    <el-col :span="6">时效卡</el-col>
                    <el-col :span="3">购买数量</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退款数量</el-col>
                    <el-col :span="4">可退金额</el-col>
                    <el-col :span="4">退款金额</el-col>
                  </el-row>
                  <el-row class="pad_10">
                      <el-col :span="24">
                        <el-col :span="6">{{packageDetail.Name}}
                          <span v-if="packageDetail.Alias">({{packageDetail.Alias}})</span>
                        </el-col>
                        <el-col :span="3">× {{packageDetail.PackageQuantity}}</el-col>
                        <el-col :span="4">¥ {{packageDetail.AccountTotalAmount | NumFormat}}</el-col>
                        <el-col :span="3">× {{packageDetail.Quantity}}</el-col>
                        <el-col :span="4">¥ {{packageDetail.TotalAmount + packageDetail.PricePreferentialAmount | NumFormat}}</el-col>
                        <el-col :span="4">¥ {{item.IsLargess?0:packageDetail.TotalAmount | NumFormat}}</el-col>
                      </el-col>
                  </el-row>
                </el-col>
                <!-- 套餐卡储值卡 -->
                <el-col :span="24" class="border_bottom font_12" v-for="(packageDetail) in item.PackageCardGoods.PackageCardSavingCard" :key="`${packageDetail.SavingCardID}`">
                  <el-row class="row_header_package_detail">
                    <el-col :span="8">储值卡</el-col>
                    <el-col :span="8">充值金额</el-col>
                    <el-col :span="8">退款金额</el-col>
                  </el-row>
                  <el-row class="pad_10">
                    <el-col :span="24">
                      <el-col :span="8">{{packageDetail.Name}}
                        <span v-if="packageDetail.Alias">({{packageDetail.Alias}})</span>
                      </el-col>
                      <el-col :span="8">¥ {{packageDetail.AccountTotalAmount | NumFormat}}</el-col>
                      <el-col :span="8">¥ {{packageDetail.TotalAmount | NumFormat}}</el-col>
                    </el-col>
                  </el-row>
                </el-col>
                <!-- 套餐卡赠送产品 -->
                <el-col :span="24" class="border_bottom font_12" v-for="(packageDetail) in item.PackageCardLargessGoods.PackageCardProduct" :key="`2-Product${packageDetail.ProductID}`">
                  <el-row class="row_header_package_detail">
                    <el-col :span="6">套餐卡产品</el-col>
                    <el-col :span="3">购买数量</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退款数量</el-col>
                    <el-col :span="4">可退金额</el-col>
                    <el-col :span="4">退款金额</el-col>
                  </el-row>
                  <el-row class="pad_10">
                    <el-col :span="24">
                      <el-col :span="6">{{packageDetail.Name}} 
                        <span v-if="packageDetail.Alias">({{packageDetail.Alias}})</span>
                        <el-tag class="marlt_5" size="mini" type="danger">赠</el-tag>
                      </el-col>
                      <el-col :span="3">× {{packageDetail.AccountQuantity}}</el-col>
                      <el-col :span="4">¥ {{packageDetail.AccountTotalAmount | NumFormat}}</el-col>
                      <el-col :span="3">× {{packageDetail.Quantity}}</el-col>
                      <el-col :span="4">¥ {{packageDetail.TotalAmount + packageDetail.PricePreferentialAmount | NumFormat}}</el-col>
                      <el-col :span="4">¥ {{item.IsLargess?0:packageDetail.TotalAmount | NumFormat}}</el-col>
                    </el-col>
                  </el-row>

                </el-col>
                <!-- 套餐卡赠送项目 -->
                <el-col :span="24" class="border_bottom font_12" v-for="(packageDetail) in item.PackageCardLargessGoods.PackageCardProject" :key="`2-Project${packageDetail.ProjectID}`">
                  <el-row class="row_header_package_detail">
                    <el-col :span="6">套餐卡项目</el-col>
                    <el-col :span="3">购买数量</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退款数量</el-col>
                    <el-col :span="4">可退金额</el-col>
                    <el-col :span="4">退款金额</el-col>
                  </el-row>
                  <el-row class="pad_10">
                    <el-col :span="24">
                      <el-col :span="6">{{packageDetail.Name}} 
                        <span v-if="packageDetail.Alias">({{packageDetail.Alias}})</span>
                        <el-tag class="marlt_5" size="mini" type="danger">赠</el-tag>
                      </el-col>
                      <el-col :span="3">× {{packageDetail.AccountQuantity}}</el-col>
                      <el-col :span="4">¥ {{packageDetail.AccountTotalAmount | NumFormat}}</el-col>
                      <el-col :span="3">× {{packageDetail.Quantity}}</el-col>
                      <el-col :span="4">¥ {{packageDetail.TotalAmount + packageDetail.PricePreferentialAmount | NumFormat}}</el-col>
                      <el-col :span="4">¥ {{item.IsLargess?0:packageDetail.TotalAmount | NumFormat}}</el-col>
                    </el-col>
                  </el-row>
                </el-col>
                <!-- 套餐卡赠送通用次卡 -->
                <el-col :span="24" class="border_bottom font_12" v-for="(packageDetail) in item.PackageCardLargessGoods.PackageCardGeneralCard" :key="`2-GeneralCard${packageDetail.GeneralCardID}`">

                  <el-row class="row_header_package_detail">
                    <el-col :span="6">套餐卡通用次卡</el-col>
                    <el-col :span="3">购买卡次数</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退卡次数</el-col>
                    <el-col :span="4">可退金额</el-col>
                    <el-col :span="4">退款金额</el-col>
                  </el-row>
                  <el-row class="pad_10">
                    <el-col :span="24">
                      <el-col :span="6">{{packageDetail.Name}} 
                        <span v-if="packageDetail.Alias">({{packageDetail.Alias}})</span>
                        <el-tag class="marlt_5" size="mini" type="danger">赠</el-tag>
                      </el-col>
                      <el-col :span="3">× {{packageDetail.AccountCardTimes}}</el-col>
                      <el-col :span="4">¥ {{packageDetail.AccountTotalAmount | NumFormat}}</el-col>
                      <el-col :span="3">× {{packageDetail.CardTimes}}</el-col>
                      <el-col :span="4">¥ {{packageDetail.TotalAmount + packageDetail.PricePreferentialAmount | NumFormat}}</el-col>
                      <el-col :span="4">¥ {{item.IsLargess?0:packageDetail.TotalAmount | NumFormat}}</el-col>
                    </el-col>
                  </el-row>
                </el-col>
                <!-- 套餐卡赠送时效卡 -->
                <el-col :span="24" class="border_bottom font_12" v-for="(packageDetail) in item.PackageCardLargessGoods.PackageCardTimeCard" :key="`2-timeCard${packageDetail.TimeCardID}`">
                  <el-row class="row_header_package_detail">
                    <el-col :span="6">时效卡</el-col>
                    <el-col :span="3">购买数量</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退款数量</el-col>
                    <el-col :span="4">可退金额</el-col>
                    <el-col :span="4">退款金额</el-col>
                  </el-row>
                  <el-row class="pad_10">
                      <el-col :span="24">
                        <el-col :span="6">{{packageDetail.Name}} 
                          <span v-if="packageDetail.Alias">({{packageDetail.Alias}})</span>
                          <el-tag class="marlt_5" size="mini" type="danger">赠</el-tag>
                        </el-col>
                        <el-col :span="3">× {{packageDetail.PackageQuantity}}</el-col>
                        <el-col :span="4">¥ {{packageDetail.AccountTotalAmount | NumFormat}}</el-col>
                        <el-col :span="3">× {{packageDetail.Quantity}}</el-col>
                        <el-col :span="4">¥ {{packageDetail.TotalAmount + packageDetail.PricePreferentialAmount | NumFormat}}</el-col>
                        <el-col :span="4">¥ {{item.IsLargess?0:packageDetail.TotalAmount | NumFormat}}</el-col>
                      </el-col>
                  </el-row>
                </el-col>
                <!-- 套餐卡赠送储值卡 -->
                <el-col :span="24" class="border_bottom font_12" v-for="(packageDetail) in item.PackageCardLargessGoods.PackageCardSavingCard" :key="`2-SavingCard${packageDetail.SavingCardID}`">
                    <el-row class="row_header_package_detail">
                    <el-col :span="8">储值卡</el-col>
                    <el-col :span="8">赠送金额</el-col>
                    <el-col :span="8">退赠送金额</el-col>
                  </el-row>
                  <el-row class="pad_10">
                    <el-col :span="24">
                      <el-col :span="8">{{packageDetail.Name}} 
                        <span v-if="packageDetail.Alias">({{packageDetail.Alias}})</span>
                        <el-tag class="marlt_5" size="mini" type="danger">赠</el-tag>
                      </el-col>
                      <el-col :span="8">¥ {{packageDetail.AccountLargessAmount | NumFormat}}</el-col>
                      <el-col :span="8">¥ {{packageDetail.TotalPrice | NumFormat}}</el-col>
                    </el-col>
                  </el-row>
                </el-col>
              </el-col>
              <el-col :span="24" v-if="item.SaleBillHandler.length>0" class="pad_10 padtp_5 padbm_5 border_bottom">
                <el-row v-for="(handler,pIndex) in item.SaleBillHandler" :key="pIndex + 'h6'">
                  <el-col :span="2">
                    <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                      <el-form-item :label="`${handler.SaleHandlerName}`"></el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="22">
                    <el-form class="saleHandler" :inline="true" size="mini">
                      <el-form-item v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{employee.Scale.toFixed(2)}}%</el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="border_left border_right border_bottom">
          <el-row>
            <el-col :span="6" :offset="17">
              <el-form class="saleInfo" size="mini">
                <el-form-item v-if="saleOrderDetail.PayAmount>0" label="现金退款：">
                  <div class="text_right">￥{{saleOrderDetail.PayAmount | NumFormat}}</div>
                </el-form-item>
                <el-form-item v-if="saleOrderDetail.CardDeductionAmount>0" label="卡抵扣退款：">
                  <div class="text_right">￥{{saleOrderDetail.CardDeductionAmount | NumFormat}}</div>
                </el-form-item>
                <el-form-item label="合计退款：">
                  <div class="text_right">￥{{saleOrderDetail.Amount | NumFormat}}</div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 支付信息 -->
      <div class="payInfo">
        <div class="tip martp_10 marbm_10">支付信息</div>
        <el-card class="marbm_10" shadow="never" v-if="saleOrderDetail.SaleBillPay!=undefined && saleOrderDetail.SaleBillPay.length>0">
          <div slot="header">
            <span>支付收款</span>
          </div>
          <el-form class="saleInfo" :inline="true" size="mini" label-position="top">
            <el-form-item v-for="salepay in saleOrderDetail.SaleBillPay" :key="salepay.ID" :label="salepay.Name">¥ {{salepay.Amount| NumFormat}}</el-form-item>
          </el-form>
        </el-card>
        <el-card shadow="never" v-if="saleOrderDetail.SaleBillPaySavingCardDeduction!=undefined && saleOrderDetail.SaleBillPaySavingCardDeduction.length>0">
          <div slot="header">
            <span>储值卡抵扣</span>
          </div>
          <el-form class="saleInfo" :inline="true" size="mini" label-position="top">
            <el-form-item v-for="savingCardReduction in saleOrderDetail.SaleBillPaySavingCardDeduction" :key="savingCardReduction.ID" :label="savingCardReduction.Name">¥ {{savingCardReduction.TotalAmount| NumFormat}}</el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>
    
    <!-- 修改备注弹框 -->
    <el-dialog width="30%" :visible.sync="innerVisible" append-to-body>
      <span slot="title" class="text_center">修改销售单备注</span>
      <el-input type="textarea" :rows="4" v-model="Remark" placeholder="请输入备注内容"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="updateRemarkClick" size="small">保存</el-button>
      </span>
    </el-dialog>

  </section>
</template>

<script>
import API from "@/api/iBeauty/Order/saleBill";
export default {
  
 name: 'refundDetailContent',
  props: {
    saleOrderDetail: Object,
  },
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      innerVisible: false, //备注弹窗
      Remark:"",
    }
  },
   /**计算属性  */
  computed: {
  },
  /**  方法集合  */
  methods: {
    /**  修改备注   */
    ChangeRemarkinnerVisible(){
      let that = this;
      that.Remark = that.saleOrderDetail.Remark;
      that.innerVisible = true;
    },
    //修改备注
    updateRemarkClick() {
      var that = this;
      var params = {
        SaleBillID: that.saleOrderDetail.ID,
        Remark: that.Remark,
      };
      API.updateRemark(params).then((res) => {
        if (res.StateCode == 200) {
          that.saleOrderDetail.Remark = that.Remark;
          that.Remark = "";
          that.innerVisible = false;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
      /**  查看套餐卡明细  */
    packDetailClick(item) {
      item.IsShowDetails = !item.IsShowDetails;
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {},
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
}
</script>

<style lang="scss">
.refundDetailContent{

    .el-scrollbar_height {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }

    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
    .saleHandler {
      .el-form-item__label {
        font-size: 12px !important;
        line-height: 18px;
        color: #c0c4cc;
      }
      .el-form-item__content {
        font-size: 12px !important;
        line-height: 20px;
        color: #c0c4cc;
      }
      .el-form-item {
        margin-bottom: 0px;
      }
    }

    .payInfo {
      .el-card__header {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .el-card__body {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

}

</style>

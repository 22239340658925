/*
 * @Author: mikey.zhaopeng 
 * @Date: 2020-07-31 10:58:09 
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: yyyy-08-Tu 05:36:35
 */

import * as API from '@/api/index'
 

export default{

     //退消耗申请列表
     getTreatApplyList:params =>{
         return API.POST('api/refundTreatBill/list',params)
     },
     //退消耗申请单详情
     getTreatApplyDetail:params =>{
         return API.POST('api/refundTreatBill/info',params)
     },

    //退消耗申请撤销
    refundTreatBillrevoke:params =>{
        return API.POST('api/refundTreatBill/revoke',params)
    },
}
<template>
  <div v-loading="loading" class="CustomerNursingLog">
    <el-scrollbar style="height: 460px;" v-scrollbar_loadmore="scrollbar_loadmore">
      <div v-for="(item,index) in nursingLostList" :key="index">
        <!-- 员工信息 -->
        <el-row class="pad_5 backf5f7fa dis_flex flex_y_center">
          <el-col :span="16" class="dis_flex flex_dir_row flex_y_center">
            <!-- <el-image style="width: 70px; height: 70px;border-radius:50%"></el-image> -->
            <div class=" font_14 color_333">
              <div>{{item.CreatedByName}}</div>
              <div class="martp_5 color_666  "><i class="el-icon-s-custom color_999 marrt_5" />{{item.JobName}}</div>
            </div>
          </el-col>
          <el-col :span="8" class="text_right " style="height:100%">{{item.CreatedOn}}</el-col>
        </el-row>
        <!-- 护理日志 文本内容 -->
        <el-row class="pad_5">
          <el-col :span="24">{{item.NursingLogContent}}</el-col>
        </el-row>
        <!-- 护理日志 媒体内容 -->
        <el-row class="pad_5">
          <el-col :span="24" class="dis_flex flex_dir_row ">
            <div v-for="(media,mIndex) in item.Media" :key="'media' + mIndex" style="width: 100px; height: 100px" class="marrt_10">
              <!-- 图片 -->
              <el-image v-if="media.MediaType == 10" :src="media.MediaURL" :preview-src-list="item.srcList" class="marrt_10" style="width: 100px; height: 100px" fit="cover" :z-index='5000'>
                <div slot="placeholder" class="image-slot">
                  <i class="el-icon-picture" style="width: 100px; height: 100px"></i>

                </div>
              </el-image>
              <!-- 视频封面 -->
              <div class="position_relative" v-if="media.MediaType == 20" @click="onVideoPlayer(media.MediaURL)">
                <el-image :src="media.ThumbnailUrl" class="marrt_10" style="width: 100px; height: 100px" fit="cover" :z-index='5000'>
                  <div slot="placeholder" class="image-slot">
                    <i class="el-icon-picture" style="width: 100px; height: 100px"></i>
                  </div>
                </el-image>
                <i class="el-icon-video-play position_absolute" style="font-size:40px;left:50%;top:50%;transform: translate(-50%, -50%); "></i>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- 评论点赞按钮 -->
        <el-row class="pad_5">
          <el-col :span="24" class="">
            <el-button :type="item.isLike?'primary':''" icon="el-icon-thumb" size="small" @click="giveLikeClick(item)" :loading="likeLoading">赞</el-button>
            <!-- <el-button  icon="el-icon-chat-line-round" size="small" @click="commentClick">评论</el-button> -->
          </el-col>
        </el-row>
        <el-row class="pad_5" v-if="item.thumbName">
          <el-col :span="24">
            <i class="el-icon-thumb color_main"></i>
            <span class="marlt_5 font_14 color_main">{{item.thumbName}} 赞了该日志</span>
          </el-col>
        </el-row>
        <!-- 评论内容 -->
        <el-row class="pad_5" v-if="item.Comment.length > 0">
          <el-col :span="24" v-for="comment in item.Comment" :key="'comment' + `${comment.CommentID}`">
            <span class="color_main font_14">{{comment.CreatedByName}}：</span>
            <span class="marlt_5 font_14 color_666">{{comment.Comment}}</span>
          </el-col>
        </el-row>
        <el-row class="pad_5">
          <el-col :span="15">
            <el-input placeholder="请输入评论内容" v-model="commentContent" size="small">
              <template slot="append">
                <el-button size="small" @click="commentClick(item)" :loading="commentLoading">发送</el-button>
              </template>
            </el-input>
          </el-col>
        </el-row>
      </div>
    </el-scrollbar>

    <el-dialog :visible.sync="videoVisible" width="1100px" append-to-body :show-close='false' custom-class="videoDialog" @opened="openedVideo" @closed="videoClosed">
      <div class='demo'>
        <videoPlayer class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions">
        </videoPlayer>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/CRM/Customer/customerNursingLog";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import treatProjectCommissionSchemeVue from "../../../views/iBeauty/HanderCommission/treatProjectCommissionScheme.vue";

var Enumerable = require("linq");

export default {
  props: {
    customerID: Number,
  },
  name: "CustomerNursingLog",
  /**  引入的组件  */
  components: {
    videoPlayer,
  },
  /**  Vue 实例的数据对象**/
  data() {
    return {
      videoVisible: false,
      loading: false,
      commentLoading: false,
      likeLoading: false,
      PageNum: 1,
      nursingTotal: 0,
      nursingLostList: [], // 护理日志列表
      commentContent: "",
      currentEmpID: "",
      storage: "",
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "https://outin-91d760371afd11e9954200163e00b174.oss-cn-shanghai.aliyuncs.com/sv/35102547-178683b3c94/35102547-178683b3c94.mp4?Expires=1616660036&OSSAccessKeyId=LTAI8bKSZ6dKjf44&Signature=B1beNM%2FrmdfEoj1C7rETACIwm84%3D", // url地址
          },
        ],
        hls: true,
        poster: "", // 你的封面地址
        width: document.documentElement.clientWidth, // 播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //时间分割线
          durationDisplay: true, //总时间
          remainingTimeDisplay: false, //剩余播放时间
          progressControl: true, // 进度条
          fullscreenToggle: true, // 全屏按钮
        },
      },
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    /**    */
    scrollbar_loadmore() {
      let that = this;
      if (that.nursingTotal > that.nursingLostList.length) {
        that.PageNum += 1;
        that.getNursingLogList();
      }
    },
    /** 视频播放   */
    onVideoPlayer(url) {
      let that = this;
      that.playerOptions.sources[0].src = url;
      that.videoVisible = true;
    },
    /**    */
    openedVideo() {
      let that = this;
      that.$nextTick(() => {
        that.$refs.videoPlayer.player.play();
      });
    },
    /**  暂停播放  */
    videoClosed() {
      this.$refs.videoPlayer.player.pause();
    },
    /**  点赞  */
    giveLikeClick(item) {
      this.getNursingLogAddThumbsUp(item);
    },
    /**  评论  */
    commentClick(item) {
      let that = this;
      if (!that.commentContent) {
        that.$message.error("请输入评论内容");
        return;
      }
      this.getNursingLogAddComment(item);
    },
    /** 获取顾客护理日志列表   */
    getNursingLogList() {
      let that = this;

      var params = {
        PageNum: that.PageNum,
        Name: "",
        StartDate: "",
        EndDate: "",
        CustomerID: that.customerID,
      };
      that.loading = true;
      API.getCustomerNursingLog(params)
        .then((res) => {
          if (res.StateCode == 200) {
            res.List.forEach((element) => {
              let srcList = [];
              element.Media.forEach((media) => {
                if (media.MediaType == 10) {
                  srcList.push(media.MediaURL);
                }
              });
              element.srcList = srcList;

              let thumbNames = [];
              let isLike = false;
              element.ThumbsUp.forEach((thumb) => {
                isLike = thumb.CreatedBy == that.currentEmpID;
                thumbNames.push(thumb.CreatedByName);
              });
              let thumbName = thumbNames.join();
              element.thumbName = thumbName;
              element.isLike = isLike;
            });
            that.nursingLostList.push(...res.List);
            that.nursingTotal = res.Total;
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  评论   */
    getNursingLogAddComment(item) {
      let that = this;
      that.commentLoading = true;
      let params = {
        NursingLogID: item.ID,
        CommentType: 10,
        Comment: that.commentContent,
        AudioURL: "",
        ReplyID: "",
      };
      API.getNursingLogAddComment(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("评论成功");
            item.Comment.push({
              CreatedByName: that.storage.EmployeeName,
              Comment: that.commentContent,
            });
            that.commentContent = "";
          }
        })
        .finally(() => {
          that.commentLoading = false;
        });
    },
    /**    */
    getNursingLogAddThumbsUp(item) {
      let that = this;
      that.likeLoading = true;
      let params = {
        NursingLogID: item.ID,
      };
      API.getNursingLogAddThumbsUp(params)
        .then((res) => {
          if (res.StateCode == 200) {
            let isLike = res.Data;
            item.isLike = isLike;
            if (item.isLike) {
              item.ThumbsUp.push({
                CreatedBy: that.storage.EmployeeID,
                CreatedByName: that.storage.EmployeeName,
              });
              let name = Enumerable.from(item.ThumbsUp)
                .select((val) => {
                  return val.CreatedByName;
                })
                .toArray();
              item.thumbName = name.join();
            } else {
              item.ThumbsUp = Enumerable.from(item.ThumbsUp)
                .where((val) => {
                  return val.CreatedBy != that.storage.EmployeeID;
                })
                .toArray();

              let name = Enumerable.from(item.ThumbsUp)
                .select((val) => {
                  return val.CreatedByName;
                })
                .toArray();
              item.thumbName = name.join();
            }
          }
        })
        .finally(() => {
          that.likeLoading = false;
        });
    },
    /**  清除数据  */
    clearListData() {
      let that = this;
      that.PageNum = 1;
      that.nursingLostList = [];
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    this.storage = JSON.parse(localStorage.getItem("access-user"));
    this.currentEmpID = this.storage.EmployeeID;
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
.CustomerNursingLog {
  .videoDialog {
    .el-dialog__body {
      padding: unset !important;
    }
    .el-dialog__header {
      padding: unset;
    }
  }

  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>

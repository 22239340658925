<template>
  <div class="content_body Customer" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="22">
          <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
            <el-form-item label="会员名称">
              <el-input v-model="name" placeholder="请输入姓名/手机号/编码搜索" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="顾问">
              <el-select v-model="employeeID" placeholder="请选择顾问" clearable @change="handleSearch">
                <el-option v-for="item in employee" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="会员等级">
              <el-select v-model="customerLevelID" placeholder="请选择会员等级" clearable @change="handleSearch">
                <el-option v-for="item in customerLevel" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="会员来源">
              <el-cascader
                v-model="customerSourceID"
                :options="customerSource"
                :props="{
                  checkStrictly: true,
                  children: 'Child',
                  value: 'ID',
                  label: 'Name',
                  emitPath: false,
                }"
                :show-all-levels="false"
                clearable
                filterable
                @change="handleSearch"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="剩余卡名称">
              <el-input v-model="GoodsName" placeholder="请输入卡名称搜索" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="会员生日">
              <el-date-picker v-model="Birthday" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="MM 月 dd 日" clearable :picker-options="pickerOptions" popper-class="customDateClass" @change="handleSearch"> </el-date-picker>
            </el-form-item>

            <el-form-item label="注册日期">
              <el-date-picker v-model="NotStoreDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" clearable :picker-options="pickerOptions" popper-class="customDateClass" @change="handleSearch"> </el-date-picker>
            </el-form-item>

            <el-form-item label="未到店天数">
              <div class="dis_flex flex_x_between">
                <el-input v-model="StartNotStoreDays" style="width:120px" placeholder="请输入内容" @change="changStartNotStoreDays"></el-input>
                <div class="text_center" style="width:30px">--</div>
                <el-input v-model="EndNotStoreDays" style="width:120px" placeholder="请输入内容" @change="changEndNotStoreDays"></el-input>
              </div>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2" class="text_right">
          <el-button type="primary" size="small" @click="showAddDialog" v-prevent-click>添加客户</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="tableData" tooltip-effect="light">
        <el-table-column prop="Name" label="会员姓名"></el-table-column>
        <el-table-column prop="EntityName" label="所属门店"></el-table-column>
        <el-table-column prop="PhoneNumber" label="手机号码"></el-table-column>
        <el-table-column prop="Gender" label="会员性别" :formatter="formatGender"></el-table-column>
        <el-table-column prop="Birthday" label="会员生日"></el-table-column>
        <el-table-column prop="EmployeeName" label="所属顾问" show-overflow-tooltip :formatter="formatterEmployeeNames"> </el-table-column>
        <el-table-column prop="CustomerSourceName" label="会员来源"></el-table-column>
        <el-table-column prop="CustomerLevelName" label="会员等级"></el-table-column>
        <el-table-column prop="Code" label="会员编号"></el-table-column>
        <el-table-column prop="NotStoreDays" show-overflow-tooltip label="未到店天数"></el-table-column>
        <el-table-column prop="CreatedOn" label="注册日期"></el-table-column>
        <el-table-column prop="GoodsName" show-overflow-tooltip :formatter="formatterGoodsNames" label="剩余卡名"></el-table-column>
        <el-table-column label="操作" width="145px">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="customerDetailClick(scope.row)" v-prevent-click>详情 </el-button>
            <el-button type="primary" size="small" @click="gotoOrderCreated(scope.row)" v-prevent-click>开单 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>
    <!-- 佣金记录弹窗 -->
    <el-dialog title="佣金记录" :visible.sync="commissionDialog" width="1200px">
      <el-table size="small" :data="commissionData" height="360px">
        <el-table-column prop="BillDate" label="销售时间"></el-table-column>
        <el-table-column prop="EntityName" label="操作门店"></el-table-column>
        <el-table-column prop="BillID" label="销售订单"></el-table-column>
        <el-table-column prop="PayAmount" label="销售金额">
          <template slot-scope="scope"> ￥{{ scope.row.PayAmount | NumFormat }} </template>
        </el-table-column>
        <el-table-column prop="ReturnedCommissionScale" label="返佣比例"></el-table-column>
        <el-table-column prop="ReturnedCommissionAmount" label="返佣金额">
          <template slot-scope="scope"> ￥{{ scope.row.ReturnedCommissionAmount | NumFormat }} </template>
        </el-table-column>
        <el-table-column prop="ReturnedCommissionType" label="返佣方式"></el-table-column>
        <el-table-column label="操作人" width="100px">
          <template slot-scope="scope">
            {{ scope.row.EmployeeName }}
          </template>
        </el-table-column>
      </el-table>
      <div class="text_right pad_15">
        <el-pagination
          background
          v-if="commissionListPagination.Total > 0"
          @current-change="Getcommission"
          :current-page.sync="commissionListPagination.Page"
          :page-size="commissionListPagination.Page_size"
          :layout="commissionListPagination.layout"
          :total="commissionListPagination.Total"
        ></el-pagination>
      </div>
    </el-dialog>
    <!--新增弹窗 :modal="false" -->
    <el-dialog :title="isAddToo ? '新增客户' : '编辑基本信息'" :visible.sync="dialogVisible" width="850px">
      <el-tabs v-model="dialogVisibleActive">
        <el-tab-pane label="基本信息" name="1">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" size="small">
            <el-row>
              <el-col :span="12">
                <el-form-item label="会员姓名" prop="Name">
                  <el-input v-model="ruleForm.Name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号码" prop="PhoneNumber">
                  <el-input v-model="ruleForm.PhoneNumber" maxlength="11"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="会员编号">
                  <el-input v-model="ruleForm.Code"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="会员来源" prop="CustomerSourceID">
                  <!-- <el-cascader
                    v-model="ruleForm.CustomerSourceID"
                    :options="customerSource"
                    :props="{
                      checkStrictly: true,
                      children: 'Child',
                      value: 'ID',
                      label: 'Name',
                      emitPath: false,
                    }"
                    :show-all-levels="false"
                    filterable
                    @change="CascaderChange"
                  ></el-cascader> -->
                  <el-select v-model="ruleForm.CustomerSourceID" placeholder="请选择" filterable size="small" @change="selectChangeToo">
                    <!-- <el-option :key="0" label="老带新" :value="1"> </el-option> -->
                    <el-option v-for="item in customerSource" :key="item.ID" :label="item.Name" :value="item.ID"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="ruleForm.CustomerSourceID == '1'">
                <el-form-item class="CustomerSourceID" prop="ReferencesName">
                  <el-select v-model="ruleForm.ReferencesName" placeholder="请选择推荐会员" filterable size="small" v-loadmore="loadMore" remote :remote-method="remoteMethod" :loading="remoteLoading" @focus="CustomerList" @change="selectChange">
                    <el-option style="padding:0 10px;" v-for="item in customerbillList" :key="item.ID" :label="item.Name" :value="item.ID">
                      <span style="float: left;margin-right:10px;">{{ item.Name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.PhoneNumber }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属顾问">
                  <el-select v-model="ruleForm.EmployeeID" placeholder="请选择所属顾问" filterable size="small" multiple collapse-tags>
                    <el-option v-for="item in employee" :key="item.ID" :label="item.Name" :value="item.ID">
                      <span style="float: left">{{ item.Name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.JobName }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="会员等级">
                  <el-select v-model="ruleForm.CustomerLevelID" placeholder="请选择会员等级" filterable size="small">
                    <el-option v-for="item in customerLevel" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="会员生日">
                  <el-date-picker v-model="ruleForm.Birthday" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"> </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="会员性别">
                  <el-radio-group v-model="ruleForm.Gender">
                    <el-radio label="2">女</el-radio>
                    <el-radio label="1">男</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="档案信息" name="2">
          <div style="height: 50vh">
            <el-scrollbar class="el-scrollbar_height">
              <el-form ref="recordForm" label-width="110px" size="small">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="年龄">
                      <el-input v-model="recordForm.Age" class="width_220"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="职业">
                      <el-input v-model="recordForm.Occupation" class="width_220"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="大腿左/右">
                      <el-input v-model="recordForm.Thigh" class="width_220"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="小腿左/右">
                      <el-input v-model="recordForm.Shank" class="width_220"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="脐维">
                      <el-input v-model="recordForm.UmbilicalDimension" class="width_220"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="胳膊左/右">
                      <el-input v-model="recordForm.Arm" class="width_220"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="是否拍照">
                      <el-radio v-model="recordForm.IsPhotograph" :label="true">是</el-radio>
                      <el-radio v-model="recordForm.IsPhotograph" :label="false">否</el-radio>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="最想改善的问题">
                      <el-select v-model="recordForm.ImproveProblem" multiple placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in problem" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="月经是否准时">
                      <el-select v-model="recordForm.Menses" placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in periodList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="胸部">
                      <el-select v-model="recordForm.Chest" multiple placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in chestList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="肩颈">
                      <el-select v-model="recordForm.ShoulderNeck" multiple placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in shouldersList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="入睡时间">
                      <el-select v-model="recordForm.SleepTime" placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in sleepList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="睡眠质量">
                      <el-select v-model="recordForm.SleepQuality" placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in qualityList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="排便情况">
                      <el-select v-model="recordForm.Defecation" placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in defecateList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="腰部情况">
                      <el-select v-model="recordForm.WaistCondition" placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in waistList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="手脚是否冰凉">
                      <el-select v-model="recordForm.IceCold" placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in ColdHandList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="饮食喜好">
                      <el-select v-model="recordForm.FoodPreference" multiple placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in biteList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="工作环境">
                      <el-select v-model="recordForm.WorkEnvironment" placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in environmentList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="A型">
                      <el-select v-model="recordForm.TypeA" placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in typeAList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="健康状况">
                      <el-select v-model="recordForm.Health" placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in healthList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="H型">
                      <el-select v-model="recordForm.TypeH" placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in typeHList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="V型">
                      <el-select v-model="recordForm.TypeV" placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in typeVList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="食疗建议">
                      <el-select v-model="recordForm.DietAdvice" placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in dietaryTherapyList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="调理建议">
                      <el-select v-model="recordForm.SuggestionsConditioning" multiple placeholder="请选择" value-key="value" class="width_220">
                        <el-option v-for="item in nurseList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-scrollbar>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div slot="footer">
        <el-button size="small" @click="dialogVisible = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="submitCustomer" :loading="modalLoading" v-prevent-click>保存 </el-button>
      </div>
    </el-dialog>
    <!--详情弹窗-->
    <el-dialog title="客户详情" :visible.sync="dialogDetail" width="1200px" :before-close="customerDetailBeforeClose">
      <div class="customer_information">
        <el-row type="flex" align="middle">
          <el-col :span="2">
            <el-upload :disabled="!customInfoEdit && !customerEntityBool" action="" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="updateCustomerUploadImage">
              <el-avatar :size="70" :src="customerDetail.Avatar ? customerDetail.Avatar : circleUrl"></el-avatar>
            </el-upload>
          </el-col>
          <el-col :span="22">
            <el-row type="flex" justify="space-between">
              <el-col :span="12">
                <strong class="marrt_5 font_18">{{ customerDetail.Name }}</strong>
                <el-image v-if="customerDetail.Gender == 2" style="width: 8px; height: 12px" :src="require('@/assets//img//gender-female.png')"></el-image>
                <el-image v-if="customerDetail.Gender == 1" style="width: 8px; height: 12px" :src="require('@/assets/img/gender-male.png')"></el-image>
                <el-button v-if="customerEntityBool || customInfoEdit" class="padlt_10" size="mini" type="text" @click="showEditDialog(customerDetail)">编辑 </el-button>
              </el-col>
              <el-col :span="12" class="text_right">
                <el-button size="small" type="primary" @click="navCreateBill" plain v-if="openBillState">开单</el-button>
                <el-button size="small" type="primary" plain @click="clickAppointment" v-if="AppointmentState">预约</el-button>
              </el-col>
            </el-row>
            <el-row class="martp_10">
              <el-col :span="6" class="color_999"
                >手机号：<span class="color_333">{{ customerDetail.PhoneNumber }}</span></el-col
              >
              <el-col :span="6" v-if="customerDetail">
                <el-tooltip :manual="customerDetail.Employee.length > 0 ? false : true" :content="formatterEmployeeNames(customerDetail)" placement="bottom" effect="light">
                  <div
                    style="
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                    "
                    class="color_999"
                  >
                    营销顾问：<span class="color_333">{{ formatterEmployeeNames(customerDetail) }}</span>
                  </div>
                </el-tooltip>
              </el-col>
              <el-col :span="6" class="color_999"
                >归属门店：<span class="color_333"> {{ customerDetail.EntityName }} </span>
                <el-button v-if="isUpdateEntity" @click="onShowUpdateEntity" type="text" size="small">修改</el-button>
              </el-col>
              <el-col :span="6" class="color_999"
                >会员编号：<span class="color_333">{{ customerDetail.Code }}</span></el-col
              >
            </el-row>
            <el-row class="martp_10">
              <el-col :span="6" class="color_999"
                >顾客来源：<span class="color_333">{{ customerDetail.CustomerSourceName }}</span></el-col
              >
              <el-col :span="6" class="color_999"
                >顾客等级：<span class="color_333">{{ customerDetail.CustomerLevelName }}</span></el-col
              >
              <el-col :span="6" class="color_999"
                >会员生日：<span class="color_333">{{ customerDetail.Birthday }}</span></el-col
              >
              <el-col :span="6" class="color_999"
                >注册时间：<span class="color_333">{{ customerDetail.CreatedOn }}</span></el-col
              >
            </el-row>
          </el-col>
        </el-row>
      </div>
      <!-- <div class="martp_5 customer_information" v-if="false">
        <el-row type="flex" align="middle" class="color_999">
          <el-col :span="3">
            <el-col :span="24">余额(元)</el-col>
          </el-col>
          <el-col :span="6">
            <el-col :span="24">累计消费金额(元)/次数/客单</el-col>
          </el-col>
          <el-col :span="6">
            <el-col :span="24">累计消耗金额(元)/次数/客单</el-col>
          </el-col>
          <el-col :span="3">
            <el-col :span="24">欠款金额(元)</el-col>
          </el-col>
          <el-col :span="3">
            <el-col :span="24">最近购买时间</el-col>
          </el-col>
          <el-col :span="3">
            <el-col :span="24">最近消耗时间</el-col>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">
            <el-col :span="24" class="martp_5 color_333">{{
              accountInfo.TotalAmount | NumFormat
            }}</el-col>
          </el-col>
          <el-col :span="6">
            <el-col :span="24" class="martp_5">
              <span class="color_333">{{
                accountInfo.SaleAmount | NumFormat
              }}</span>
              <span>{{ " / " + accountInfo.SaleQuantity + "次 / " }}</span>
              <span class="color_main">{{
                accountInfo.SalePrice | NumFormat
              }}</span>
            </el-col>
          </el-col>
          <el-col :span="6">
            <el-col :span="24" class="martp_5">
              <span class="color_333">{{
                accountInfo.ConsumeProjectAmount | NumFormat
              }}</span>
              <span>{{
                " / " + accountInfo.ConsumeProjectQuantity + "次 / "
              }}</span>
              <span class="color_main">{{
                accountInfo.ConsumePrice | NumFormat
              }}</span>
            </el-col>
          </el-col>
          <el-col :span="3">
            <el-col :span="24" class="martp_10 color_red">
              {{ accountInfo.ArrearAmount | NumFormat }}</el-col
            >
          </el-col>
          <el-col :span="3">
            <el-col :span="24" class="martp_10 color_333">{{
              accountInfo.LastSaleBillDate
            }}</el-col>
          </el-col>
          <el-col :span="3">
            <el-col :span="24" class="martp_10 color_333">{{
              accountInfo.LastConsumeProjectBillDate
            }}</el-col>
          </el-col>
        </el-row>
      </div> -->
      <div class="customer_content">
        <el-button type="primary" size="small" class="AddNurse" v-show="tabPane == 7" @click="AddNurse">填写</el-button>
        <el-tabs v-model="tabPane" @tab-click="handleClick">
          <el-tab-pane label="客户档案" name="0">
            <div class="customer_record">
              <el-row class="martp_15">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">年龄:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.Age || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">职业:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.Occupation || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">脐纬:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.UmbilicalDimension || "-" }}</el-col>
                </el-col>
              </el-row>
              <el-row class="martp_20">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">大腿左/右:</el-col>
                  <el-tooltip class="item" effect="light" :open-delay="500" placement="top-start" v-if="Record.Thigh && Record.Thigh.length > 15">
                    <div slot="content" class="width_220">
                      {{ Record.Thigh }}
                    </div>
                    <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.Thigh }}</el-col>
                  </el-tooltip>
                  <el-col :span="17" :offset="1" v-else class="text_ellipsis color_000">{{ Record.Thigh || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">小腿左/右:</el-col>
                  <el-tooltip class="item" effect="light" :open-delay="500" placement="top-start" v-if="Record.Shank && Record.Shank.length > 15">
                    <div slot="content" class="width_220">
                      {{ Record.Shank }}
                    </div>
                    <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.Shank }}</el-col>
                  </el-tooltip>
                  <el-col :span="17" :offset="1" v-else class="text_ellipsis color_000">{{ Record.Shank || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">胳膊左右:</el-col>
                  <el-tooltip class="item" effect="light" :open-delay="500" placement="top-start" v-if="Record.Arm && Record.Arm.length > 15">
                    <div slot="content" class="width_220">{{ Record.Arm }}</div>
                    <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.Arm }}</el-col>
                  </el-tooltip>
                  <el-col :span="17" :offset="1" v-else class="text_ellipsis color_000">{{ Record.Arm || "-" }}</el-col>
                </el-col>
              </el-row>
              <el-row class="martp_20">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">是否拍照:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.IsPhotograph ? "是" : "否" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">最想改善问题:</el-col>
                  <el-tooltip class="item" effect="light" :open-delay="500" placement="top-start" v-if="Record.ImproveProblem && Record.ImproveProblem.length > 5">
                    <div slot="content" class="width_220">
                      {{ Record.ImproveProblem.join(",") }}
                    </div>
                    <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.ImproveProblem.join(",") }}</el-col>
                  </el-tooltip>
                  <el-col :span="17" :offset="1" v-else class="text_ellipsis color_000">{{ Record.ImproveProblem.join(",") || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">月经是否准时:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.Menses || "-" }}</el-col>
                </el-col>
              </el-row>
              <el-row class="martp_20">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">胸部:</el-col>
                  <el-tooltip class="item" effect="light" :open-delay="500" placement="top-start" v-if="Record.Chest && Record.Chest.length > 5">
                    <div slot="content" class="width_220">
                      {{ Record.Chest.join(",") }}
                    </div>
                    <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.Chest.join(",") }}</el-col>
                  </el-tooltip>
                  <el-col :span="17" :offset="1" v-else class="text_ellipsis color_000">{{ Record.Chest.join(",") || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">肩颈:</el-col>
                  <el-tooltip class="item" effect="light" :open-delay="500" placement="top-start" v-if="Record.ShoulderNeck && Record.ShoulderNeck.length > 5">
                    <div slot="content" class="width_220">
                      {{ Record.ShoulderNeck.join(",") }}
                    </div>
                    <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.ShoulderNeck.join(",") }}</el-col>
                  </el-tooltip>
                  <el-col :span="17" :offset="1" v-else class="text_ellipsis color_000">{{ Record.ShoulderNeck.join(",") || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">入睡时间:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.SleepTime || "-" }}</el-col>
                </el-col>
              </el-row>
              <el-row class="martp_20">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">睡眠质量:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.SleepQuality || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">排便情况:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.Defecation || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">腰部情况:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.WaistCondition || "-" }}</el-col>
                </el-col>
              </el-row>
              <el-row class="martp_20">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">手脚是否冰凉:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.IceCold || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">饮食喜好:</el-col>
                  <el-tooltip class="item" effect="light" :open-delay="500" placement="top-start" v-if="Record.FoodPreference && Record.FoodPreference.length > 5">
                    <div slot="content" class="width_220">
                      {{ Record.FoodPreference.join(",") }}
                    </div>
                    <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.FoodPreference.join(",") }}</el-col>
                  </el-tooltip>
                  <el-col :span="17" :offset="1" v-else class="text_ellipsis color_000">{{ Record.FoodPreference.join(",") || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">工作环境:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.WorkEnvironment || "-" }}</el-col>
                </el-col>
              </el-row>
              <el-row class="martp_20">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">A型:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.TypeA || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">H型:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.TypeH || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">V型:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.TypeV || "-" }}</el-col>
                </el-col>
              </el-row>
              <el-row class="martp_20">
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">健康状况:</el-col>
                  <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.Health || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">食疗建议:</el-col>
                  <el-tooltip class="item" effect="light" :open-delay="500" placement="top-start" v-if="Record.DietAdvice && Record.DietAdvice.length > 15">
                    <div slot="content" class="width_220">
                      {{ Record.DietAdvice }}
                    </div>
                    <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.DietAdvice }}</el-col>
                  </el-tooltip>
                  <el-col :span="17" :offset="1" v-else class="text_ellipsis color_000">{{ Record.DietAdvice || "-" }}</el-col>
                </el-col>
                <el-col :span="8">
                  <el-col :span="6" class="text_right color_999">调理建议:</el-col>
                  <el-tooltip class="item" effect="light" :open-delay="500" placement="top-start" v-if="Record.SuggestionsConditioning && Record.SuggestionsConditioning.length > 15">
                    <div slot="content" class="width_220">
                      {{ Record.SuggestionsConditioning.join(",") }}
                    </div>
                    <el-col :span="17" :offset="1" class="text_ellipsis color_000">{{ Record.SuggestionsConditioning.join(",") }}</el-col>
                  </el-tooltip>
                  <el-col :span="17" :offset="1" v-else class="text_ellipsis color_000">{{ Record.SuggestionsConditioning.join(",") || "-" }}</el-col>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane label="客户标签" name="1">
            <div class="tip">
              <el-row type="flex" justify="space-between" align="middle">
                <el-col :span="12">标签</el-col>
                <el-col :span="12" class="text_right">
                  <el-button size="mini" type="text" style="padding: 0px" @click="tagClick">编辑</el-button>
                </el-col>
              </el-row>
            </div>
            <div class="martp_5">
              <el-tag v-for="(item, index) in customerTag" :key="index" effect="plain" class="mar_5">{{ item.Name }} </el-tag>
            </div>
          </el-tab-pane>

          <el-tab-pane label="卡项信息" name="2">
            <customer-account :customerID="customerID" ref="customerAccount"></customer-account>
          </el-tab-pane>

          <el-tab-pane label="订单信息" name="3">
            <customerbill :customerID="customerID" ref="customerbill"></customerbill>
          </el-tab-pane>

          <el-tab-pane label="预约记录" name="4">
            <appointmentRecord :customerID="customerID" ref="appointmentRecord"></appointmentRecord>
          </el-tab-pane>
          <el-tab-pane label="推荐顾客列表" name="5">
            <el-table size="small" :data="recommendList" height="360px">
              <el-table-column prop="Name" label="顾客名称"></el-table-column>
              <el-table-column prop="PhoneNumber" label="手机号"></el-table-column>
              <el-table-column prop="LevelName" label="会员等级"></el-table-column>
              <el-table-column prop="PayAmount" label="消费金额">
                <template slot-scope="scope"> ￥{{ scope.row.PayAmount | NumFormat }} </template>
              </el-table-column>
              <el-table-column prop="ReturnedCommissionAmount" label="返佣金额">
                <template slot-scope="scope"> ￥{{ scope.row.ReturnedCommissionAmount | NumFormat }} </template>
              </el-table-column>
              <el-table-column prop="CreatedOn" label="注册时间"></el-table-column>
              <el-table-column label="操作" width="100px">
                <template slot-scope="scope">
                  <el-button type="primary" size="small" @click="Setcommission(scope.row)" v-prevent-click>返佣记录 </el-button>
                  <!-- <el-button v-if="scope.row.ArrearAmount > 0" type="primary" size="small" @click="balancingAccount(scope.row, 0)" v-prevent-click>平账
              </el-button> -->
                </template>
              </el-table-column>
            </el-table>
            <div class="text_right pad_15">
              <el-pagination
                background
                v-if="recommendListPagination.Total > 0"
                @current-change="GetRecommendList"
                :current-page.sync="recommendListPagination.Page"
                :page-size="recommendListPagination.Page_size"
                :layout="recommendListPagination.layout"
                :total="recommendListPagination.Total"
              ></el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="护理计划" name="6">
            <NursingLog :customerID="customerID" ref="nursingLog"></NursingLog>
          </el-tab-pane>
          <el-tab-pane label="调理减重计划" name="7">
            <NurseHealth ref="NurseHealth" :customerID="customerID"></NurseHealth>
          </el-tab-pane>
          <el-tab-pane label="成长值记录" name="8">
            <el-table size="small" :data="recommendList" height="360px">
              <el-table-column prop="ValueType" label="事件"></el-table-column>
              <el-table-column prop="GrowthValueNumber" label="成长值"></el-table-column>
              <el-table-column prop="CreatedOn" label="时间"></el-table-column>
            </el-table>
            <div class="text_right pad_15">
              <el-pagination
                background
                v-if="recommendListPagination.Total > 0"
                @current-change="GetRecommendList"
                :current-page.sync="recommendListPagination.Page"
                :page-size="recommendListPagination.Page_size"
                :layout="recommendListPagination.layout"
                :total="recommendListPagination.Total"
              ></el-pagination>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="跟进记录" name="3">
          <div class="border">
                            <el-form label-width="85px" size="mini">
                                <el-row class="border_bottom backColor_f8 padtp_10">
                                    <el-col :span="12">
                                        <el-form-item label="客户回访"></el-form-item>
                                    </el-col>
                                    <el-col :span="12" class="text_right padrt_10">
                                        <el-button type="text" @click="editFollowUp">编辑</el-button>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-form-item label="回访时间:">567890</el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row class="border_bottom padtp_10">
                                    <el-col :span="24" class="font_20 padlt_15 padtp_10 padbm_10">关联订单
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="订单时间" size="small">2019-11-29 17:17:17</el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="下单门店">下单门店:</el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="订单金额">订单金额:</el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-form-item label="项目名称" size="small">项目名称:</el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row class="padtp_10">
                                    <el-col :span="12">
                                        <el-form-item label="回访类型:">567890</el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="跟进人:">567890</el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-form-item label="回访记录:">567890</el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-form-item label="上传图片:">
                                            <el-image
                                                    class="marrt_10"
                                                    style="width: 100px; height: 100px"
                                                    :fit="fit"
                                                    v-for="(item,index) in 4" :key="index"
                                            >
                                                <div slot="placeholder" class="image-slot">
                                                    <i class="el-icon-picture" style="width: 100px; height: 100px"></i>
                                                </div>
                                            </el-image>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </div>
          
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </el-dialog>

    <!--标签弹窗-->
    <el-dialog title="编辑标签" :visible.sync="dialogTag" width="1000px">
      <el-row style="max-height: 130px; overflow-y: auto">
        <el-tag v-for="(item, index) in editCustomerTag" :key="index" closable @close="removeTag(index)" effect="plain" class="mar_5">{{ item.Name }} </el-tag>
      </el-row>
      <el-row class="pad_5" v-if="customTagLibrary">
        <el-col :span="10">
          <div class="el-form-item el-form-item--small" style="margin-bottom: 0px">
            <label class="el-form-item__label" style="width: 98px">自定义标签：</label>
            <div class="el-form-item__content" style="margin-left: 98px">
              <div class="el-input el-input--small">
                <el-input type="text" autocomplete="off" placeholder="标签名限8个字" v-model="tagName" maxlength="8" size="small" clearable>
                  <template slot="append">
                    <el-button size="small" @click="addTagClick" clearable v-prevent-click>添加</el-button>
                  </template>
                </el-input>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="pad_5">
        <div class="pad_5_0">选择已有标签</div>
        <el-col style="height: 180px; overflow-y: auto" class="border radius5 pad_10">
          <el-tag v-for="item in tagList" :key="item.ID" :type="item.type" effect="plain" @click="tagSelectClick(item)" class="cursor_pointer mar_5">{{ item.Name }} </el-tag>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button size="small" @click="dialogTag = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="tagSaveClick" :loading="modalLoading" v-prevent-click>保存 </el-button>
      </div>
    </el-dialog>
    <!--修改跟进-->
    <el-dialog title="修改跟进" :visible.sync="editFollowVisble" width="800px">
      <el-row>
        <el-col :span="24" class="backColor_f8 border_bottom pad_10 font_16">客户信息：{{ customerDetail.Name }} 【{{ customerDetail.Code }}】 </el-col>
      </el-row>

      <el-form label-width="85px" size="mini">
        <el-row class="backColor_f8 padtp_10">
          <el-col :span="24" class="font_14">
            <el-form-item label="关联订单">
              <el-button type="text" size="small">重新选择</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单时间">2020-09-29 :15:45</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="下单门店">苏州中心店</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单金额">5000.00</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="项目名称">2如今看来是对方过后就</el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-form-item label="跟进类型" required>
            <el-radio-group>
              <el-radio label="回访"></el-radio>
              <el-radio label="邀约"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="回访记录" required>
            <el-input type="textarea" placeholder="请输入内容"> </el-input>
          </el-form-item>

          <el-form-item label="添加图片">
            <el-upload ref="upload" action="/" list-type="picture-card" :limit="5" :on-exceed="exceed" :file-list="fileList1" :http-request="auxiliaryUpload" :on-remove="handleRemove" :before-upload="onBeforeUploadImage">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-row>
      </el-form>

      <div slot="footer">
        <el-button size="small" @click="editFollowVisble = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="followSaveClick" :loading="modalLoading" v-prevent-click>保存 </el-button>
      </div>
    </el-dialog>
    <!--预约-->
    <el-dialog :visible.sync="appointmentDialogShow" width="900px" title="添加预约">
      <!-- 预约顾客信息  -->
      <el-row>
        <!-- <el-col :span="11">
              <el-autocomplete
                popper-class="customer-autocomplete"
                prefix-icon="el-icon-user-solid"
                v-model="customerName"
                style="width: 100%"
                placeholder="请输入会员名称、手机号、编号"
                :fetch-suggestions="saleCustomerData"
                @select="handleCustomerSelect"
                :disabled="CustomerID != null"
                :trigger-on-focus="false"
                :hide-loading="true"
                :highlight-first-item="true"
                :select-when-unmatched="true"
                size="small"
              >
                <template slot="append">
                  <el-button
                    icon="el-icon-delete"
                    @click="removeCustomer"
                    :disabled="appointmentDetailsTitleState == 0"
                  ></el-button>
                </template>
                <template slot-scope="{ item }">
                  <div class="name">
                    {{ item.Name }}
                    <el-tag size="mini" v-if="item.CustomerLevelName"
                      >{{ item.CustomerLevelName }}
                    </el-tag>
                  </div>
                  <div class="info">手机号：{{ item.PhoneNumber }}</div>
                  <span class="info" v-if="item.Code"
                    >客户编号：{{ item.Code }}</span
                  >
                </template>
              </el-autocomplete>
            </el-col> -->
        <el-col :span="11" :offset="13" class="back_f8 dis_flex flex_y_center radius5 line_height_38 pad_0_10">
          <el-col :span="12" class="back_f8">预约项目</el-col>
          <el-col :span="12" class="text_right">
            <el-button type="text" size="small" @click="addAppointmentProject">添 加 </el-button>
          </el-col>
        </el-col>
      </el-row>

      <el-row class="martp_10">
        <el-col :span="11" class="left_item">
          <div class="back_f8 pad_10" style="height: 400px">
            <div class="martp_5">
              <el-form :model="appointmentRuleForm" :rules="appointmentRules" ref="appointmentRuleForm" label-width="100px" class="demo-ruleForm" size="small">
                <el-form-item label="接待人:" prop="EmployeeID" v-if="IsMustEmployee">
                  <el-col :span="20">
                    <el-select v-model="appointmentRuleForm.EmployeeID" placeholder="请选择接待人" :disabled="appointmentRuleForm.Status == '30' || appointmentRuleForm.Status == '20'">
                      <el-option :label="item.EmployeeName" :value="item.EmployeeID" v-for="(item, index) in employeeList" :key="index"></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="接待人:" v-else>
                  <el-col :span="20">
                    <el-select v-model="appointmentRuleForm.EmployeeID" placeholder="请选择接待人">
                      <el-option :label="item.EmployeeName" :value="item.EmployeeID" v-for="(item, index) in employeeList" :key="index" :disabled="appointmentRuleForm.Status == '30' || appointmentRuleForm.Status == '20'"></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="接待人状态:" prop="Type">
                  <el-radio-group v-model="appointmentRuleForm.Type" :disabled="appointmentRuleForm.Status == '30' || appointmentRuleForm.Status == '20'">
                    <el-radio label="10">点单</el-radio>
                    <el-radio label="20">排单</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="预约时间:" required>
                  <el-col :span="20">
                    <el-form-item prop="AppointmentDate">
                      <el-date-picker type="date" placeholder="选择日期" v-model="appointmentRuleForm.AppointmentDate" style="width: 100%" :picker-options="expireTimeOption" :disabled="appointmentRuleForm.Status == '30' || appointmentRuleForm.Status == '20'"> </el-date-picker>
                      <el-time-select
                        :disabled="appointmentRuleForm.Status == '30' || appointmentRuleForm.Status == '20'"
                        v-model="appointmentRuleForm.AppointmentTime"
                        :picker-options="{
                          start: StartTimeData,
                          step: Period,
                          end: EndTimeData,
                        }"
                        @focus="getTimeArr"
                        @change="confirmTime"
                        placeholder="选择时间"
                        class="martp_10"
                        style="width: 100%"
                      >
                      </el-time-select>
                    </el-form-item>
                  </el-col>
                </el-form-item>
                <el-form-item label="预约时长:" prop="Period">
                  <el-col :span="20">
                    <el-select v-model="appointmentRuleForm.Period" placeholder="请选择预约时长">
                      <el-option :label="item.time" :value="item.value" v-for="item in timeArr" :key="item.value"></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="备注:" prop="Remark">
                  <el-col :span="20">
                    <el-input type="textarea" v-model="appointmentRuleForm.Remark" placeholder="200字以内备注"> </el-input>
                  </el-col>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-col>
        <el-col :span="11" :offset="2" class="right_item">
          <div style="height: 400px" class="back_f8 pad_10">
            <el-scrollbar style="height: 100%">
              <div class="dis_flex flex_x_between pad_10 flex_y_center" v-for="(item, index) in appointmentProjectList" :key="index">
                <span>{{ item.Name || item.ProjectName }}</span>
              </div>
            </el-scrollbar>
          </div>
        </el-col>
      </el-row>

      <span slot="footer">
        <el-button @click="appointmentDialogShow = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveAppointment" size="small">保 存</el-button>
      </span>
    </el-dialog>
    <!--选择项目-->
    <el-dialog :visible.sync="selectProjectDialogState" title="选择项目" width="900px">
      <template>
        <el-row>
          <el-col :span="8">
            <el-input placeholder="输入项目名称进行搜索" v-model="filterText" size="small" clearable></el-input>
            <el-scrollbar style="height: 460px" class="martp_5">
              <el-tree class="filter-tree" :data="projectList" show-checkbox node-key="PID" ref="treeRef" accordion highlight-current :props="defaultProps" :default-checked-keys="defaultCheckedKeysApplyApp" :filter-node-method="filterNode" @check="selectApplicableItems" default-expand-all>
                <span slot-scope="{ node, data }">
                  <span>{{ data.Name }}</span>
                  <el-tag plain class="marlt_5" size="mini" v-if="!data.IsProject">分类</el-tag>
                </span>
              </el-tree>
            </el-scrollbar>
          </el-col>
          <el-col :span="16" class="border_left">
            <el-table size="small" :data="selectedTableData.filter((data) => !filterText || data.Name.toLowerCase().includes(filterText.toLowerCase()))" max-height="500px">
              <el-table-column prop="Name" label="项目名称"></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" @click="deleteSelectRow(scope.row, scope.$index)">删除 </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </template>
      <div slot="footer">
        <el-button size="small" @click="selectProjectDialogState = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="confirmProjectSelect" v-prevent-click>确 认</el-button>
      </div>
    </el-dialog>
    <!--修改门店-->
    <el-dialog :visible.sync="dialogViableEntity" title="选择门店" width="500px">
      <el-form :model="entityForm" :rules="entityFormRules" ref="entityFormRef" label-width="90px" size="small">
        <el-form-item label="归属门店" prop="EntityID">
          <el-select v-model="entityForm.EntityID" size="small" clearable>
            <el-option v-for="entity in entityList" :value="entity.ID" :label="entity.EntityName" :key="entity.ID"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="dialogViableEntity = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="onSaveUpdateEntity" v-prevent-click>确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/CRM/Customer/customer";
import APICustomerLevel from "@/api/CRM/Customer/customerLevel";
import APICustomerSource from "@/api/CRM/Customer/customerSource";
import APITagLibrary from "@/api/CRM/Customer/customerTagLibrary";
import APIConfig from "@/api/iBeauty/Appointment/appointmentConfig";
import APIAppointment from "@/api/iBeauty/Appointment/appointmentView";
import date from "@/components/js/date";
import permission from "@/components/js/permission.js";
import validate from "@/components/js/validate.js";
var Enumerable = require("linq");

import customerbill from "@/components/iBeauty/Customer/customerBill";
import CustomerAccount from "../../../components/iBeauty/Customer/customerAccount";
import appointmentRecord from "@/components/iBeauty/Customer/appointmentRecord";
import NursingLog from "@/components/iBeauty/Customer/CustomerNursingLog";
import NurseHealth from "@/components/iBeauty/Customer/NurseHealth";
export default {
  name: "Customer",
  components: {
    customerbill,
    CustomerAccount,
    appointmentRecord,
    NurseHealth,
    NursingLog,
  },
  data() {
    return {
      entityForm:{
        EntityID:"",
      },
      
      entityFormRules: {
        EntityID: [{ required: true, message: "请选择归属门店", trigger: "change" }],
      },
      entityList:[],
      dialogViableEntity: false,
      isUpdateEntity: false,
      StartNotStoreDays: "",
      EndNotStoreDays: "",
      NotStoreDate: [],
      GoodsName: "",
      a: true,
      b: "",
      IsReturnedCommissionToo: null, // 显示卡金现金的状态
      commissionID: "", //佣金记录ID
      commissionDialog: false, //佣金记录
      commissionData: [],
      commissionListPagination: {
        Page: 1, // 当前位于哪页
        Total: 2, // 总数
        Page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      dialogVisibleActive: "1",
      avatarcircleUrl: "",
      customTagLibrary: false, //自定义标签权限
      loading: false,
      modalLoading: false,
      dialogVisible: false,
      dialogDetail: false,
      dialogTag: false,
      editFollowVisble: false,
      isAdd: true,
      isAddToo: true,
      tabPane: "0",
      name: null,
      employeeID: null,
      customerLevelID: null,
      customerSourceID: null,
      AppointmentState: false, // 是否可预约
      openBillState: false, // 是否可开单
      appointmentDialogShow: false, // 预约弹框
      IsMustEmployee: true, // 是否必选接待人
      selectProjectDialogState: false, // 选择项目弹框展示状态
      filterText: "",
      timeArr: [], // 预约时长数据
      appointmentProjectList: [], // 项目列表
      projectList: [], // 项目列表数据
      StartTime: "",
      EndTime: "",
      StartTimeData: "", // 开始时间(选择时间)
      EndTimeData: "", // 结束时间(选择时间)
      employeeList: [], // 可预约员工列表
      Period: "", // 预约间隔
      defaultCheckedKeysApplyApp: [], // 默认选中的适用项目节点
      selectedTableData: [], // 选中项目
      interval: 0,
      periodArr: [],
      tableData: [],
      fileList1: [],
      fit: "",
      employee: [], //营销顾问
      customerLevel: [], //顾客等级
      customerSource: [], //顾客来源
      customerTag: [], //顾客标签
      recommendList: [], //推荐顾客列表
      recommendListPagination: {
        Page: 1, // 当前位于哪页
        Total: 0, // 总数
        Page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      editCustomerTag: [],
      tagList: [], //所有标签
      customerID: null,
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      customerDetail: "",
      tagName: "",
      defaultProps: {
        children: "Child",
        label: "Name",
      },
      expireTimeOption: {
        disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      }, // 预约时间配置
      appointmentRuleForm: {
        ID: "", // 预约ID
        CustomerID: "", // 顾客ID
        EmployeeID: "", // 接待人ID
        AppointmentDate: new Date(), // 预约日期
        AppointmentTime: "", // 预约时间
        Type: "", // 接待人状态
        Status: "10", // 审批状态
        Period: "", // 预约时长
        Remark: "", // 备注
      }, // 预约
      appointmentRules: {
        EmployeeID: [{ required: true, message: "请选择接待人", trigger: "change" }],
        AppointmentDate: [{ required: true, message: "请选择预约日期", trigger: "change" }],
        AppointmentTime: [{ required: true, message: "请选择预约时间", trigger: "change" }],
        Type: [{ required: true, message: "请选择接待人状态", trigger: "change" }],
      },
      qualityList: [
        { value: "很好", label: "很好" },
        { value: "一般", label: "一般" },
        { value: "差", label: "差" },
      ],
      problem: [
        //最想改善的问题
        { value: "瘦肚子", label: "瘦肚子" },
        { label: "瘦腿", value: "瘦腿" },
        { value: "瘦背", label: "瘦背" },
        { value: "瘦胳膊", label: "瘦胳膊" },
        { value: "全身减重", label: "全身减重" },
        { value: "疏通经络", label: "疏通经络" },
        { value: "胸部胀痛", label: "胸部胀痛" },
        { value: "胸部松弛", label: "胸部松弛" },
        { value: "臀部下垂", label: "臀部下垂" },
        { value: "全身松弛", label: "全身松弛" },
        { value: "代谢缓慢", label: "代谢缓慢" },
        { value: "身体寒凉", label: "身体寒凉" },
      ],
      periodList: [
        { value: "提前", label: "提前" },
        { label: "延迟", value: "延迟" },
        { value: "准时", label: "准时" },
      ],
      chestList: [
        { value: "胀痛", label: "胀痛" },
        { value: "增生", label: "增生" },
        { value: "结节", label: "结节" },
      ],
      shouldersList: [
        { value: "僵硬", label: "僵硬" },
        { value: "酸痛", label: "酸痛" },
      ],
      sleepList: [
        { value: "11点前", label: "11点前" },
        { value: "12点前", label: "12点前" },
        { value: "12点后", label: "12点后" },
      ],
      defecateList: [
        { value: "经常便秘", label: "经常便秘" },
        { value: "偶尔便秘", label: "偶尔便秘" },
        { value: "正常", label: "正常" },
        { value: "腹泻", label: "腹泻" },
      ],
      waistList: [
        { value: "经常酸痛/寒凉", label: "经常酸痛/寒凉" },
        { value: "偶尔酸痛/寒凉", label: "偶尔酸痛/寒凉" },
        { value: "正常", label: "正常" },
      ],
      ColdHandList: [
        { value: "经常手脚冰凉", label: "经常手脚冰凉" },
        { value: "正常", label: "正常" },
      ],
      biteList: [
        { value: "甜食", label: "甜食" },
        { value: "冰冷", label: "冰冷" },
        { value: "辛辣", label: "辛辣" },
        { value: "海鲜", label: "海鲜" },
        { value: "油炸", label: "油炸" },
        { value: "果蔬类居多", label: "果蔬类居多" },
        { value: "肉食类居多", label: "肉食类居多" },
        { value: "规律", label: "规律" },
        { value: "不规律", label: "不规律" },
      ],
      environmentList: [
        { value: "室外居多", label: "室外居多" },
        { value: "空调房居多", label: "空调房居多" },
      ],
      typeAList: [
        { value: "带脉凸(不通)", label: "带脉凸(不通)" },
        { value: "大腿外翻(肝胆经不通)", label: "大腿外翻(肝胆经不通)" },
      ],
      healthList: [
        { value: "有过重大手术", label: "有过重大手术" },
        { value: "有过轻微手术", label: "有过轻微手术" },
        { value: "正常", label: "正常" },
      ],
      typeHList: [
        { value: "胳膊、腿偏粗", label: "胳膊、腿偏粗" },
        { value: "易乏力/怕累", label: "易乏力/怕累" },
      ],
      typeVList: [
        { value: "上宽下小,背厚", label: "上宽下小,背厚" },
        {
          value: "肚子凸口(易带脉、任脉堵)",
          label: "肚子凸口(易带脉、任脉堵)",
        },
      ],
      dietaryTherapyList: [
        { value: "小米粥", label: "小米粥" },
        { value: "鸡蛋", label: "鸡蛋" },
        { value: "山楂+荷叶煮水", label: "山楂+荷叶煮水" },
        { value: "山楂+陈皮煮水", label: "山楂+陈皮煮水" },
        { value: "早饭按时吃", label: "早饭按时吃" },
        { value: "黄芪泡水", label: "黄芪泡水" },
      ],
      nurseList: [
        { value: "寒湿体质", label: "寒湿体质" },
        { value: "腿部疏通肝胆经", label: "腿部疏通肝胆经" },
        { value: "肚子疏通带脉", label: "肚子疏通带脉" },
        { value: "臀疗", label: "臀疗" },
        { value: "偏热性体质", label: "偏热性体质" },
        { value: "大背", label: "大背" },
        { value: "肚子", label: "肚子" },
        { value: "小周天", label: "小周天" },
        { value: "胳膊", label: "胳膊" },
        {
          value: "偏寒性体质(易造成免疫力低,四肢循环差,手脚易凉)",
          label: "偏寒性体质(易造成免疫力低,四肢循环差,手脚易凉)",
        },
        {
          value: "先让身体热起来(小周天+全身去寒)",
          label: "先让身体热起来(小周天+全身去寒)",
        },
      ],
      remoteLoading: false,
      CustomerNameOrPhone: "", //推荐会员搜索关键字
      customerbillList: [], //推荐会员
      CustomerNamePaginations: {
        Total: 0,
        page: 1,
      },
      recordForm: {
        Age: "", //年龄
        Occupation: "", //工作
        Thigh: "", //大腿
        Shank: "", //小腿
        UmbilicalDimension: "", //脐维
        Arm: "", //胳膊
        IsPhotograph: false, //拍照
        ImproveProblem: [], //最想改善的问题选择结果
        Menses: "", //月经
        Chest: [], //胸部
        ShoulderNeck: [], //肩部
        SleepTime: "", //入睡时间
        SleepQuality: "", //睡眠质量
        Defecation: "", // 排便情况
        WaistCondition: "", // 腰部情况
        IceCold: "", //手脚寒凉
        FoodPreference: [], //饮食
        WorkEnvironment: "", //工作环境
        TypeA: "", //A型
        Health: "", //健康状况
        TypeH: "", //H型
        TypeV: "", //V型
        DietAdvice: "", //食疗建议
        SuggestionsConditioning: [], //调理建议
      },
      Record: {
        Age: "",
        Occupation: "",
        Thigh: "",
        Shank: "",
        UmbilicalDimension: "",
        Arm: "",
        IsPhotograph: false,
        ImproveProblem: [],
        Menses: "",
        Chest: [],
        ShoulderNeck: [],
        SleepTime: "",
        SleepQuality: "",
        Defecation: "",
        WaistCondition: "",
        IceCold: "",
        FoodPreference: [],
        WorkEnvironment: "",
        TypeA: "",
        Health: "",
        TypeH: "",
        TypeV: "",
        DietAdvice: "",
        SuggestionsConditioning: [],
      },
      ruleForm: {
        Name: "",
        PhoneNumber: "",
        Gender: "2",
        CustomerSourceID: null,
        ReferencesID: "",
        EmployeeID: [],
        CustomerLevelID: "",
        Code: "",
        Birthday: "",
        ReferencesName: "",
      },
      rules: {
        Name: [{ required: true, message: "请输入会员名称", trigger: "blur" }],
        PhoneNumber: [
          {
            required: true,
            validator: validate.validPhoneNumber,
            trigger: "blur",
          },
        ],
        Gender: [{ required: true, message: "请选择会员性别", trigger: "change" }],
        CustomerSourceID: [{ required: true, message: "请选择会员来源", trigger: "change" }],
        EmployeeID: [{ required: true, message: "请选择顾问", trigger: "change" }],
        CustomerLevelID: [{ required: true, message: "请选择会员等级", trigger: "change" }],
        Code: [{ required: true, message: "请输入会员编号", trigger: "blur" }],
        ReferencesName: [{ required: true, message: "请选择推荐会员", trigger: "blur" }],
      },
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      Birthday: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      customInfoEdit: "", //顾客信息编辑权限
      customerEntityBool: "", //是否是归属门店
      accountInfo: {}, //顾客存量余额信息
    };
  },
  watch: {
    filterText(val) {
      var that = this;
      that.$refs.treeRef.filter(val);
    },
  },

  methods: {
    /*  */
    onSaveUpdateEntity(){
      let that = this;
      that.$refs.entityFormRef.validate(valid=>{
        if(valid){
          that.customer_updateEntity();
        }
      })
    },
    /**    */
    onShowUpdateEntity(){
      let that = this;
      that.entityForm.EntityID = that.customerDetail.EntityID + "";
      that.dialogViableEntity = true;
    },
    /**  修改结束未到店天数  */
    changEndNotStoreDays(val) {
      let that = this;
      if (that.StartNotStoreDays && parseInt(val) < parseInt(that.StartNotStoreDays)) {
        that.$message.error("开始天数不能大于结束天数");
        that.EndNotStoreDays = that.StartNotStoreDays;
      }

      that.search();
    },
    /**  修改开始未到店天数  */
    changStartNotStoreDays(val) {
      let that = this;
      if (that.EndNotStoreDays && parseInt(val) > parseInt(that.EndNotStoreDays)) {
        that.$message.error("开始天数不能大于结束天数");
        that.StartNotStoreDays = that.EndNotStoreDays;
      }

      that.search();
    },
    /**    */
    formatterGoodsNames(row) {
      return row.GoodsName.reduce((per, cur, index) => {
        if (index == row.GoodsName.length - 1) {
          return per + cur;
        } else {
          return per + cur + "，";
        }
      }, "");
    },
    selectChange(ID) {
      this.ruleForm.ReferencesID = ID;
    },
    selectChangeToo(e) {
      if (e == 1) {
        this.a = false;
        this.b = e;
      }
    },
    //加载更多
    loadMore() {
      var that = this;
      // console.log(that.CustomerNamePaginations)
      if (that.customerbillList.length < that.CustomerNamePaginations.Total) {
        // that.selectLoading = true;
        that.CustomerNamePaginations.page++;
        that.CustomerList();
      } else {
        return;
      }
    },
    // 远程搜索
    remoteMethod(query) {
      var that = this;
      that.customerbillList = [];
      that.CustomerNamePaginations.page = 1;
      that.CustomerNameOrPhone = query.trim();
      setTimeout(() => {
        that.CustomerList();
      }, 200);
    },
    //获取来源顾客列表
    CustomerList() {
      this.remoteLoading = true;
      var params = {
        CustomerNameOrPhone: this.CustomerNameOrPhone,
        PageNum: this.CustomerNamePaginations.page,
        CustomerID: this.customerID,
      };
      API.recommendCustomerList(params).then((res) => {
        console.log(res);
        this.remoteLoading = false;
        if (res.StateCode == 200) {
          this.customerbillList = [...this.customerbillList, ...res.List];
          this.CustomerNamePaginations.Total = res.Total;
        }
      });
    },
    //客户详情tab切换
    handleClick() {
      var that = this;
      var tabPane = this.tabPane;
      switch (tabPane) {
        case "0":
          that.GetRecord();
          break;
        case "1":
          that.customerTagData();
          break;
        case "2":
          that.$refs.customerAccount.customerID = that.customerID;
          that.$refs.customerAccount.activeName = "0";
          that.$refs.customerAccount.handleClick();
          break;
        case "3":
          that.$refs.customerbill.customerID = that.customerID;
          that.$refs.customerbill.searchSaleBill();
          that.$refs.customerbill.clearSearchData();
          break;
        case "4":
          that.$refs.appointmentRecord.customerID = that.customerID;
          that.$refs.appointmentRecord.getAppointmentRecordList();
          break;
        case "5":
          that.recommendListPagination.Page = 1;
          that.GetRecommendList();
          break;
        case "6":
          that.$refs.nursingLog.customerID = that.customerID;
          that.$refs.nursingLog.clearListData();
          that.$refs.nursingLog.getNursingLogList();
          break;
        case "7":
          this.$refs.NurseHealth.DataList = [];
          this.$refs.NurseHealth.getNurseHealth();
          break;
        case "8":
          that.recommendListPagination.Page = 1;
          that.getValueGroup();
          break;
      }
    },
    //获取推荐顾客列表
    GetRecommendList() {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        PageNum: that.recommendListPagination.Page,
      };
      API.CommissionList(params).then((res) => {
        if (res.StateCode == 200) {
          that.recommendList = res.List;
          that.recommendListPagination.Total = res.Total;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    //成长值记录
    getValueGroup() {
      var that = this;
      var params = {
        CustomerID: that.customerID,
        PageNum: that.recommendListPagination.Page,
      };
      API.getGrowthValue(params).then((res) => {
        if (res.StateCode == 200) {
          that.recommendList = res.List;
          that.recommendListPagination.Total = res.Total;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    //佣金记录弹窗
    Setcommission(row) {
      this.commissionID = row.ID;
      this.Getcommission();
      this.commissionListPagination.Page = 1;
      this.commissionDialog = true;
    },
    //获取佣金记录
    Getcommission() {
      var that = this;
      var params = {
        CustomerID: that.commissionID,
        PageNum: that.commissionListPagination.Page,
      };
      API.recommendCommissionList(params).then((res) => {
        if (res.StateCode == 200) {
          that.commissionData = res.List;
          that.commissionListPagination.Total = res.Total;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 添加减重计划弹窗
    AddNurse() {
      this.$refs.NurseHealth.ruleForm = {
        PlanName: "",
        planDetailedOutForms: [
          {
            dataTime: [],
            Remark: "",
          },
        ],
      };
      this.$refs.NurseHealth.isAddNur = true;
      this.$refs.NurseHealth.NurVisible = true;
    },
    /** 顾客详情 弹窗界面关闭前调用   */
    customerDetailBeforeClose(done) {
      let that = this;
      that.$refs.customerAccount.clearAccountData();
      that.$refs.customerbill.clearNetWorkData();
      that.$refs.appointmentRecord.clearAppoinmentRecordData();
      // that.$refs.nursingLog.clearListData();

      done();
    },
    // //状态显示转换
    formatGender: function(row) {
      return row.Gender == "1" ? "男" : "女";
    },
    handleRemove() {},
    auxiliaryUpload() {},
    onBeforeUploadImage() {},
    exceed() {},
    /**  列表顾问格式化  */
    formatterEmployeeNames(row) {
      return Enumerable.from(row.Employee)
        .select((i) => i.EmployeeName)
        .toArray()
        .join(", ");
    },

    // 营销顾问
    employeeData: function() {
      var that = this;
      API.getConsultant()
        .then((res) => {
          if (res.StateCode == 200) {
            that.employee = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 顾客来源
    CustomerSourceData: function() {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICustomerSource.getCustomerSource(params)
        .then((res) => {
          console.log(res);
          if (res.StateCode == 200) {
            that.customerSource = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 顾客等级
    CustomerLevelData: function() {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICustomerLevel.getCustomerLevel(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerLevel = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 顾客标签
    customerTagData: function() {
      var that = this;
      var params = {
        ID: that.customerDetail.ID,
      };
      API.getCustomerTag(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerTag = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 标签列表
    tagData: function() {
      var that = this;
      APITagLibrary.customerTagLibraryAll()
        .then((res) => {
          if (res.StateCode == 200) {
            that.tagList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 数据显示
    handleSearch: function() {
      let that = this;
      that.paginations.page = 1;
      that.search();
    },
    // 数据显示
    search: function() {
      let that = this;
      var params = {
        Name: that.name,
        EmployeeID: that.employeeID,
        CustomerLevelID: that.customerLevelID,
        CustomerSourceID: that.customerSourceID,
        PageNum: that.paginations.page,
        StartDate: that.Birthday ? date.formatDate.format(that.Birthday[0], "MM-DD") : "",
        EndDate: that.Birthday ? date.formatDate.format(that.Birthday[1], "MM-DD") : "",
        GoodsName: that.GoodsName, //商品搜索
        CreateStartDate: that.NotStoreDate && that.NotStoreDate.length > 0 ? date.formatDate.format(that.NotStoreDate[0], "YYYY-MM-DD") : "", //开始注册时间
        CreateEndDate: that.NotStoreDate && that.NotStoreDate.length > 0 ? date.formatDate.format(that.NotStoreDate[1], "YYYY-MM-DD") : "", //结束注册时间
        StartNotStoreDays: that.StartNotStoreDays, //开始未到店天数
        EndNotStoreDays: that.EndNotStoreDays, //结束未到店天数
      };
      API.getCustomer(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.search();
    },
    clearnRecordForm() {
      this.recordForm = {
        Age: "", //年龄
        Occupation: "", //工作
        Thigh: "", //大腿
        Shank: "", //小腿
        UmbilicalDimension: "", //脐维
        Arm: "", //胳膊
        IsPhotograph: false, //拍照
        ImproveProblem: [], //最想改善的问题选择结果
        Menses: "", //月经
        Chest: [], //胸部
        ShoulderNeck: [], //肩部
        SleepTime: "", //入睡时间
        SleepQuality: "", //睡眠质量
        Defecation: "", // 排便情况
        WaistCondition: "", // 腰部情况
        IceCold: "", //手脚寒凉
        FoodPreference: [], //饮食
        WorkEnvironment: "", //工作环境
        TypeA: "", //A型
        Health: "", //健康状况
        TypeH: "", //H型
        TypeV: "", //V型
        DietAdvice: "", //食疗建议
        SuggestionsConditioning: [], //调理建议
      };
    },
    // 新增顾客
    showAddDialog: function() {
      var that = this;
      that.isAdd = true;
      that.isAddToo = true;
      that.customerID = null;
      that.dialogVisibleActive = "1";
      that.ruleForm = {
        Name: "",
        PhoneNumber: "",
        Gender: "2",
        CustomerSourceID: null,
        EmployeeID: [],
        CustomerLevelID: "",
        Code: "",
        Birthday: "",
      };
      that.CustomerNamePaginations.page = 1;
      that.customerbillList = [];
      that.clearnRecordForm();
      that.dialogVisible = true;
      that.employeeData();
    },
    // 编辑顾客
    showEditDialog: function(row) {
      var that = this;
      that.isAddToo = false;
      console.log(row.CustomerSourceID);
      that.isAdd = false;
      if (row.CustomerSourceID !== 1) {
        that.a = false;
      }
      that.CustomerNamePaginations.page = 1;
      that.customerbillList = [];
      row.EmployeeID = new Array();
      row.Employee.forEach((i) => {
        row.EmployeeID.push(i.EmployeeID);
      });
      that.dialogVisibleActive = "1";
      that.ruleForm = Object.assign({}, row);
      console.log(that.ruleForm);
      that.recordForm = Object.assign({}, that.Record);
      that.dialogVisible = true;
      that.employeeData();
    },
    // 顾客详情
    customerDetailClick: function(row) {
      var that = this;
      that.customerID = row.ID;
      that.IsReturnedCommissionToo = row.IsReturnedCommission;
      that.tabPane = "0";
      that.customerDetail = Object.assign({}, row);
      that.clearnRecordForm();
      // 请求顾客档案 赋值Record
      that.GetRecord();
      that.dialogDetail = true;
      const EntityID = JSON.parse(localStorage.getItem("access-user")).EntityID;
      that.customerEntityBool = EntityID == row.EntityID ? true : false;
    },
    //获取档案信息
    GetRecord() {
      var that = this;
      API.getCustomerBasicsArchives({ CustomerID: that.customerID }).then((res) => {
        if (res.StateCode == 200) {
          that.Record = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 新客
    submitCustomer: function() {
      var that = this;
      console.log(that.recordForm);
      console.log(that.ruleForm);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.modalLoading = true;
          let para = Object.assign(this.recordForm, that.ruleForm);
          para.ReferencesName = "";
          console.log(para);
          if (that.isAdd) {
            API.createCustomer(para)
              .then(function(res) {
                if (res.StateCode === 200) {
                  that.$message.success({
                    message: "新增成功",
                    duration: 2000,
                  });
                  that.search();
                  that.$refs["ruleForm"].resetFields();
                  that.dialogVisible = false;
                  that.isAddToo = false;
                  that.a = true;
                } else {
                  that.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
              })
              .finally(function() {
                that.modalLoading = false;
              });
          }

          if (!that.isAddToo) {
            API.updateCustomer(para)
              .then(function(res) {
                if (res.StateCode === 200) {
                  that.$message.success({
                    message: "编辑成功",
                    duration: 2000,
                  });
                  that.customerDetail = Object.assign({}, res.Data);
                  that.search();
                  that.$refs["ruleForm"].resetFields();
                  that.GetRecord();
                  that.dialogVisible = false;
                } else {
                  that.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
              })

              .finally(function() {
                that.modalLoading = false;
              });
            if (that.b == 1) {
              that.a = true;
            }
          }
        }
      });
    },
    // 标签
    tagClick: function() {
      var that = this;
      that.editCustomerTag = Object.assign([], that.customerTag);
      that.tagType();
      that.dialogTag = true;
    },
    tagType: function() {
      var that = this;
      that.tagList.forEach(function(item) {
        item.type = "info";
        that.editCustomerTag.forEach(function(tag) {
          if (item.ID == tag.ID) {
            item.type = "primary";
          }
        });
      });
    },
    // 删除标签
    removeTag: function(index) {
      var that = this;
      that.editCustomerTag.splice(index, 1);
      that.tagType();
    },
    // 选择标签
    tagSelectClick: function(row) {
      var that = this;
      if (row.type == "info") {
        that.editCustomerTag.push(row);
      }
      that.tagType();
    },
    // 添加标签
    addTagClick: function() {
      var that = this;
      var params = {
        Name: that.tagName,
      };
      APITagLibrary.customerTagLibraryCreate(params)
        .then(function(res) {
          if (res.StateCode === 200) {
            that.editCustomerTag.push(res.Data);
            that.tagList.push(res.Data);
            that.tagType();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.modalLoading = false;
        });
    },

    // 标签保存
    tagSaveClick: function() {
      var that = this;
      that.modalLoading = true;
      var TagLibrary = Enumerable.from(that.editCustomerTag)
        .select((val) => val.ID)
        .toArray();
      var params = {
        ID: that.customerDetail.ID,
        TagLibrary: TagLibrary,
      };
      API.updateCustomerTagLibrary(params)
        .then(function(res) {
          if (res.StateCode === 200) {
            that.dialogTag = false;
            that.customerTagData();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.modalLoading = false;
        });
    },
    /**  跟进编辑  */
    editFollowUp() {
      var that = this;
      that.editFollowVisble = true;
    },
    followSaveClick() {
      var that = this;
      that.editFollowVisble = false;
    },
    /** 顾客 开单   */
    navCreateBill() {
      var that = this;
      that.dialogDetail = false;
      var IsReturnedCommission = that.IsReturnedCommissionToo;
      that.$router.push({
        path: "/Order/Bill",
        name: "Bill",
        params: { customerID: that.customerID, IsReturnedCommission: IsReturnedCommission },
      });
    },
    /**    */
    gotoOrderCreated(row) {
      var that = this;
      that.dialogDetail = false;
      console.log(row);
      that.$router.push({
        path: "/Order/Bill",
        name: "Bill",
        params: { customerID: row.ID, IsReturnedCommission: row.IsReturnedCommission },
      });
    },

    // 预约
    clickAppointment() {
      var that = this;
      // that.dialogDetail = false;
      that.appointmentRuleForm.ID = "";
      that.appointmentRuleForm.CustomerID = "";
      that.appointmentRuleForm.EmployeeID = "";
      that.appointmentRuleForm.AppointmentDate = new Date();
      that.appointmentRuleForm.AppointmentTime = "";
      that.appointmentRuleForm.Type = "10";
      that.appointmentRuleForm.Status = "10";
      that.appointmentRuleForm.Period = that.timeArr[0].value;
      that.appointmentRuleForm.Remark = "";
      that.appointmentProjectList = [];

      that.appointmentDialogShow = true;
    },
    // 获取预约时长
    getDateAppointmentIntervalList(period) {
      var that = this;
      var startTimestamp = new Date(that.currentDate + " " + "00:" + period).getTime();
      var endTimestamp = new Date(that.currentDate + " " + "08:00").getTime();
      var periodTimestamp = Number(period) * 60 * 1000; // 间隔的毫秒
      var interValue = period;
      for (var index = startTimestamp; index <= endTimestamp; index += periodTimestamp) {
        if (interValue < 60) {
          that.timeArr.push({
            time: date.formatDate.format(new Date(index), "mm分钟"), // 时间轴显示字符串
            value: interValue,
          });
        } else {
          that.timeArr.push({
            time: date.formatDate.format(new Date(index), "h小时mm分钟"), // 时间轴显示字符串
            value: interValue,
          });
        }
        interValue += period;
      }
    },
    // 获取预约配置
    getAppointmentConfig() {
      var that = this;
      that.loading = true;
      var params = {};
      var period = 0;
      var dataTime = [];
      APIConfig.appointmentConfig(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.StartTime = res.Data.StartTime;
            that.EndTime = res.Data.EndTime;
            that.IsMustEmployee = res.Data.IsMustEmployee;
            that.interval = res.Data.Period;
            that.Period = "00:" + res.Data.Period;
            var number = parseInt(60 / res.Data.Period);
            for (let i = 0; i <= number - 1; i++) {
              period += res.Data.Period;
              dataTime.push(period);
            }
            that.periodArr = dataTime;

            that.getDateAppointmentIntervalList(res.Data.Period);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 获取可预约员工列表
    getEmployeeList() {
      var that = this;
      that.loading = true;
      var params = {};
      APIAppointment.getEmployeeList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.employeeList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 递归
    setRecursion(data) {
      var that = this;
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].IsProject) {
          data[i].PID = "1" + data[i].ID;
        } else {
          data[i].PID = "0" + data[i].ID;
        }
        if (data[i].Child) {
          that.setRecursion(data[i].Child);
        }
      }
    },
    // 获取项目列表
    getProjectList() {
      var that = this;
      that.loading = true;
      var params = {
        Name: "",
      };
      APIAppointment.getProjectList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.setRecursion(res.Data);
            // that.projectList = res.Data;
            that.projectList = Enumerable.from(res.Data)
              .where((i) => {
                if (!i.IsProject) {
                  i.Child = Enumerable.from(i.Child)
                    .where((i) => {
                      return !i.IsProject && i.Child.length > 0;
                    })
                    .toArray();
                }
                return !i.IsProject && i.Child.length > 0;
              })
              .toArray();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }

          console.log(that.projectList);
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 添加预约项目
    addAppointmentProject() {
      var that = this;
      that.selectedTableData = Object.assign([], that.appointmentProjectList);
      that.defaultCheckedKeysApplyApp = [];
      that.selectProjectDialogState = true;
      that.$nextTick(() => {
        var defaultCheckedKeys = Enumerable.from(that.appointmentProjectList)
          .select((val) => "1" + val.ID)
          .toArray();
        that.$refs.treeRef.setCheckedKeys(defaultCheckedKeys);
      });
    },
    // 选择适用项目事件
    selectApplicableItems(item, list) {
      var that = this;
      var timeCardProject = Enumerable.from(list.checkedNodes)
        .where(function(i) {
          return i.IsProject;
        })
        .select((item) => ({
          ID: item.ID,
          Name: item.Name,
          PID: item.PID,
          ParentID: item.ParentID,
          Price: item.Price,
          ProjectCategoryName: item.ProjectCategoryName,
          TreatTime: item.TreatTime,
        }))
        .toArray();
      that.selectedTableData = timeCardProject;
    },
    // 选择项目确认
    confirmProjectSelect() {
      var that = this;
      // var appointmentProjectList = Object.assign([], that.appointmentProjectList);
      var selectedTableData = Object.assign([], that.selectedTableData);
      if (that.selectedTableData.length == 0) {
        that.$message.error("请选择项目");
        return false;
      } else {
        var totalPeriod = 0;
        that.appointmentProjectList = Object.assign([], selectedTableData);
        that.selectProjectDialogState = false;
        that.appointmentProjectList.forEach((val) => {
          totalPeriod += val.TreatTime;
        });
        for (let i = 0; i <= that.timeArr.length - 1; i++) {
          if (that.timeArr[i].value >= totalPeriod) {
            that.appointmentRuleForm.Period = that.timeArr[i].value;
            break;
          }
        }
      }
    },
    // 删除所选中的适用项目
    deleteSelectRow(row, index) {
      var that = this;
      var ParentID = row.ParentID;
      that.selectedTableData.splice(index, 1);
      // that.selectedTableData.forEach((val, index) => {
      //   if (val.ID == ParentID) {
      //     that.selectedTableData.splice(index, 1);
      //   }
      // });
      that.$nextTick(() => {
        var defaultCheckedKeys = Enumerable.from(that.selectedTableData)
          .select((val) => val.PID)
          .toArray();
        that.$refs.treeRef.setCheckedKeys(defaultCheckedKeys);
      });
    },
    // 选择时间
    getTimeArr() {
      var that = this;
      var currentDate = new Date();

      let selTime = new Date(date.formatDate.format(that.appointmentRuleForm.AppointmentDate, "YYYY-MM-DD")).getTime();

      let currentTime = new Date(date.formatDate.format(currentDate, "YYYY-MM-DD")).getTime();

      if (selTime == currentTime) {
        // 今天
        let minutes = parseInt(date.formatDate.format(currentDate, "mm"));
        var startIndex = parseInt(minutes / that.interval);
        var tempMinutes = that.periodArr[startIndex];

        if (tempMinutes == 60) {
          let hour = date.formatDate.format(new Date(new Date().setHours(new Date().getHours() + 1)), "hh");
          that.StartTimeData = hour + ":" + "00";
          that.EndTimeData = that.EndTime;
        } else {
          let hours = date.formatDate.format(currentDate, "hh");
          that.StartTimeData = hours + ":" + tempMinutes;
          that.EndTimeData = that.EndTime;
        }
      } else if (selTime > currentTime) {
        that.StartTimeData = that.StartTime;
        that.EndTimeData = that.EndTime;
      }
    },

    // 选择时间
    // getTimeArr() {
    //   var that = this;
    //   var interval = that.interval;
    //   var currentDate = new Date();
    //   var StartTimeData = '';
    //   var EndTimeData = '';
    //   var currentHours = currentDate.getHours();
    //   var currentMinutes = currentDate.getMinutes();
    //   var startIndex = parseInt(currentMinutes / interval);
    //   var startminutes = that.periodArr[startIndex];

    //   var currentYear = currentDate.getFullYear();
    //   var currentMonth = currentDate.getMonth() + 1;
    //   var currentDay = currentDate.getDate();
    //   var todayDate = currentYear + '-' + (currentMonth >= 10 ? currentMonth : '0' + currentMonth) + '-' + (currentDay >= 10 ? currentDay : '0' + currentDay);

    //   var beforeTime = new Date(that.currentDate).getTime();
    //   var currentTime = new Date(todayDate).getTime();

    //   if (beforeTime == currentTime) {
    //     if (startminutes == 60) {
    //       currentHours += 1;
    //       startminutes = Number('00');
    //     }
    //     StartTimeData = currentHours >= 10 ? currentHours + ':' + (startminutes >= 10 ? startminutes : `0` + startminutes) : `0${currentHours}` + ':' + (startminutes >= 10 ? startminutes : `0` + startminutes);
    //     that.StartTimeData = StartTimeData;
    //     that.EndTimeData = that.EndTime;
    //   } else if (beforeTime > currentTime) {
    //     that.StartTimeData = that.StartTime;
    //     that.EndTimeData = that.EndTime;
    //   }
    // },
    // 选择时间确认
    confirmTime(date) {
      var that = this;
      var dateArr = date.split(":");
      var currentDate = "";
      var dateTime = "";
      currentDate = Number(dateArr[0]) >= 10 ? Number(dateArr[0]) + ":" + (Number(dateArr[1]) >= 10 ? Number(dateArr[1]) : `0` + Number(dateArr[1])) : `0${Number(dateArr[0])}` + ":" + (Number(dateArr[1]) >= 10 ? Number(dateArr[1]) : `0` + Number(dateArr[1]));
      var AppointmentDate = that.appointmentRuleForm.AppointmentDate.split(" ");
      dateTime = AppointmentDate[0] + " " + currentDate + ":00";
      that.appointmentRuleForm.AppointmentDate = dateTime;
    },
    // 保存预约
    saveAppointment() {
      var that = this;
      var AppointmentTime = that.appointmentRuleForm.AppointmentTime;
      that.$refs["appointmentRuleForm"].validate((valid) => {
        if (valid) {
          if (AppointmentTime == undefined || AppointmentTime == null || AppointmentTime == "") {
            this.$message({
              type: "error",
              message: "请选择预约时间",
            });
          } else {
            that.createAppointment();
          }
        }
      });
    },
    // 创建预约
    createAppointment() {
      var that = this;
      var appointmentProjectList = [];
      var obj = {};
      var params = {};

      var appointmentDate = date.formatDate.format(new Date(that.appointmentRuleForm.AppointmentDate), "YYYY-MM-DD");
      var AppointmentTime = that.appointmentRuleForm.AppointmentTime;
      var AppointmentDate = appointmentDate + " " + AppointmentTime;

      that.loading = true;

      obj.EmployeeID = that.appointmentRuleForm.EmployeeID;
      obj.CustomerID = that.customerID;
      obj.AppointmentDate = AppointmentDate;
      obj.Period = that.appointmentRuleForm.Period;
      obj.Remark = that.appointmentRuleForm.Remark;
      obj.Type = that.appointmentRuleForm.Type;

      that.appointmentProjectList.forEach((val) => {
        var obj = {};
        obj.ProjectID = val.ID;
        appointmentProjectList.push(obj);
      });
      params = Object.assign({}, obj);
      params.Project = appointmentProjectList;

      APIAppointment.appointmentBillCreate(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "成功创建预约",
              duration: 2000,
            });
            that.appointmentDialogShow = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 适用项目弹框搜索事件
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },

    /**  上传头像请求  */
    updateCustomerUploadImage(base64) {
      let that = this;
      let params = {
        CustomerImage: base64,
        CustomerID: that.customerDetail.ID,
      };
      API.updateCustomerUploadImage(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerDetail.Avatar = res.Message;
            that.search();
          }
        })
        .finally(() => {});
    },
    // 顾客存量余额信息
    AccountInfo() {
      const that = this;
      API.AccountInfo({ CustomerID: that.customerDetail.ID }).then((res) => {
        if (res.StateCode == 200) {
          that.accountInfo = res.Data;
        } else {
          this.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //
    beforeAvatarUpload(file) {
      let that = this;
      let isFileType = false;
      if (file.type === "image/jpg" || file.type === "image/png" || file.type === "image/jpeg") {
        isFileType = true;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isFileType) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return false;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(evt) {
        let base64 = evt.target.result;
        that.updateCustomerUploadImage(base64);
      };
      return false;
    },


    
    /**    */
    customer_updateEntity(){
      let that = this;
      let params = {
        CustomerID: that.customerDetail.ID, //顾客编号
        EntityID: that.entityForm.EntityID //门店编号是
       };
      API.
      customer_updateEntity(params)
       .then((res) => {
         if(res.StateCode == 200){
          that.$message.success("操作成功");
          that.dialogViableEntity = false;
          let entityItem = that.entityList.find(i=>i.ID == that.entityForm.EntityID);
          if(entityItem){
          that.$nextTick(()=>{
            that.customerDetail.EntityID = entityItem.ID;
            that.customerDetail.EntityName = entityItem.EntityName;
          })
        }
          that.search();
          }
          else{
             that.$message.error(res.Message);
           }
         })
         .catch((fail) => {
           that.$message.error(fail);
         });
        },
    /**    */
    customer_entity(){
      let that = this;
      let params = {
       };
      API.
      customer_entity(params)
       .then((res) => {
         if(res.StateCode == 200){
          that.entityList = res.Data;
          }
          else{
             that.$message.error(res.Message);
           }
         })
         .catch((fail) => {
           that.$message.error(fail);
         });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.AppointmentState = permission.routerPermission("/Appointment/AppointmentView");
      vm.openBillState = permission.routerPermission("/Order/Bill");
      vm.customInfoEdit = permission.permission(to.meta.Permission, "iBeauty-Customer-Customer-ModifyNotBelongEntityCustomer");
      vm.customTagLibrary = permission.permission(to.meta.Permission, "iBeauty-Customer-Customer-CustomTagLibrary");
      vm.isUpdateEntity = permission.permission(to.meta.Permission, "iBeauty-Customer-Customer-UpdateEntity");
    });
  },
  mounted() {
    var that = this;
    that.AppointmentState = permission.routerPermission("/Appointment/AppointmentView");
    that.openBillState = permission.routerPermission("/Order/Bill");
    that.customTagLibrary = permission.permission(that.$route.meta.Permission, "iBeauty-Customer-Customer-CustomTagLibrary");
    that.customInfoEdit = permission.permission(that.$route.meta.Permission, "iBeauty-Customer-Customer-ModifyNotBelongEntityCustomer");
    that.isUpdateEntity = permission.permission(that.$route.meta.Permission, "iBeauty-Customer-Customer-UpdateEntity");
    var time = new Date();
    that.currentDate = date.formatDate.format(new Date(time), "YYYY-MM-DD");
    that.employeeData();
    that.CustomerSourceData();
    that.CustomerLevelData();
    that.tagData();
    that.handleSearch();
    that.getEmployeeList();
    that.getProjectList();
    that.getAppointmentConfig();
    that.customer_entity();
  },
};
</script>

<style lang="scss">
.Customer {
  .right_item {
    background: #f8f8f8;
  }

  .tip {
    margin: 0px;
    background-color: #f7f8fa;
  }

  .customer_information {
    background-color: #f7f8fa;
    padding: 8px;
  }

  .ui-sex-female {
    background: "url(" + require("@/assets/img/logo2.png") + ")";
    background-size: 8px 12px;
  }

  .backColor_f8 {
    background-color: #f8f8f8;
  }
  .CustomerSourceID {
    .el-form-item__content {
      margin-left: 20px !important;
      margin-right: 15px !important;
    }
  }
}
.el-scrollbar_height {
  height: 100%;
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
.width_220 {
  width: 220px;
}
.customer_content {
  position: relative;
  min-height: 50vh;
}
.width_100 {
  width: 100px;
}
.left .el-tooltip__popper,
.right .el-tooltip__popper {
  padding: 8px 10px;
}
// .customDateClass{

//   .el-date-picker__header>span:nth-child(0){

//       background-color: red;

//   }
.AddNurse {
  position: absolute;
  right: 20px;
  top: 3px;
  padding: 9px 20px;
  z-index: 99;
}

// }
</style>

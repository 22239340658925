/*
 * @Author: zh
 * @Date: 2020-07-25 09:44:26
 *
 */

import * as API from '@/api/index'

export default {
    // 消耗订单列表
    treatBilllist: params => {
        return API.POST('api/treatBill/list', params)
    },
    // 消耗订单详情
    treatBillinfo: params => {
        return API.POST('api/treatBill/info', params)
    },
    // 获取订单经手人信息
    employeePerformanceCommission: params => {
        return API.POST('api/treatBill/employeePerformanceCommission', params)
    },
    // 56.4. 修改订单经手人及业绩佣金
    updateEmployeePerformanceCommission: params => {
        return API.POST('api/treatBill/updateEmployeePerformanceCommission', params)
    },
    //  销售订单取消
    cancel: params => {
        return API.POST('api/treatBill/cancel', params)
    },
    //  56.6. 计算员工提佣
    employeeCommission: params => {
        return API.POST('api/treatBill/employeeCommission', params)
    },


    /**  52.11.获取项目消耗经手人 */
    treatGoodsProjectHandler: params => {
        return API.POST('api/treatHandler/projectHandler', params)
    },
    /**  52.12.获取产品消耗经手人 */
    treatGoodsProductHandler: params => {
        return API.POST('api/treatHandler/productHandler', params)
    },
    /**  52.13.获取储值卡消耗经手人 */
    treatGoodsSavingCardHandler: params => {
        return API.POST('api/treatHandler/savingCardHandler', params)
    },
    /** 修改备注 */
    updateRemark: params => {
        return API.POST('api/treatBill/updateRemark', params)
    },
    //上传附件
    addTreatBillAttachment:params =>{
        return API.POST('api/saleBill/addTreatBillAttachment',params)
    },
    // 获取附件
    getTreatBillAttachment:params =>{
        return API.POST('api/saleBill/getTreatBillAttachment',params)
    },
    // 删除附件
    deleteTreatBillAttachment:params =>{
        return API.POST('api/saleBill/deleteTreatBillAttachment',params)
    },
    // 获取消耗单签名
    getSignature:params =>{
        return API.POST('api/treatBill/getSignature',params)
    },
}
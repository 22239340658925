
function getBase64Image(img, width, height) {
  var canvas = document.createElement("canvas");
  canvas.width = width ? width : img.width;
  canvas.height = height ? height : img.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  var dataURL = canvas.toDataURL();
  return dataURL;
}


export default {
  // 通过图片地址获取 base64
  getCanvasBase64(img) {
    return new Promise((resolve) => {
      var image = new Image();
      //至关重要
      image.crossOrigin = '';
      image.src = img;
      //至关重要
      if (img) {
        image.onload = function () {
          let base64 = getBase64Image(image);//将base64传给done上传处理
          resolve(base64);//将base64传给done上传处理
          //document.getElementById("container2").appendChild(image);
        }
        // return deferred;//问题要让onload完成后再return sessionStorage['imgTest']
      }
    })
  },
  // 函数节流
  throttle(fn,wait){
  var pre = Date.now();
  wait = wait || 500;
  return function(){
    var context = this;
    var args = arguments;
    var now = Date.now();
    if( now - pre >= wait){
        fn.apply(context,args);
        pre = Date.now();
    }
  }
  },

  /** * 函数防抖 */
  debounce(fn, delay) {
    // 记录上一次的延时器
    var timer = null;
    delay = delay || 200;
    return function() {
      var args = arguments;
      var that = this;
      // 清除上一次延时器
      clearTimeout(timer)
      timer = setTimeout(function() {
          fn.apply(that,args)
      }, delay);
    }
  },
  // 深拷贝
 deepClone(obj) {
  // 判断传入目标 类型 
  if (typeof obj !== "object") {
      return obj;
  } else {
      var result = (obj instanceof Array) ? [] : {};
      for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
              result[key] = this.deepClone(obj[key]);
          }
      }
      return result;
  }
}

}
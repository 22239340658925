/**
 * Created by jerry on 2020/03/24.
 * 顾客api
 */
import * as API from "@/api/index";

export default {
  //获取会员基本信息
  getCustomerInfo: (params) => {
    return API.POST("api/customer/info", params);
  },
  //获取营销顾问
  getConsultant: (params) => {
    return API.POST("api/customer/consultant", params);
  },
  // 顾客列表
  getCustomer: (params) => {
    return API.POST("api/customer/list", params);
  },
  // 创建顾客
  createCustomer: (params) => {
    return API.POST("api/customer/create", params);
  },
  // 修改顾客信息
  updateCustomer: (params) => {
    return API.POST("api/customer/update", params);
  },
  // 获取顾客标签信息
  getCustomerTag: (params) => {
    return API.POST("api/customer/getCustomerTagLibrary", params);
  },
  //保存选中顾客标签
  updateCustomerTagLibrary: (params) => {
    return API.POST("api/customer/updateCustomerTagLibrary", params);
  },
  //修改顾客头像
  updateCustomerUploadImage: (params) => {
    return API.POST("api/customer/uploadImage", params);
  },
  // 顾客存量余额信息
  AccountInfo: (params) => {
    return API.POST("api/customer/accountInfo", params);
  },
  // 顾客档案信息
  getCustomerBasicsArchives: (params) => {
    return API.POST("api/customer/getCustomerBasicsArchives", params);
  },
  // 选择推荐会员列表
  recommendCustomerList: (params) => {
    return API.POST("api/customer/recommendCustomerList", params);
  },
  // 返佣列表
  CommissionList: (params) => {
    return API.POST("api/customer/recommendCustomerCommissionList", params);
  },
  // 返佣记录
  recommendCommissionList: (params) => {
    return API.POST("api/customer/recommendCommissionList", params);
  },
  // 成长值记录
  getGrowthValue: (params) => {
    return API.POST("api/customerAccount/getGrowthValueLogByCustomerID", params);
  },

  customer_updateEntity: (params) => {
    return API.POST("api/customer/updateEntity", params);
  },
  customer_entity: (params) => {
    return API.POST("api/customer/entity", params);
  },

  // // 添加客户
  // newKeHu: params => {
  //     return API.POST('/api/customer/update',params)
  // },
};

import * as API from '@/api/index'
export default{
    //延期审批列表
   PostponeBillApprovalList: params =>{
    return API.POST('api/postponeBill/approvalList',params)
  },
  //提交审批
  SubmitPostponeBill: params =>{
    return API.POST("api/postponeBill/create", params)
  },
  //延期审批
  ExtendPostponeBill: params =>{
    return API.POST('api/postponeBill/approval',params)
  },
  //延期申请详情
  PostponeBillDeatail: params =>{
    return API.POST('api/postponeBill/info', params)
  },
}
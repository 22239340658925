/**
 * Created by preference on 2020/07/029
 */

import * as API from '@/api/index'

export default {
    /**  退款项目存量列表 */
    getProjectAccount: params => {
        return API.POST('api/refundAccount/projectAccount', params)
    },
    /**  退款产品存量列表 */
    getProductAccount: params => {
        return API.POST('api/refundAccount/productAccount', params)
    },
    /**  退款储值卡存量列表 */
    getSavingCardAccount: params => {
        return API.POST('api/refundAccount/savingCardAccount', params)
    },
    /**  退款通用次卡存量列表 */
    getGeneralCardAccount: params => {
        return API.POST('api/refundAccount/generalCardAccount', params)
    },
    /**   退款时效卡存量列表 */
    getTimeCardAccount: params => {
        return API.POST('api/refundAccount/timeCardAccount', params)
    },
    /**  退款套餐卡存量列表 */
    getPackageCardAccount: params => {
        return API.POST('api/refundAccount/packageCardAccount', params)
    },
    /**  退款套餐卡存量明细 */
    getPackageCardAccountDetails: params => {
        return API.POST('api/refundAccount/packageCardAccountDetails', params)
    },
    /**  获取退款存量支付方式 */
    getPay: params => {
        return API.POST('api/refundBill/pay', params)
    },
    /**  退款申请 */
    createApply: params => {
        return API.POST('api/refundBill/create', params)
    },
    /**  退款申请列表 */
    getRefundBillList: params => {
        return API.POST('api/refundBill/list', params)
    },
    /**  退款申请单详情 */
    getRefundBillInfo: params => {
        return API.POST('api/refundBill/info', params)
    },
    /**  退款审批列表 */
    approvalList: params => {
        return API.POST('api/refundBill/approvalList', params)
    },
    /**  退款审批撤销 */
    revoke: params => {
        return API.POST('api/refundBill/revoke', params)
    },
    /**  退款审批撤*/
    approval: params => {
        return API.POST('api/refundBill/approval', params)
    },
    // 获取 全部 支付方式
    getSalePayMethod: params => {
      return API.POST('api/salePayMethod/all', params)
    },
}

<!--  顾客详情 预约记录 -->
<template>
  <div v-loading="loading" class="appointmentRecord">
    <el-form :inline="true" size="small" label-width="auto" @keyup.enter.native="handleSearch">
      <el-form-item label="预约类型:">
        <el-select placeholder="请选择订单类型" clearable v-model="searchData.BillStatus" style="width:150px;" @change="handleSearch" @clear="handleSearch">
          <el-option label="未到店" value="10"></el-option>
          <el-option label="已到店" value="20"></el-option>
          <el-option label="已完成" value="30"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预约时间:">
        <el-date-picker type="daterange" v-model="searchData.createTime" unlink-panels range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" clearable @change="handleSearch" @clear="handleSearch"></el-date-picker>
      </el-form-item>
      <el-form-item label="接待人:">
        <el-input placeholder="请输入姓名" v-model="searchData.Name" clearable size="small" @clear="handleSearch"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch" v-prevent-click size="small">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table size="mini" height="300" :data="AppointmentRecordList">
      <el-table-column label="接待人" prop="EmployeeName"></el-table-column>
      <el-table-column label="预约时间" prop="AppointmentDate"></el-table-column>
      <el-table-column label="状态" prop="Status">
        <template slot-scope="scope">{{scope.row.Status ==10?'未到店':scope.row.Status ==20?'已到店':'已完成'}}</template>
      </el-table-column>
      <el-table-column label="预约门店" prop="EntityName"></el-table-column>
      <el-table-column label="操作" width="90" align="center" prop="BillStatus">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="getAppointmentDetail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pad_15 text_right">
      <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
    </div>
    <!-- 详情弹窗 -->
    <el-dialog title="预约详情" custom-class="customerRecordClass" :visible.sync="dialogVisible" width="1000px" append-to-body>
      <div class="tip marbm_10" style="margin-top:0">预约信息</div>
      <el-form label-width="100px" size="small">
        <el-row>
          <el-col :span="8">
            <el-form-item label="订单编号:">{{AppointmentRecordDetail.ID}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预约门店:">{{AppointmentRecordDetail.EntityName}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="到店时间:">{{AppointmentRecordDetail.CompletionTime}}</el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="预约时间:">{{AppointmentRecordDetail.AppointmentDate}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预约时长:">{{AppointmentRecordDetail.Period}}分钟</el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="预约状态:">{{AppointmentRecordDetail.Status ==10?'未到店':AppointmentRecordDetail.Status ==20?'已到店':'已完成'}}</el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="备注:">{{AppointmentRecordDetail.Remark}}</el-form-item>
          </el-col>
        </el-row>
        <div class="tip marbm_10" style="margin-top:0">预约内容</div>
        <el-row>
          <el-col :span="24">
            <!-- label="预约内容和接待人:" -->
            <el-form-item label-width="0">
              <el-table size="mini" height="300" :data="AppointmentRecordDetail.Project">
                <el-table-column label="预约内容" prop="ProjectName"></el-table-column>
                <el-table-column label="接待人" prop="EmployeeName"></el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>


<script>
import API from "@/api/CRM/Customer/appointmentRecord";
export default {
  props: {
    customerID: Number,
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      position: "left",
      searchData: {
        createTime: "",
        EndDate: "",
        StartDate: "",
        BillType: "",
        Name: "",
        AppointmentBillID: "",
        BillStatus: "",
      },
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      AppointmentRecordList: [],
      AppointmentRecordDetail: "",
    };
  },
  methods: {
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.getAppointmentRecordList();
    },
    handleSearch() {
      let that = this;
      that.paginations.page = 1;
      that.getAppointmentRecordList();
    },
    getAppointmentRecordList() {
      var that = this;
      that.loading = true;
      if (
        that.searchData.createTime == null ||
        that.searchData.createTime == ""
      ) {
        that.StartDate = "";
        that.EndDate = "";
      } else {
        that.StartDate = that.searchData.createTime[0];
        that.EndDate = that.searchData.createTime[1];
      }

      var params = {
        PageNum: that.paginations.page,
        CustomerID: that.customerID,
        StartDate: that.StartDate,
        EndDate: that.EndDate,
        Status: that.searchData.BillStatus,
        EmployeeName: that.searchData.Name,
      };
      API.getAppointmentRecordList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.AppointmentRecordList = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    getAppointmentDetail(row) {
      var that = this;
      var params = {
        ID: row.ID,
      };
      API.getAppoinementRecordDetail(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.AppointmentRecordDetail = res.Data;
            that.AppointmentRecordDetail.Project.forEach((item) => {
              item.EmployeeName = row.EmployeeName;
            });
            that.dialogVisible = true;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**    */
    clearAppoinmentRecordData() {
      let that = this;
      that.AppointmentRecordList = [];
    },
  },
  mounted() {
    var that = this;
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    that.searchData.createTime = [new Date(y, m, 1), new Date(y, m + 1, 0)];
  },
};
</script>


<style lang="scss">
.customerRecordClass {
  .el-form-item__label {
    font-size: 13px !important;
  }
  .el-form-item__content {
    font-size: 13px !important;
  }
  .el-form-item {
    margin-bottom: 0px;
  }
}
</style>
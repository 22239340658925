<template>
  <div v-loading="loading" class="CustomerNursingLog">
    <el-scrollbar
      style="height: 460px"
      v-scrollbar_loadmore="scrollbar_loadmore"
    >
      <div class="PlanCare" v-for="item in DataList" :key="item.ID">
        <div class="heard">
          <el-row type="flex" justify="space-between" align="middle">
            <el-col :span="12">
              <div class="f-w color_000">{{item.PlanName}}</div>
              <el-row class="font_12 color_666 dis_flex">
                <el-col :span="8">时间：{{item.StartAndEndDate}}</el-col>
                <el-col :span="8">创建人：{{item.EmployeeName}}</el-col>
              </el-row>
            </el-col>
            <el-col :span="12" class="text_right">
              <el-button
                size="mini"
                type="text"
                style="padding: 0px"
                @click="edit(item)"
                >编辑</el-button
              >
            </el-col>
          </el-row>
        </div>
        <el-form label-width="80px" size="small" v-for="Stage in item.planDetailedOutForms" :key="item.ID+'-'+Stage.Stage">
          <el-form-item label="阶段：" class="color_000">第 {{Stage.Stage}} 阶段</el-form-item>
          <el-form-item label="时间：" class="color_000"
            >{{Stage.StartDate.replace(/\-/g,'.')}} - {{Stage.EndDate.replace(/\-/g,'.')}}</el-form-item
          >
          <el-form-item label="内容：" class="color_000">
            <div class="line_26">
              {{Stage.Remark}}
            </div>
          </el-form-item>
          <el-form-item label="图片：">
            <el-upload
              action="#"
              list-type="picture-card"
              :before-upload="file=>{return beforeAvatarUpload(file,item.ID,Stage.Stage)}"
              :file-list="Stage.imageOutForms"
            >
              <!-- :file-list="fileList" -->
              <i
                slot="default"
                class="el-icon-camera-solid"
                style="font-size: 40px; color: #999"
              ></i>
              <div style="width: 120px; height: 170px" slot="file" slot-scope="{ file }">
                <el-image
                  class="el-upload-list__item-thumbnail"
                  style="width: 120px; height: 120px"
                  :src="file.url"
                  :id="file.uid"
                  :preview-src-list="Stage.images"
                  :z-index="9999"
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file,item.ID,Stage.Stage)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
                <div class="text_center font_12 color_000">{{file.CreatedOn}}</div>
                <div class="dis_flex font_14 color_000 flex_x_between">顾客端展示 <el-switch v-model="file.IsShow" @change="bool=>{return CahngeShow(bool,file)}"></el-switch></div>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
    </el-scrollbar>
    <!-- 添加减重计划 -->
    <el-dialog
      append-to-body
      :title="isAddNur ? '添加减重计划' : '编辑减重计划'"
      :visible.sync="NurVisible"
      width="1200px"
    >
      <div style="height: 50vh">
        <el-scrollbar class="el-scrollbar_height">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="90px"
            size="small"
          >
            <el-row>
              <el-col>
                <el-form-item label="计划名称" prop="PlanName" class="PlanName">
                  <el-input
                    placeholder="请填写计划名称"
                    v-model="ruleForm.PlanName"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-for="(item, index) in ruleForm.planDetailedOutForms" :key="index">
              <el-col :span="24" class="dis_flex flex_x_between">
                <div class="color_000 mar_15_0">第 {{ index + 1 }} 阶段</div>
                <el-button
                  type="text"
                  @click="deleteMount(index)"
                  v-show="index == ruleForm.planDetailedOutForms.length - 1 && index>0"
                  >删除</el-button
                >
              </el-col>
              <!-- <div style="border:1px solid red"> -->
              <el-col :span="24" class="moment">
                <el-form-item label="阶段时间">
                  <el-date-picker
                    v-model="item.dataTime"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    @focus ="PickerFocus(ruleForm.planDetailedOutForms,index)"
                    @change ="PickerChange(ruleForm.planDetailedOutForms,index)"
                    :disabled="TimeRule(item.dataTime)"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="阶段内容">
                  <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="请输入内容"
                    v-model="item.Remark"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <!-- </div> -->
            </el-row>
          </el-form>
        </el-scrollbar>
      </div>

      <el-button
        type="primary"
        plain
        size="small"
        class="addMount"
        @click="addMount"
        >添加阶段</el-button
      >
      <div slot="footer">
        <el-button size="small" @click="NurVisible = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="submitCustomer"
          v-prevent-click
          >保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/CRM/Customer/NurseHealth";
import utils from '@/components/js/utils'
var Enumerable = require("linq");
export default {
  props: ["customerID"],
  name: "NurseHealth",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      isAddNur: false,
      NurVisible: false,
      loading: true,
      storage: "",
      currentEmpID: "",
      fileList: [],
      //新增数据
      ruleForm: {
        PlanName: "",
        planDetailedOutForms: [
          {
            dataTime: [],
            Remark: "",
          },
        ],
      },
      //计划展示
      DataList: [],
      rules: {
        PlanName: [
          { required: true, message: "请输入计划名称", trigger: "blur" },
        ],
      },
      //时间选择器配置
      pickerOptions:{
        disabledDate(time){
            return time.getTime() < Date.now() - 8.64e7;
            
        }
      },

    
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    PickerFocus(row,index){
      this.pickerOptions = {
        disabledDate(time){
          var rules = time.getTime() < Date.now() - 8.64e7;
          if (index > 0) {
            if (row[index-1].dataTime[1]) {
              rules = time.getTime() < new Date(row[index-1].dataTime[1]).getTime();
            }
          }
            return rules
        }
      }
    },
    PickerChange(row,index){
      this.ruleForm.planDetailedOutForms.forEach((item,idx)=>{
        if (idx > index) {
           item.dataTime = [];
        }
      })
    },
    // 计划时间选择规则
    TimeRule(time){
      var bool = false;
      if (time && time.length > 0) {
        bool = new Date(time[1].replace(/\-/g,"/")).getTime() < Date.now()- 8.64e7;
        }
      return bool;
    },
    // 添加阶段
    addMount() {
      this.ruleForm.planDetailedOutForms.push({ dataTime: [], Remark: "" });
    },
    deleteMount(index) {
      this.ruleForm.planDetailedOutForms.splice(index, 1);
    },
    /**    */
    scrollbar_loadmore() {
      let that = this;
      //   if (that.nursingTotal > that.nursingLostList.length) {
      //     that.PageNum += 1;
      //     that.getNursingLogList();
      //   }
    },
    /** 获取顾客护理日志列表   */
    getNurseHealth() {
      let that = this;
      that.loading = true;
      API.customerReduceWeightPlanList({ CustomerID: this.customerID }).then(
        (res) => {
          that.loading = false;
          if (res.StateCode == 200) {
            res.Data.forEach(item=>{
              item.planDetailedOutForms.forEach(Stage=>{
                Stage.images = Stage.imageOutForms.map(url=>{
                  return url.Image;
                })
                Stage.imageOutForms.forEach(image=>{
                  image.url = image.Image;
                })
              })
            })
            that.DataList = res.Data;
          } else {
            that.$message.error(res.Message);
          }
        }
      );
    },
    //上传图片钩子函数
    beforeAvatarUpload(file,ID,Stage) {
      let that = this;
      let isFileType = false;
      if (
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      ) {
        isFileType = true;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isFileType) {
        return false;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (evt) {
        let base64 = evt.target.result;
        that.updateCustomerUploadImage(base64,ID,Stage);
      };
      return false;
    },
    //上传图片
    updateCustomerUploadImage(base64,PlanID,Stage) {
      let that = this;
      let params = {
        ID:PlanID,
        Stage,
        Image: base64,
      };
      API.addImage(params)
        .then((res) => {
          if (res.StateCode == 200) {
           const {CreatedOn,ID,Image,IsShow,PlanID,Stage} = res.Data;
           const index = that.DataList.findIndex(item=>{return item.ID==PlanID});
           let self = that.DataList[index].planDetailedOutForms[Stage-1];
           self.imageOutForms.push({
        url: Image,
        ID,
        CreatedOn,
        IsShow,
        PlanID,
        Stage
      })
            self.images.push(Image);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {});
    },
    //设置图片显示状态
    CahngeShow(value,row){
      var params = {
        ID:row.ID,
        IsShow:value
      }
      API.updateShow(params).then(res=>{
        if (res.StateCode != 200) {
          this.$message.error(res.Message);
          row.IsShow = !value;
        }
      })
    },
    // 删除图片
    handleRemove(file,PlanID,StageID) {
      var that = this;
      var params ={
        ID:file.ID,
        Image:file.url
      }
 const index = that.DataList.findIndex(item=>{return item.ID==PlanID});
           let slef = that.DataList[index].planDetailedOutForms[StageID-1];
          let deleIndex = slef.imageOutForms.findIndex(image=>{return image.ID == file.ID});
          this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           slef.imageOutForms.splice(deleIndex,1);
           slef.images.splice(deleIndex,1);
      API.deleteImage(params).then(res=>{
        if (res.StateCode != 200){
          that.$message.error(res.Message)
        }
      })
        }).catch();
         
    },
    handlePictureCardPreview(file) {
      document.getElementById(file.uid).click();
    },
    //保存减重计划
    submitCustomer() {
      let that = this;
      this.$refs["ruleForm"].validate((valid)=>{
        if (valid) {
           var params = {};
            //阶段计划
      var plan = that.ruleForm.planDetailedOutForms.map((item, index) => {
          return {
            StartDate: item.dataTime[0],
            EndDate: item.dataTime[1],
            Remark: item.Remark,
            Stage: index + 1,
          };
        });
      params.PlanName = that.ruleForm.PlanName;
      if (that.isAddNur) {
        params.CustomerID = that.customerID;
        params.customerReduceWeightPlanDetailedAddForms = plan;
        this.AddPlan(params);
        }else{
          params.ID = that.ruleForm.ID;
          params.customerReduceWeightPlanDetailedUpdateForms = plan;
          this.EditPlan(params);
          }
        }else{
          return false;
        }
      })
     
    },
    AddPlan(params){
      API.addCustomerReduceWeightPlan(params).then((res) => {
        if (res.StateCode == 200) {
          this.$message.success("添加成功!");
          this.NurVisible = false;
          this.DataList = [];
          this.getNurseHealth();
        } else {
          this.$message.error(res.Message);
        }
      });
    },
    EditPlan(params){
      API.updateCustomerReduceWeightPlan(params).then((res) => {
        if (res.StateCode == 200) {
          this.$message.success("修改成功!");
          this.NurVisible = false;
          this.DataList = [];
          this.getNurseHealth();
        } else {
          this.$message.error(res.Message);
        }
      });
    },
    // 编辑减重计划弹窗
    edit(item) {
      var row = utils.deepClone(item); //对原数据进行深拷，防止显示错乱
      row.planDetailedOutForms.forEach(item=>{
      item.dataTime = [item.StartDate,item.EndDate]
      })
      this.ruleForm = Object.assign({},row);
      this.isAddNur = false;
      this.NurVisible = true;
    },
  },
  /** 监听数据变化   */
  watch: {
    NurVisible(n,o){
      if (!n) {
        this.$refs["ruleForm"].clearValidate();
      }
    }
  },
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    this.storage = JSON.parse(localStorage.getItem("access-user"));
    this.currentEmpID = this.storage.EmployeeID;
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {
    //   console.log("组件激活");
  },
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
.CustomerNursingLog {
  margin-bottom: 20px;
  .videoDialog {
    .el-dialog__body {
      padding: unset !important;
    }
    .el-dialog__header {
      padding: unset;
    }
  }

  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .PlanCare {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border: 1px #eeeeee solid;
    // min-height: 300px;
    .heard {
      background-color: #fafafa;
      border-bottom: 1px #eeeeee solid;
      padding: 10px 20px;
      box-sizing: border-box;
      margin-bottom: 10px;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }
  .f-w {
    font-weight: bold;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 120px;
    height: 120px;
    overflow: unset;
    position: relative;
    border: unset;
  }
  .el-upload.el-upload--picture-card {
    width: 120px !important;
    height: 120px !important;
    line-height: 136px;
  }
}
.moment {
  border: 1px solid #eee;
  padding: 20px 10px;
  box-sizing: border-box;
}
.PlanName {
  margin-bottom: 10px !important;
}
.addMount {
  margin-top: 20px;
  width: 100%;
}
</style>

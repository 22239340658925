/*
 * @Author: mikey.zhaopeng 
 * @Date: 2020-07-31 11:00:34 
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: yyyy-08-Tu 05:39:05
 */

import * as API from '@/api/index'

export default{
     //退消耗审批列表
     getRefundTreatApproveList: params =>{
    return API.POST('api/refundTreatBill/approvalList',params)
    },
    //退消耗申请单详情
    getTreatApplyDetail:params =>{
        return API.POST('api/refundTreatBill/info',params)
    },
    //退消耗审批
    refundTreatBillApprove:params =>{
        return API.POST('api/refundTreatBill/approval',params)
    }
}
<template>
  <div v-loading="loading" class="CustomerBill">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="销售订单" name="1">
        <el-form :inline="true" size="small" :label-position="position" label-width="auto" @keyup.enter.native="handleSearch">
          <el-form-item label="订单类型:">
            <el-select placeholder="请选择订单类型" clearable v-model="searchData.BillType" @change="handleSearch">
              <el-option label="销售单" value="10"></el-option>
              <el-option label="销售退款单" value="20"></el-option>
              <el-option label="补欠款单" value="30"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单状态:">
            <el-select placeholder="请选择订单状态" clearable v-model="searchData.BillStatus" @change="handleSearch">
              <el-option label="待付款" value="10"></el-option>
              <el-option label="已完成" value="20"></el-option>
              <el-option label="已取消" value="30"></el-option>
            </el-select>
          </el-form-item>

          <!-- <el-form-item>
            <el-button type="primary" @click="handleSearch" v-prevent-click size="small">搜索</el-button>
          </el-form-item> -->
        </el-form>
        <el-table size="mini" :data="saleOrderList" height="300">
          <el-table-column label="订单编号" prop="ID"></el-table-column>
          <el-table-column label="订单金额" prop="Amount">
            <template slot-scope="scope">￥{{scope.row.Amount | NumFormat}}</template>
          </el-table-column>
          <el-table-column label="订单类型">
            <template slot-scope="scope">{{scope.row.BillType == 10?"销售单":scope.row.BillType == 20?"销售退款单" :'补欠款单'}}</template>
          </el-table-column>
          <el-table-column label="订单状态" prop="BillStatus">
            <template slot-scope="scope">{{scope.row.BillStatus == 10?"待付款":scope.row.BillStatus == 20?"已完成" :'已取消'}}</template>
          </el-table-column>
          <el-table-column label="开单门店" prop='EntityName'></el-table-column>
          <el-table-column label="收银员" prop="EmployeeName"></el-table-column>
          <el-table-column label="录单时间" prop="CreatedOn"></el-table-column>
          <el-table-column label="下单时间" prop="BillDate"></el-table-column>
          <el-table-column label="操作" width="80px">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="getOrderDetail(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pad_15 text_right">
          <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="消耗订单" name="2">
        <el-form :inline="true" size="small" :label-position="position" label-width="80px" @keyup.enter.native="handleSearch">
          <!-- <el-form-item label="订单编号:">
            <el-input placeholder="输入订单编号" v-model="searchData.SaleBillID" clearable size="small" @clear="handleSearch"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="订单时间:">
            <el-date-picker type="daterange" v-model="searchData.createTime" range-separator="至" :picker-options="pickerOptions" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" clearable @change="handleSearch"></el-date-picker>
          </el-form-item> -->
          <el-form-item label="订单类型:">
            <el-select placeholder="请选择订单类型" clearable v-model="searchData.BillType" @change="handleSearch">
              <el-option label="消耗单" value="10"></el-option>
              <el-option label="消耗退单" value="20"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单状态:">
            <el-select placeholder="请选择订单状态" clearable v-model="searchData.BillStatus" @change="handleSearch">
              <el-option label="已完成" value="20"></el-option>
              <el-option label="已取消" value="30"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
            <el-button type="primary" @click="handleSearch" v-prevent-click size="small">搜索</el-button>
          </el-form-item> -->
        </el-form>
        <el-table size="mini" :data="consumeList" height="300">
          <el-table-column label="订单编号" prop="ID"></el-table-column>
          <el-table-column label="消耗金额" prop="Amount">
            <template slot-scope="scope">￥{{scope.row.Amount | NumFormat}}</template>
          </el-table-column>
          <el-table-column label="订单类型" prop="BillType">
            <template slot-scope="scope">{{scope.row.BillType == 10?"消耗单":"消耗退单"}}</template>
          </el-table-column>
          <el-table-column label="订单状态" prop="BillStatus">
            <template slot-scope="scope">{{scope.row.BillStatus == 20?"已完成":'已取消'}}</template>
          </el-table-column>
          <el-table-column label="开单门店" prop="EntityName"></el-table-column>
          <!-- <el-table-column label="收银员" prop="EmployeeName"></el-table-column> -->
          <el-table-column label="录单时间" prop="CreatedOn"></el-table-column>
          <el-table-column label="订单时间" prop="BillDate"></el-table-column>
          <el-table-column label="操作" width="90" align="center" prop="BillStatus">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="getTreatListDetail(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pad_15 text_right">
          <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="转账记录" name="5">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" size="small" :label-position="position" label-width="80px" @keyup.enter.native="handleSearch">
              <el-form-item label="订单编号:">
                <el-input clearable size="small" placeholder="输入订单编号" v-model="searchData.SaleBillID" @clear="handleSearch"></el-input>
              </el-form-item>
              <el-form-item label="申请时间:">
                <el-date-picker type="daterange" v-model="searchData.createTime" range-separator="至" :picker-options="pickerOptions" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" clearable @change="handleSearch"></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleSearch" v-prevent-click size="small">搜索</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-table size="small" :data="TransferList" height="300">
          <el-table-column label="订单编号" prop="ID"></el-table-column>
          <el-table-column label="转出顾客" prop="TransferOutCustomerName"></el-table-column>
          <el-table-column label="转入顾客" prop="TransferInCustomerName"></el-table-column>
          <el-table-column label="操作门店" prop="EntityName"></el-table-column>
          <el-table-column label="操作人" prop="CreatedByName"></el-table-column>
          <el-table-column label="订单时间" prop="CreatedOn"></el-table-column>
          <el-table-column label="操作" width="90" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="transerInfo(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pad_15 text_right">
          <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="延期记录" name="6">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" size="small" :label-position="position" label-width="80px" @keyup.enter.native="handleSearch">
              <el-form-item label="订单编号:">
                <el-input clearable size="small" placeholder="输入订单编号" v-model="searchData.SaleBillID" @clear="handleSearch"></el-input>
              </el-form-item>
              <el-form-item label="申请时间:">
                <el-date-picker type="daterange" v-model="searchData.createTime" range-separator="至" value-format="yyyy-MM-dd" :picker-options="pickerOptions" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSearch" clearable></el-date-picker>
              </el-form-item>
              <el-form-item label="订单状态:">
                <el-select placeholder="请选择订单状态" clearable v-model="searchData.BillStatus" @change="handleSearch">
                  <el-option label="草稿" value="10"></el-option>
                  <el-option label="待审批" value="20"></el-option>
                  <el-option label="已同意" value="30"></el-option>
                  <el-option label="已拒绝" value="40"></el-option>
                  <el-option label="已撤销" value="50"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleSearch" v-prevent-click size='small'>搜索</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-table size="small" :data="PostponeBillList" height="300">
          <el-table-column label="订单编号" prop="ID"></el-table-column>
          <el-table-column label="状态" prop="ApprovalStatus">
            <template slot-scope="scope">{{scope.row.ApprovalStatus == 10?"草稿":scope.row.ApprovalStatus == 20?"待审批":scope.row.ApprovalStatus == 30?"已同意":scope.row.ApprovalStatus == 40?"已驳回":'已撤销'}}</template>
          </el-table-column>
          <el-table-column label="申请门店" prop="EntityName"></el-table-column>
          <el-table-column label="申请人" prop="CreatedByName"></el-table-column>
          <el-table-column label="订单时间" prop="CreatedOn"></el-table-column>
          <el-table-column label="操作" width="90" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="showApplyDetailDialog(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pad_15 text_right">
          <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 销售订单详情 -->
    <el-dialog custom-class="customerBillClass" title="销售订单详情" :visible.sync="SaleListVisible" width="1100px" append-to-body>
      <div class="font_13" style="height:60vh;">
        <el-scrollbar class="customerBillScrollbar">
          <SaleBillDetailContent :saleOrderDetail="saleOrderDetail"></SaleBillDetailContent>
        </el-scrollbar>
      </div>
    </el-dialog>
    <!-- 销售订单 补欠款 详情 -->
    <el-dialog custom-class="customerBillClass" title="补欠款订单详情" :visible.sync="arreardialogVisible" width="1100px" append-to-body>
      <div class="font_13" style="height:60vh;">
        <el-scrollbar class="customerBillScrollbar">
          <ArrearDetailContent :saleOrderDetail="saleOrderDetail"></ArrearDetailContent>
        </el-scrollbar>
      </div>
    </el-dialog>
    <!-- 销售订单 退款 订单详情 -->
    <el-dialog custom-class="customerBillClass" title="退款订单详情" :visible.sync="refundDialogVisible" width="1100px" append-to-body>
      <div class="font_13" style="height:60vh;">
        <el-scrollbar class="customerBillScrollbar">
          <RefundDetailContent :saleOrderDetail="saleOrderDetail"></RefundDetailContent>
        </el-scrollbar>
      </div>
    </el-dialog>

    <!-- 消耗订单 -->
    <el-dialog custom-class="customerBillClass" title="消耗单详情" width="1100px" :visible.sync="TreatBillDetailVisible" append-to-body>
      <div style="height: 60vh" class="font_13">
        <el-scrollbar class="customerBillScrollbar">
          <TreatBillDetailContent :treatInfo="treatInfo"></TreatBillDetailContent>
        </el-scrollbar>
      </div>
    </el-dialog>

    <!--  退 消耗订单 -->
    <el-dialog custom-class="customerBillClass" title="消耗单详情" width="1100px" :visible.sync="RefundTreatBillDetailVisible" append-to-body>
      <div style="height: 60vh" class="font_13">
        <el-scrollbar class="customerBillScrollbar">
          <TreatRefundDetailContent :treatInfo="treatInfo"></TreatRefundDetailContent>
        </el-scrollbar>
      </div>
    </el-dialog>
    <!-- 退存量详情 -->
    <el-dialog width="1100px" custom-class="customerBillClass" title="退存量详情" :visible.sync="dialogVisible" append-to-body>
      <div style="height:60vh" class="font_13">
        <el-scrollbar class="customerBillScrollbar">

          <!-- 订单信息 -->
          <div style="margin-right: 10px">
            <div class="tip" style="margin-top: 0">订单信息</div>
            <el-form label-width="100px" size="small" class="customBillInfo">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="订单编号:">{{
                    refoundInfo.ID
                  }}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="订单金额:">￥{{ refoundInfo.TotalAmount | NumFormat }}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="订单状态:">{{
                    refoundInfo.ApprovalStatusName
                  }}</el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="顾客信息:">{{ refoundInfo.CustomerName }} ({{
                      refoundInfo.PhoneNumber
                    }})
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="申请人:">{{
                    refoundInfo.CreatedByName
                  }}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="提交时间:">{{
                    refoundInfo.BillDate
                  }}</el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="现金退款:">￥{{ refoundInfo.PayAmount | NumFormat }}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="卡抵扣退款:">￥{{ refoundInfo.CardDeductionAmount | NumFormat }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="退款时间:">
                    {{refoundInfo.BillDate}}
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="24">
                  <el-form-item label="退款说明:">
                    {{refoundInfo.RefundRemark}}
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row v-if="refoundInfo.ApprovalStatus == '30'">
                <el-col :span="8">
                  <el-form-item label="审批时间:">{{
                    refoundInfo.ApprovalOn
                  }}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="审批人:">{{
                    refoundInfo.ApprovalByName
                  }}</el-form-item>
                </el-col>
                <!-- <el-col :span="8">
                  <el-form-item label="销售单号:">{{
                    refoundInfo.SaleBillID
                  }}</el-form-item>
                </el-col> -->
              </el-row>
              <el-row v-if="refoundInfo.ApprovalStatus == '30'">
                <el-col :span="24">
                  <el-form-item label="审批说明:">
                    <span v-if="refoundInfo.ApprovalRemark" class="marrt_5">{{
                      refoundInfo.ApprovalRemark
                    }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="tip">消费信息</div>
          <div class="border_left border_right border_top">
            <!-- 项目 -->
            <div v-if="
                refoundInfo.Project != undefined &&
                refoundInfo.Project.length > 0
              ">
              <el-row class="row_header">
                <el-col :span="6">项目</el-col>
                <el-col :span="6">数量</el-col>
                <el-col :span="6">金额</el-col>
                <el-col :span="6">退款</el-col>
              </el-row>
              <el-row v-for="(item, index) in refoundInfo.Project" :key="index + 'x1'" class="text_left">
                <el-col :span="24" class="pad_10 border_bottom">
                  <el-col :span="6">
                    <div>
                      {{ item.Name }}({{ item.Alias }})
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠
                      </el-tag>
                    </div>
                    <div class="color_red martp_5 font_12" v-if="item.Price">
                      ￥{{ item.Price | NumFormat }}
                    </div>
                  </el-col>
                  <el-col :span="6">x {{item.Quantity}}</el-col>
                  <el-col :span="6">¥ {{ item.TotalAmount | NumFormat }}</el-col>
                  <el-col :span="6">
                    <div>¥ {{ item.TotalAmount | NumFormat }}</div>
                    <div v-if="item.PricePreferentialAmount" class="font_12 color_gray">手动改价: -{{item.PricePreferentialAmount | NumFormat}}</div>
                  </el-col>
                </el-col>

                <el-col :span="24" v-if="item.RefundBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                  <el-row v-for="(handler, pIndex) in item.RefundBillHandler" :key="pIndex + 'h5'">
                    <el-col :span="2">
                      <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                        <el-form-item :label="`${handler.RefundHandlerName}`"></el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="22">
                      <el-form class="saleHandler" :inline="true" size="mini">
                        <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <!-- 产品 -->
            <div v-if="
                refoundInfo.Product != undefined &&
                refoundInfo.Product.length > 0
              ">
              <el-row class="row_header">
                <el-col :span="6">产品</el-col>
                <el-col :span="6">数量</el-col>
                <el-col :span="6">金额</el-col>
                <el-col :span="6">退款</el-col>
              </el-row>
              <el-row v-for="(item, index) in refoundInfo.Product" :key="index + 'x2'" class="text_left">
                <el-col :span="24" class="pad_10 border_bottom border_top">
                  <el-col :span="6">
                    <div>
                      {{ item.Name }}({{ item.Alias }})
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠
                      </el-tag>
                    </div>
                    <div class="color_red font_12 martp_5" v-if="item.Price">
                      ￥{{ item.Price | NumFormat  }}
                    </div>
                  </el-col>
                  <el-col :span="6">x {{ item.Quantity }}</el-col>
                  <el-col :span="6">¥{{ item.TotalAmount | NumFormat }} </el-col>
                  <el-col :span="6">
                    <div>¥ {{ item.TotalAmount | NumFormat }} </div>
                    <div v-if="item.PricePreferentialAmount" class="font_12 color_gray">手动改价: -{{item.PricePreferentialAmount | NumFormat}}</div>
                  </el-col>
                </el-col>
                <el-col :span="24" v-if="item.RefundBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                  <el-row v-for="(handler, pIndex) in item.RefundBillHandler" :key="pIndex + 'h5'">
                    <el-col :span="2">
                      <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                        <el-form-item :label="`${handler.RefundHandlerName}`"></el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="22">
                      <el-form class="saleHandler" :inline="true" size="mini">
                        <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <!-- 通用次卡 -->
            <div v-if="
                refoundInfo.GeneralCard != undefined &&
                refoundInfo.GeneralCard.length > 0
              ">
              <el-row class="row_header">
                <el-col :span="6">通用次卡</el-col>
                <el-col :span="6">数量</el-col>
                <el-col :span="6">金额</el-col>
                <el-col :span="6">退款</el-col>
              </el-row>
              <el-row v-for="(item, index) in refoundInfo.GeneralCard" :key="index + '333'" class="text_left">
                <el-col :span="24" class="pad_10 border_bottom">
                  <el-col :span="6">
                    <div>
                      {{ item.Name }}({{ item.Alias }})
                      <el-tag v-if="item.IsLargess" type="danger" size="mini" class="marlt_10">赠
                      </el-tag>
                    </div>
                    <div class="color_red font_12 martp_5" v-if="item.Price">
                      ￥{{ item.Price| NumFormat  }}
                    </div>
                  </el-col>
                  <el-col :span="6">x {{ item.Quantity }}</el-col>
                  <el-col :span="6">¥{{ item.TotalAmount | NumFormat }} </el-col>
                  <el-col :span="6">
                    <div>¥ {{ item.TotalAmount | NumFormat }} </div>
                    <div v-if="item.PricePreferentialAmount" class="font_12 color_gray">手动改价: -{{item.PricePreferentialAmount | NumFormat}}</div>
                  </el-col>
                </el-col>
                <el-col :span="24" v-if="item.RefundBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                  <el-row v-for="(handler, pIndex) in item.RefundBillHandler" :key="pIndex + 'h5'">
                    <el-col :span="2">
                      <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                        <el-form-item :label="`${handler.RefundHandlerName}`"></el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="22">
                      <el-form class="saleHandler" :inline="true" size="mini">
                        <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <!-- 时效卡 -->
            <div v-if="
                refoundInfo.TimeCard != undefined &&
                refoundInfo.TimeCard.length > 0
              ">
              <el-row class="row_header">
                <el-col :span="12">时效卡</el-col>
                <el-col :span="6">金额</el-col>
                <el-col :span="6">退款</el-col>
              </el-row>
              <el-row v-for="(item, index) in refoundInfo.TimeCard" :key="index + 'x44'">
                <el-col :span="24" class="border_bottom pad_10">
                  <el-col :span="12">
                    <div>
                      {{ item.Name }}({{ item.Alias }})
                      <el-tag v-if="item.IsLargess" type="danger" size="mini" class="marlt_10">赠
                      </el-tag>
                    </div>
                    <div class="font_12 color_red martp_5" v-if="item.Price">
                      ￥{{ item.Price | NumFormat }}
                    </div>
                  </el-col>
                  <el-col :span="6">¥ {{ item.TotalAmount | NumFormat }} </el-col>
                  <el-col :span="6">
                    <div>¥ {{ item.TotalAmount | NumFormat }} </div>
                    <div v-if="item.PricePreferentialAmount" class="font_12 color_gray">手动改价: -{{item.PricePreferentialAmount | NumFormat}}</div>
                  </el-col>
                </el-col>
                <el-col :span="24" v-if="item.RefundBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                  <el-row v-for="(handler, pIndex) in item.RefundBillHandler" :key="pIndex + 'h5'">
                    <el-col :span="2">
                      <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                        <el-form-item :label="`${handler.RefundHandlerName}`"></el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="22">
                      <el-form class="saleHandler" :inline="true" size="mini">
                        <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <!-- 储值卡 -->
            <div v-if="
                refoundInfo.SavingCard != undefined &&
                refoundInfo.SavingCard.length > 0
              ">
              <el-row class="row_header">
                <el-col :span="12">储值卡</el-col>
                <el-col :span="6">赠送</el-col>
                <el-col :span="6">退款</el-col>
              </el-row>
              <el-row v-for="(item, index) in refoundInfo.SavingCard" :key="index + 'x45'" class="text_left">
                <el-col :span="24" class="border_bottom pad_10">
                  <el-col :span="12">
                    <div>
                      {{ item.Name }}
                      <span v-if="item.Alias">({{ item.Alias }})</span>
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠
                      </el-tag>
                    </div>
                    <!-- <div class="color_red martp_5" v-if="item.LargessAmount">
                      ￥{{ item.LargessAmount | NumFormat}} -->
                    <!-- </div> -->
                  </el-col>
                  <el-col :span="6">￥{{ item.LargessAmount | NumFormat}}</el-col>
                  <el-col :span="6">￥{{ item.Amount | NumFormat}}</el-col>
                </el-col>
                <el-col :span="24" v-if="item.RefundBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                  <el-row v-for="(handler, pIndex) in item.RefundBillHandler" :key="pIndex + 'h5'">
                    <el-col :span="2">
                      <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                        <el-form-item :label="`${handler.RefundHandlerName}`"></el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="22">
                      <el-form class="saleHandler" :inline="true" size="mini">
                        <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>

            <!--套餐卡-->
            <div v-if=" refoundInfo.PackageCard != undefined && refoundInfo.PackageCard.length > 0 ">
              <!-- <el-row class="row_header">
                <el-col :span="12">套餐卡</el-col>
                <el-col :span="6">数量</el-col>
                <el-col :span="6">金额/赠额</el-col>
              </el-row> -->

              <el-row v-for="(item, index) in refoundInfo.PackageCard" :key="index + 'x6'">
                <el-row class="pad_10 border_bottom tipback_col">
                  <el-col :span="12">【套餐卡】{{ item.Name
                    }}<span v-if="item.Alias">({{ item.Alias }})</span>
                  </el-col>
                  <el-col :span="6">数量</el-col>
                  <el-col :span="6">金额/赠额</el-col>
                </el-row>
                <!-- 项目 -->
                <el-row v-for="(childItem, childIndex) in item.Project" :key="childIndex + 'c6-1'" class="border_bottom">
                  <el-col :span="24" class="pad_10">
                    <el-col :span="12">
                      <div>
                        {{ childItem.Name }}
                        <span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                        <el-tag v-if="childItem.IsLargess" type="danger" size="mini" class="marlt_10">赠
                        </el-tag>
                        <el-tag size="mini" class="marlt_10">项目</el-tag>
                      </div>
                      <div class="color_red martp_5 font_12">
                        ￥{{ childItem.Price }}
                      </div>
                    </el-col>
                    <el-col :span="6">x {{ childItem.Quantity }}</el-col>
                    <el-col :span="6">
                      <div>¥{{ childItem.TotalAmount | NumFormat }}</div>
                      <div v-if="childItem.PricePreferentialAmount" class="font_12 color_gray">手动改价: -{{childItem.PricePreferentialAmount | NumFormat}}</div>
                    </el-col>
                  </el-col>
                </el-row>

                <!-- 产品 -->
                <el-row v-for="(childItem, childIndex) in item.Product" :key="childIndex + 'c6-2'" class="border_bottom">
                  <el-col :span="24" class="pad_10">
                    <el-col :span="12">
                      <div>
                        {{ childItem.ProductName
                        }}<span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                        <el-tag v-if="childItem.IsLargess" type="danger" size="mini" class="marlt_10">赠
                        </el-tag>
                        <el-tag size="mini" class="marlt_10">产品</el-tag>
                      </div>
                      <div class="color_red martp_5 font_12">
                        ￥{{ childItem.Price }}
                      </div>
                    </el-col>
                    <el-col :span="6">x {{ childItem.Quantity }}</el-col>
                    <el-col :span="6">

                      <div>¥ {{ childItem.TotalAmount | NumFormat }}</div>
                      <div v-if="childItem.PricePreferentialAmount" class="font_12 color_gray">手动改价: -{{childItem.PricePreferentialAmount | NumFormat}}</div>
                    </el-col>
                  </el-col>
                </el-row>

                <!-- 通用次卡 -->
                <el-row v-for="(childItem, childIndex) in item.GeneralCard" :key="childIndex + 'c6-3'" class="border_bottom">
                  <el-col :span="24" class="pad_10">
                    <el-col :span="12">
                      <div>
                        {{ childItem.Name
                        }}<span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                        <el-tag v-if="childItem.IsLargess" type="danger" size="mini" class="marlt_10">赠
                        </el-tag>
                        <el-tag size="mini" class="marlt_10">通用次卡</el-tag>
                      </div>
                      <div class="color_red martp_5 font_12">
                        ￥{{ childItem.Price | NumFormat  }}
                      </div>
                    </el-col>
                    <el-col :span="6">x {{ childItem.Quantity }}</el-col>
                    <el-col :span="6">
                      <div>¥ {{ childItem.TotalAmount | NumFormat }}</div>
                      <div v-if="childItem.PricePreferentialAmount" class="font_12 color_gray">手动改价: -{{childItem.PricePreferentialAmount | NumFormat}}</div>
                    </el-col>
                  </el-col>
                </el-row>

                <!-- 时效卡 -->
                <el-row v-for="(childItem, childIndex) in item.TimeCard" :key="childIndex + 'c6-4'" class="border_bottom">
                  <el-col :span="24" class="pad_10">
                    <el-col :span="12">
                      <div>
                        {{ childItem.Name
                        }}<span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                        <el-tag v-if="childItem.IsLargess" type="danger" size="mini" class="marlt_10">赠
                        </el-tag>
                        <el-tag size="mini" class="marlt_10">时效卡</el-tag>
                      </div>
                      <div class="color_red martp_5 font_12">
                        ￥{{ childItem.Price | NumFormat  }}
                      </div>
                    </el-col>
                    <el-col :span="6">x 1</el-col>
                    <el-col :span="6">

                      <div>¥ {{ childItem.TotalAmount | NumFormat }}</div>
                      <div v-if="childItem.PricePreferentialAmount" class="font_12 color_gray">手动改价: -{{childItem.PricePreferentialAmount | NumFormat}}</div>
                    </el-col>
                  </el-col>
                </el-row>

                <!--  储值卡 -->
                <el-row v-for="(childItem, childIndex) in item.SavingCard" :key="childIndex + 'c6-5'" class="border_bottom">
                  <el-col :span="24" class="pad_10">
                    <el-col :span="12">
                      <div>
                        {{ childItem.Name
                        }}<span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                        <el-tag v-if="childItem.IsLargess" type="danger" size="mini" class="marlt_10">赠
                        </el-tag>
                        <el-tag size="mini" class="marlt_10">储值卡</el-tag>
                      </div>
                      <div class="color_red martp_5 font_12" v-if="childItem.Price">
                        ￥{{ childItem.Price }}
                      </div>
                    </el-col>
                    <el-col :span="6">x 1</el-col>
                    <el-col :span="6">
                      <div>非赠送：¥ {{ childItem.Amount | NumFormat }}</div>
                      <div v-if="childItem.LargessAmount">赠送：¥ {{childItem.LargessAmount | NumFormat}}</div>
                    </el-col>
                  </el-col>
                </el-row>

                <el-col :span="24" v-if="item.RefundBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                  <el-row v-for="(handler, pIndex) in item.RefundBillHandler" :key="pIndex + 'h5'">
                    <el-col :span="2">
                      <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                        <el-form-item :label="`${handler.RefundHandlerName}`"></el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="22">
                      <el-form class="saleHandler" :inline="true" size="mini">
                        <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>

          <div class="border_left border_right border_bottom">
            <el-row>
              <el-col :span="6" :offset="17">
                <el-form class="customBillInfo" size="mini">
                  <el-form-item label="合计退款金额：">
                    <div class="text_right">
                      ￥{{ refoundInfo.TotalAmount | NumFormat }}
                    </div>
                  </el-form-item>
                  <el-form-item label="现金退款：">
                    <div class="text_right">
                      ￥{{ Math.abs(refoundInfo.PayAmount) | NumFormat }}
                    </div>
                  </el-form-item>
                  <el-form-item label="卡抵扣退款：">
                    <div class="text_right">
                      ￥{{
                        Math.abs(refoundInfo.CardDeductionAmount) | NumFormat
                      }}
                    </div>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </div>

          <div class="payInfo">
            <div class="tip martp_10 marbm_10">退款信息</div>
            <el-card class="marbm_10" shadow="never" v-if="
                refoundInfo.PayMethod != undefined &&
                refoundInfo.PayMethod.length > 0
              ">
              <div slot="header">
                <span>支付收款</span>
              </div>
              <el-form class="customBillInfo" :inline="true" size="mini" label-position="top">
                <el-form-item v-for="(item, index) in refoundInfo.PayMethod" :key="index" :label="item.Name">¥ {{ item.Amount | NumFormat }}</el-form-item>
              </el-form>
            </el-card>
            <el-card shadow="never" v-if="
                refoundInfo.CardDeductionAmount != undefined &&
                refoundInfo.SavingCardDeduction.length > 0
              ">
              <div slot="header">
                <span>储值卡抵扣</span>
              </div>
              <el-form class="customBillInfo" :inline="true" size="mini" label-position="top">
                <el-form-item v-for="(item, index) in refoundInfo.SavingCardDeduction" :key="index" :label="item.Name">¥ {{ item.TotalAmount | NumFormat }}</el-form-item>
              </el-form>
            </el-card>
          </div>

        </el-scrollbar>
      </div>
    </el-dialog>

    <!-- 转账记录详情    -->
    <!-- 详情 -->
    <el-dialog custom-class="customerBillClass" title="转账订单详情" :visible.sync="applyPostponeDetailVisible" width="1100px" append-to-body>
      <!-- //订单详情弹窗 -->
      <div style="height:60vh" class="font_13">
        <el-scrollbar class="customerBillScrollbar">
          <div style="margin-right:10px" class="marbm_10">
            <el-form label-width="120px" size="small" class="customBillInfo">
              <div class="tip" style="margin-top:0">订单信息</div>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="订单编号:">{{transDetail.ID}}</el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="操作门店:">{{transDetail.EntityName}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="操作人:">{{transDetail.CreatedByName}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="操作时间:">{{transDetail.CreatedOn}}</el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="转出顾客:">
                    {{transDetail.TransferOutCustomerName}}
                    <span>({{transDetail.TransferOutCustomerPhoneNumber}})</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="转入顾客:">
                    {{transDetail.TransferInCustomerName}}
                    <span>({{transDetail.TransferInCustomerPhoneNumber}})</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="订单备注:">
                    <span class="marrt_5">{{transDetail.Remark}}</span>
                    <!-- <el-button type="text" @click="remarkVisible = true">修改备注</el-button> -->
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="tip">消费信息</div>

          <div class="border_right border_left border_top marbm_10">
            <el-row class="row_header" v-if="transDetail.Project != undefined && transDetail.Project.length>0">
              <el-col :span="12">项目</el-col>
              <el-col :span="12">转出余量</el-col>
            </el-row>
            <el-row class="border_bottom" v-for="item in transDetail.Project" :key="item.ProjectAccountID">
              <el-col :span="24" class="pad_10 ">
                <el-col :span="24">
                  <el-col :span="12">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="12" v-if="item.Quantity">x{{item.Quantity}}</el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_header" v-if="transDetail.PackageCard!= undefined && transDetail.PackageCard.length>0">
              <el-col :span="12">套餐卡</el-col>
              <el-col :span="12">转出余量</el-col>
            </el-row>
            <el-row class="" v-for="item in transDetail.PackageCard" :key="item.PackageCardAccountID">

              <el-row class="pad_10 border_bottom">
                <el-col :span="12">
                  {{item.Name}}
                  <span v-if="item.Alias">({{item.Alias}})</span>
                </el-col>
                <el-col :span="12" v-if="item.Quantity">x{{item.Quantity}}</el-col>
              </el-row>
              <el-row v-for="(packList, index) in item.Project" :key="index+'listPRO'" class="padlt_15  border_bottom pad_10 font_12">
                <el-col :span="12" class="martp_5">
                  {{packList.Name}}
                  <span v-if="packList.Alias">({{packList.Alias}})</span>
                  <el-tag size="mini" class="marlt_10 font_12" effect="plain">项目</el-tag>
                </el-col>
                <el-col :span="12" class="martp_5">x{{packList.Quantity}}</el-col>
              </el-row>
              <el-row v-for="(packList, index) in item.Product" :key="index+'listduc'" class="padlt_15  border_bottom pad_10 font_12">
                <el-col :span="12" class="martp_5">
                  {{packList.Name}}
                  <span v-if="packList.Alias">({{packList.Alias}})</span>
                  <el-tag size="mini" class="marlt_10 font_12" effect="plain">产品</el-tag>
                </el-col>
                <el-col :span="12" class="martp_5">x{{packList.Quantity}}</el-col>
              </el-row>
              <el-row v-for="(packList, index) in item.GeneralCard" :key="index+'listgen'" class="padlt_15  border_bottom pad_10 font_12">
                <el-col :span="12" class="martp_5">
                  {{packList.Name}}
                  <span v-if="packList.Alias">({{packList.Alias}})</span>
                  <el-tag size="mini" class="marlt_10 font_12" effect="plain">通用次卡</el-tag>
                </el-col>
                <el-col :span="12" class="martp_5">x{{packList.Quantity}}</el-col>
              </el-row>
              <el-row v-for="(packList, index) in item.TimeCard" :key="index+'listtime'" class="padlt_15 border_bottom pad_10 font_12">
                <el-col :span="12" class="martp_5">
                  {{packList.Name}}
                  <span v-if="packList.Alias">({{packList.Alias}})</span>
                  <el-tag size="mini" class="marlt_10 font_12" effect="plain">时效卡</el-tag>
                </el-col>
                <el-col :span="12"></el-col>
              </el-row>
              <el-row v-for="(packList, index) in item.SavingCard" :key="index+'listsave'" class="padlt_15 border_bottom pad_10 font_12">
                <el-col :span="12" class="martp_12">
                  {{packList.Name}}
                  <span v-if="packList.Alias">({{packList.Alias}})</span>
                  <el-tag size="mini" class="marlt_10 font_12" effect="plain">储值卡</el-tag>
                </el-col>
                <el-col :span="12" class="martp_5">
                  <div>本金：￥{{packList.Amount |NumFormat}}</div>
                  <div>赠金：￥{{packList.LargessAmount | NumFormat}}</div>
                </el-col>
              </el-row>
            </el-row>
            <el-row class="row_header" v-if="transDetail.SavingCard!= undefined && transDetail.SavingCard.length>0">
              <el-col :span="12">储值卡</el-col>
              <el-col :span="12">转出余量</el-col>
            </el-row>
            <el-row class="border_bottom" v-for="item in transDetail.SavingCard" :key="item.SavingCardAccountID">
              <el-col :span="24" class="pad_10 ">
                <el-col :span="24">
                  <el-col :span="12" class="martp_10">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="12">
                    <div>本金：￥{{item.Amount | NumFormat}}</div>
                    <div>赠金：￥{{item.LargessAmount | NumFormat}}</div>
                  </el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_header" v-if="transDetail.Product!=undefined && transDetail.Product.length >0">
              <el-col :span="12">产品</el-col>
              <el-col :span="12">转出余量</el-col>
            </el-row>
            <el-row class="border_bottom" v-for="item in transDetail.Product" :key="item.ProductAccountID">
              <el-col :span="24" class="pad_10 ">
                <el-col :span="24">
                  <el-col :span="12">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="12" v-if="item.Quantity">x{{item.Quantity}}</el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_header" v-if="transDetail.GeneralCard != undefined && transDetail.GeneralCard.length>0">
              <el-col :span="12">通用次卡</el-col>
              <el-col :span="12">转出余量</el-col>
            </el-row>
            <el-row v-for="item in transDetail.GeneralCard" :key="item.GeneralCardAccountID">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="12">
                  {{item.Name}}
                  <span v-if="item.Alias">({{item.Alias}})</span>
                </el-col>
                <el-col :span="12" v-if="item.Quantity">x{{item.Quantity}}</el-col>
              </el-col>
            </el-row>

            <el-row class="row_header" v-if="transDetail.TimeCard !=undefined && transDetail.TimeCard.length>0">
              <el-col :span="12">时效卡</el-col>
              <el-col :span="12">转出余量</el-col>
            </el-row>
            <el-row class="border_bottom" v-for="item in transDetail.TimeCard" :key="item.TimeCardAccountID">
              <el-col :span="24" class="pad_10 ">
                <el-col :span="24">
                  <el-col :span="12">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="12" v-if="item.Quantity">x{{item.Quantity}}</el-col>
                </el-col>
              </el-col>
            </el-row>
          </div>
        </el-scrollbar>
      </div>
    </el-dialog>

    <!-- 延期审批记录 -->
    <!-- 详情 -->
    <el-dialog custom-class="customerBillClass" title="延期订单详情" :visible.sync="applyDetailVisibles" width="1100px" center append-to-body>
      <!-- //订单详情弹窗 -->
      <div style="height:60vh;font-size:13px !important">
        <el-scrollbar class="customerBillScrollbar">
          <div style="margin-right:10px" class="marbm_10">
            <el-form label-width="120px" size="small" class="customBillInfo">
              <div class="tip" style="margin-top:0">订单信息</div>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="订单编号:">{{ApplyDetail.ID}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="订单状态:">{{ApplyDetail.ApprovalStatus == 10?"草稿":ApplyDetail.ApprovalStatus == 20?"待审批" :ApplyDetail.ApprovalStatus == 30?"已同意":ApplyDetail.ApprovalStatus ==40 ?"已驳回":'已撤销'}}</el-form-item>
                </el-col>
                <el-col :span="8">
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="顾客信息:">{{ApplyDetail.CustomerName}} ({{ApplyDetail.PhoneNumber}})</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="申请人:">{{ApplyDetail.CreatedByName}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="提交时间:">{{ApplyDetail.CreatedOn}}</el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="延期说明:">
                    <span class="marrt_5">{{ApplyDetail.PostponeRemark}}</span>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row v-if="IsShow">
                <el-col :span="8">
                  <el-form-item label="审批时间:">{{ApplyDetail.ApprovalOn}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="审批人:">{{ApplyDetail.ApprovalByName}}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="审批说明:">
                    <span class="marrt_5">{{ApplyDetail.ApprovalRemark}}</span>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="tip">消费信息</div>

          <div class="border_left border_right border_top marbm_10">
            <el-row class="row_header" v-if="ApplyDetail.SavingCard!=undefined && ApplyDetail.SavingCard.length >0">
              <el-col :span="8">储值卡</el-col>
              <el-col :span="8">原失效日期</el-col>
              <el-col :span="8">新失效日期</el-col>
            </el-row>
            <el-row class="border_bottom" v-for="item in ApplyDetail.SavingCard" :key="item.SavingCardAccountID">
              <el-col :span="24" class="pad_10 ">
                <el-col :span="24">
                  <el-col :span="8">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="8">{{item.ValidDayName}}</el-col>
                  <el-col :span="8">{{item.NewValidDayName}}</el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_header" v-if="ApplyDetail.GeneralCard!=undefined && ApplyDetail.GeneralCard.length>0">
              <el-col :span="8">通用次卡</el-col>
              <el-col :span="8">原失效日期</el-col>
              <el-col :span="8">新失效日期</el-col>
            </el-row>
            <el-row v-for="item in ApplyDetail.GeneralCard" :key="item.GeneralCardAccountID">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="8">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="8">{{item.ValidDayName}}</el-col>
                  <el-col :span="8">{{item.NewValidDayName}}</el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_header" v-if="ApplyDetail.TimeCard!=undefined && ApplyDetail.TimeCard.length >0">
              <el-col :span="8">时效卡</el-col>
              <el-col :span="8">原失效日期</el-col>
              <el-col :span="8">新失效日期</el-col>
            </el-row>
            <el-row v-for="item in ApplyDetail.TimeCard" :key="item.TimeCardAccountID">
              <el-col :span="24" class="pad_10 border_bottom">
                <el-col :span="24">
                  <el-col :span="8">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="8">{{item.ValidDayName}}</el-col>
                  <el-col :span="8">{{item.NewValidDayName}}</el-col>
                </el-col>
              </el-col>
            </el-row>

            <el-row class="row_header" v-if="ApplyDetail.PackageCard!= undefined && ApplyDetail.PackageCard.length>0">
              <el-col :span="8">套餐卡</el-col>
              <el-col :span="8">原失效日期</el-col>
              <el-col :span="8">新失效日期</el-col>
            </el-row>
            <el-row class="border_bottom" v-for="item in ApplyDetail.PackageCard" :key="item.PackageCardAccountID">
              <el-col :span="24" class="pad_10 ">
                <el-col :span="24">
                  <el-col :span="8">
                    {{item.Name}}
                    <span v-if="item.Alias">({{item.Alias}})</span>
                  </el-col>
                  <el-col :span="8">{{item.ValidDayName}}</el-col>
                  <el-col :span="8">{{item.NewValidDayName}}</el-col>
                </el-col>
              </el-col>
            </el-row>
          </div>
        </el-scrollbar>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import API from "@/api/iBeauty/Order/saleBill";
import APIcustomerBill from "@/api/CRM/Customer/customerBill";
import APItreatBill from "@/api/iBeauty/Order/treatBill";
import APIrefundTreatBillApprove from "@/api/iBeauty/RefundBill/refundTreatBillApprove";
import APIrefundStock from "@/api/iBeauty/RefundBill/refundStock";
import APIrefundTreatBillApply from "@/api/iBeauty/RefundBill/refundTreatBillApply";
import APItransfer from "@/api/iBeauty/PostponeTransfer/transfer";
import APIPostponeApproval from "@/api/iBeauty/PostponeTransfer/PostponeApproval";

import SaleBillDetailContent from "@/views/iBeauty/Components/saleBillDetailContent";
import ArrearDetailContent from "@/views/iBeauty/Components/arrearDetailContent";
import RefundDetailContent from "@/views/iBeauty/Components/refundDetailContent";
import TreatBillDetailContent from "@/views/iBeauty/Components/treatBillDetailContent";
import TreatRefundDetailContent from "@/views/iBeauty/Components/treatRefundDetailContent";

var Enumerable = require("linq");
export default {
  props: {
    customerID: Number,
  },

  components: {
    SaleBillDetailContent,
    ArrearDetailContent,
    RefundDetailContent,
    TreatBillDetailContent,
    TreatRefundDetailContent,
  },
  name: "customerBill",
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      TreatBillVisibles: false,
      activeName: "1",
      position: "left",
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      loading: false,
      handlerName: "",
      tabHandle: "0",
      Remark: "",
      searchData: {
        createTime: "",
        EndDate: "",
        StartDate: "",
        BillType: "",
        Name: "",
        SaleBillID: "",
        BillStatus: "",
      },
      saleOrderList: [], //订单列表
      saleOrderDetail: {},
      backMoneyTable: [],
      SaleListVisible: false, // 销售订单详情
      refundDialogVisible: false, // 退款订单详情
      arreardialogVisible: false, // 补欠款订单详情

      innerVisible: false, //备注弹窗
      innerVisible1: false, //备注弹窗
      backMoneyVisible: false, //还款记录弹窗
      backMoney: false, //退款弹窗
      cancelOrder: false, //取消订单
      changePerformance: false, //修改员工业绩弹窗
      beauticianVisible: false, //美容师经手人弹窗
      showPackageDetail: false, //显示套餐卡详情
      CardVisble: false,
      PerformanceInfo: null, //获取员工业绩和佣金信息
      ProjectEmployee: [],
      projectHandlerList: [], //项目经手人列表
      savingCardHandlerList: [], //储蓄卡经手人列表
      timeCardHandlerList: [],
      generalCardHandlerList: [],
      packageCardHandlerList: [],
      productHandlerList: [],
      handlerList: [], //公共经手人列表
      type: 1,
      SaleHandler: [],
      IsPackageCard: "",
      /** 修改是使用的属性字段  */
      currentItem: {}, //当前修改业绩的品项
      currentItemId: "", //计算佣金时使用
      currentItemType: 0, //计算佣金时使用 商品类型，1：产品 2：项目 3：次卡 4：时效卡 5：储值卡 \
      // 退消耗
      IsShow: true,
      IsCheck: false,
      ApproveBillDetail: "",
      TreatBillVisible: false,
      approvalVisible: false,
      ApprovalRemark: "",
      BillID: "",
      ApproveStatus: "", //审批条件
      consumeList: [],
      treatInfo: "",
      TreatBillDetailVisible: false,
      RefundTreatBillDetailVisible: false,
      //退存量
      loading: false,
      dialogVisible: false,
      cancelOrder: false,
      dialogBill: false,
      refoundList: [],
      refoundInfo: "",
      dialogParams: {
        ApprovalStatus: 30,
        ApprovalRemark: "",
      },
      rules: {
        ApprovalStatus: [
          { required: true, message: "请选择有效性", trigger: "blur" },
        ],
      },

      //退消耗
      TreatBillVisible: false,
      withdrawDialog: false,
      IsShow: true,
      IsCheck: false,
      loading: false,
      TreatList: [],
      ApplyBillId: "",
      Name: "",
      StartDate: "",
      EndDate: "",
      ApprovalStatus: "",
      ApplyBillDetail: "",
      TreatBillApplyID: "",

      //转账记录
      transDetail: "",
      applyPostponeDetailVisible: false,
      TransferList: [],
      //延期审批
      IsShow: true,
      IsDraft: false,
      IsApprove: false,
      Approve: 50,
      applyDetailVisibles: false,
      approvalVisible: false,
      ApplyDetail: "",
      PostponeBillList: [],
    };
  },
  methods: {
    handleClick() {
      var that = this;
      var tabIndex = that.activeName;
      that.paginations.page = 1;
      switch (tabIndex) {
        case "1":
          that.searchSaleBill();
          break;
        case "2":
          that.getTreatBillList();
          break;
        case "3":
          that.getTreatStockList();
          break;
        case "4":
          that.getTreatRefundBillList();
          break;
        case "5":
          that.getTranserList();
          break;
        case "6":
          that.getPostponeList();
          break;
      }
      that.searchData = {
        createTime: "",
        EndDate: "",
        StartDate: "",
        BillType: "",
        Name: "",
        SaleBillID: "",
        BillStatus: "",
      };
    },
    handleSearch: function () {
      let that = this;
      var tabIndex = that.activeName;
      that.paginations.page = 1;
      switch (tabIndex) {
        case "1":
          that.searchSaleBill();
          break;
        case "2":
          that.getTreatBillList();
          break;
        case "3":
          that.getTreatStockList();
          break;
        case "4":
          that.getTreatRefundBillList();
          break;
        case "5":
          that.getTranserList();
          break;
        case "6":
          that.getPostponeList();
          break;
      }
    },
    upRemarkDialog() {
      var that = this;
      that.Remark = that.treatInfo.Remark;
      that.innerVisible1 = true;
    },
    //搜索
    searchSaleBill() {
      var that = this;
      that.loading = true;
      that.activeName = "1";
      if (
        that.searchData.createTime == null ||
        that.searchData.createTime == ""
      ) {
        that.StartDate = "";
        that.EndDate = "";
      } else {
        that.StartDate = that.searchData.createTime[0];
        that.EndDate = that.searchData.createTime[1];
      }

      var params = {
        PageNum: that.paginations.page,
        CustomerID: that.customerID,
        SaleBillID: that.searchData.SaleBillID,
        StartDate: that.StartDate,
        EndDate: that.EndDate,
        BillStatus: that.searchData.BillStatus,
        BillType: that.searchData.BillType,
      };
      APIcustomerBill.CustomerBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.saleOrderList = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //消耗订单列表
    getTreatBillList() {
      var that = this;
      that.loading = true;
      if (
        that.searchData.createTime == null ||
        that.searchData.createTime == ""
      ) {
        that.StartDate = "";
        that.EndDate = "";
      } else {
        that.StartDate = that.searchData.createTime[0];
        that.EndDate = that.searchData.createTime[1];
      }
      var params = {
        PageNum: that.paginations.page,
        CustomerID: that.customerID,
        BillID: that.searchData.SaleBillID,
        StartDate: that.StartDate,
        EndDate: that.EndDate,
        BillStatus: that.searchData.BillStatus,
        BillType: that.searchData.BillType,
      };
      APIcustomerBill.getTreatBillList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.consumeList = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    confrimWithdrawApplyClick() {
      var that = this;
      that.ApprovalRemark = "";
      that.ApproveStatus = "";
      this.approvalVisible = true;
    },
    //退消耗审批
    SaveApproveTreatBill() {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.BillID,
        ApprovalStatus: that.ApproveStatus,
        ApprovalRemark: that.ApprovalRemark,
      };
      APIrefundTreatBillApprove.refundTreatBillApprove(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "提交成功",
              duration: 2000,
            });
            that.approvalVisible = false;
            that.TreatBillVisible = false;
            that.searchBillList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //翻页
    handleCurrentChange(page) {
      var that = this;
      var tabIndex = that.activeName;
      that.paginations.page = page;
      switch (tabIndex) {
        case "1":
          that.searchSaleBill();
          break;
        case "2":
          that.getTreatBillList();
          break;
        case "3":
          that.getTreatStockList();
          break;
        case "4":
          that.getTreatRefundBillList();
          break;
        case "5":
          that.getTranserList();
          break;
        case "6":
          that.getPostponeList();
          break;
      }
    },
    //查看 消耗 详情
    getTreatListDetail(row) {
      var that = this;
      that.BillID = row.ID;
      that.loading = true;
      let params = {
        ID: that.BillID,
      };
      APItreatBill.treatBillinfo(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.treatInfo = res.Data;
            if (that.treatInfo.BillType == 10) {
              that.TreatBillDetailVisible = true;
            }
            if (that.treatInfo.BillType == 20) {
              that.RefundTreatBillDetailVisible = true;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //查看详情
    getOrderDetail(row) {
      var that = this;
      that.SaleBillID = row.ID;
      var params = {
        SaleBillID: row.ID,
      };
      API.getOrderDetail(params).then((res) => {
        if (res.StateCode == 200) {
          that.saleOrderDetail = res.Data;
          that.backMoneyTable = res.Data.RepayBill;
          that.Remark = that.saleOrderDetail.Remark;
          // that.SaleListVisible = true;
          if (that.saleOrderDetail.BillType == 10) {
            // 销售订单详情
            that.SaleListVisible = true;
          } else if (that.saleOrderDetail.BillType == 20) {
            // 销售退单详情
            that.refundDialogVisible = true;
          } else if (that.saleOrderDetail.BillType == 30) {
            // 销售补欠款订单详情
            that.arreardialogVisible = true;
          }
        }
      });
    },
    packDetailClick(item) {
      item.IsShowDetails = !item.IsShowDetails;
    },
    //取消订单
    CancelOrder() {
      var that = this;
      var params = {
        SaleBillID: that.SaleBillID,
      };
      API.cancelOrder(params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success({
            message: "取消成功",
            duration: 2000,
          });
          that.handleSearch();
          that.cancelOrder = false;
          that.dialogVisible = false;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //修改 y员工业绩
    changePerformancecommission() {
      var that = this;
      var params = {
        SaleBillID: that.SaleBillID,
      };
      API.getOrderPerformanceCommission(params).then((res) => {
        if (res.StateCode == 200) {
          that.AllCardList = res.Data;
          that.PerformanceInfo = res.Data;
          /**  项目  */
          that.PerformanceInfo.Project.forEach((item) => {
            if (item.SaleBillHandler.length == 0) {
              that.projectHandlerList.forEach((hand) => {
                item.SaleBillHandler.push({
                  SaleHandlerID: hand.ID,
                  SaleHandlerName: hand.Name,
                  Employee: [],
                });
              });
            }
          });
          /**  产品  */
          that.PerformanceInfo.Product.forEach((item) => {
            if (item.SaleBillHandler.length == 0) {
              that.productHandlerList.forEach((hand) => {
                item.SaleBillHandler.push({
                  SaleHandlerID: hand.ID,
                  SaleHandlerName: hand.Name,
                  Employee: [],
                });
              });
            }
          });
          /**  储值卡  */
          that.PerformanceInfo.SavingCard.forEach((item) => {
            if (item.SaleBillHandler.length == 0) {
              that.savingCardHandlerList.forEach((hand) => {
                item.SaleBillHandler.push({
                  SaleHandlerID: hand.ID,
                  SaleHandlerName: hand.Name,
                  Employee: [],
                });
              });
            }
          });
          /**  时效卡  */
          that.PerformanceInfo.TimeCard.forEach((item) => {
            if (item.SaleBillHandler.length == 0) {
              that.timeCardHandlerList.forEach((hand) => {
                item.SaleBillHandler.push({
                  SaleHandlerID: hand.ID,
                  SaleHandlerName: hand.Name,
                  Employee: [],
                });
              });
            }
          });
          /**  通用次卡  */
          that.PerformanceInfo.GeneralCard.forEach((item) => {
            if (item.SaleBillHandler.length == 0) {
              that.generalCardHandlerList.forEach((hand) => {
                item.SaleBillHandler.push({
                  SaleHandlerID: hand.ID,
                  SaleHandlerName: hand.Name,
                  Employee: [],
                });
              });
            }
          });
          /**  套餐卡  */
          that.PerformanceInfo.PackageCard.forEach((item) => {
            if (item.SaleBillHandler.length == 0) {
              that.packageCardHandlerList.forEach((hand) => {
                item.SaleBillHandler.push({
                  SaleHandlerID: hand.ID,
                  SaleHandlerName: hand.Name,
                  Employee: [],
                });
              });
            }
          });
          that.CardVisble = true;
        }
        that.changePerformance = true;
      });
    },

    //获取项目经手人列表
    getProjectSaleHandlerList() {
      var that = this;
      API.getProjectSaleHandlerList().then((res) => {
        if (res.StateCode == 200) {
          that.projectHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //获取产品经手人列表
    getProductSaleHandlerList() {
      var that = this;
      API.getproductSaleHandlerList().then((res) => {
        if (res.StateCode == 200) {
          that.productHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取储值卡经手人列表
    getSaveCardSaleHandlerList() {
      var that = this;
      API.getSaveSaleHandlerList().then((res) => {
        if (res.StateCode == 200) {
          that.savingCardHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //获取通用次卡经手人列表
    getGenerSaleHandlerList() {
      var that = this;
      API.getGenerSaleHandlerList().then((res) => {
        if (res.StateCode == 200) {
          that.generalCardHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //获取时效卡经手人列表
    getTimeSaleHandlerList() {
      var that = this;
      API.getTimeSaleHandlerList().then((res) => {
        if (res.StateCode == 200) {
          that.timeCardHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //获取套餐卡经手人列表
    getPackageSaleHandlerList() {
      var that = this;
      API.getPackageSaleHandlerList().then((res) => {
        if (res.StateCode == 200) {
          that.packageCardHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //点击判断经手人
    employeeHandleClick(type, item) {
      var that = this;
      var Employee = [];
      switch (type) {
        case 1:
          that.handlerList = that.projectHandlerList;
          that.IsPackageCard = false;
          that.currentItemId = item.ProjectID;
          that.currentItemType = 2;
          break;
        case 2:
          that.handlerList = that.productHandlerList;
          that.IsPackageCard = false;
          that.currentItemId = item.ProductID;
          that.currentItemType = 1;
          break;
        case 3:
          that.handlerList = that.generalCardHandlerList;
          that.IsPackageCard = false;
          that.currentItemId = item.GeneralCardID;
          that.currentItemType = 3;
          break;
        case 4:
          that.handlerList = that.timeCardHandlerList;
          that.IsPackageCard = false;
          that.currentItemId = item.TimeCardID;
          that.currentItemType = 4;
          break;
        case 5:
          that.handlerList = that.savingCardHandlerList;
          that.IsPackageCard = false;
          that.currentItemId = item.SavingCardID;
          that.currentItemType = 5;
          break;
        case 6:
          that.handlerList = that.packageCardHandlerList;
          that.currentItemId = item.PackageCardID;
          that.IsPackageCard = true;
          that.currentItemType = 6;
          break;
      }
      item.SaleBillHandler.forEach(function (hand) {
        hand.Employee.forEach(function (emp) {
          Employee.push({
            ID: hand.SaleHandlerID + "-" + emp.EmployeeID,
            Discount: emp.Scale,
          });
        });
      });
      that.handlerList.forEach(function (handler) {
        handler.Employee.forEach(function (emp) {
          emp.Checked = false;
          emp.Discount = "";
          Employee.forEach(function (i) {
            if (emp.ID == i.ID) {
              emp.Checked = true;
              emp.Discount = i.Discount;
            }
          });
        });
      });

      that.type = type;
      that.currentItem = item;
      that.beauticianVisible = true;
    },
    //删除经手人
    deleteHandler(item, index) {
      var that = this;
      item.Employee.splice(index, 1);
    },
    //经手人选择
    handlerCheckedChange: function (row, item) {
      var that = this;
      var discount = 0;
      var SaleHandlers = [];
      var employee = Enumerable.from(row)
        .where(function (i) {
          return i.Checked;
        })
        .toArray();
      employee.forEach(function (emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
      if (!item.Checked) {
        item.Discount = "";
      } else {
        if (item.Discount == "") {
          if (discount > 100) {
            item.Discount = 0;
          } else {
            item.Discount = 100 - discount;
          }
        }
      }
      that.handlerList.forEach((empList) => {
        empList.Employee.forEach((hand) => {
          if (hand.Checked == true) {
            SaleHandlers.push({
              SaleHandlerID: hand.SaleHandlerID,
              EmployeeID: hand.EmployeeID,
              Scale: hand.Discount,
            });
          }
        });
      });

      that.SaleHandler = SaleHandlers;
    },
    //经手人确认
    submitHandleClick: function () {
      var that = this;
      var SaleHandlers = [];
      that.handlerList.forEach(function (handler) {
        handler.Employee.forEach(function (emp) {
          if (emp.Checked == true) {
            SaleHandlers.push({
              SaleHandlerID: emp.SaleHandlerID,
              EmployeeID: emp.EmployeeID,
              Scale: emp.Discount,
            });
          }
        });
      });

      that.SaleHandler = SaleHandlers;
      that.getsaleBillemployeeCommission();
    },
    // 修改备注
    updateRemarkClick() {
      var that = this;
      that.innerVisible = false;
      that.updateRemark();
    },
    updateRemarkClick1() {
      var that = this;
      that.innerVisible1 = false;
      that.updateRemark1();
    },
    //获取计算佣金
    getsaleBillemployeeCommission() {
      var that = this;
      if (that.currentItem.PayAmount == null) {
        that.currentItem.PayAmount = 0;
      }
      if (that.currentItem.CardDeductionAmount == null) {
        that.currentItem.CardDeductionAmount = 0;
      }
      if (that.currentItem.CardDeductionLargessAmount == null) {
        that.currentItem.CardDeductionLargessAmount = 0;
      }
      var params = {
        GoodsID: that.currentItemId,
        Type: that.currentItemType,
        PayAmount: that.currentItem.PayAmount,
        CardDeductionAmount: that.currentItem.CardDeductionAmount,
        CardDeductionLargessAmount: that.currentItem.CardDeductionLargessAmount,
        IsPackageCard: that.IsPackageCard,
        SaleHandler: that.SaleHandler,
      };
      API.saleBillemployeeCommission(params).then((res) => {
        if (res.StateCode == 200) {
          that.currentItem.SaleBillHandler = res.Data;
          that.beauticianVisible = false;
          if (that.type == 6) {
            that.currentItem.Product.forEach((childItem) => {
              childItem.SaleBillHandler = that.currentItem.SaleBillHandler;
            });
            that.currentItem.Project.forEach((childItem) => {
              childItem.SaleBillHandler = that.currentItem.SaleBillHandler;
            });
            that.currentItem.SavingCard.forEach((childItem) => {
              childItem.SaleBillHandler = that.currentItem.SaleBillHandler;
            });
            that.currentItem.GeneralCard.forEach((childItem) => {
              childItem.SaleBillHandler = that.currentItem.SaleBillHandler;
            });
            that.currentItem.TimeCard.forEach((childItem) => {
              childItem.SaleBillHandler = that.currentItem.SaleBillHandler;
            });
          }
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //修改经手人比例
    changeSaleHandlerRate(item, event, IsSavingCard) {
      var empItem = event.empItem;
      if (IsSavingCard) {
        if (item.TotalAmount == null) {
          empItem.PayPerformance = 0;
          empItem.PayPerformanceRate = 0;
        } else {
          empItem.PayPerformanceRate = empItem.Scale;
          empItem.PayPerformance = (
            (empItem.PayPerformanceRate * item.TotalAmount) /
            100
          ).toFixed(2);
        }
      } else {
        if (item.PayAmount == null) {
          empItem.PayPerformance = 0;
          empItem.PayPerformanceRate = 0;
        } else {
          empItem.PayPerformanceRate = 0;
          empItem.PayPerformance = (
            (empItem.PayPerformanceRate * item.PayAmount) /
            100
          ).toFixed(2);
        }
      }
      empItem.PayRateCommission = (
        (empItem.PayPerformance * empItem.PayRate) /
        100
      ).toFixed(2);

      if (item.CardDeductionAmount == null) {
        empItem.SavingCardPerformanceRate = 0;
        empItem.SavingCardPerformance = 0;
      } else {
        empItem.SavingCardPerformanceRate = empItem.Scale;
        empItem.SavingCardPerformance = (
          (empItem.SavingCardPerformanceRate * item.CardDeductionAmount) /
          100
        ).toFixed(2);
      }
      empItem.SavingCardRateCommission = (
        (empItem.SavingCardPerformance * empItem.SavingCardRate) /
        100
      ).toFixed(2);

      if (item.CardDeductionLargessAmount == null) {
        empItem.SavingCardLargessPerformance = 0;
        empItem.SavingCardLargessPerformanceRate = 0;
      } else {
        empItem.SavingCardLargessPerformanceRate = empItem.Scale;
        empItem.SavingCardLargessPerformance = (
          (empItem.SavingCardLargessPerformanceRate *
            item.CardDeductionLargessAmount) /
          100
        ).toFixed(2);
      }
      empItem.SavingCardLargessRateCommission = (
        (empItem.SavingCardLargessPerformance * empItem.SavingCardLargessRate) /
        100
      ).toFixed(2);
    },

    /**  修改员工业绩提成   */
    changeSaleHandlerPerformance(item, event, IsSavingCard) {
      var empItem = event.empItem;
      var changType = event.empChangeType;
      switch (changType) {
        case "0-0-0": //修改现金业绩
          if (IsSavingCard) {
            if (item.TotalAmount == null) {
              empItem.PayPerformance = 0;
            } else {
              empItem.PayPerformanceRate = (
                (empItem.PayPerformance / item.TotalAmount) *
                100
              ).toFixed(2);
            }
            empItem.PayRateCommission = (
              (empItem.PayPerformance * empItem.PayRate) /
              100
            ).toFixed(2);
          } else {
            if (item.PayAmount == null) {
              empItem.PayPerformance = 0;
            } else {
              empItem.PayPerformanceRate = (
                (empItem.PayPerformance / item.PayAmount) *
                100
              ).toFixed(2);
            }
            empItem.PayRateCommission = (
              (empItem.PayPerformance * empItem.PayRate) /
              100
            ).toFixed(2);
          }
          break;

        case "0-0-1":
          empItem.PayPerformance = (
            (item.PayAmount * empItem.PayPerformanceRate) /
            100
          ).toFixed(2);
          empItem.PayRateCommission = (
            (empItem.PayPerformance * empItem.PayRate) /
            100
          ).toFixed(2);
          break;
        case "0-1-0":
          empItem.PayRate = (
            (empItem.PayRateCommission / empItem.PayPerformance) *
            100
          ).toFixed(2);
          break;
        case "0-1-1":
          empItem.PayRateCommission = (
            (empItem.PayPerformance * empItem.PayRate) /
            100
          ).toFixed(2);
          break;

        case "1-0-0":
          if (item.CardDeductionAmount == null) {
            empItem.SavingCardPerformanceRate = 0;
          } else {
            empItem.SavingCardPerformanceRate = (
              (empItem.SavingCardPerformance / item.CardDeductionAmount) *
              100
            ).toFixed(2);
          }
          empItem.SavingCardRateCommission = (
            (empItem.SavingCardPerformance * empItem.SavingCardRate) /
            100
          ).toFixed(2);
          break;
        case "1-0-1":
          empItem.SavingCardPerformance = (
            (item.CardDeductionAmount * empItem.SavingCardPerformanceRate) /
            100
          ).toFixed(2);
          empItem.SavingCardRateCommission = (
            (empItem.SavingCardPerformance * empItem.SavingCardRate) /
            100
          ).toFixed(2);
          break;
        case "1-1-0":
          empItem.SavingCardRate = (
            (empItem.SavingCardRateCommission / empItem.SavingCardPerformance) *
            100
          ).toFixed(2);
          break;
        case "1-1-1":
          empItem.SavingCardRateCommission = (
            (empItem.SavingCardPerformance * empItem.SavingCardRate) /
            100
          ).toFixed(2);
          break;
        case "2-0-0":
          if (item.CardDeductionLargessAmount == null) {
            empItem.SavingCardLargessPerformanceRate = 0;
          } else {
            empItem.SavingCardLargessPerformanceRate = (
              (empItem.SavingCardLargessPerformance /
                item.CardDeductionLargessAmount) *
              100
            ).toFixed(2);
          }
          empItem.SavingCardLargessRateCommission = (
            (empItem.SavingCardLargessPerformance *
              empItem.SavingCardLargessRate) /
            100
          ).toFixed(2);
          break;
        case "2-0-1":
          empItem.SavingCardLargessPerformance = (
            (item.CardDeductionLargessAmount *
              empItem.SavingCardLargessPerformanceRate) /
            100
          ).toFixed(2);
          empItem.SavingCardLargessRateCommission = (
            (empItem.SavingCardLargessPerformance *
              empItem.SavingCardLargessRate) /
            100
          ).toFixed;
          break;
        case "2-1-0":
          empItem.SavingCardLargessRate = (
            (empItem.SavingCardLargessRateCommission /
              empItem.SavingCardLargessPerformance) *
            100
          ).toFixed(2);
          break;
        case "2-1-1":
          empItem.SavingCardLargessRateCommission = (
            (empItem.SavingCardLargessPerformance *
              empItem.SavingCardLargessRate) /
            100
          ).toFixed(2);
          break;
        default:
          break;
      }
    },

    //修改套餐卡经手人比例
    changeSaleHandlerPkgRate(currentVla, item, empItem, handId) {
      item.Project.forEach((project) => {
        project.SaleBillHandler.forEach((handlerType) => {
          handlerType.Employee.forEach((emp) => {
            if (
              handId == handlerType.SaleHandlerID &&
              empItem.EmployeeID == emp.EmployeeID
            ) {
              emp.Scale = currentVla;
              emp.PayPerformanceRate = currentVla;
              emp.PayPerformance = (
                (emp.PayPerformanceRate * project.PayAmount) /
                100
              ).toFixed(2);
              emp.PayRateCommission = (
                (emp.PayPerformance * emp.PayRate) /
                100
              ).toFixed(2);

              emp.SavingCardPerformance = (
                (emp.SavingCardPerformanceRate * project.CardDeductionAmount) /
                100
              ).toFixed(2);
              emp.SavingCardRateCommission = (
                (emp.SavingCardPerformance * emp.SavingCardRate) /
                100
              ).toFixed(2);

              emp.SavingCardLargessPerformance = (
                (emp.SavingCardLargessPerformanceRate *
                  project.CardDeductionLargessAmount) /
                100
              ).toFixed(2);
              emp.SavingCardLargessRateCommission = (
                (emp.CardDeductionLargessAmount * emp.SavingCardLargessRate) /
                100
              ).toFixed(2);
            }
          });
        });
      });
      item.Product.forEach((project) => {
        project.SaleBillHandler.forEach((handlerType) => {
          handlerType.Employee.forEach((emp) => {
            if (
              handId == handlerType.SaleHandlerID &&
              empItem.EmployeeID == emp.EmployeeID
            ) {
              emp.Scale = currentVla;

              emp.PayPerformanceRate = currentVla;
              emp.PayPerformance = (
                (emp.PayPerformanceRate * project.PayAmount) /
                100
              ).toFixed(2);
              emp.PayRateCommission = (
                (emp.PayPerformance * emp.PayRate) /
                100
              ).toFixed(2);

              emp.SavingCardPerformance = (
                (emp.SavingCardPerformanceRate * project.CardDeductionAmount) /
                100
              ).toFixed(2);
              emp.SavingCardRateCommission = (
                (emp.SavingCardPerformance * emp.SavingCardRate) /
                100
              ).toFixed(2);

              emp.SavingCardLargessPerformance = (
                (emp.SavingCardLargessPerformanceRate *
                  project.CardDeductionLargessAmount) /
                100
              ).toFixed(2);
              emp.SavingCardLargessRateCommission = (
                (emp.CardDeductionLargessAmount * emp.SavingCardLargessRate) /
                100
              ).toFixed(2);
            }
          });
        });
      });

      item.TimeCard.forEach((project) => {
        project.SaleBillHandler.forEach((handlerType) => {
          handlerType.Employee.forEach((emp) => {
            if (
              handId == handlerType.SaleHandlerID &&
              empItem.EmployeeID == emp.EmployeeID
            ) {
              emp.Scale = currentVla;
              emp.PayPerformanceRate = currentVla;
              emp.PayPerformance = (
                (emp.PayPerformanceRate * project.PayAmount) /
                100
              ).toFixed(2);
              emp.PayRateCommission = (
                (emp.PayPerformance * emp.PayRate) /
                100
              ).toFixed(2);

              emp.SavingCardPerformance = (
                (emp.SavingCardPerformanceRate * project.CardDeductionAmount) /
                100
              ).toFixed(2);
              emp.SavingCardRateCommission = (
                (emp.SavingCardPerformance * emp.SavingCardRate) /
                100
              ).toFixed(2);

              emp.SavingCardLargessPerformance = (
                (emp.SavingCardLargessPerformanceRate *
                  project.CardDeductionLargessAmount) /
                100
              ).toFixed(2);
              emp.SavingCardLargessRateCommission = (
                (emp.CardDeductionLargessAmount * emp.SavingCardLargessRate) /
                100
              ).toFixed(2);
            }
          });
        });
      });

      item.GeneralCard.forEach((project) => {
        project.SaleBillHandler.forEach((handlerType) => {
          handlerType.Employee.forEach((emp) => {
            if (
              handId == handlerType.SaleHandlerID &&
              empItem.EmployeeID == emp.EmployeeID
            ) {
              emp.Scale = currentVla;
              emp.PayPerformanceRate = currentVla;
              emp.PayPerformance = (
                (emp.PayPerformanceRate * project.PayAmount) /
                100
              ).toFixed(2);
              emp.PayRateCommission = (
                (emp.PayPerformance * emp.PayRate) /
                100
              ).toFixed(2);

              emp.SavingCardPerformance = (
                (emp.SavingCardPerformanceRate * project.CardDeductionAmount) /
                100
              ).toFixed(2);
              emp.SavingCardRateCommission = (
                (emp.SavingCardPerformance * emp.SavingCardRate) /
                100
              ).toFixed(2);

              emp.SavingCardLargessPerformance = (
                (emp.SavingCardLargessPerformanceRate *
                  project.CardDeductionLargessAmount) /
                100
              ).toFixed(2);
              emp.SavingCardLargessRateCommission = (
                (emp.CardDeductionLargessAmount * emp.SavingCardLargessRate) /
                100
              ).toFixed(2);
            }
          });
        });
      });

      item.SavingCard.forEach((project) => {
        project.SaleBillHandler.forEach((handlerType) => {
          handlerType.Employee.forEach((emp) => {
            if (
              handId == handlerType.SaleHandlerID &&
              empItem.EmployeeID == emp.EmployeeID
            ) {
              emp.Scale = currentVla;
              emp.PayPerformanceRate = currentVla;
              emp.PayPerformance = (
                (emp.PayPerformanceRate * project.TotalAmount) /
                100
              ).toFixed(2);
              emp.PayRateCommission = (
                (emp.PayPerformance * emp.PayRate) /
                100
              ).toFixed(2);
            }
          });
        });
      });
    },

    //修改套餐卡现金业绩
    changePackagePayPerformance(
      item,
      CardIndex,
      SaleHandlerIndex,
      EmployeeIndex,
      scale
    ) {
      var that = this;
      if (item[CardIndex].PayAmount == null) {
        item[CardIndex].SaleBillHandler[SaleHandlerIndex].Employee[
          EmployeeIndex
        ].PayPerformanceRate = 0;
      } else {
        item[CardIndex].SaleBillHandler[SaleHandlerIndex].Employee[
          EmployeeIndex
        ].PayPerformanceRate = (
          (item[CardIndex].SaleBillHandler[SaleHandlerIndex].Employee[
            EmployeeIndex
          ].PayPerformance /
            item[CardIndex].PayAmount) *
          100
        ).toFixed(2);
      }

      item[CardIndex].SaleBillHandler[SaleHandlerIndex].Employee[
        EmployeeIndex
      ].PayRateCommission = (
        (scale *
          item[CardIndex].SaleBillHandler[SaleHandlerIndex].Employee[
            EmployeeIndex
          ].PayRate) /
        100
      ).toFixed(2);
    },
    //获取产品经手人列表
    updateRemark() {
      var that = this;
      var params = {
        SaleBillID: that.SaleBillID,
        Remark: that.Remark,
      };
      API.updateRemark(params).then((res) => {
        if (res.StateCode == 200) {
          that.saleOrderDetail.Remark = that.Remark;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    updateRemark1() {
      var that = this;
      var params = {
        TreatBillID: that.treatInfo.ID,
        Remark: that.Remark,
      };
      APItreatBill.updateRemark(params).then((res) => {
        if (res.StateCode == 200) {
          that.innerVisible = false;
          that.treatInfo.Remark = that.Remark;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    //保存修改业绩
    saveSaleBill() {
      var that = this;
      var params = {
        SaleBillID: that.SaleBillID,
        ProjectEmployee: [],
        ProductEmployee: [],
        SavingCardEmployee: [],
        GeneralCardEmployee: [],
        TimeCardEmployee: [],
        PackageCardEmployee: {
          ProductEmployee: [],
          ProjectEmployee: [],
          SavingCardEmployee: [],
          GeneralCardEmployee: [],
          TimeCardEmployee: [],
        },
      };
      //项目
      that.PerformanceInfo.Project.forEach((card) => {
        card.SaleBillHandler.forEach((hand) => {
          hand.Employee.forEach((emp) => {
            params.ProjectEmployee.push({
              SaleBillProjectID: card.SaleBillProjectID,
              ProjectID: card.ProjectID,
              SaleHandlerID: hand.SaleHandlerID,
              EmployeeID: emp.EmployeeID,
              Scale: emp.Scale,
              PayPerformance: emp.PayPerformance,
              PayRateCommission: emp.PayRateCommission,
              PayFixedCommission: emp.PayFixedCommission,
              SavingCardPerformance: emp.SavingCardPerformance,
              SavingCardRateCommission: emp.SavingCardRateCommission,
              SavingCardFixedCommission: emp.SavingCardFixedCommission,
              SavingCardLargessPerformance: emp.SavingCardLargessPerformance,
              SavingCardLargessRateCommission:
                emp.SavingCardLargessRateCommission,
              SavingCardLargessFixedCommission:
                emp.SavingCardLargessFixedCommission,
              SpecialBenefitCommission: emp.SpecialBenefitCommission,
            });
          });
        });
      });
      //产品
      that.PerformanceInfo.Product.forEach((card) => {
        card.SaleBillHandler.forEach((hand) => {
          hand.Employee.forEach((emp) => {
            params.ProductEmployee.push({
              SaleBillProductID: card.SaleBillProductID,
              ProductID: card.ProductID,
              SaleHandlerID: hand.SaleHandlerID,
              EmployeeID: emp.EmployeeID,
              Scale: emp.Scale,
              PayPerformance: emp.PayPerformance,
              PayRateCommission: emp.PayRateCommission,
              PayFixedCommission: emp.PayFixedCommission,
              SavingCardPerformance: emp.SavingCardPerformance,
              SavingCardRateCommission: emp.SavingCardRateCommission,
              SavingCardFixedCommission: emp.SavingCardFixedCommission,
              SavingCardLargessPerformance: emp.SavingCardLargessPerformance,
              SavingCardLargessRateCommission:
                emp.SavingCardLargessRateCommission,
              SavingCardLargessFixedCommission:
                emp.SavingCardLargessFixedCommission,
              SpecialBenefitCommission: emp.SpecialBenefitCommission,
            });
          });
        });
      });
      //储值卡
      that.PerformanceInfo.SavingCard.forEach((card) => {
        card.SaleBillHandler.forEach((hand) => {
          hand.Employee.forEach((emp) => {
            params.SavingCardEmployee.push({
              SaleBillSavingCardID: card.SaleBillSavingCardID,
              SavingCardID: card.SavingCardID,
              SaleHandlerID: hand.SaleHandlerID,
              EmployeeID: emp.EmployeeID,
              Scale: emp.Scale,
              PayPerformance: emp.PayPerformance,
              PayRateCommission: emp.PayRateCommission,
              PayFixedCommission: emp.PayFixedCommission,
              SpecialBenefitCommission: emp.SpecialBenefitCommission,
            });
          });
        });
      });
      //通用次卡
      that.PerformanceInfo.GeneralCard.forEach((card) => {
        card.SaleBillHandler.forEach((hand) => {
          hand.Employee.forEach((emp) => {
            params.GeneralCardEmployee.push({
              SaleBillGeneralCardID: card.SaleBillGeneralCardID,
              GeneralCardID: card.GeneralCardID,
              SaleHandlerID: hand.SaleHandlerID,
              EmployeeID: emp.EmployeeID,
              Scale: emp.Scale,
              PayPerformance: emp.PayPerformance,
              PayRateCommission: emp.PayRateCommission,
              PayFixedCommission: emp.PayFixedCommission,
              SavingCardPerformance: emp.SavingCardPerformance,
              SavingCardRateCommission: emp.SavingCardRateCommission,
              SavingCardFixedCommission: emp.SavingCardFixedCommission,
              SavingCardLargessPerformance: emp.SavingCardLargessPerformance,
              SavingCardLargessRateCommission:
                emp.SavingCardLargessRateCommission,
              SavingCardLargessFixedCommission:
                emp.SavingCardLargessFixedCommission,
              SpecialBenefitCommission: emp.SpecialBenefitCommission,
            });
          });
        });
      });
      //时效卡
      that.PerformanceInfo.TimeCard.forEach((card) => {
        card.SaleBillHandler.forEach((hand) => {
          hand.Employee.forEach((emp) => {
            params.TimeCardEmployee.push({
              SaleBillTimeCardID: card.SaleBillTimeCardID,
              TimeCardID: card.TimeCardID,
              SaleHandlerID: hand.SaleHandlerID,
              EmployeeID: emp.EmployeeID,
              Scale: emp.Scale,
              PayPerformance: emp.PayPerformance,
              PayRateCommission: emp.PayRateCommission,
              PayFixedCommission: emp.PayFixedCommission,
              SavingCardPerformance: emp.SavingCardPerformance,
              SavingCardRateCommission: emp.SavingCardRateCommission,
              SavingCardFixedCommission: emp.SavingCardFixedCommission,
              SavingCardLargessPerformance: emp.SavingCardLargessPerformance,
              SavingCardLargessRateCommission:
                emp.SavingCardLargessRateCommission,
              SavingCardLargessFixedCommission:
                emp.SavingCardLargessFixedCommission,
              SpecialBenefitCommission: emp.SpecialBenefitCommission,
            });
          });
        });
      });
      //套餐卡
      that.PerformanceInfo.PackageCard.forEach((PackageCard) => {
        PackageCard.Product.forEach((card) => {
          card.SaleBillHandler.forEach((hand) => {
            hand.Employee.forEach((emp) => {
              params.PackageCardEmployee.ProductEmployee.push({
                SaleBillPackageCardID: PackageCard.SaleBillPackageCardID,
                PackageCardID: PackageCard.PackageCardID,
                SaleBillPackageCardProductID: card.SaleBillPackageCardProductID,
                ProductID: card.ProductID,
                SaleHandlerID: hand.SaleHandlerID,
                EmployeeID: emp.EmployeeID,
                Scale: emp.Scale,
                PayPerformance: parseFloat(emp.PayPerformance) || 0,
                PayRateCommission: parseFloat(emp.PayRateCommission) || 0,
                PayFixedCommission: parseFloat(emp.PayFixedCommission) || 0,
                SavingCardPerformance:
                  parseFloat(emp.SavingCardPerformance) || 0,
                SavingCardRateCommission:
                  parseFloat(emp.SavingCardRateCommission) || 0,
                SavingCardFixedCommission:
                  parseFloat(emp.SavingCardFixedCommission) || 0,
                SavingCardLargessPerformance:
                  parseFloat(emp.SavingCardLargessPerformance) || 0,
                SavingCardLargessRateCommission:
                  parseFloat(emp.SavingCardLargessRateCommission) || 0,
                SavingCardLargessFixedCommission:
                  parseFloat(emp.SavingCardLargessFixedCommission) || 0,
                SpecialBenefitCommission:
                  parseFloat(emp.SpecialBenefitCommission) || 0,
              });
            });
          });
        });
        PackageCard.Project.forEach((card) => {
          card.SaleBillHandler.forEach((hand) => {
            hand.Employee.forEach((emp) => {
              params.PackageCardEmployee.ProjectEmployee.push({
                SaleBillPackageCardID: PackageCard.SaleBillPackageCardID,
                PackageCardID: PackageCard.PackageCardID,
                SaleBillPackageCardProjectID: card.SaleBillPackageCardProjectID,
                ProjectID: card.ProjectID,
                SaleHandlerID: hand.SaleHandlerID,
                EmployeeID: emp.EmployeeID,
                Scale: emp.Scale,
                PayPerformance: parseFloat(emp.PayPerformance) || 0,
                PayRateCommission: parseFloat(emp.PayRateCommission) || 0,
                PayFixedCommission: parseFloat(emp.PayFixedCommission) || 0,
                SavingCardPerformance:
                  parseFloat(emp.SavingCardPerformance) || 0,
                SavingCardRateCommission:
                  parseFloat(emp.SavingCardRateCommission) || 0,
                SavingCardFixedCommission:
                  parseFloat(emp.SavingCardFixedCommission) || 0,
                SavingCardLargessPerformance:
                  parseFloat(emp.SavingCardLargessPerformance) || 0,
                SavingCardLargessRateCommission:
                  parseFloat(emp.SavingCardLargessRateCommission) || 0,
                SavingCardLargessFixedCommission:
                  parseFloat(emp.SavingCardLargessFixedCommission) || 0,
                SpecialBenefitCommission:
                  parseFloat(emp.SpecialBenefitCommission) || 0,
              });
            });
          });
        });
        PackageCard.SavingCard.forEach((card) => {
          card.SaleBillHandler.forEach((hand) => {
            hand.Employee.forEach((emp) => {
              params.PackageCardEmployee.SavingCardEmployee.push({
                SaleBillPackageCardID: PackageCard.SaleBillPackageCardID,
                PackageCardID: PackageCard.PackageCardID,
                SaleBillPackageCardSavingCardID:
                  card.SaleBillPackageCardSavingCardID,
                SavingCardID: card.SavingCardID,
                SaleHandlerID: hand.SaleHandlerID,
                EmployeeID: emp.EmployeeID,
                Scale: emp.Scale,
                PayPerformance: parseFloat(emp.PayPerformance) || 0,
                PayRateCommission: parseFloat(emp.PayRateCommission) || 0,
                PayFixedCommission: parseFloat(emp.PayFixedCommission) || 0,
                SpecialBenefitCommission:
                  parseFloat(emp.SpecialBenefitCommission) || 0,
              });
            });
          });
        });
        PackageCard.GeneralCard.forEach((card) => {
          card.SaleBillHandler.forEach((hand) => {
            hand.Employee.forEach((emp) => {
              params.PackageCardEmployee.GeneralCardEmployee.push({
                SaleBillPackageCardID: PackageCard.SaleBillPackageCardID,
                PackageCardID: PackageCard.PackageCardID,
                SaleBillPackageCardGeneralCardID:
                  card.SaleBillPackageCardGeneralCardID,
                GeneralCardID: card.GeneralCardID,
                SaleHandlerID: hand.SaleHandlerID,
                EmployeeID: emp.EmployeeID,
                Scale: emp.Scale,
                PayPerformance: parseFloat(emp.PayPerformance) || 0,
                PayRateCommission: parseFloat(emp.PayRateCommission) || 0,
                PayFixedCommission: parseFloat(emp.PayFixedCommission) || 0,
                SavingCardPerformance:
                  parseFloat(emp.SavingCardPerformance) || 0,
                SavingCardRateCommission:
                  parseFloat(emp.SavingCardRateCommission) || 0,
                SavingCardFixedCommission:
                  parseFloat(emp.SavingCardFixedCommission) || 0,
                SavingCardLargessPerformance:
                  parseFloat(emp.SavingCardLargessPerformance) || 0,
                SavingCardLargessRateCommission:
                  parseFloat(emp.SavingCardLargessRateCommission) || 0,
                SavingCardLargessFixedCommission:
                  parseFloat(emp.SavingCardLargessFixedCommission) || 0,
                SpecialBenefitCommission:
                  parseFloat(emp.SpecialBenefitCommission) || 0,
              });
            });
          });
        });
        PackageCard.TimeCard.forEach((card) => {
          card.SaleBillHandler.forEach((hand) => {
            hand.Employee.forEach((emp) => {
              params.PackageCardEmployee.TimeCardEmployee.push({
                SaleBillPackageCardID: PackageCard.SaleBillPackageCardID,
                PackageCardID: PackageCard.PackageCardID,
                SaleBillPackageCardTimeCardID:
                  card.SaleBillPackageCardTimeCardID,
                TimeCardID: card.TimeCardID,
                SaleHandlerID: hand.SaleHandlerID,
                EmployeeID: emp.EmployeeID,
                Scale: emp.Scale,
                PayPerformance: parseFloat(emp.PayPerformance) || 0,
                PayRateCommission: parseFloat(emp.PayRateCommission) || 0,
                PayFixedCommission: parseFloat(emp.PayFixedCommission) || 0,
                SavingCardPerformance:
                  parseFloat(emp.SavingCardPerformance) || 0,
                SavingCardRateCommission:
                  parseFloat(emp.SavingCardRateCommission) || 0,
                SavingCardFixedCommission:
                  parseFloat(emp.SavingCardFixedCommission) || 0,
                SavingCardLargessPerformance:
                  parseFloat(emp.SavingCardLargessPerformance) || 0,
                SavingCardLargessRateCommission:
                  parseFloat(emp.SavingCardLargessRateCommission) || 0,
                SavingCardLargessFixedCommission:
                  parseFloat(emp.SavingCardLargessFixedCommission) || 0,
                SpecialBenefitCommission:
                  parseFloat(emp.SpecialBenefitCommission) || 0,
              });
            });
          });
        });
      });
      API.updateEmployeePerformanceCommission(params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success({
            message: "修改成功",
            duration: 2000,
          });
          that.changePerformance = false;
          that.dialogVisible = false;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    //推存量
    //消耗订单列表
    getTreatStockList() {
      var that = this;
      that.loading = true;
      if (
        that.searchData.createTime == null ||
        that.searchData.createTime == ""
      ) {
        that.StartDate = "";
        that.EndDate = "";
      } else {
        that.StartDate = that.searchData.createTime[0];
        that.EndDate = that.searchData.createTime[1];
      }
      var params = {
        PageNum: that.paginations.page,
        CustomerID: that.customerID,
        ID: that.searchData.SaleBillID,
        StartDate: that.StartDate,
        EndDate: that.EndDate,
        ApprovalStatus: that.searchData.BillStatus,
      };
      APIcustomerBill.getTreatStockList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.refoundList = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //审批弹窗
    ApprovalDialog() {
      var that = this;
      that.ApprovalRemark = "";
      that.dialogBill = true;
    },
    //审核确认
    submitSave() {
      var that = this;
      that.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          var params = {
            ID: that.refoundInfo.ID,
            ApprovalStatus: that.dialogParams.ApprovalStatus,
            ApprovalRemark: that.dialogParams.ApprovalRemark,
          };
          APIrefundStock.approval(params).then((res) => {
            if (res.StateCode == 200) {
              that.$message.success({
                message: res.Message,
                duration: 2000,
              });
              that.dialogBill = false;
              that.dialogVisible = false;
              that.handleSearch();
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    /**详情 数据    */
    showEditDialog: function (row) {
      var that = this;
      let params = {
        ID: row.ID,
      };
      APIrefundStock.getRefundBillInfo(params)
        .then((res) => {
          if (res.StateCode == 200) {
            if (res.Data.ApprovalStatus == "20") {
              res.Data.ApprovalStatusName = "待审批";
            } else if (res.Data.ApprovalStatus == "30") {
              res.Data.ApprovalStatusName = "已同意";
            } else if (res.Data.ApprovalStatus == "40") {
              res.Data.ApprovalStatusName = "已拒绝";
            } else {
              res.Data.ApprovalStatusName = "已撤销";
            }
            that.refoundInfo = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
      that.dialogVisible = true;
    },

    //撤销申请
    CancelOrder() {
      var that = this;
      var params = {
        ID: that.refoundInfo.ID,
      };
      APIrefundStock.cancel(params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success({
            message: res.Message,
            duration: 2000,
          });
          that.cancelOrder = false;
          that.dialogVisible = false;
          that.handleSearch();
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    //退消耗
    getTreatRefundBillList() {
      var that = this;
      that.loading = true;
      if (
        that.searchData.createTime == null ||
        that.searchData.createTime == ""
      ) {
        that.StartDate = "";
        that.EndDate = "";
      } else {
        that.StartDate = that.searchData.createTime[0];
        that.EndDate = that.searchData.createTime[1];
      }
      var params = {
        PageNum: that.paginations.page,
        CustomerID: that.customerID,
        ID: that.searchData.SaleBillID,
        StartDate: that.StartDate,
        EndDate: that.EndDate,
        ApprovalStatus: that.searchData.BillStatus,
      };
      APIcustomerBill.getTreatRefundBillList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.TreatList = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //详情
    // TreatBillInfo(row) {
    //   var that = this;
    //   that.loading = true;
    //   that.TreatBillApplyID = row.ID;
    //   var params = {
    //     ID: row.ID,
    //   };
    //   APIrefundTreatBillApply.getTreatApplyDetail(params)
    //     .then((res) => {
    //       if (res.StateCode == 200) {
    //         that.ApplyBillDetail = res.Data;
    //         that.TreatBillVisible = true;
    //         if (
    //           that.ApplyBillDetail.ApprovalStatus == 20 ||
    //           that.ApplyBillDetail.ApprovalStatus == 50
    //         ) {
    //           that.IsShow = false;
    //         } else {
    //           that.IsShow = true;
    //         }
    //         if (that.ApplyBillDetail.ApprovalStatus == 20) {
    //           that.IsCheck = true;
    //         } else {
    //           that.IsCheck = false;
    //         }
    //       } else {
    //         that.$message.error({
    //           message: res.Message,
    //           duration: 2000,
    //         });
    //       }
    //     })
    //     .finally(function () {
    //       that.loading = false;
    //     });
    // },
    //撤销申请

    CancelTreatBillApply() {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.TreatBillApplyID,
      };
      APIrefundTreatBillApply.refundTreatBillrevoke(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "撤销申请成功",
              duration: 2000,
            });
            that.searchBillList();
            that.withdrawDialog = false;
            that.TreatBillVisible = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    confrimWithdrawApplyClick() {
      this.withdrawDialog = true;
    },

    // 转账列表
    getTranserList() {
      var that = this;
      that.loading = true;
      if (
        that.searchData.createTime == null ||
        that.searchData.createTime == ""
      ) {
        that.StartDate = "";
        that.EndDate = "";
      } else {
        that.StartDate = that.searchData.createTime[0];
        that.EndDate = that.searchData.createTime[1];
      }
      var params = {
        PageNum: that.paginations.page,
        CustomerID: that.customerID,
        StartDate: that.StartDate,
        EndDate: that.EndDate,
        TranserBillID: that.searchData.SaleBillID,
      };
      APIcustomerBill.getTranserList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.TransferList = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    transerInfo(row) {
      var that = this;
      that.ID = row.ID;
      var params = {
        ID: row.ID,
      };
      APItransfer.transerInfo(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.transDetail = res.Data;
            that.applyPostponeDetailVisible = true;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    //延期记录
    getPostponeList() {
      var that = this;
      that.loading = true;
      if (
        that.searchData.createTime == null ||
        that.searchData.createTime == ""
      ) {
        that.StartDate = "";
        that.EndDate = "";
      } else {
        that.StartDate = that.searchData.createTime[0];
        that.EndDate = that.searchData.createTime[1];
      }
      var params = {
        PageNum: that.paginations.page,
        CustomerID: that.customerID,
        StartDate: that.StartDate,
        EndDate: that.EndDate,
        PostponeBillID: that.searchData.SaleBillID,
        ApprovalStatus: that.searchData.BillStatus,
      };
      APIcustomerBill.getPostponeList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.PostponeBillList = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    changeApprove(e) {
      this.ApproveApprovalStatus = e;
    },
    withdrawApplyClick() {
      var that = this;
      that.Approve = 50;
      that.ApprovalRemark = "";
      that.approvalVisible = true;
    },

    showApplyDetailDialog(row) {
      var that = this;
      that.ID = row.ID;
      var params = {
        ID: that.ID,
      };
      APIPostponeApproval.PostponeBillDeatail(params).then((res) => {
        if (res.StateCode == 200) {
          that.ApplyDetail = res.Data;
          if (
            that.ApplyDetail.ApprovalStatus == 10 ||
            that.ApplyDetail.ApprovalStatus == 20 ||
            that.ApplyDetail.ApprovalStatus == 50
          ) {
            that.IsShow = false;
          } else {
            that.IsShow = true;
          }
          if (that.ApplyDetail.ApprovalStatus == 10) {
            that.IsDraft = true;
            that.IsApprove = false;
          } else if (that.ApplyDetail.ApprovalStatus == 20) {
            that.IsApprove = true;
          } else {
            that.IsApprove = false;
            that.IsDraft = false;
          }
          that.applyDetailVisibles = true;
        }
      });
    },

    confrimWithdrawApplyClick() {
      var that = this;
      var params = {
        ID: that.ID,
        ApprovalStatus: that.ApproveApprovalStatus,
        ApprovalRemark: that.ApprovalRemark,
      };
      APIPostponeApproval.ExtendPostponeBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "提交成功",
              duration: 2000,
              customClass: "zZindex",
            });
            that.applyDetailVisibles = false;
            that.approvalVisible = false;
            that.PostponeBillApprovalList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    clearSearchData() {
      var that = this;
      that.searchData = {
        createTime: "",
        EndDate: "",
        StartDate: "",
        BillType: "",
        Name: "",
        SaleBillID: "",
        BillStatus: "",
      };
    },
    /** 清除数据请求 */
    clearNetWorkData() {
      let that = this;
      that.saleOrderList = [];
      that.consumeList = [];
      that.TransferList = [];
      that.PostponeBillList = [];
    },
  },

  mounted() {
    var that = this;
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
  },
};
</script>

<style lang="scss">
.customerBillClass {
  .customerBillScrollbar {
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  
  .customBillInfo {
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
    
  }
  .handlerInfoClass {
    .el-form-item__label {
      font-size: 12px !important;
      line-height: 18px;
      color: #c0c4cc;
    }
    .el-form-item__content {
      font-size: 12px !important;
      line-height: 20px;
      color: #c0c4cc;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .employee_num {
    width: 90px;
    .el-input-group__append {
      padding: 0 10px;
    }
  }
  .payInfo {
    .el-card__header {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .el-card__body {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .row_header {
    background-color: #ecf8ff;
    padding: 10px;
    color: #303133;
  }
  .tipback_col {
    background-color: #ecf8ff;
  }
  .el-form-item {
    margin-bottom: 0px;
  }
}

.CustomerBill {
  .col_line_height {
    line-height: 35px;
  }

  .custB_row_header {
    background-color: #ecf8ff;
    padding: 10px;
    color: #303133;
  }

  .row_Detalheader {
    background-color: #ecf8ff;
    padding: 5px 10px;
  }
}
</style>